import React from 'react';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import apiIcon from '../../assets/images/icons/habitats/apiIcon.svg' 

const HabitatApiBox = ({
  apiGateways,
  habitatSetUp,
  handleSelectFeature
}) => {
  return (
    <>
      {habitatSetUp === 'APIs' ? (
        <div className={'apis-selected box-feature'}>
          <div className="box-feature-name">
            <div className="box-feature-name-icon-apis">
                <img src={apiIcon} />
            </div>
            <p>APIs</p>
          </div>

          <div className="box-feature-plus-1">
            <p>{apiGateways.length}</p>
          </div>
        </div>
      ) : (
        <div className="box-feature" onClick={() => handleSelectFeature('APIs')}>
          <div className="box-feature-name">
            <div className="box-feature-name-icon-apis">
                <img src={apiIcon} />
            </div>
            <p>APIs</p>
          </div>

          <div className="box-feature-plus-1">
            <p>{apiGateways.length}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default HabitatApiBox;