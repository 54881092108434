import {
  CLONE_PULL_BEGIN,
  CLONE_PULL_SUCCESS,
  CLONE_PULL_ERROR,
  CLONE_CHECKOUT_BEGIN,
  CLONE_CHECKOUT_SUCCESS,
  CLONE_CHECKOUT_ERROR,
  GET_BRANCHES_BEGIN,
  GET_BRANCHES_SUCCESS,
  GET_BRANCHES_ERROR,
  CLONE_GIT_BEGIN,
  CLONE_GIT_SUCCESS,
  CLONE_GIT_ERROR,
  GET_CLONE_GIT_BEGIN,
  GET_CLONE_GIT_SUCCESS,
  GET_CLONE_GIT_ERROR,
  DELETE_CLONE_GIT_BEGIN,
  DELETE_CLONE_GIT_SUCCESS,
  DELETE_CLONE_GIT_ERROR,
  NEW_TEMPLATE_BEGIN,
  NEW_TEMPLATE_SUCCESS,
  NEW_TEMPLATE_ERROR,
  DELETE_FILETREE_BEGIN,
  DELETE_FILETREE_SUCCESS,
  DELETE_FILETREE_ERROR,
  FILE_OPEN_BEGIN,
  FILE_OPEN_SUCCESS,
  FILE_OPEN_ERROR,
  ZIP_FILE_BEGIN,
  ZIP_FILE_SUCCESS,
  ZIP_FILE_ERROR,
  GIT_REMOTE_BEGIN,
  GIT_REMOTE_SUCCESS,
  GIT_REMOTE_ERROR,
} from './types';
import { getFileSystem, openFile, zipFile } from '../api/fileSystem';
import {
  gitClone,
  gitRemote,
  getBranches,
  gitClonePull,
  gitClonesList,
  gitCreateHook,
  gitCloneDelete,
  gitCloneCheckout,
} from '../api/git/gitClone';

export const getFileTree = (teamId) => {
  return (dispatch) => {
    dispatch({
      type: NEW_TEMPLATE_BEGIN,
      loading_message: 'Loading repositories...',
    });
    getFileSystem(teamId)
      .then((response) => {
        const jsonTree = response.data.childrens;
        dispatch({
          type: NEW_TEMPLATE_SUCCESS,
          payload: jsonTree,
        });
      })
      .catch((error) =>
        dispatch({
          type: NEW_TEMPLATE_ERROR,
          errorGetTree: 'Showing repository fail',
          error,
        })
      );
  };
};

export const removeFileTree = (id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_FILETREE_BEGIN,
      loading_message: 'Deleting Clone...',
    });
    try {
      dispatch({
        type: DELETE_FILETREE_SUCCESS,
        payload: id,
      });
    } catch (error) {
      dispatch({
        type: DELETE_FILETREE_ERROR,
        error,
      });
    }
  };
};

export const getFile = (directory) => {
  return (dispatch) => {
    dispatch({
      type: FILE_OPEN_BEGIN,
      loading_message: 'Getting File...',
    });
    const data = {
      directory,
    };
    openFile(data)
      .then((response) => {
        dispatch({
          type: FILE_OPEN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => dispatch({ type: FILE_OPEN_ERROR, error }));
  };
};

export const zipClone = (funcName, teamId) => {
  return (dispatch) => {
    dispatch({
      type: ZIP_FILE_BEGIN,
      loading_message: 'Zip Cloning...',
    });
    const data = {
      file_name: funcName,
      team_id: teamId,
    };
    zipFile(data)
      .then((response) => {
        dispatch({
          type: ZIP_FILE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => dispatch({ type: ZIP_FILE_ERROR, error }));
  };
};

export const cloneGit = (repoUrl, projectName, gitCred, teamId) => {
  return (dispatch) => {
    dispatch({
      type: CLONE_GIT_BEGIN,
      loading_message: 'Cloning Git...',
    });
    const data = {
      uri: repoUrl,
      project_name: projectName.replace(/[^A-Z0-9]/gi, '-'),
      team_id: teamId,
      git_credential_id: gitCred,
    };
    gitClone(data)
      .then((response) => {
        const payload = response.data;
        dispatch({
          type: CLONE_GIT_SUCCESS,
          payload,
        });
      })
      .catch((error) =>
        dispatch({
          type: CLONE_GIT_ERROR,
          errorCloneGit: 'Error cloning repository',
          error,
        })
      );
  };
};

export const cloneGitWithHooks = (repoUrl, projectName, git_credential_id, teamId) => {
  return (dispatch) => {
    dispatch({
      type: CLONE_GIT_BEGIN,
      loading_message: 'Cloning Git...',
    });
    const data = {
      uri: repoUrl,
      project_name: projectName.replace(/[^A-Z0-9]/gi, '-'),
      team_id: teamId,
      git_credential_id,
    };
    gitClone(data)
      .then((response) => {
        const clone_id = response.data.repo.id;
        const payload = response.data;
        const funcHook = {
          git_credential_id,
          clone_id
        }
        gitCreateHook(funcHook)
        .then(response => {
          dispatch({
            type: CLONE_GIT_SUCCESS,
            payload,
          });
        })
        .catch((error) =>
          dispatch({
            type: CLONE_GIT_ERROR,
            errorCloneGit: 'Error cloning repository',
            error,
          })
        );
      })
      .catch((error) =>
        dispatch({
          type: CLONE_GIT_ERROR,
          errorCloneGit: 'Error cloning repository',
          error,
        })
      );
  };
};


export const getClones = (team) => {
  return (dispatch) => {
    dispatch({
      type: GET_CLONE_GIT_BEGIN,
      loading_message: 'Loading clones...',
    });
    gitClonesList(team.id)
      .then((response) => {
        dispatch({
          type: GET_CLONE_GIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => dispatch({ type: GET_CLONE_GIT_ERROR, error }));
  };
};

export const removeClone = (clone_id, directoryName) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_CLONE_GIT_BEGIN,
      loading_message: 'Deleting clone...',
    });
    gitCloneDelete(clone_id, directoryName)
      .then((response) => {
        dispatch({
          type: DELETE_CLONE_GIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: DELETE_CLONE_GIT_ERROR,
          errorRemoveClone: 'Error deleting repository',
          error,
        })
      );
  };
};

export const pullClone = (clone) => {
  let data;
  if (!clone.git_credential_id) {
    data = {
      id: clone.id,
      directory_name: clone.directory_name,
      uri: clone.uri,
      git_credential_id: '',
    };
  } else {
    data = {
      id: clone.id,
      directory_name: clone.directory_name,
      uri: clone.uri,
      git_credential_id: clone.git_credential_id,
    };
  }
  return (dispatch) => {
    dispatch({
      type: CLONE_PULL_BEGIN,
      loading_message: 'Loading pull clone...',
    });
    gitClonePull(data)
      .then((response) => {
        const payload = response.data;
        dispatch({
          type: CLONE_PULL_SUCCESS,
          payload,
        });
      })
      .catch((error) =>
        dispatch({
          type: CLONE_PULL_ERROR,
          errorPullClone: 'Pull git fail',
          error,
        })
      );
  };
};

export const cloneBranches = (clone) => {
  let data;
  if (!clone.git_credential_id) {
    data = {
      uri: clone.uri,
      git_credential_id: '',
    };
  } else {
    data = {
      uri: clone.uri,
      git_credential_id: clone.git_credential_id,
    };
  }
  const payload = [];

  return (dispatch) => {
    dispatch({
      type: GET_BRANCHES_BEGIN,
      loading_message: 'Cloning branches...',
    });
    getBranches(data)
      .then((response) => {
        const branches = Object.entries(response.data.branches);
        branches.forEach((branch) => {
          payload.push({
            name: branch[0],
            sha: branch[1].sha,
          });
        });
        dispatch({
          type: GET_BRANCHES_SUCCESS,
          payload,
        });
      })
      .catch((error) => dispatch({ type: GET_BRANCHES_ERROR, error }));
  };
};

export const cloneCheckout = (clone) => {
  let data;
  if (!clone.git_credential_id) {
    data = {
      id: clone.id,
      directory_name: clone.directory_name,
      uri: clone.uri,
      git_branch: clone.git_branch,
      git_credential_id: '',
    };
  } else {
    data = {
      id: clone.id,
      directory_name: clone.directory_name,
      uri: clone.uri,
      git_branch: clone.git_branch,
      git_credential_id: clone.git_credential_id,
    };
  }
  return (dispatch) => {
    dispatch({
      type: CLONE_CHECKOUT_BEGIN,
      loading_message: 'Loading Clone checkout...',
    });
    gitCloneCheckout(data)
      .then((response) => {
        const payload = response.data;
        dispatch({
          type: CLONE_CHECKOUT_SUCCESS,
          payload,
        });
      })
      .catch((error) => dispatch({ type: CLONE_CHECKOUT_ERROR, error }));
  };
};

export const remoteGit = (id, git_credential_id, uri) => {
  const data = {
    id,
    git_credential_id,
    uri,
  };
  return (dispatch) => {
    dispatch({
      type: GIT_REMOTE_BEGIN,
      loading_message: 'Loading remote git...',
    });
    gitRemote(data)
      .then((response) => {
        dispatch({
          type: GIT_REMOTE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: GIT_REMOTE_ERROR,
          errorRemoteGit: 'Change git remote fail',
          error,
        })
      );
  };
};
