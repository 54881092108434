import produce from 'immer';

import {
    TEST_APIGATEWAY_BEGIN,
    TEST_APIGATEWAY_SUCCESS,
    TEST_APIGATEWAY_ERROR,
    CREATE_APIGATEWAY_BEGIN,
    CREATE_APIGATEWAY_SUCCESS,
    CREATE_APIGATEWAY_ERROR,
    CREATE_METHOD_BEGIN,
    CREATE_METHOD_SUCCESS,
    CREATE_METHOD_ERROR,
    GET_METHOD_BEGIN,
    GET_METHOD_SUCCESS,
    GET_METHOD_ERROR,
    DELETE_METHOD_BEGIN,
    DELETE_METHOD_SUCCESS,
    DELETE_METHOD_ERROR,
    GET_RESOURCE_BEGIN,
    GET_RESOURCE_SUCCESS,
    GET_RESOURCE_ERROR,
    GET_APIGATEWAY_BEGIN, 
    GET_APIGATEWAY_SUCCESS, 
    GET_APIGATEWAY_ERROR,
    DELETE_APIGATEWAY_BEGIN,
    DELETE_APIGATEWAY_SUCCESS,
    DELETE_APIGATEWAY_ERROR,
    CREATE_CRUD_API_BEGIN,
    CREATE_CRUD_API_SUCCESS,
    CREATE_CRUD_API_ERROR,
    GET_APIGATEWAY_METRICS_BEGIN,
    GET_APIGATEWAY_METRICS_SUCCESS,
    GET_APIGATEWAY_METRICS_ERROR,
    GET_CLOUDFRONT_METRICS_BEGIN,
    GET_CLOUDFRONT_METRICS_SUCCESS,
    GET_CLOUDFRONT_METRICS_ERROR
} from '../actions/types';

const initialState = {
    resourceError: null,
    methodError: null,
    apiGateways: [],
    apiMetrics: null,
    frontMetrics: null,
    resources: null,
    loader: false,
    loading_message: '',
    testApi: null,
    methods: null,
    error: null
}

const reducer = produce((draft, action) => {
    switch (action.type) {
        case DELETE_METHOD_BEGIN:
        case TEST_APIGATEWAY_BEGIN:
        case CREATE_APIGATEWAY_BEGIN:
        case GET_APIGATEWAY_BEGIN:
        case CREATE_CRUD_API_BEGIN:
        case DELETE_APIGATEWAY_BEGIN:
        case GET_APIGATEWAY_METRICS_BEGIN:
        case GET_CLOUDFRONT_METRICS_BEGIN:
            draft.loading = true;
            draft.error = null;
            draft.loading_message = action.loading_message;
            return;
        case GET_RESOURCE_BEGIN:
            draft.loading = true;
            draft.resourceError = null;
            draft.loading_message = action.loading_message;
            return;
        case CREATE_METHOD_BEGIN:
        case GET_METHOD_BEGIN:
            draft.loading = true;
            draft.error = null;
            draft.testApi = null;
            draft.loading_message = action.loading_message;
            return;
        case DELETE_METHOD_SUCCESS:
            draft.loading = false;
            draft.error = null;
            draft.methods = action.payload;
            draft.loading_message = action.loading_message;
            return;
        case TEST_APIGATEWAY_SUCCESS:
            draft.loading = false;
            draft.testApi = action.payload;
            draft.error =  null;
            return;
        case CREATE_APIGATEWAY_SUCCESS:
            draft.loading = false;
            draft.apiGateways = action.payload;
            draft.loading = false;
            return;
        case GET_APIGATEWAY_SUCCESS:
        case CREATE_CRUD_API_SUCCESS:
            draft.apiGateways = action.payload;
            draft.loading = false;
            draft.error = null;
            return;
        case DELETE_APIGATEWAY_SUCCESS:
            draft.apiGateways = action.payload;
            draft.loading = false;
            draft.error = null;
            return;
        case CREATE_METHOD_SUCCESS:
        case GET_METHOD_SUCCESS:
            draft.methods = action.payload;
            draft.loading = false;
            draft.methodError = null;
            return;
        case GET_RESOURCE_SUCCESS:
            draft.resources = action.payload;
            draft.loading = false;
            draft.resourceError = null;
            return;
        case GET_APIGATEWAY_METRICS_SUCCESS:
            draft.loading = false;
            draft.apiMetrics = action.payload;
            draft.error = null;
            return;
        case GET_CLOUDFRONT_METRICS_SUCCESS:
            draft.loading = false;
            draft.frontMetrics = action.payload;
            draft.error = null;
            return;
        case CREATE_APIGATEWAY_ERROR:
            draft.loading = false;
            draft.error = action.errorNewApi;
            return; 
        case GET_APIGATEWAY_ERROR:
            draft.loading = false;
            draft.error = action.errorGetApi;
            return; 
        case DELETE_APIGATEWAY_ERROR:
            draft.loading = false;
            draft.error = action.errorDeleteApi;
            return;
        case CREATE_CRUD_API_ERROR:
            draft.loading = false;
            draft.error = action.error;
            return;
        case GET_CLOUDFRONT_METRICS_ERROR:
        case GET_APIGATEWAY_METRICS_ERROR:
        case TEST_APIGATEWAY_ERROR:
            draft.loading = false;
            draft.error = action.error;
            return;
        case DELETE_METHOD_ERROR:
        case CREATE_METHOD_ERROR:
        case GET_METHOD_ERROR:
            draft.loading = false;
            draft.methodError = action.error;
            return;
        case GET_RESOURCE_ERROR:
            draft.loading = false;
            draft.resourceError = action.error;
            return; 
        default:
            return;
    }
}, initialState)

export default reducer;