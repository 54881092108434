import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spinner, Button, Row, Col } from 'react-bootstrap';
import '../assets/styles/pages/Habitats.scss';
import { getTests, saveTest } from '../actions/funcs.actions';
import { listAWSS3 } from '../actions/aws-s3.actions';
import { listFunctions } from '../actions/funcs.actions';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { listAWSCreds } from '../actions/aws-creds.actions';
import { fetchAWSRole } from '../actions/aws-roles.actions';
import { listCognito } from '../actions/aws-cognito.actions';
import { retrieveHabitat } from '../actions/habitat.actions';
import { getApiList } from '../actions/aws-apigateway.actions';
import '../assets/styles/containers/HabitatAPIGatewayPage.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HabitatAPIList from '../components/Habitats/HabitatAPIList';
import HabitatTopMenu from '../components/Habitats/HabitatTopMenu';
import APIGatewayModal from '../components/Modals/APIGatewayModal';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import HabitatDynamoAPI from '../components/Habitats/HabitatDynamoAPI';
import HabitatAPIGateway from '../components/Habitats/HabitatAPIGateway';
import {
  apiTest,
  createAPI,
  deleteApi,
  createRoute,
  deleteMethod,
  getApiMetrics,
  getApiMethods,
  createCrudApi,
  createCognitoAPI,
  createCognitoCrudApi,
} from '../actions/aws-apigateway.actions';

const HabitatAPIGatewayPage = ({
  team,
  funcs,
  error,
  params,
  habitat,
  apiTest,
  testApi,
  methods,
  loading,
  awsCreds,
  awsRoles,
  cognitos,
  getTests,
  funcTest,
  saveTest,
  deleteApi,
  createAPI,
  s3Buckets,
  listAWSS3,
  getApiList,
  apiMetrics,
  funcLoading,
  createRoute,
  methodError,
  apiGateways,
  listCognito,
  listAWSCreds,
  deleteMethod,
  fetchAWSRole,
  getApiMetrics,
  getApiMethods,
  createCrudApi,
  listFunctions,
  loadingMessage,
  retrieveHabitat,
  createCognitoAPI,
  loadingFuncMessage,
  createCognitoCrudApi
}) => {

  useEffect(() => {
    if (habitat.id) {
      listAWSS3(habitat.id);
      getApiList(habitat.id);
      listCognito(habitat.id);
      listAWSCreds(habitat.id);
      fetchAWSRole(habitat.id);
      listFunctions(habitat.id);
    } else {
      listAWSS3(params.habitatId);
      getApiList(params.habitatId);
      listCognito(params.habitatId);
      listAWSCreds(params.habitatId);
      fetchAWSRole(params.habitatId);
      listFunctions(params.habitatId);
      retrieveHabitat(params.habitatId);
    }
  }, []);

  const [show, setShow] = useState(false);
  const [apiId, setApiId] = useState('');
  const [modalType, setModalType] = useState(null);
  const [selectedAPI, setSelectedAPI] = useState({});

  const handlerNew = () => {
    setModalType('new');
    setShow(true);
  }

  const handlerInfo = (api) => {
    getApiMethods(api.id, habitat.id);
    setSelectedAPI(api);
    setModalType('info');
    setShow(true);
  }

  return (
    <>
      <div className="habitat-ctn">

        {loading && (
          <div className="spinner-container repository-spinner ml-5">
            <Spinner animation="border" variant="primary" />
            <p>{loadingMessage}</p>
          </div>
        )}

        {error && (
          <div className="error-display text-danger ml-5">
            <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {error}</p>
          </div>
        )}

        {apiGateways.map(api => (
          <HabitatAPIList
            api={api}
            key={api.id}
            handlerInfo={handlerInfo}
          />
        ))}
      </div>

      <APIGatewayModal
        show={show}
        team={team}
        funcs={funcs}
        apiTest={apiTest}
        testApi={testApi}
        methods={methods}
        setShow={setShow}
        habitat={habitat}
        awsCreds={awsCreds}
        cognitos={cognitos}
        awsRoles={awsRoles}
        deleteApi={deleteApi}
        modalType={modalType}
        createAPI={createAPI}
        s3Buckets={s3Buckets}
        createRoute={createRoute}
        selectedAPI={selectedAPI}
        deleteMethod={deleteMethod}
        createCrudApi={createCrudApi}
        createCognitoAPI={createCognitoAPI}
        createCognitoCrudApi={createCognitoCrudApi}
      />
    </>
  );
}

const mapState = (state, props) => ({
  team: state.team.team,
  funcs: state.func.funcs,
  params: props.params,
  error: state.apiGateway.error,
  funcTest: state.func.funcTest,
  habitat: state.habitat.habitat,
  funcLoading: state.func.loading,
  dynamos: state.awsDynamo.dynamos,
  s3Buckets: state.awsS3.s3Buckets,
  testApi: state.apiGateway.testApi,
  awsRoles: state.awsRoles.awsRoles,
  loading: state.apiGateway.loading,
  methods: state.apiGateway.methods,
  cognitos: state.cognito.cognitoList,
  awsCreds: state.awsCreds.awsCredentials,
  apiMetrics: state.apiGateway.apiMetrics,
  methodError: state.apiGateway.methodError,
  apiGateways: state.apiGateway.apiGateways,
  loadingFuncMessage: state.func.loading_message,
  loadingMessage: state.apiGateway.loading_message
});

const mapDispatch = {
  apiTest,
  getTests,
  saveTest,
  listAWSS3,
  createAPI,
  deleteApi,
  getApiList,
  listCognito,
  createRoute,
  fetchAWSRole,
  deleteMethod,
  listAWSCreds,
  getApiMethods,
  getApiMetrics,
  listFunctions,
  createCrudApi,
  retrieveHabitat,
  createCognitoAPI,
  createCognitoCrudApi
}

export default connect(
  mapState,
  mapDispatch
)(HabitatAPIGatewayPage);