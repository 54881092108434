import Moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import closeIcon from '../../../assets/images/icons/close-icon.svg';
import sitesIcon from '../../../assets/images/icons/habitats/sitesIcon.svg';
import HabitatSitesDeploymentInfo from '../HabitatSitesDeploymentInfo';

const HabitatReleasesTemplatesModal = ({
    setShowModalTemplate,
    selectedSite,
    template,
    habitat,
    siteStatus,
    ownBuild,
    templates,
    showCode,
    code,
    setOwnBuild,
    handleCloudFrontStatus,
    showInvalidations,
    invalidationsArray,
    handleFetchInvalidations,
    invalidationItem,
    invalidations,
    setInvalidations,
    handleNewInvalidation,
    handleShowInvalidation,
    handleSiteStatus
}) => {
    return (
        <>
            <div className="modal-kor-small-right">
                <div className="box-modal-options-container-small-right">
                    <div className="close-title" onClick={() => setShowModalTemplate(false)}>
                        <img src={closeIcon} />
                        <h3>{template.name}</h3>
                    </div>

                    <div className="box-feature-name-container funcs-modal">
                        <div className="box-feature-name-icon">
                            <img src={sitesIcon} />
                        </div>
                        <div className="func-name-date-deploy">
                            <p className="name-func-box">
                                {template.name}
                            </p>
                            <p className="last-update-func">
                                <span>Last Update</span> {Moment(template.updated_at).format('lll')}
                            </p>
                            <div className="managed-by-repo">
                                <p className="managed-by-text">Managed</p>
                                <div className="avatar-repo-author">
                                    <div className="avatar-1">
                                        <p>U</p>
                                    </div>
                                    <div className="name-author">
                                        <p>Unknown</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <HabitatSitesDeploymentInfo
                        template={template}
                        habitat={habitat}
                        siteStatus={siteStatus}
                        ownBuild={ownBuild}
                        templates={templates}
                        showCode={showCode}
                        code={code}
                        setOwnBuild={setOwnBuild}
                        handleCloudFrontStatus={handleCloudFrontStatus}
                        showInvalidations={showInvalidations}
                        invalidationsArray={invalidationsArray}
                        handleFetchInvalidations={handleFetchInvalidations}
                        invalidationItem={invalidationItem}
                        invalidations={invalidations}
                        setInvalidations={setInvalidations}
                        handleNewInvalidation={handleNewInvalidation}
                        handleShowInvalidation={handleShowInvalidation}
                        handleSiteStatus={handleSiteStatus}
                    />
                </div>
            </div>
        </>
    )
}


const mapState = (state, props) => ({

});

const mapDispatch = {

}

export default connect(
    mapState,
    mapDispatch
)(HabitatReleasesTemplatesModal);