import React from 'react';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import databaseIcon from '../../assets/images/icons/habitats/databaseIcon.svg';

const HabitatDBBox = ({
  dynamos,
  habitatSetUp,
  handleSelectFeature
}) => {
  return (
    <>
      {habitatSetUp === 'Databases' ? (
        <div className={'databases-selected box-feature'}>
          <div className="box-feature-name">
            <div className="box-feature-name-icon-databases">
                <img src={databaseIcon} />
            </div>
            <p>Databases</p>
          </div>

          <div className="box-feature-plus-1">
            <p>{dynamos.length}</p>
          </div>
        </div>
      ) : (
        <div className="box-feature" onClick={() => handleSelectFeature('Databases')}>
          <div className="box-feature-name">
            <div className="box-feature-name-icon-databases">
                <img src={databaseIcon} />
            </div>
            <p>Databases</p>
          </div>

          <div className="box-feature-plus-1">
            <p>{dynamos.length}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default HabitatDBBox;