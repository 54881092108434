import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import databaseIcon from '../../assets/images/icons/habitats/databaseIcon.svg';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import trash from '../../assets/images/icons/trash.svg';
import Moment from 'moment';

const HabitatDynamos = (props) => {
    return (
        <>

            {props.loading && (
                <div className="spinner-container funcs-habitat-spinner">
                    <Spinner animation="border" variant="primary" />
                    <p>{props.loadingMessage}</p>
                </div>
            )}


            {props.dynamos.length === 0 ? (
                <div className="blank-section">No Dynamos added yet.</div>
            ) : (
                <div className="container-dynamos-box">
                    {props.dynamos.map(db => (
                        <div className="funcs-setup-box" key={db.id}>
                            <div className="box-feature-name-funcs">
                                <div className="box-feature-name-icon">
                                    <img src={databaseIcon} />
                                </div>
                                <div className="func-name-author">
                                    <p className="name-func-box">{db.table_name}</p>
                                    <p className="last-update-func"><span>Last Update</span> {Moment(db.created_at).format('lll')}</p>
                                    <div className="managed-by-repo">
                                        <p className="managed-by-text">Managed</p>
                                        <div className="avatar-repo-author">
                                            <div className="avatar-1">
                                                <p>U</p>
                                            </div>
                                            <div className="name-author">
                                                <p>Unknown</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="box-feature-item-button">
                                <Button onClick={() => props.handleRemoveDynamo(db)}>
                                    <img src={trash} />
                                    Remove
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    )
}

export default HabitatDynamos;