import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { createS3Bucket } from '../../actions/aws-s3.actions';

const HabitatNewS3Bucket = ({
    handleSelectFeatureModal,
    awsCreds,
    habitat,
    createS3Bucket,
    handleShowModal
}) => {
    const [s3BucketName, sets3BucketName] = useState('');

    const handleNewBucket = () => {
        const region = awsCreds.region
        createS3Bucket(s3BucketName, habitat.id, region);
        handleShowModal()
      };

    return (
        <div className="s3-bucket-modal-create">
            <div className="create-s3-input">
                <p>Bucket Name</p>
                <Form.Control
                    type="text"
                    aria-label="handler"
                    aria-describedby="handler"
                    value={s3BucketName}
                    onChange={e => sets3BucketName(e.target.value)}
                    placeholder="Bucket Name"
                />
            </div>

            <div className="name-s3-restrictions">
                <p>Please, read the following restrictions for your bucket name:</p>
                <p>*Bucket names must be between 3 and 63 characters long.
                <br />*Bucket names can consist only of lowercase letters, numbers, dots (.), and hyphens (-).
                <br />*Bucket names must begin and end with a letter or number.
                <br />*Bucket names must not be formatted as an IP address.
                <br />*Bucket names can&apos;t begin with xn-- (for buckets created after February 2020).
                <br />*Bucket names must be unique within a partition. A partition is a grouping of Regions.
                <br />*Buckets used with Amazon S3 Transfer Acceleration can&apos;t have dots (.) in their names.
                </p>
            </div>

            <div className="button-next-modal">
                <Button onClick={() => handleSelectFeatureModal('')}>
                    Back to resources
                </Button>
                <Button onClick={() =>  handleNewBucket()} disabled={!s3BucketName}>
                    Create
                </Button>
            </div>
        </div>
    )
}

const mapState = (state, props) => ({
    habitat: state.habitat.habitat,
    awsCreds: state.awsCreds.awsCredentials,
})

const mapDispatch = {
    createS3Bucket
}

export default connect(mapState, mapDispatch)(HabitatNewS3Bucket)