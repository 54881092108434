import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFileCode,
    faFill
} from '@fortawesome/free-solid-svg-icons';
import storageIcon from '../../assets/images/icons/habitats/storageIcon.svg'

const HabitatS3 = (props) => {
    return (
        <div>
            {props.habitatSetUp === 'File Storage' ? (
                <div className={'s3-selected box-feature'}>
                    <div className="box-feature-name">
                        <div className="box-feature-name-icon-s3">
                            <img src={storageIcon} />
                        </div>
                        <p>File Storage</p>
                    </div>

                    <div className="box-feature-plus-1">
                        <p>{props.awsS3.length}</p>
                    </div>
                </div>
            ) : (
                    <div className="box-feature" onClick={() => props.handleSelectFeature('File Storage')}>
                        <div className="box-feature-name">
                            <div className="box-feature-name-icon-s3">
                                <img src={storageIcon} />
                            </div>
                            <p>File Storage</p>
                        </div>

                        <div className="box-feature-plus-1">
                            <p>{props.awsS3.length}</p>
                        </div>
                    </div>
                )}
        </div>
    )
}

export default HabitatS3;