import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button'
import '../../assets/styles/modals/ModalKorSmallRight.scss';
import user from '../../assets/images/icons/UserCircle.svg';
import Moment from 'moment';

const RemoveHabitatModal = (props) => {
    const handleCloseModal = () => {
        props.setShowRemoveHabitat('display-none-kor');
    }

    return (
        <div className={props.showRemoveHabitat + ' modal-kor-container'} >

        {props.errorDeleteHabitatCode === 409 ? (
            <div className="modal-kor-small-right">
            <div className="box-modal-options-container-small-right  teams-modal delete--modal">

                <div className="close-title">
                    <h3>Confirm deletion of <span>{props.habitat.name}</span></h3>
                </div>

                <div className="delete-modal-container">
                    <div className="team-name-update">
                        <div className="avatar-team">
                            <img src={user} alt='logo'></img>
                        </div>
                        <div className="details-item">
                            <p className="name-item-delete">{props.habitat.name}</p>
                            <p className="last-updated-team">Last Updated <span>{Moment(props.habitat.created_at).format('lll')}</span></p>
                            <div className="owner-team">
                                <p>Managed</p>
                                <div>
                                    <p>
                                        A
                                    </p>
                                    <p>
                                        Author
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="verification-delete">
                        <p className="error-text-delete">You can&apos;t delete this habitat</p>
                        <div>
                            <p>Resource Name</p>
                            <p>{props.habitat.name}</p>
                        </div>
                        <div>
                            <p>Type</p>
                            <p>Habitat</p>
                        </div>

                        <p className="muted-text-2">You still have <b> {props.numResources} </b> resource(s) attached to this habitat, please address them before deletion.</p>
                    </div>
                </div>

                <div className="buttons-delete-item">
                    <Button onClick={handleCloseModal}>
                        Return
                     </Button>
                </div>

            </div>
        </div>
        ):(
        <div className="modal-kor-small-right">
        <div className="box-modal-options-container-small-right  teams-modal delete--modal">

            <div className="close-title">
                <h3>Confirm deletion of <span>{props.habitat.name}</span></h3>
            </div>

            <div className="delete-modal-container">
                <div className="team-name-update">
                    <div className="avatar-team">
                        <img src={user} alt='logo'></img>
                    </div>
                    <div className="details-item">
                        <p className="name-item-delete">{props.habitat.name}</p>
                        <p className="last-updated-team">Last Updated <span>{Moment(props.habitat.created_at).format('lll')}</span></p>
                        <div className="owner-team">
                            <p>Managed</p>
                            <div>
                                <p>
                                    A
                                </p>
                                <p>
                                    Author
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="verification-delete">
                    <p className="muted-text-1">Verify your information before delete the resource</p>
                    <div>
                        <p>Resource Name</p>
                        <p>{props.habitat.name}</p>
                    </div>
                    <div>
                        <p>Type</p>
                        <p>Habitat</p>
                    </div>  

                    <p className="muted-text-2">You can safely delete this habitat. Before we delete the resource, please verify all the information. </p>
                </div>
            </div>

            <div className="buttons-delete-item">
                <Button onClick={handleCloseModal}>
                    Return
                </Button>
                <Button onClick={() => props.handleRemoveHabitat(props.habitat.id)}>
                    Delete
                </Button>
            </div>

        </div>
    </div>
        )}


        </div>
    )
}

export default RemoveHabitatModal;