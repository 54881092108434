import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import HabitatListTests from './HabitatListTests';
import HabitatSaveTestModal from './modals/HabitatSaveTestModal';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HabitatAPITest = ({
  methods,
  habitat,
  apiTest,
  testApi,
  getTests,
  funcTest,
  saveTest,
  apiGateway,
  funcLoading,
  setShowTest,
  getApiMethods,
  loadingFuncMessage
}) => {

  useEffect(() => {
    getApiMethods(apiGateway.id, habitat.id);
  }, []);

  const [ show, setShow ]           = useState(false);
  const [ payload, setPayload ]     = useState('{}'); 
  const [ headers, setHeaders ]     = useState('{}');
  const [ lambdaID, setLambdaID ]   = useState('');
  const [ testName, setTestName ]   = useState('');
  const [ apiMethod, setApiMethod ] = useState(null);

  const handleToggle = () => {
    setShow(!show);
  }

  const handleSetTest = (test) => {
    setPayload(JSON.stringify(test.payload));
  }

  const handleSetMethod = (method) => {
    setApiMethod(JSON.parse(method).id);
    setLambdaID(JSON.parse(method).lambda_id);
  }

  const handleSaveTest = () => {
    saveTest(lambdaID, testName, payload);
    setShow(false);
  }

  return (
    <>

      <div className="mt-5">
        <p className="mb-3" onClick={() => setShowTest(false)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </p>
        <p className="font-weight-bold">Test Api {apiGateway.name}</p>
      </div>

      <div >
        <Form.Control
          as="select"
          defaultValue={'DEFAULT'}
          onChange={e => handleSetMethod(e.target.value)}
        >
          <option disabled value="DEFAULT">--Select a Method--</option>
          {methods && methods.map(method => (
            <option key={method.id} value={JSON.stringify(method)}>{method.method}</option>
          ))}
        </Form.Control>
        <Form.Label>Headers</Form.Label>
        <Form.Control 
          rows={3} 
          as="textarea"
          value={headers}
          onChange={e => setHeaders(e.target.value)} 
        />
        <Form.Label>Payload</Form.Label>
        <Form.Control 
          rows={3} 
          as="textarea"
          value={payload}
          onChange={e => setPayload(e.target.value)} 
        />
        <Form.Label>Response</Form.Label>
        <div className="d-flex justify-content-center bg-light p-5">
          {testApi && (
            <pre>
              { JSON.stringify(JSON.parse(testApi.body), null, 2) }
            </pre>
          )}
        </div>
      </div>

      <div className="button-container-aws d-flex justify-content-between mt-3">
        <Button
          size="sm"
          variant="primary"
          className="newCredBtn-1"
          onClick={e => apiTest(habitat.id, headers, payload, apiMethod, apiGateway.id)}
        >
          Invoke
        </Button>
        
        {lambdaID && (
          <Button
            size="sm"
            variant="outline-primary"
            className="newCredBtn-1"
            onClick={e => handleToggle()}
          >
            Save
          </Button>
        )}
      </div>

      {lambdaID && (
        <HabitatListTests 
          funcId={lambdaID}
          funcTest={funcTest}
          getTests={getTests}
          loading={funcLoading}
          handleSetTest={handleSetTest}
          loadingMessage={loadingFuncMessage}
        />
      )}

      {show && (
        <div className="modal-kor-container">
          <HabitatSaveTestModal 
            setShow={setShow}
            testName={testName}
            setTestName={setTestName}
            handleSaveTest={handleSaveTest}
          />
        </div>
      )}  

    </>
  );
}

export default HabitatAPITest;