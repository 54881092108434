import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Form } from 'react-bootstrap';
import enabledIcon from '../../assets/images/icons/habitats/enabledUser.svg';
import disabledIcon from '../../assets/images/icons/habitats/disabledUser.svg';

const HabitatAuthManagement = (props) => {
    return (
        <div>
            <div className="title-habitat-costs title-auth">
                <p>Management and actions</p>
            </div>

            {props.selectedUser ? (

                <div>
                    <div className="info-user-selected">
                        <div>
                            <p>User selected</p>
                            <p>{props.selectedUser.username}</p>
                        </div>

                        <div>
                            <p>Created on</p>
                            <p>{props.selectedUser.user_create_date}</p>
                        </div>

                        <div>
                            <p>Status</p>
                            {props.selectedUser.enabled ? (
                                <div className="img-disabled-icon">
                                    <img src={enabledIcon} /> 
                                    <p onClick={props.handleShowDisableModal}>Disable user</p>
                                </div>
                            ) : (
                                <div className="img-disabled-icon">
                                    <img src={disabledIcon} /> 
                                    <p  onClick={() => props.handleEnableUser(props.selectedCognito, props.habitat.id, props.selectedUser.username)}>Enable user</p>
                                </div>
                            )}
                        </div>

                        <div>
                            <p>Reset Password</p>
                            <div className="inputs-new-aws-credential">
                                <Form.Control
                                    type="password"
                                    aria-label="handler"
                                    placeholder="New Password"
                                    aria-describedby="handler"
                                    onChange={e => props.setResetPass(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="buttons-management">
                            <Button
                                onClick={() => props.setShowManagement(false)}
                            >Close</Button>
                            <Button 
                                onClick={() => props.handleResetPassword}
                                disabled={!props.resetPass}>
                                    Save Changes
                            </Button>
                    </div>
                </div>
            ) : (
                <div className="empty-section-auth-management">
                    <p>No user management selected yet.</p>
                </div>
            )}

            {props.messageDisable && (
                <div className="error-display text-success">
                    <p><FontAwesomeIcon icon={faCheckCircle} /> Done: {props.messageDisable}</p>
                </div>
            )}

            {props.resetMessage && (
                <div className="error-display text-success">
                    <p><FontAwesomeIcon icon={faCheckCircle} /> Done: {props.resetMessage}</p>
                </div>
            )}
        </div>
    )
}

export default HabitatAuthManagement;