import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import Moment from 'moment';
import { Controlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/keymap/sublime';
import 'codemirror/theme/base16-light.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCode, faEye, faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';

const HabitatSitesDeploymentInfo = ({
    template,
    habitat,
    siteStatus,
    ownBuild,
    templates,
    showCode,
    code,
    setOwnBuild,
    handleCloudFrontStatus,
    showInvalidations,
    invalidationsArray,
    handleFetchInvalidations,
    invalidationItem,
    invalidations,
    setInvalidations,
    handleNewInvalidation,
    handleShowInvalidation,
    handleSiteStatus
}) => {
    return (
        <>
            <table className={'table'}>
                <thead>
                    <tr>
                        <th scope="col">Domain</th>
                        <th scope="col">Additional Information</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <p>
                                <b>Name:</b> {template.domain_name}
                            </p>
                        </td>
                        <td>
                            <p>
                                <b>Category:</b> {template.category}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className={'enable-ci-container'}>
                <div className="check-enable-build-container margin-left">
                    <Form.Check
                        checked={template.cicd_enable}
                        type="checkbox"
                        className="check-enable-build-1"
                        onChange={() => handleSiteStatus(template.id, habitat.id, !template.cicd_enable)}
                    />
                    <p>Enable CI</p>
                </div>
            </div>

            {template.cicd_enable && (
                <div>
                    <div className={'buildspec-file'}>
                        <div>buildspec.yml</div>
                        <div className="check-enable-build-container">
                            <Form.Check
                                type="checkbox"
                                checked={ownBuild}
                                className="check-enable-build"
                                onChange={() => setOwnBuild(!ownBuild)}
                            />
                            <p>Use project&apos;s buildspec file</p>
                        </div>
                    </div>

                    {!ownBuild && templates.length > 0 && (
                        <div className={showCode}>
                            <CodeMirror
                                value={code}
                                options={{
                                    mode: 'javascript',
                                    theme: 'base16-light',
                                    lineNumbers: true,
                                    readOnly: false
                                }}
                                onBeforeChange={(editor, data, value) => {
                                    setCode(value)
                                }}
                            />
                        </div>
                    )}
                </div>
            )}

            {!template.distribution_status ? (
                <div className={'enable-ci-container'}>
                    <div className="check-enable-build-container margin-left">
                        <Form.Check
                            checked={template.distribution_status}
                            type="checkbox"
                            className="check-enable-build-1"
                            onChange={() => handleCloudFrontStatus(true)}
                        />
                        <p>Enable Cloudfront</p>
                    </div>
                </div>
            ) : (
                <div className={showInfo + ' enable-ci-container'}>
                    <div className="check-enable-build-container margin-left">
                        <Form.Check
                            checked={template.distribution_status}
                            type="checkbox"
                            className="check-enable-build-1"
                            onChange={() => handleCloudFrontStatus(false)}
                        />
                        <p>Cloudfront Enabled</p>
                    </div>
                </div>
            )}

<div className={'invalidations-container'}>
            <Button
              variant='outline-primary'
              className='button-validations'
              onClick={() => handleFetchInvalidations(template.id)}
            >
              Show Invalidations
            </Button>

            <div className={showInvalidations + ' invalidation-container'}>
              {invalidationsArray.templateId === template.id && (
                <table className='table table-invalidations'>
                  <thead>
                    <tr>
                      <th scope="col">Invalidation</th>
                      <th scope="col">Created at</th>
                      <th scope="col">State</th>
                      <th scope="col">Show</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invalidationsArray.items.map(invalidation => (
                      <tr key={invalidation.id}>
                        <td>
                          <p>{invalidation.id}</p>
                        </td>
                        <td>
                          <p>{invalidation.create_time}</p>
                        </td>
                        <td>
                          {invalidation.status === 'Completed' ? (
                            <p className='status-completed'>Completed &nbsp;&nbsp;<FontAwesomeIcon icon={faCheckCircle} /> </p>
                          ) : (
                            <p className='status-inprogress'>In Progress &nbsp;&nbsp;<FontAwesomeIcon icon={faSpinner} /> </p>
                          )}
                        </td>
                        <td>
                          <p
                            className='show-invalidation'
                            onClick={() => handleShowInvalidation(invalidation.id)}>
                            Show <FontAwesomeIcon icon={faEye} />
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              <br></br>
              {invalidationsArray.templateId === template.id && (
                <>
                  {invalidationItem && (
                    <>
                      <p><b>Invalidation:  {invalidationItem.id}</b></p>


                      <div className='invalidation-selected-container'>
                        <pre>
                          {invalidationItem.invalidation_batch.paths.items.map(item => (
                            <p key={item}>{item}</p>
                          ))}
                        </pre>
                      </div>
                    </>
                  )}
                </>
              )}



              <br></br>
              {invalidationsArray.templateId === template.id && (
                <>
                  <p><b>New Invalidation</b></p>
                  <textarea
                    onChange={e => setInvalidations(e.target.value)}
                    className="text-area-invalidations">
                  </textarea>
                  <div className="save-invalidations-button">
                    <Button
                      variant="outline-primary"
                      className="button-validations"
                      value={invalidations}
                      onClick={() => handleNewInvalidation()}
                    >
                      Save Invalidation
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
    )
}


const mapState = (state, props) => ({

});

const mapDispatch = {

}

export default connect(
    mapState,
    mapDispatch
)(HabitatSitesDeploymentInfo);