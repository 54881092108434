import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCloud
} from '@fortawesome/free-solid-svg-icons';

const HabitatAWSCreds = ({
    habitatSetUp,
    handleSelectFeature,
    habitat,
    onboardingClassAWS,
    handleNextStep
}) => {
    return (
        <div className={onboardingClassAWS} onClick={handleNextStep}>
            {habitatSetUp === 'habitat' ? (
                 <div className={habitatSetUp + '-selected box-feature'} onClick={() => handleSelectFeature('habitat')}>
                    <div className="box-feature-name">
                        <div className="box-feature-name-icon"><FontAwesomeIcon icon={faCloud} /></div>
                        <p>AWS Credentials</p>
                    </div>
                </div>
            ) : (
                    <div className="box-feature" onClick={() => handleSelectFeature('habitat')}>
                        <div className="box-feature-name">
                            <div className="box-feature-name-icon"><FontAwesomeIcon icon={faCloud} /></div>
                            <p>AWS Credentials</p>
                        </div>
                    </div>
                )}

        </div>
    )
}

const mapState = (state, props) => ({
    habitatSetUp: state.habitat.habitatSetup,
    handleSelectFeature: props.handleSelectFeature,
    habitat: state.habitat.habitat,
    onboardingClassAWS: props.onboardingClassAWS,
    handleNextStep: props.handleNextStep
})

const mapDispatch = {

}

export default connect(mapState, mapDispatch)(HabitatAWSCreds);