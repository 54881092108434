import React from 'react';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HabitatInstanceBox = ({
  instances,
  habitatSetUp,
  handleSelectFeature
}) => {
  return (
    <>
      {habitatSetUp === 'Instances' ? (
        <div className={'instance-selected box-feature'}>
          <div className="box-feature-name">
            <div className="box-feature-name-icon-funcs"><FontAwesomeIcon icon={faServer} /></div>
            <p>Instances</p>
          </div>

          <div className="box-feature-plus-1">
            <p>{instances.length}</p>
          </div>
        </div>
      ) : (
        <div className="box-feature" onClick={() => handleSelectFeature('Instances')}>
          <div className="box-feature-name">
            <div className="box-feature-name-icon-funcs"><FontAwesomeIcon icon={faServer} /></div>
            <p>Instances</p>
          </div>

          <div className="box-feature-plus-1">
            <p>{instances.length}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default HabitatInstanceBox;