import React, { useState, useEffect, useDebugValue } from 'react';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/keymap/sublime';
import 'codemirror/theme/base16-light.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import { Button, Form } from 'react-bootstrap';
import { Controlled as CodeMirror } from 'react-codemirror2';
import HabitatDeployEnvVars from './HabitatDeployEnvVars';

const HabitatDeploymentInfo = ({
    func,
    varShow,
    lambdaBuildStatus,
    habitat,
    ownBuild,
    showCode,
    code,
    setVarShow,
    fields,
    setFields,
    setOwnBuild,
    handleBuild,
    setCode,
    handleLambdaBuildStatus
}) => {
    return(
        <>
          <div>
            <table className={'table'}>
              <thead>
                <tr>
                  <th scope="col">Repository</th>
                  <th scope="col">Additional Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>
                      <b>Name:</b> {func.function_name}
                    </p>
                    <p>
                      <b>Region:</b> Region
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>Handler:</b> {func.handler}
                    </p>
                    <p>
                      <b>Run Time:</b> {func.runtime}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className={'enable-ci-container'}>
              <div className="check-enable-build-container margin-left">
                <Form.Check
                  checked={func.cicd_enable}
                  type="checkbox"
                  className="check-enable-build-1"
                  onChange={() => handleLambdaBuildStatus(func.id, !func.cicd_enable, habitat.id, func)}
                />
                <p>Enable CI</p>

              </div>
              
              <div>
                {func.cicd_enable && (
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => handleBuild(func)}
                  >
                    Build
                  </Button>
                )}
              </div>
            </div>
            <div className={'enable-ci-container'}>
              <div className="check-enable-build-container margin-left">
                <Form.Check
                  checked={varShow}
                  type="checkbox"
                  className="check-enable-build-1"
                  onChange={() => setVarShow(!varShow)}
                />
                <p>Add Enviroment Variables</p>
              </div>
            </div>

            {func.cicd_enable && (
              <div>
                <div className={'buildspec-file'}>
                  <div>buildspec.yml</div>
                  <div className="check-enable-build-container">
                    <Form.Check
                      type="checkbox"
                      checked={ownBuild}
                      className="check-enable-build"
                      onChange={() => setOwnBuild(!ownBuild)}
                    />
                    <p>Use project&apos;s buildspec file</p>
                  </div>
                </div>

                {!ownBuild && (
                  <div>
                    <CodeMirror
                      value={code}
                      options={{
                        mode: 'javascript',
                        theme: 'base16-light',
                        lineNumbers: true,
                        readOnly: false
                      }}
                      onBeforeChange={(editor, data, value) => {
                        setCode(value)
                      }}
                    />
                  </div>
                )}
              </div>
            )}

            {varShow && (
              <HabitatDeployEnvVars
                fields={fields}
                setFields={setFields}
              />
            )}
          </div>
        </>
    )
}

export default HabitatDeploymentInfo;