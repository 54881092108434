import React from 'react';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import HabitatDeployAll from '../../components/Habitats/HabitatDeployAll';

const HabitatRelasesTitle = (props) => {
  return (
    <div className="title-deployments">
      <div className="flex-funcs-title">



        {props.featureSelected === 'funcs' && (
          <>

            <p className="funcs-title-releases inactive" onClick={() => props.handleChangeActive('sites')}>Sites</p>
            <p className="sites-title-releases active-funcs" onClick={() => props.handleChangeActive('funcs')}>Functions</p>
            <p className="sites-title-releases inactive" onClick={() => props.handleChangeActive('history')}>Deployment History</p>
          </>
        )}

        {props.featureSelected === 'sites' && (
          <>
            <p className="funcs-title-releases  active-funcs" onClick={() => props.handleChangeActive('sites')}>Sites</p>
            <p className="sites-title-releases inactive" onClick={() => props.handleChangeActive('funcs')}>Functions</p>
            <p className="sites-title-releases inactive" onClick={() => props.handleChangeActive('history')}>Deployment History</p>
          </>
        )}

        {props.featureSelected === 'history' && (
          <>
            <p className="funcs-title-releases inactive" onClick={() => props.handleChangeActive('sites')}>Sites</p>
            <p className="sites-title-releases inactive" onClick={() => props.handleChangeActive('funcs')}>Functions</p>
            <p className="sites-title-releases active-sites" onClick={() => props.handleChangeActive('history')}>Deployment History</p>
          </>
        )}




      </div>
    </div>
  )
}

export default HabitatRelasesTitle;