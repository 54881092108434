import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

const HabitatCreateMethod = ({
  funcs,
  habitat,
  awsRoles,
  setSection,
  selectedAPI,
  createRoute,
}) => {
  
  const [ path, setPath ]             = useState('');
  const [ method, setMethod ]         = useState('');
  const [ lambda, setLambda ]         = useState('');
  const [ methodType, setMethodType ] = useState('');

  const hanldeNewEndpoint = () => {
    setSection('api');
    createRoute(path, selectedAPI.id, method, awsRoles, lambda, habitat.id, methodType);
  }

  return (
    <>
      <p className="title-item font-weight-bold mt-4">New Endpoint</p>
      <Row>
        <Col xs={2} className="mb-2 mt-5">
          <p>
            Lambda
          </p>
        </Col>
        <Col xs={9} className="p-0 mb-2 mt-5">
          <Form.Control
            as="select"
            defaultValue={'DEFAULT'}
            onChange={e => setLambda(e.target.value)}
          >
            <option disabled value="DEFAULT">
              Select Lambda
            </option>
            {funcs && funcs.map(func => (
              func.function_arn && (
                <option
                  key={func.id}
                  value={func.function_arn}>
                  {func.function_name}
                </option>
              )
            ))}
          </Form.Control>
        </Col>
        <Col xs={2} className="mb-2 mt-2">
          <p>
            Path
          </p>
        </Col>
        <Col xs={9} className="p-0 mb-2 mt-2">
          <Form.Control
            type="text"
            placeholder="Path"
            onChange={(e => setPath(e.target.value))}
          />
        </Col>
        <Col xs={12} className="mb-2 mt-2">
          <span>Configure Methods</span>
        </Col>
        <Col xs={2} className="mb-2 mt-2">
          <p>
            Method
          </p>
        </Col>
        <Col xs={9} className="mb-2 mt-2">
          <Form.Control
            as="select"
            defaultValue={'DEFAULT'}
            onChange={e => setMethod(e.target.value)}
          >
            <option disabled value="DEFAULT">--Select a Method--</option>
            <option>POST</option>
            <option>GET</option>
            <option>PUT</option>
            <option>DELETE</option>
            <option>ANY</option>
          </Form.Control>
        </Col>
        <Col xs={2} className="mb-2 mt-2">
          <p>
            Type
          </p>
        </Col>
        <Col xs={9} className="mb-2 mt-2">
          <Form.Control
            as="select"
            defaultValue={'DEFAULT'}
            onChange={e => setMethodType(e.target.value)}
          >
            <option disabled value="DEFAULT">--Select a Type--</option>
            <option>AWS</option>
            <option>MOCK</option>
            <option>AWS_PROXY</option>
          </Form.Control>
        </Col>
      </Row>
      <div className="d-flex justify-content-around mt-5 pt-5">
        <button 
          type="button"
          onClick={() => setSection('api')} 
          className="btn btn-outline-primary"
        >BACK</button>
        <button 
          type="button" 
          className="btn btn-primary"
          onClick={() => hanldeNewEndpoint()}
        >SAVE ENDPOINT</button>
      </div> 
    </>
  );
}

export default HabitatCreateMethod;