import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import HabitatNewAPIForm from './HabitatNewAPIForm';
import HabitatNewCRUDForm from './HabitatNewCRUDForm';
import closeIcon from '../../assets/images/icons/close-icon.svg';

const HabitatNewAPIGateway = ({
  team,
  show,
  setShow,
  habitat,
  cognitos,
  awsRoles,
  awsCreds,
  s3Buckets,
  createAPI,
  createCrudApi,
  createCognitoApi,
  createCognitoCrudApi,
  handleSelectFeatureModal
}) => {

  const [ showApi, setShowApi ] = useState(true);

  return (
    <>
      <Row className="mt-5">
        <Col xs={12} md={2} onClick={() => setShowApi(true)}>
          <p className={showApi ? 'text-center tab active' : 'text-center tab'}>API</p>
        </Col>
        <Col xs={12} md={2} onClick={() => setShowApi(false)}>
          <p className={!showApi ? 'text-center tab active' : 'text-center tab'}>CRUD</p>
        </Col>
      </Row>

      {showApi ? (
        <HabitatNewAPIForm 
          setShow={setShow}
          habitat={habitat}
          cognitos={cognitos}
          createAPI={createAPI}
          createCognitoApi={createCognitoApi}
          handleSelectFeatureModal={handleSelectFeatureModal}
        />
      ) : (
        <HabitatNewCRUDForm
          team={team}
          setShow={setShow}
          habitat={habitat}
          awsCreds={awsCreds}
          awsRoles={awsRoles}
          cognitos={cognitos}
          s3Buckets={s3Buckets} 
          createCrudApi={createCrudApi}
          createCognitoCrudApi={createCognitoCrudApi}
          handleSelectFeatureModal={handleSelectFeatureModal}
        />
      )}
    </>
  );
}

export default HabitatNewAPIGateway;