import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../assets/styles/components/habitats/HabitatList.scss'
import { 
  listHabitats, 
  newHabitat, 
  newHabitatWithCreds, 
  addHabitadWithURL 
} from '../actions/habitat.actions';
import { fetchTeams } from '../actions/team.actions';
import HabitatList from '../components/Habitats/HabitatList';
import '../assets/styles/pages/Habitats.scss';
import { Button, Alert, Row, Col } from 'react-bootstrap';
import AppTour from '../components/AppTour';
import { nextStepTour, stopTour } from '../actions/app-tour.actions';
import { changeStatusOnboarding } from '../actions/user.actions';
import { getClones } from '../actions/git-clone.actions';
import CreateHabitatModal from '../components/Habitats/modals/CreateHabitatModal'
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import { getUserAWSCreds } from '../actions/aws-creds.actions.js'
import plus from '../assets/images/icons/overview/plus.svg';

const HabitatsPage = ({
  team,
  step,
  active,
  nextStep,
  stopTour,
  habitats,
  fetchTeams,
  newHabitat,
  getClones,
  currentUser,
  stepAppTour,
  listHabitats,
  nextStepTour,
  statusAppTour,
  changeStatusOnboarding,
  error,
  loadingMessage,
  loading,
  getUserAWSCreds,
  awsCredsUser,
  newHabitatWithCreds,
  errorMessage,
  errorHabitat,
  addHabitadWithURL
}) => {
  const [stepHabitat, setStepHabitat] = useState('');
  let team_id;

  useEffect(() => {
    getClones(team);
    listHabitats(team);
    getUserAWSCreds();
  }, [team]);

  const AWSRegions = [
    'us-east-2',
    'us-east-1',
    'us-west-1',
    'us-west-2',
    'ap-east-1',
    'ap-south-1',
    'ap-northeast-3',
    'ap-northeast-2',
    'ap-southeast-1',
    'ap-southeast-2',
    'ap-northeast-1',
    'ca-central-1',
    'eu-central-1',
    'eu-west-1',
    'eu-west-2',
    'eu-west-3',
    'eu-north-1',
    'me-south-1',
    'sa-east-1',
  ];

  const [nameCert, setNameCert] = useState('')
  const [nameHabitat, setNameHabitat] = useState('')
  const [apiKey, setAPIKey] = useState('')
  const [region, setRegion] = useState('')
  const [apiSecret, setAPISecret] = useState('')
  const [showCreateHabitat, setShowCreateHabitat] = useState('show-create-habitat')
  const [userCreds, setUserCreds] = useState({})
  const [selectedCred, setSelectedCred] = useState('')


  const handleNewHabitat = (e) => {
    setShowCreateHabitat('')
    getUserAWSCreds()
    setUserCreds(awsCredsUser.aws_credentials)
  };

  const handleNewHabitatWithURL = () => {
    addHabitadWithURL(team.id)
    setShowCreateHabitat('show-create-habitat')
  }

  const handleCloseHabitat = () => {
    setShowCreateHabitat('show-create-habitat')
  }

  const handleCreateHabitat = () => {
    if (stepAppTour === 3) {
      newHabitat(nameCert, apiKey, apiSecret, region, nameHabitat, team.id);
      document.body.click();
      setShowCreateHabitat('show-create-habitat')
      if (habitats.length > 1) {
        document
          .getElementById('habitats')
          .setAttribute('class', 'main-content-right');
        document.getElementById('habitat-0').setAttribute('class', 'zindex');
      }
      nextStepTour();
    } else {
      newHabitat(nameCert, apiKey, apiSecret, region, nameHabitat, team.id);
      setShowCreateHabitat('show-create-habitat')
    }
  }

  const handleCreateHabitatWithCreds = () => {
    if (stepAppTour === 3) {
      newHabitatWithCreds(selectedCred, team.id)
      setShowCreateHabitat('show-create-habitat')
      document.body.click();
      if (habitats.length > 1) {
        document
          .getElementById('habitats')
          .setAttribute('class', 'main-content-right');
        document.getElementById('habitat-0').setAttribute('class', 'zindex');
      }
      nextStepTour();
    } else {
      newHabitatWithCreds(selectedCred, team.id)
      setShowCreateHabitat('show-create-habitat')
    }

  }

  const handleSetSelectedCred = (idCreds) => {
    if(idCreds === 'DEFAULT'){
      setSelectedCred('')
    }else{
      setSelectedCred(idCreds)
    }
    setNameCert('')
    setAPIKey('')
    setRegion('')
    setAPISecret('')
  }

  if (team.length > 0) {
    team_id = team.id;
  }

  return (
    <div>
      <div className={showCreateHabitat + ' modal-container-create-habitat'}>
        <CreateHabitatModal
          AWSRegions={AWSRegions}
          nameCert={nameCert}
          setNameCert={setNameCert}
          nameHabitat={nameHabitat}
          setNameHabitat={setNameHabitat}
          apiKey={apiKey}
          setAPIKey={setAPIKey}
          setRegion={setRegion}
          apiSecret={apiSecret}
          setAPISecret={setAPISecret}
          region={region}
          handleCreateHabitat={handleCreateHabitat}
          handleCloseHabitat={handleCloseHabitat}
          awsCredsUser={awsCredsUser}
          habitats={habitats}
          userCreds={userCreds}
          handleCreateHabitatWithCreds={handleCreateHabitatWithCreds}
          setSelectedCred={setSelectedCred}
          selectedCred={selectedCred}
          handleSetSelectedCred={handleSetSelectedCred}
          handleNewHabitatWithURL={handleNewHabitatWithURL}
          error={errorHabitat}
        />
      </div>
      <div className="habitat-container">
        <div className="habitat-main">
          <div className="init-ctn title-section-ui">
            <h2>Habitats</h2>
            {stepAppTour !== 3 && (
            <div
              className="habitat-main-content main-content-right button-container-habitat"
              id="habitats"
            >
              {team.id ? (
                <Button
                  className="new-habitat-btn"
                  onClick={handleNewHabitat}
                  disabled={
                    ((team.subscription === 'Project' && habitats.length >= 2) ||
                      (team.subscription === 'Startup' && habitats.length >= 8))
                  }
                >
                  <img src={plus} />
                  Create
                </Button>
              ) : (
                  <Button
                    className="new-habitat-btn"
                    onClick={handleNewHabitat}
                    disabled={
                      currentUser.subscription === 'Basic' && habitats.length >= 2
                    }
                  >
                    <img src={plus} />
                    Create
                  </Button>
                )}
              {team.id
                ? ((team.subscription === 'Project' && habitats.length >= 2) ||
                  (team.subscription === 'Startup' && habitats.length >= 8)) && (
                  <div className="m-4">
                    <Alert variant="warning">
                      Upgrade your plan to add more habitats
                    </Alert>
                  </div>
                )
                : currentUser.subscription === 'Basic' &&
                habitats.length >= 2 && (
                  <div className="m-4">
                    <Alert variant="warning">
                      Upgrade your plan to add more habitats
                    </Alert>
                  </div>
                )}
              <div>
              </div>
            </div>

          )}
          </div>

          {loading && (
            <div className="spinner-container habitat-loader">
              <Spinner animation="border" variant="primary" />
              <p>{loadingMessage}</p>
            </div>
          )}


          {error && (
            <div className="error-display text-danger error-create-habitat">
              <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {error}</p>
            </div>
          )}
        
          {habitats && habitats.length > 0 ? (
            <HabitatList
              habitats={habitats}
              teamId_habitat={team_id}
              stepAppTour={stepAppTour}
              stepHabitat={stepHabitat}
              error={error}
              errorMessage={errorMessage}
            />
          ) : (
              <p className="empty-habitat-list">
                Create a habitat for your functions!
              </p>
            )}
        </div>
      </div>

      <AppTour
        stepAppTour={stepAppTour}
        statusAppTour={statusAppTour}
        nextStepTour={nextStepTour}
        stopTour={stopTour}
        changeStatusOnboarding={changeStatusOnboarding}
      />
    </div>
  );
};

const mapState = (state, props) => ({
  team: state.team.team,
  currentUser: state.user.user,
  habitats: state.habitat.habitats,
  stepAppTour: state.appTour.stepAppTour,
  statusAppTour: state.user.user.onboarding,
  error: state.habitat.error,
  errorMessage: state.habitat.errorMessage,
  loadingMessage: state.habitat.loading_message,
  loading: state.habitat.loading,
  awsCredsUser: state.awsCreds.userCredentials,
  errorHabitat: state.templates.error
});

const mapDispatch = {
  getUserAWSCreds,
  stopTour,
  getClones,
  fetchTeams,
  newHabitat,
  listHabitats,
  nextStepTour,
  changeStatusOnboarding,
  newHabitatWithCreds,
  addHabitadWithURL
};

export default connect(mapState, mapDispatch)(HabitatsPage);
