import {
  LOGIN_BEGIN,
  LOGIN_ERROR,
  SIGNUP_BEGIN,
  SIGNUP_ERROR,
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
  LOGOUT_SUCCESS,
  DELETE_USER_BEGIN,
  DELETE_USER_ERROR,
  UPDATE_USER_BEGIN,
  UPDATE_USER_ERROR,
  SIGNUP_OAUTH_BEGIN,
  DELETE_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  SIGNUP_OAUTH_SUCCESS,
  RESET_PASSWORD_BEGIN,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  CHANGE_ONBOARDING_BEGIN,
  CHANGE_ONBOARDING_ERROR,
  UPDATE_SUBSCRIPTION_PLAN,
  CHANGE_ONBOARDING_SUCCESS,
} from './types';
import {
  loginUser,
  createUser,
  updateUser,
  deleteUser,
  passwordNew,
  resetUserPassword,
  statusOnboarding,
  validToken,
} from '../api/users';

const URL = `${process.env.REACT_APP_BACKEND_URL}/auth`;

export const changeStatusOnboarding = (data) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_ONBOARDING_BEGIN });
    statusOnboarding(data)
      .then((response) => {
        dispatch({
          type: CHANGE_ONBOARDING_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: CHANGE_ONBOARDING_ERROR, error });
      });
  };
};

export const login = (username, password) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_BEGIN });
    loginUser(username, password)
      .then((response) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({ type: LOGIN_ERROR, error: error.response.data.errors });
      });
  };
};

export const loginWithOauth = search => {
  return dispatch => {
    dispatch({ type: LOGIN_BEGIN });
    const email = new URLSearchParams(search).get('email');
    const condition =  new URLSearchParams(search).get('condition');
    if (email && condition) {
      dispatch({
        type: LOGIN_ERROR,
        error: `You already have an account with the email ${new URLSearchParams(search).get('email')}, please sign up with a different email!`
      });
    } else {
      const payload = {
        headers: {
          'access-token' : new URLSearchParams(search).get('auth_token'),
          'cache-control': 'max-age=0, private, must-revalidate',
          client: new URLSearchParams(search).get('client_id'),
          'content-type': 'application/json; charset=utf-8',
          expiry: new URLSearchParams(search).get('expiry'),
          'token-type': 'Bearer',
          uid: new URLSearchParams(search).get('uid'),
        },
        data: {
          data: {
            confirmed: true, 
            email: new URLSearchParams(search).get('email'),
            country: new URLSearchParams(search).get('country'),
            job_function: null,
            name: new URLSearchParams(search).get('name'),
            onboarding: new URLSearchParams(search).get('onboarding'),
            provider: new URLSearchParams(search).get('provider'),
            subscription: new URLSearchParams(search).get('subscription'),
            uid: new URLSearchParams(search).get('uid')
          }
        }
      }
      dispatch({
        type: LOGIN_SUCCESS,
        payload
      });
    }
  }
}

export const signup = (username, name, password, country, job) => {
  return (dispatch) => {
    dispatch({ type: SIGNUP_BEGIN });
    createUser(username, name, password, country, job)
      .then((response) => {
        dispatch({
          type: SIGNUP_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: SIGNUP_ERROR, error: error.response.data.errror });
      });
  };
};

const openWindow = (provider) => {
  const uri = `${URL}/${provider}`;
  window.open(uri, '_self');
  return { type: SIGNUP_OAUTH_SUCCESS }
}

export const signupGithubOauth = () => {
  return dispatch => {
    dispatch({ type: SIGNUP_OAUTH_BEGIN });
    dispatch(openWindow('github'));
  }
}

export const signupGoogleOauth = () => {
  return dispatch => {
    dispatch({ type: SIGNUP_OAUTH_BEGIN });
    dispatch(openWindow('google_oauth2'));
  }
}

export const loadLastUser = () => {
  return (dispatch) => {
    const json = localStorage.getItem('currentUser');
    if (json && json !== 'undefined') {
      const user = JSON.parse(json);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: user,
      });
    } else {
      // Clear localstorage
      dispatch(logout());
    }
  };
};

export const validateSession = () => {
  return (dispatch) => {
    const headers = localStorage.getItem('currentHeaders');
    if (!headers || headers === 'undefined') {
      dispatch(logout());
    } else {
      validToken()
        .then((response) => {
          if (!response.data.success) {
            dispatch(logout());
          }
        })
        .catch((error) => {
          dispatch(logout());
        });
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    // Remove other session info
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  };
};

export const updatePlan = (plan) => {
  let user = JSON.parse(localStorage.getItem('currentUser'));
  user.subscription.name = plan;
  localStorage.setItem('currentUser', JSON.stringify(user));
  return (dispatch) => {
    dispatch({
      type: UPDATE_SUBSCRIPTION_PLAN,
      payload: plan,
    });
  };
};

export const updateName = (user) => {
  const data = {
    email: user.email,
    name: user.name,
  };
  return (dispatch) => {
    dispatch({ type: UPDATE_USER_BEGIN });
    updateUser(data)
      .then((response) => {
        dispatch({
          type: UPDATE_USER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_USER_ERROR,
          errorUpdateName: 'Update name fail',
          error,
        });
      });
  };
};

export const updatePassword = (email, password) => {
  const data = {
    email,
    password,
  };
  return (dispatch) => {
    dispatch({ type: UPDATE_USER_BEGIN });
    updateUser(data)
      .then((response) => {
        dispatch({ type: UPDATE_USER_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_USER_ERROR,
          errorUpdatePassword: 'Update password fail',
          error,
        });
      });
  };
};

export const removeUser = () => {
  return (dispatch) => {
    dispatch({ type: DELETE_USER_BEGIN });
    deleteUser()
      .then((response) => {
        localStorage.clear();
        dispatch({ type: DELETE_USER_SUCCESS });
      })
      .catch((error) => {
        localStorage.clear();
        dispatch({ type: DELETE_USER_ERROR, error: 'Your KOR account has been deleted' });
      });
  };
};

export const newPassword = (params, password, confirmPassword) => {
  return (dispatch) => {
    dispatch({ type: RESET_PASSWORD_BEGIN });
    passwordNew(params, password, confirmPassword)
      .then((response) => {
        dispatch({ type: RESET_PASSWORD_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: RESET_PASSWORD_ERROR });
      });
  };
};

export const resetPassword = (email) => {
  return (dispatch) => {
    dispatch({ type: RESET_PASSWORD_BEGIN });
    resetUserPassword(email)
      .then((response) => {
        dispatch({ type: RESET_PASSWORD_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: RESET_PASSWORD_ERROR });
      });
  };
};
