import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { addAurora, removeAurora } from '../../actions/aws-rds-actions';
import Spinner from 'react-bootstrap/Spinner';

const HabitatAuroraDB = ({
    showAuroraManagement,
    addAurora,
    habitat,
    loading,
    loadingMessage,
    error,
    handleSelectFeatureModal,
    handleShowModal
}) => {

    const [port, setPort] = useState('');
    const [tableName, setTableName] = useState('');
    const [clusterName, setClusterName] = useState('');
    const [password, setPassword] = useState('');
    const [masterusername, setMasterusername] = useState('');
    const [engine, setEngine] = useState('');
    const [engineVersion, setEngineVersion] = useState('');

    const handleNewAuroraDB = (
    ) => {
        if (engine === 'aurora-postgresql') {
            setPort('5432')
            addAurora(
                port,
                engine,
                password,
                habitat.id,
                tableName,
                engineVersion,
                masterusername,
                clusterName
            );
            handleShowModal();
        } else if (engine === 'aurora') {
            setPort('3306')
            addAurora(
                port,
                'aurora-mysql',
                password,
                habitat.id,
                tableName,
                engineVersion,
                masterusername,
                clusterName
            );
            handleShowModal();
        }
    }


    return (
        <>
            {showAuroraManagement === 'display-none' ? (
                <div className="blank-section">No action selected.</div>
            ) : (
                <div className={showAuroraManagement + ' aurora-db-management'}>
                    {loading && (
                        <div className="spinner-container">
                            <Spinner animation="border" variant="primary" />
                            <p>{loadingMessage}</p>
                        </div>
                    )}

                    {error && (
                        <div className="error-display text-danger">
                            <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {error}</p>
                        </div>
                    )}
                    <p className="dynamo-management-title">Table Name</p>
                    <div className="new-aurora-input">
                        <p>Table Name</p>
                        <Form.Control
                            type="text"
                            placeholder="Table Name"
                            onChange={(e) => setTableName(e.target.value)}
                        />
                    </div>

                    <p className="dynamo-management-title">Fields</p>

                    <div className="new-aurora-input">
                        <p>Cluster Indentifier</p>
                        <Form.Control
                            type="text"
                            placeholder="Cluster Indentifier"
                            onChange={(e) => setClusterName(e.target.value)}
                        />
                    </div>

                    <div className="new-aurora-input">
                        <p>Password</p>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <div className="new-aurora-input">
                        <p>Master Username</p>
                        <Form.Control
                            type="text"
                            placeholder="Master Username"
                            onChange={(e) => setMasterusername(e.target.value)}
                        />
                    </div>

                    <div className="new-aurora-input">
                        <p>Engine</p>
                        <Form.Control
                            as="select"
                            defaultValue="DEFAULT"
                            placeholder="AWS Region"
                            onChange={(e) => setEngine(e.target.value)}
                        >
                            <option disabled value="DEFAULT">--Select an engine--</option>
                            <option value="aurora">MySQL</option>
                            <option value="aurora-postgresql">PostgreSQL</option>
                        </Form.Control>
                    </div>

                    {engine === 'aurora-postgresql' && (
                        <div className="new-aurora-input">
                            <p>Engine version</p>
                            <Form.Control
                                as="select"
                                defaultValue="DEFAULT"
                                placeholder="AWS Region"
                                onChange={(e) => setEngineVersion(e.target.value)}
                            >
                                <option disabled value="DEFAULT">--Select an engine version--</option>
                                <option value="10.12">10.12</option>
                            </Form.Control>
                        </div>
                    )}

                    {engine === 'aurora' && (
                        <div className="new-aurora-input">
                            <p>Engine version</p>
                            <Form.Control
                                as="select"
                                defaultValue="DEFAULT"
                                placeholder="AWS Region"
                                onChange={(e) => setEngineVersion(e.target.value)}
                            >
                                <option disabled value="DEFAULT">--Select an engine version--</option>
                                <option value="5.7">5.7</option>
                                <option value="2.07.1">2.07.1</option>
                            </Form.Control>
                        </div>
                    )}

                    <div className="button-next-modal">
                        <Button onClick={() => handleSelectFeatureModal('')}>
                            Back to resources
                        </Button>
                        <Button onClick={handleNewAuroraDB}> Create</Button>
                    </div>


                </div>
            )}

        </>
    )
}

const mapState = (state, props) => ({
    habitat: state.habitat.habitat,
    loading: props.loading,
    loadingMessage: props.loadingMessage
});

const mapDispatch = {
    addAurora
}

export default connect(mapState, mapDispatch)(HabitatAuroraDB);