import React from 'react';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HabitatDynamo from '../Habitats/HabitatDynamo';
import HabitatAuroraDB from '../Habitats/HabitatAuroraDB';
import HabitatDynamos from '../Habitats/HabitatDynamos';
import HabitatAuroras from '../Habitats/HabitatAuroras';

const HabitatDB = (props) => {
    return (
        <div className="habitatdb-container">

            <div className="feature-info-habitatdb">
                {props.selectedDB === 'Dynamo' && (
                    <HabitatDynamos
                        dynamos={props.dynamos}
                        handleRemoveDynamo={props.handleRemoveDynamo}
                        handleShowDyanoManagement={props.handleShowDynamoManagement}
                        showDynamoManagement={props.showDynamoManagement}
                        loading={props.loading}
                    />
                )}

                {props.selectedDB === 'Aurora' && (
                    <HabitatAuroras
                        auroras={props.auroras}
                        handleShowAuroraManagement={props.handleShowAuroraManagement}
                        handleRemoveAurora={props.handleRemoveAurora}
                    />
                )}

            </div>
        </div>
    )
}

export default HabitatDB;