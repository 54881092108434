import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

const HabitatNewTest = ({
  apiTest,
  testApi,
  methods,
  habitat,
  setSection,
  selectedAPI,
}) => {

  const [ payload, setPayload ]     = useState('{}'); 
  const [ headers, setHeaders ]     = useState('{}');
  const [ apiMethod, setApiMethod ] = useState(null);

  const handleSetMethod = (method) => {
    setApiMethod(JSON.parse(method).id);
  }
  
  return (
    <>
      <Row>
        <Col xs={2} className="mb-2 mt-5">
          <p className="input-label">
            Method
          </p>
        </Col>
        <Col xs={9} className="p-0 mb-2 mt-5">
          <Form.Control
            as="select"
            defaultValue={'DEFAULT'}
            onChange={e => handleSetMethod(e.target.value)}
          >
            <option disabled value="DEFAULT">
              Select Method
            </option>
            {methods && methods.map(method => (
              <option
                key={method.id}
                value={JSON.stringify(method)}>
                {method.method}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col xs={2} className="mt-2 mb-2">
          <p className="input-label">
            Headers
          </p>
        </Col>
        <Col xs={9} className="p-0 mt-2 mb-2">
          <Form.Control 
            rows={3} 
            as="textarea"
            value={headers}
            onChange={e => setHeaders(e.target.value)} 
          />
        </Col>
        <Col xs={2} className="mt-2 mb-2">
          <p className="input-label">
            Payload
          </p>
        </Col>
        <Col xs={9} className="p-0 mt-2 mb-2">
          <Form.Control 
            rows={3} 
            as="textarea"
            value={payload}
            onChange={e => setPayload(e.target.value)} 
          />
        </Col>
      </Row>
      <div>
        <p className="font-weight-bold mb-4">Response</p>
      </div>
      <div className="d-flex justify-content-center ctn-response p-2">
        {testApi && (
          <pre className="text-light">
            { JSON.stringify(JSON.parse(testApi.body), null, 2) }
          </pre>
        )}
      </div>
      <div className="d-flex justify-content-around mt-5">
        <button 
          type="button"
          onClick={() => setSection('api')} 
          className="btn btn-outline-primary"
        >BACK</button>
        <button 
          type="button" 
          className="btn btn-primary"
          onClick={() => apiTest(habitat.id, headers, payload, apiMethod, selectedAPI.id)}
        >INVOKE</button>
      </div>
    </>
  );
}

export default HabitatNewTest;