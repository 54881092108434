import {
  ADD_GIT_INFO_BEGIN,
  ADD_GIT_INFO_SUCCESS,
  ADD_DOMIAN_INFO_BEGIN,
  ADD_DOMIAN_INFO_SUCCESS,
  GET_TEMPLATE_INFO_BEGIN,
  GET_TEMPLATE_INFO_SUCCESS,
  DEPLOY_STATIC_BEGIN,
  DEPLOY_STATIC_SUCCESS,
  DEPLOY_STATIC_ERROR,
  CREATE_STATIC_BEGIN,
  CREATE_STATIC_SUCCESS,
  CREATE_STATIC_ERROR,
  GET_STATIC_TEMPLATE_BEGIN,
  GET_STATIC_TEMPLATE_SUCCESS,
  GET_STATIC_TEMPLATE_ERROR,
  CREATE_STRAPI_TEMPLATE_BEGIN,
  CREATE_STRAPI_TEMPLATE_SUCCESS,
  CREATE_STRAPI_TEMPLATE_ERROR,
  CREATE_STATIC_TEMPLATE_BEGIN,
  CREATE_STATIC_TEMPLATE_SUCCESS,
  CREATE_STATIC_TEMPLATE_ERROR,
  REMOVE_STATIC_TEMPLATE_BEGIN,
  REMOVE_STATIC_TEMPLATE_SUCCESS,
  REMOVE_STATIC_TEMPLATE_ERROR,
  CREATE_WEB_APP_TEMPLATE_BEGIN,
  CREATE_WEB_APP_TEMPLATE_SUCCESS,
  CREATE_WEB_APP_TEMPLATE_ERROR,
  ADD_HABITAT_AND_CREDS_TEMPLATE_BEGIN,
  ADD_HABITAT_AND_CREDS_TEMPLATE_SUCCCESS,
  ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR,
  CREATE_STRAPI_WITHOUT_DOMAIN_BEGIN,
  CREATE_STRAPI_WITHOUT_DOMAIN_SUCCESS,
  CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
  BUILD_AND_UPDATE_BEGIN,
  BUILD_AND_UPDATE_SUCCESS,
  BUILD_AND_UPDATE_ERROR,
  CHANGE_SITE_STATUS_BEGIN,
  CHANGE_SITE_STATUS_SUCCESS,
  CHANGE_SITE_STATUS_ERROR,
  HABITAT_SETUP_BEGIN,
  MANAGE_CLOUDFRONT_BEGIN,
  MANAGE_CLOUDFRONT_SUCCESS,
  MANAGE_CLOUDFRONT_ERROR,
  GET_INDEX_INVALIDATION_BEGIN,
  GET_INDEX_INVALIDATION_SUCCESS,
  GET_INDEX_INVALIDATION_ERROR,
  GET_INVALIDATIONS_BEGIN,
  GET_INVALIDATIONS_SUCCESS,
  GET_INVALIDATIONS_ERROR,
  CREATE_INVALIDATIONS_BEGIN,
  CREATE_INVALIDATIONS_SUCCESS,
  CREATE_INVALIDATIONS_ERROR,
  CREATE_STATIC_WITHOUTDOMAIN_BEGIN,
  CREATE_STATIC_WITHOUTDOMAIN_SUCCESS,
  CREATE_STATIC_WITHOUTDOMAIN_ERROR,
  CREATE_WEB_APP_WITHOUTDOMAIN_BEGIN,
  CREATE_WEB_APP_WITHOUTDOMAIN_SUCCESS,
  CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
  CREATE_EC2_STRAPI_WITHDOMAIN_BEGIN,
  CREATE_EC2_STRAPI_WITHDOMAIN_SUCCESS,
  CREATE_EC2_STRAPI_WITHDOMAIN_ERROR,
  CREATE_EC2_STRAPI_WITHOUTDOMAIN_BEGIN,
  CREATE_EC2_STRAPI_WITHOUTDOMAIN_SUCCESS,
  CREATE_EC2_STRAPI_WITHOUTDOMAIN_ERROR
} from './types';
import {
  addEnv,
  s3ToEbs,
  createDB,
  getDomain,
  addDomian,
  uploadFile,
  createBuild,
  uploadClone,
  uploadFolder,
  addBuildspec,
  createProject,
  requestDomain,
  addStaticBucket,
  changeSiteStatus,
  contentDistribution,
} from '../api/aws/sdk';
import {
  rollBack,
  update_hash,
  deleteTemplates,
  createTemplate,
  getTemplates,
  manageCloudfront,
  getIndexInvalidations,
  getInvalidations,
  distributionValidation,
} from '../api/templates';
import { buildspec } from '../models/strapiBuildspec';
import { serverStrapi } from '../models/serverStrapi';
import { createHabitat } from '../api/habitats';
import { gitClone, gitRemote, gitClonesList, gitCreateHook, gitCreateRepo } from '../api/git/gitClone';
import { newCognito, createCognitoUser } from '../api/aws/cognito';
import { setFunc, deployFunc } from '../api/funcs';
import { addBucket } from '../api/aws/sdk';
import { getAWSRoles, createAWSRole } from '../api/awsRoles';
import {
  createApiGateway,
  authorizeCognito,
  createResource,
  createRespose,
  createMethod,
  deployApi,
  listResources,
} from '../api/aws/apigateway';
import { awsCredModel } from '../models/awsCreds';
import {
  awsRedirect,
  requestEncryptKey,
  accessAwsCredential,
  createAWSCredential,
} from '../api/aws/creds';
import { verifyCreds, getBuckets } from '../api/aws/sdk';
import { zipFile, envRepo, zipRemove } from '../api/fileSystem';
import { attachCredHabitat } from '../api/habitats';
import passwordGenerator from 'password-generator';
import { createRds } from '../api/aws/rds';
import randomHash from 'random-hash';
import crypto from 'crypto';
import randomRickandMorty from 'random-rick-and-morty-character';
import { 
  createEC2, 
  addElasticIP, 
  instanceBuild, 
  DBPasswordGenerator
} from '../api/aws/ec2';

export const getTemplateInfo = (templateName) => {
  return (dispatch) => {
    dispatch({
      type: GET_TEMPLATE_INFO_BEGIN,
      loading_message: 'Loading template info...',
    });
    dispatch({
      type: GET_TEMPLATE_INFO_SUCCESS,
      payload: templateName,
    });
  };
};

export const getStaticSites = (habitat_id) => {
  return (dispatch) => {
    dispatch({
      type: GET_STATIC_TEMPLATE_BEGIN,
      loading_message: 'Loading static sites...',
    });
    getTemplates(habitat_id)
      .then((response) => {
        dispatch({
          type: GET_STATIC_TEMPLATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => dispatch({ type: GET_STATIC_TEMPLATE_ERROR, error: error.response.data.error }));
  };
};

export const addDomainInfo = (name, domain_name, prefix) => {
  return (dispatch) => {
    dispatch({
      type: ADD_DOMIAN_INFO_BEGIN,
      loading_message: 'Adding domain info...',
    });
    dispatch({
      type: ADD_DOMIAN_INFO_SUCCESS,
      payload: {
        name,
        domain_name,
        prefix,
      },
    });
  };
};

export const addGitInfo = (uri, project_name, git_creds) => {
  return (dispatch) => {
    dispatch({
      type: ADD_GIT_INFO_BEGIN,
      loading_message: 'Adding git info...',
    });
    dispatch({
      type: ADD_GIT_INFO_SUCCESS,
      payload: {
        uri,
        project_name,
        git_creds,
      },
    });
  };
};

export const addHabitadAndCreds = (
  name,
  apiKey,
  apiSecret,
  region,
  team_id
) => {
  return (dispatch) => {
    dispatch({
      type: ADD_HABITAT_AND_CREDS_TEMPLATE_BEGIN,
      loading_message: 'Creating template...',
    });
    const algorithm = 'aes-256-cfb';
    let key = '';
    const iv = '0000000000000000';
    const habitat = {
      name,
      team_id,
    };
    createHabitat(habitat)
      .then((response) => {
        const habitat = response.data;
        const rollBackInfo = {
          habitat_id: habitat.id,
          rol_id: null,
        };
        requestEncryptKey(habitat.id)
          .then((response) => {
            key = response.data.key;
            const cipherApiKey = crypto.createCipheriv(algorithm, key, iv);
            let cryptedApiKey = cipherApiKey.update(apiKey, 'utf-8', 'base64');
            cryptedApiKey += cipherApiKey.final('base64');

            const cipherApiSecret = crypto.createCipheriv(algorithm, key, iv);
            let cryptedApiSecret = cipherApiSecret.update(
              apiSecret,
              'utf-8',
              'base64'
            );
            cryptedApiSecret += cipherApiSecret.final('base64');

            const awsCreds = awsCredModel(
              name,
              cryptedApiKey,
              cryptedApiSecret,
              region,
              habitat.id,
              key
            );
            createAWSCredential(awsCreds)
              .then((response) => {
                const awsCred = response.data;
                const credId = response.data.id;
                const newHabCredId = { aws_credential_id: credId };
                verifyCreds(credId)
                  .then((response) => {
                    awsCred.verified = true;
                    attachCredHabitat(habitat.id, newHabCredId)
                      .then((response) => {
                        let awsPolicyDocument = {
                          Version: '2012-10-17',
                          Statement: [
                            {
                              Effect: 'Allow',
                              Action: [
                                'logs:CreateLogGroup',
                                'logs:CreateLogStream',
                                'logs:DescribeLogGroups',
                                'logs:DescribeLogStreams',
                                'logs:PutLogEvents',
                                'logs:GetLogEvents',
                                'logs:FilterLogEvents',
                              ],
                              Resource: '*',
                            },
                            {
                              Effect: 'Allow',
                              Action: 'states:*',
                              Resource: '*',
                            },
                            {
                              Effect: 'Allow',
                              Action: 'logs:CreateLogGroup',
                              Resource: 'arn:aws:logs:us-east-1:973651203340:*',
                            },
                            {
                              Effect: 'Allow',
                              Action: [
                                'logs:CreateLogStream',
                                'logs:PutLogEvents',
                              ],
                              Resource: [
                                'arn:aws:logs:us-east-1:973651203340:log-group:/aws/lambda/my-app-lambda:*',
                                'arn:aws:logs:us-east-1:973651203340:log-group:/aws/codebuild/*',
                              ],
                            },
                            {
                              Effect: 'Allow',
                              Action: ['ecr:*', 'cloudtrail:LookupEvents'],
                              Resource: '*',
                            },
                            {
                              Sid: 'CloudWatchEventsFullAccess',
                              Effect: 'Allow',
                              Action: 'events:*',
                              Resource: '*',
                            },
                            {
                              Action: ['iam:PassRole'],
                              Resource: '*',
                              Effect: 'Allow',
                              Condition: {
                                StringEqualsIfExists: {
                                  'iam:PassedToService': [
                                    'cloudformation.amazonaws.com',
                                    'elasticbeanstalk.amazonaws.com',
                                    'ec2.amazonaws.com',
                                    'ecs-tasks.amazonaws.com',
                                  ],
                                },
                              },
                            },
                            {
                              Action: [
                                'codecommit:CancelUploadArchive',
                                'codecommit:GetBranch',
                                'codecommit:GetCommit',
                                'codecommit:GetUploadArchiveStatus',
                                'codecommit:UploadArchive',
                              ],
                              Resource: '*',
                              Effect: 'Allow',
                            },
                            {
                              Action: [
                                'codedeploy:CreateDeployment',
                                'codedeploy:GetApplication',
                                'codedeploy:GetApplicationRevision',
                                'codedeploy:GetDeployment',
                                'codedeploy:GetDeploymentConfig',
                                'codedeploy:RegisterApplicationRevision',
                              ],
                              Resource: '*',
                              Effect: 'Allow',
                            },
                            {
                              Action: ['codestar-connections:UseConnection'],
                              Resource: '*',
                              Effect: 'Allow',
                            },
                            {
                              Action: [
                                'elasticbeanstalk:*',
                                'ec2:*',
                                'elasticloadbalancing:*',
                                'autoscaling:*',
                                'cloudwatch:*',
                                's3:*',
                                'sns:*',
                                'cloudformation:*',
                                'rds:*',
                                'sqs:*',
                                'ecs:*',
                              ],
                              Resource: '*',
                              Effect: 'Allow',
                            },
                            {
                              Action: [
                                'lambda:InvokeFunction',
                                'lambda:ListFunctions',
                              ],
                              Resource: '*',
                              Effect: 'Allow',
                            },
                            {
                              Action: [
                                'opsworks:CreateDeployment',
                                'opsworks:DescribeApps',
                                'opsworks:DescribeCommands',
                                'opsworks:DescribeDeployments',
                                'opsworks:DescribeInstances',
                                'opsworks:DescribeStacks',
                                'opsworks:UpdateApp',
                                'opsworks:UpdateStack',
                              ],
                              Resource: '*',
                              Effect: 'Allow',
                            },
                            {
                              Action: [
                                'cloudformation:CreateStack',
                                'cloudformation:DeleteStack',
                                'cloudformation:DescribeStacks',
                                'cloudformation:UpdateStack',
                                'cloudformation:CreateChangeSet',
                                'cloudformation:DeleteChangeSet',
                                'cloudformation:DescribeChangeSet',
                                'cloudformation:ExecuteChangeSet',
                                'cloudformation:SetStackPolicy',
                                'cloudformation:ValidateTemplate',
                              ],
                              Resource: '*',
                              Effect: 'Allow',
                            },
                            {
                              Action: [
                                'codebuild:BatchGetBuilds',
                                'codebuild:StartBuild',
                                'codebuild:BatchGetBuildBatches',
                                'codebuild:StartBuildBatch',
                              ],
                              Resource: '*',
                              Effect: 'Allow',
                            },
                            {
                              Effect: 'Allow',
                              Action: [
                                'devicefarm:ListProjects',
                                'devicefarm:ListDevicePools',
                                'devicefarm:GetRun',
                                'devicefarm:GetUpload',
                                'devicefarm:CreateUpload',
                                'devicefarm:ScheduleRun',
                              ],
                              Resource: '*',
                            },
                            {
                              Effect: 'Allow',
                              Action: [
                                'servicecatalog:ListProvisioningArtifacts',
                                'servicecatalog:CreateProvisioningArtifact',
                                'servicecatalog:DescribeProvisioningArtifact',
                                'servicecatalog:DeleteProvisioningArtifact',
                                'servicecatalog:UpdateProduct',
                              ],
                              Resource: '*',
                            },
                            {
                              Effect: 'Allow',
                              Action: ['cloudformation:ValidateTemplate'],
                              Resource: '*',
                            },
                            {
                              Effect: 'Allow',
                              Action: ['ecr:DescribeImages'],
                              Resource: '*',
                            },
                            {
                              Effect: 'Allow',
                              Action: [
                                'states:DescribeExecution',
                                'states:DescribeStateMachine',
                                'states:StartExecution',
                              ],
                              Resource: '*',
                            },
                            {
                              Effect: 'Allow',
                              Action: [
                                'appconfig:StartDeployment',
                                'appconfig:StopDeployment',
                                'appconfig:GetDeployment',
                              ],
                              Resource: '*',
                            },
                            {
                              Sid: 'IAMPassRoleForCloudWatchEvents',
                              Effect: 'Allow',
                              Action: 'iam:PassRole',
                              Resource:
                                'arn:aws:iam::*:role/AWS_Events_Invoke_Targets',
                            },
                            {
                              Effect: 'Allow',
                              Action: [
                                'xray:PutTraceSegments',
                                'xray:PutTelemetryRecords',
                                'xray:GetSamplingRules',
                                'xray:GetSamplingTargets',
                              ],
                              Resource: ['*'],
                            },
                            {
                              Effect: 'Allow',
                              Action: [
                                'cloudformation:DescribeChangeSet',
                                'cloudformation:DescribeStackResources',
                                'cloudformation:DescribeStacks',
                                'cloudformation:GetTemplate',
                                'cloudformation:ListStackResources',
                                'cloudwatch:*',
                                'cognito-identity:ListIdentityPools',
                                'cognito-sync:GetCognitoEvents',
                                'cognito-sync:SetCognitoEvents',
                                'dynamodb:*',
                                'ec2:DescribeSecurityGroups',
                                'ec2:DescribeSubnets',
                                'ec2:DescribeVpcs',
                                'events:*',
                                'iam:GetPolicy',
                                'iam:GetPolicyVersion',
                                'iam:GetRole',
                                'iam:GetRolePolicy',
                                'iam:ListAttachedRolePolicies',
                                'iam:ListRolePolicies',
                                'iam:ListRoles',
                                'iam:PassRole',
                                'iot:AttachPrincipalPolicy',
                                'iot:AttachThingPrincipal',
                                'iot:CreateKeysAndCertificate',
                                'iot:CreatePolicy',
                                'iot:CreateThing',
                                'iot:CreateTopicRule',
                                'iot:DescribeEndpoint',
                                'iot:GetTopicRule',
                                'iot:ListPolicies',
                                'iot:ListThings',
                                'iot:ListTopicRules',
                                'iot:ReplaceTopicRule',
                                'kinesis:DescribeStream',
                                'kinesis:ListStreams',
                                'kinesis:PutRecord',
                                'kms:ListAliases',
                                'codebuild:*',
                                'lambda:*',
                                'logs:*',
                                's3:*',
                                'sns:ListSubscriptions',
                                'sns:ListSubscriptionsByTopic',
                                'sns:ListTopics',
                                'sns:Publish',
                                'sns:Subscribe',
                                'sns:Unsubscribe',
                                'sqs:ListQueues',
                                'sqs:SendMessage',
                                'tag:GetResources',
                                'xray:PutTelemetryRecords',
                                'xray:PutTraceSegments',
                              ],
                              Resource: '*',
                            },
                          ],
                        };

                        let awsRolePolicy = {
                          Version: '2012-10-17',
                          Statement: {
                            Effect: 'Allow',
                            Principal: {
                              Service: [
                                'lambda.amazonaws.com',
                                'apigateway.amazonaws.com',
                                'codebuild.amazonaws.com',
                              ],
                            },
                            Action: 'sts:AssumeRole',
                          },
                        };

                        const jsonPolicyDocument = JSON.stringify(
                          awsPolicyDocument
                        );
                        const awsPolicyDocumentReplace = jsonPolicyDocument
                          .replace(/\\n/g, '\\n')
                          .replace(/\\'/g, "\\'")
                          .replace(/\\"/g, '\\"')
                          .replace(/\\&/g, '\\&')
                          .replace(/\\r/g, '\\r')
                          .replace(/\\t/g, '\\t')
                          .replace(/\\b/g, '\\b')
                          .replace(/\\f/g, '\\f');

                        const jsonAwsRolePolicy = JSON.stringify(awsRolePolicy);
                        const awsRolePolicyReplace = jsonAwsRolePolicy
                          .replace(/\\n/g, '\\n')
                          .replace(/\\'/g, "\\'")
                          .replace(/\\"/g, '\\"')
                          .replace(/\\&/g, '\\&')
                          .replace(/\\r/g, '\\r')
                          .replace(/\\t/g, '\\t')
                          .replace(/\\b/g, '\\b')
                          .replace(/\\f/g, '\\f');
                        const role_name = `KOR-TEMPLATE-${Date.now()}`;
                        const roleInfo = {
                          role_name,
                          role_policy: awsRolePolicyReplace,
                          habitat_id: habitat.id,
                          policy_document: awsPolicyDocumentReplace,
                        };

                        createAWSRole(roleInfo)
                          .then((response) => {
                            const role = response.data;
                            rollBackInfo.rol_id = role.id;
                            getDomain(habitat.id)
                              .then((response) => {
                                const payload = {
                                  role,
                                  name,
                                  awsCred,
                                  habitat,
                                  domains: response.data,
                                };
                                dispatch({
                                  type: ADD_HABITAT_AND_CREDS_TEMPLATE_SUCCCESS,
                                  payload,
                                });
                              })
                              .catch((error) => {
                                dispatch({
                                  type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR,
                                  error: error.response.data.error,
                                });
                                rollBack(rollBackInfo);
                              });
                          })
                          .catch((error) => {
                            dispatch({
                              type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR,
                              error: error.response.data.error,
                            });
                            rollBack(rollBackInfo);
                          });
                      })
                      .catch((error) => {
                        dispatch({
                          type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR,
                          error: error.response.data.error,
                        });
                        rollBack(rollBackInfo);
                      });
                  })
                  .catch((error) => {
                    dispatch({
                      type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR,
                      error: error.response.data.error,
                    });
                    rollBack(rollBackInfo);
                  });
              })
              .catch((error) => {
                dispatch({ type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR, error: error.response.data.error });
                rollBack(rollBackInfo);
              });
          })
          .catch((error) => {
            dispatch({ type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR, error: error.response.data.error });
            rollBack(rollBackInfo);
          });
      })
      .catch((error) =>
        dispatch({ type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR, error: error.response.data.error })
      );
  };
};

export const addHabitadWithCreds = (awsCred, team_id) => {
  return (dispatch) => {
    dispatch({
      type: ADD_HABITAT_AND_CREDS_TEMPLATE_BEGIN,
      loading_message: 'Creating template...',
    });
    const name = randomRickandMorty.rickAndMortyCharacter();
    const habitat = {
      name,
      team_id,
    };
    createHabitat(habitat)
      .then((response) => {
        const habitat = response.data;
        const rollBackInfo = {
          habitat_id: habitat.id,
          rol_id: null,
        };
        const newHabCredId = { aws_credential_id: awsCred.id };
        verifyCreds(awsCred.id)
          .then((response) => {
            awsCred.verified = true;
            attachCredHabitat(habitat.id, newHabCredId)
              .then((response) => {
                let awsPolicyDocument = {
                  Version: '2012-10-17',
                  Statement: [
                    {
                      Effect: 'Allow',
                      Action: [
                        'logs:CreateLogGroup',
                        'logs:CreateLogStream',
                        'logs:DescribeLogGroups',
                        'logs:DescribeLogStreams',
                        'logs:PutLogEvents',
                        'logs:GetLogEvents',
                        'logs:FilterLogEvents',
                      ],
                      Resource: '*',
                    },
                    {
                      Effect: 'Allow',
                      Action: 'states:*',
                      Resource: '*',
                    },
                    {
                      Effect: 'Allow',
                      Action: 'logs:CreateLogGroup',
                      Resource: 'arn:aws:logs:us-east-1:973651203340:*',
                    },
                    {
                      Effect: 'Allow',
                      Action: ['logs:CreateLogStream', 'logs:PutLogEvents'],
                      Resource: [
                        'arn:aws:logs:us-east-1:973651203340:log-group:/aws/lambda/my-app-lambda:*',
                        'arn:aws:logs:us-east-1:973651203340:log-group:/aws/codebuild/*',
                      ],
                    },
                    {
                      Effect: 'Allow',
                      Action: ['ecr:*', 'cloudtrail:LookupEvents'],
                      Resource: '*',
                    },
                    {
                      Sid: 'CloudWatchEventsFullAccess',
                      Effect: 'Allow',
                      Action: 'events:*',
                      Resource: '*',
                    },
                    {
                      Action: ['iam:PassRole'],
                      Resource: '*',
                      Effect: 'Allow',
                      Condition: {
                        StringEqualsIfExists: {
                          'iam:PassedToService': [
                            'cloudformation.amazonaws.com',
                            'elasticbeanstalk.amazonaws.com',
                            'ec2.amazonaws.com',
                            'ecs-tasks.amazonaws.com',
                          ],
                        },
                      },
                    },
                    {
                      Action: [
                        'codecommit:CancelUploadArchive',
                        'codecommit:GetBranch',
                        'codecommit:GetCommit',
                        'codecommit:GetUploadArchiveStatus',
                        'codecommit:UploadArchive',
                      ],
                      Resource: '*',
                      Effect: 'Allow',
                    },
                    {
                      Action: [
                        'codedeploy:CreateDeployment',
                        'codedeploy:GetApplication',
                        'codedeploy:GetApplicationRevision',
                        'codedeploy:GetDeployment',
                        'codedeploy:GetDeploymentConfig',
                        'codedeploy:RegisterApplicationRevision',
                      ],
                      Resource: '*',
                      Effect: 'Allow',
                    },
                    {
                      Action: ['codestar-connections:UseConnection'],
                      Resource: '*',
                      Effect: 'Allow',
                    },
                    {
                      Action: [
                        'elasticbeanstalk:*',
                        'ec2:*',
                        'elasticloadbalancing:*',
                        'autoscaling:*',
                        'cloudwatch:*',
                        's3:*',
                        'sns:*',
                        'cloudformation:*',
                        'rds:*',
                        'sqs:*',
                        'ecs:*',
                      ],
                      Resource: '*',
                      Effect: 'Allow',
                    },
                    {
                      Action: ['lambda:InvokeFunction', 'lambda:ListFunctions'],
                      Resource: '*',
                      Effect: 'Allow',
                    },
                    {
                      Action: [
                        'opsworks:CreateDeployment',
                        'opsworks:DescribeApps',
                        'opsworks:DescribeCommands',
                        'opsworks:DescribeDeployments',
                        'opsworks:DescribeInstances',
                        'opsworks:DescribeStacks',
                        'opsworks:UpdateApp',
                        'opsworks:UpdateStack',
                      ],
                      Resource: '*',
                      Effect: 'Allow',
                    },
                    {
                      Action: [
                        'cloudformation:CreateStack',
                        'cloudformation:DeleteStack',
                        'cloudformation:DescribeStacks',
                        'cloudformation:UpdateStack',
                        'cloudformation:CreateChangeSet',
                        'cloudformation:DeleteChangeSet',
                        'cloudformation:DescribeChangeSet',
                        'cloudformation:ExecuteChangeSet',
                        'cloudformation:SetStackPolicy',
                        'cloudformation:ValidateTemplate',
                      ],
                      Resource: '*',
                      Effect: 'Allow',
                    },
                    {
                      Action: [
                        'codebuild:BatchGetBuilds',
                        'codebuild:StartBuild',
                        'codebuild:BatchGetBuildBatches',
                        'codebuild:StartBuildBatch',
                      ],
                      Resource: '*',
                      Effect: 'Allow',
                    },
                    {
                      Effect: 'Allow',
                      Action: [
                        'devicefarm:ListProjects',
                        'devicefarm:ListDevicePools',
                        'devicefarm:GetRun',
                        'devicefarm:GetUpload',
                        'devicefarm:CreateUpload',
                        'devicefarm:ScheduleRun',
                      ],
                      Resource: '*',
                    },
                    {
                      Effect: 'Allow',
                      Action: [
                        'servicecatalog:ListProvisioningArtifacts',
                        'servicecatalog:CreateProvisioningArtifact',
                        'servicecatalog:DescribeProvisioningArtifact',
                        'servicecatalog:DeleteProvisioningArtifact',
                        'servicecatalog:UpdateProduct',
                      ],
                      Resource: '*',
                    },
                    {
                      Effect: 'Allow',
                      Action: ['cloudformation:ValidateTemplate'],
                      Resource: '*',
                    },
                    {
                      Effect: 'Allow',
                      Action: ['ecr:DescribeImages'],
                      Resource: '*',
                    },
                    {
                      Effect: 'Allow',
                      Action: [
                        'states:DescribeExecution',
                        'states:DescribeStateMachine',
                        'states:StartExecution',
                      ],
                      Resource: '*',
                    },
                    {
                      Effect: 'Allow',
                      Action: [
                        'appconfig:StartDeployment',
                        'appconfig:StopDeployment',
                        'appconfig:GetDeployment',
                      ],
                      Resource: '*',
                    },
                    {
                      Sid: 'IAMPassRoleForCloudWatchEvents',
                      Effect: 'Allow',
                      Action: 'iam:PassRole',
                      Resource: 'arn:aws:iam::*:role/AWS_Events_Invoke_Targets',
                    },
                    {
                      Effect: 'Allow',
                      Action: [
                        'xray:PutTraceSegments',
                        'xray:PutTelemetryRecords',
                        'xray:GetSamplingRules',
                        'xray:GetSamplingTargets',
                      ],
                      Resource: ['*'],
                    },
                    {
                      Effect: 'Allow',
                      Action: [
                        'cloudformation:DescribeChangeSet',
                        'cloudformation:DescribeStackResources',
                        'cloudformation:DescribeStacks',
                        'cloudformation:GetTemplate',
                        'cloudformation:ListStackResources',
                        'cloudwatch:*',
                        'cognito-identity:ListIdentityPools',
                        'cognito-sync:GetCognitoEvents',
                        'cognito-sync:SetCognitoEvents',
                        'dynamodb:*',
                        'ec2:DescribeSecurityGroups',
                        'ec2:DescribeSubnets',
                        'ec2:DescribeVpcs',
                        'events:*',
                        'iam:GetPolicy',
                        'iam:GetPolicyVersion',
                        'iam:GetRole',
                        'iam:GetRolePolicy',
                        'iam:ListAttachedRolePolicies',
                        'iam:ListRolePolicies',
                        'iam:ListRoles',
                        'iam:PassRole',
                        'iot:AttachPrincipalPolicy',
                        'iot:AttachThingPrincipal',
                        'iot:CreateKeysAndCertificate',
                        'iot:CreatePolicy',
                        'iot:CreateThing',
                        'iot:CreateTopicRule',
                        'iot:DescribeEndpoint',
                        'iot:GetTopicRule',
                        'iot:ListPolicies',
                        'iot:ListThings',
                        'iot:ListTopicRules',
                        'iot:ReplaceTopicRule',
                        'kinesis:DescribeStream',
                        'kinesis:ListStreams',
                        'kinesis:PutRecord',
                        'kms:ListAliases',
                        'codebuild:*',
                        'lambda:*',
                        'logs:*',
                        's3:*',
                        'sns:ListSubscriptions',
                        'sns:ListSubscriptionsByTopic',
                        'sns:ListTopics',
                        'sns:Publish',
                        'sns:Subscribe',
                        'sns:Unsubscribe',
                        'sqs:ListQueues',
                        'sqs:SendMessage',
                        'tag:GetResources',
                        'xray:PutTelemetryRecords',
                        'xray:PutTraceSegments',
                      ],
                      Resource: '*',
                    },
                  ],
                };

                let awsRolePolicy = {
                  Version: '2012-10-17',
                  Statement: {
                    Effect: 'Allow',
                    Principal: {
                      Service: [
                        'lambda.amazonaws.com',
                        'apigateway.amazonaws.com',
                        'codebuild.amazonaws.com',
                      ],
                    },
                    Action: 'sts:AssumeRole',
                  },
                };

                const jsonPolicyDocument = JSON.stringify(awsPolicyDocument);
                const awsPolicyDocumentReplace = jsonPolicyDocument
                  .replace(/\\n/g, '\\n')
                  .replace(/\\'/g, "\\'")
                  .replace(/\\"/g, '\\"')
                  .replace(/\\&/g, '\\&')
                  .replace(/\\r/g, '\\r')
                  .replace(/\\t/g, '\\t')
                  .replace(/\\b/g, '\\b')
                  .replace(/\\f/g, '\\f');

                const jsonAwsRolePolicy = JSON.stringify(awsRolePolicy);
                const awsRolePolicyReplace = jsonAwsRolePolicy
                  .replace(/\\n/g, '\\n')
                  .replace(/\\'/g, "\\'")
                  .replace(/\\"/g, '\\"')
                  .replace(/\\&/g, '\\&')
                  .replace(/\\r/g, '\\r')
                  .replace(/\\t/g, '\\t')
                  .replace(/\\b/g, '\\b')
                  .replace(/\\f/g, '\\f');
                const role_name = `KOR-TEMPLATE-${Date.now()}`;
                const roleInfo = {
                  role_name,
                  role_policy: awsRolePolicyReplace,
                  habitat_id: habitat.id,
                  policy_document: awsPolicyDocumentReplace,
                };

                createAWSRole(roleInfo)
                  .then((response) => {
                    const role = response.data;
                    rollBackInfo.rol_id = role.id;
                    getDomain(habitat.id)
                      .then((response) => {
                        const payload = {
                          role,
                          name,
                          awsCred,
                          habitat,
                          domains: response.data,
                        };
                        dispatch({
                          type: ADD_HABITAT_AND_CREDS_TEMPLATE_SUCCCESS,
                          payload,
                        });
                      })
                      .catch((error) => {
                        dispatch({
                          type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR,
                          error: error.response.data.error,
                        });
                        rollBack(rollBackInfo);
                      });
                  })
                  .catch((error) => {
                    dispatch({
                      type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR,
                      error: error.response.data.error,
                    });
                    rollBack(rollBackInfo);
                  });
              })
              .catch((error) => {
                dispatch({ type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR, error: error.response.data.error });
                rollBack(rollBackInfo);
              });
          })
          .catch((error) => {
            dispatch({ type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR, error: error.response.data.error });
            rollBack(rollBackInfo);
          });
      })
      .catch((error) =>
        dispatch({ type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR, error: error.response.data.error })
      );
  };
};

export const addHabitadWithURL = (team_id) => {
  return (dispatch) => {
    dispatch({
      type: ADD_HABITAT_AND_CREDS_TEMPLATE_BEGIN,
      loading_message: 'Creating template...',
    });
    const name = randomRickandMorty.rickAndMortyCharacter();
    const habitat = {
      name,
      team_id,
    };
    awsRedirect()
      .then((response) => {
        window.open(response.data.aws_link, '_blank');
        const accessInfo = {
          topic_arn: response.data.topic_arn,
          role_name: response.data.role_name,
        };
        accessAwsCredential(accessInfo)
          .then((response) => {
            const awsCred =
              response.data.aws_credentials[
                response.data.aws_credentials.length - 1
              ];
            createHabitat(habitat)
              .then((response) => {
                const habitat = response.data;
                const rollBackInfo = {
                  habitat_id: habitat.id,
                  rol_id: null,
                };
                const newHabCredId = { aws_credential_id: awsCred.id };
                verifyCreds(awsCred.id)
                  .then((response) => {
                    awsCred.verified = true;
                    attachCredHabitat(habitat.id, newHabCredId)
                      .then((response) => {
                        let awsPolicyDocument = {
                          Version: '2012-10-17',
                          Statement: [
                            {
                              Effect: 'Allow',
                              Action: [
                                'logs:CreateLogGroup',
                                'logs:CreateLogStream',
                                'logs:DescribeLogGroups',
                                'logs:DescribeLogStreams',
                                'logs:PutLogEvents',
                                'logs:GetLogEvents',
                                'logs:FilterLogEvents',
                              ],
                              Resource: '*',
                            },
                            {
                              Effect: 'Allow',
                              Action: 'states:*',
                              Resource: '*',
                            },
                            {
                              Effect: 'Allow',
                              Action: 'logs:CreateLogGroup',
                              Resource: 'arn:aws:logs:us-east-1:973651203340:*',
                            },
                            {
                              Effect: 'Allow',
                              Action: [
                                'logs:CreateLogStream',
                                'logs:PutLogEvents',
                              ],
                              Resource: [
                                'arn:aws:logs:us-east-1:973651203340:log-group:/aws/lambda/my-app-lambda:*',
                                'arn:aws:logs:us-east-1:973651203340:log-group:/aws/codebuild/*',
                              ],
                            },
                            {
                              Effect: 'Allow',
                              Action: ['ecr:*', 'cloudtrail:LookupEvents'],
                              Resource: '*',
                            },
                            {
                              Sid: 'CloudWatchEventsFullAccess',
                              Effect: 'Allow',
                              Action: 'events:*',
                              Resource: '*',
                            },
                            {
                              Action: ['iam:PassRole'],
                              Resource: '*',
                              Effect: 'Allow',
                              Condition: {
                                StringEqualsIfExists: {
                                  'iam:PassedToService': [
                                    'cloudformation.amazonaws.com',
                                    'elasticbeanstalk.amazonaws.com',
                                    'ec2.amazonaws.com',
                                    'ecs-tasks.amazonaws.com',
                                  ],
                                },
                              },
                            },
                            {
                              Action: [
                                'codecommit:CancelUploadArchive',
                                'codecommit:GetBranch',
                                'codecommit:GetCommit',
                                'codecommit:GetUploadArchiveStatus',
                                'codecommit:UploadArchive',
                              ],
                              Resource: '*',
                              Effect: 'Allow',
                            },
                            {
                              Action: [
                                'codedeploy:CreateDeployment',
                                'codedeploy:GetApplication',
                                'codedeploy:GetApplicationRevision',
                                'codedeploy:GetDeployment',
                                'codedeploy:GetDeploymentConfig',
                                'codedeploy:RegisterApplicationRevision',
                              ],
                              Resource: '*',
                              Effect: 'Allow',
                            },
                            {
                              Action: ['codestar-connections:UseConnection'],
                              Resource: '*',
                              Effect: 'Allow',
                            },
                            {
                              Action: [
                                'elasticbeanstalk:*',
                                'ec2:*',
                                'elasticloadbalancing:*',
                                'autoscaling:*',
                                'cloudwatch:*',
                                's3:*',
                                'sns:*',
                                'cloudformation:*',
                                'rds:*',
                                'sqs:*',
                                'ecs:*',
                              ],
                              Resource: '*',
                              Effect: 'Allow',
                            },
                            {
                              Action: [
                                'lambda:InvokeFunction',
                                'lambda:ListFunctions',
                              ],
                              Resource: '*',
                              Effect: 'Allow',
                            },
                            {
                              Action: [
                                'opsworks:CreateDeployment',
                                'opsworks:DescribeApps',
                                'opsworks:DescribeCommands',
                                'opsworks:DescribeDeployments',
                                'opsworks:DescribeInstances',
                                'opsworks:DescribeStacks',
                                'opsworks:UpdateApp',
                                'opsworks:UpdateStack',
                              ],
                              Resource: '*',
                              Effect: 'Allow',
                            },
                            {
                              Action: [
                                'cloudformation:CreateStack',
                                'cloudformation:DeleteStack',
                                'cloudformation:DescribeStacks',
                                'cloudformation:UpdateStack',
                                'cloudformation:CreateChangeSet',
                                'cloudformation:DeleteChangeSet',
                                'cloudformation:DescribeChangeSet',
                                'cloudformation:ExecuteChangeSet',
                                'cloudformation:SetStackPolicy',
                                'cloudformation:ValidateTemplate',
                              ],
                              Resource: '*',
                              Effect: 'Allow',
                            },
                            {
                              Action: [
                                'codebuild:BatchGetBuilds',
                                'codebuild:StartBuild',
                                'codebuild:BatchGetBuildBatches',
                                'codebuild:StartBuildBatch',
                              ],
                              Resource: '*',
                              Effect: 'Allow',
                            },
                            {
                              Effect: 'Allow',
                              Action: [
                                'devicefarm:ListProjects',
                                'devicefarm:ListDevicePools',
                                'devicefarm:GetRun',
                                'devicefarm:GetUpload',
                                'devicefarm:CreateUpload',
                                'devicefarm:ScheduleRun',
                              ],
                              Resource: '*',
                            },
                            {
                              Effect: 'Allow',
                              Action: [
                                'servicecatalog:ListProvisioningArtifacts',
                                'servicecatalog:CreateProvisioningArtifact',
                                'servicecatalog:DescribeProvisioningArtifact',
                                'servicecatalog:DeleteProvisioningArtifact',
                                'servicecatalog:UpdateProduct',
                              ],
                              Resource: '*',
                            },
                            {
                              Effect: 'Allow',
                              Action: ['cloudformation:ValidateTemplate'],
                              Resource: '*',
                            },
                            {
                              Effect: 'Allow',
                              Action: ['ecr:DescribeImages'],
                              Resource: '*',
                            },
                            {
                              Effect: 'Allow',
                              Action: [
                                'states:DescribeExecution',
                                'states:DescribeStateMachine',
                                'states:StartExecution',
                              ],
                              Resource: '*',
                            },
                            {
                              Effect: 'Allow',
                              Action: [
                                'appconfig:StartDeployment',
                                'appconfig:StopDeployment',
                                'appconfig:GetDeployment',
                              ],
                              Resource: '*',
                            },
                            {
                              Sid: 'IAMPassRoleForCloudWatchEvents',
                              Effect: 'Allow',
                              Action: 'iam:PassRole',
                              Resource:
                                'arn:aws:iam::*:role/AWS_Events_Invoke_Targets',
                            },
                            {
                              Effect: 'Allow',
                              Action: [
                                'xray:PutTraceSegments',
                                'xray:PutTelemetryRecords',
                                'xray:GetSamplingRules',
                                'xray:GetSamplingTargets',
                              ],
                              Resource: ['*'],
                            },
                            {
                              Effect: 'Allow',
                              Action: [
                                'cloudformation:DescribeChangeSet',
                                'cloudformation:DescribeStackResources',
                                'cloudformation:DescribeStacks',
                                'cloudformation:GetTemplate',
                                'cloudformation:ListStackResources',
                                'cloudwatch:*',
                                'cognito-identity:ListIdentityPools',
                                'cognito-sync:GetCognitoEvents',
                                'cognito-sync:SetCognitoEvents',
                                'dynamodb:*',
                                'ec2:DescribeSecurityGroups',
                                'ec2:DescribeSubnets',
                                'ec2:DescribeVpcs',
                                'events:*',
                                'iam:GetPolicy',
                                'iam:GetPolicyVersion',
                                'iam:GetRole',
                                'iam:GetRolePolicy',
                                'iam:ListAttachedRolePolicies',
                                'iam:ListRolePolicies',
                                'iam:ListRoles',
                                'iam:PassRole',
                                'iot:AttachPrincipalPolicy',
                                'iot:AttachThingPrincipal',
                                'iot:CreateKeysAndCertificate',
                                'iot:CreatePolicy',
                                'iot:CreateThing',
                                'iot:CreateTopicRule',
                                'iot:DescribeEndpoint',
                                'iot:GetTopicRule',
                                'iot:ListPolicies',
                                'iot:ListThings',
                                'iot:ListTopicRules',
                                'iot:ReplaceTopicRule',
                                'kinesis:DescribeStream',
                                'kinesis:ListStreams',
                                'kinesis:PutRecord',
                                'kms:ListAliases',
                                'codebuild:*',
                                'lambda:*',
                                'logs:*',
                                's3:*',
                                'sns:ListSubscriptions',
                                'sns:ListSubscriptionsByTopic',
                                'sns:ListTopics',
                                'sns:Publish',
                                'sns:Subscribe',
                                'sns:Unsubscribe',
                                'sqs:ListQueues',
                                'sqs:SendMessage',
                                'tag:GetResources',
                                'xray:PutTelemetryRecords',
                                'xray:PutTraceSegments',
                              ],
                              Resource: '*',
                            },
                          ],
                        };

                        let awsRolePolicy = {
                          Version: '2012-10-17',
                          Statement: {
                            Effect: 'Allow',
                            Principal: {
                              Service: [
                                'lambda.amazonaws.com',
                                'apigateway.amazonaws.com',
                                'codebuild.amazonaws.com',
                              ],
                            },
                            Action: 'sts:AssumeRole',
                          },
                        };

                        const jsonPolicyDocument = JSON.stringify(
                          awsPolicyDocument
                        );
                        const awsPolicyDocumentReplace = jsonPolicyDocument
                          .replace(/\\n/g, '\\n')
                          .replace(/\\'/g, "\\'")
                          .replace(/\\"/g, '\\"')
                          .replace(/\\&/g, '\\&')
                          .replace(/\\r/g, '\\r')
                          .replace(/\\t/g, '\\t')
                          .replace(/\\b/g, '\\b')
                          .replace(/\\f/g, '\\f');

                        const jsonAwsRolePolicy = JSON.stringify(awsRolePolicy);
                        const awsRolePolicyReplace = jsonAwsRolePolicy
                          .replace(/\\n/g, '\\n')
                          .replace(/\\'/g, "\\'")
                          .replace(/\\"/g, '\\"')
                          .replace(/\\&/g, '\\&')
                          .replace(/\\r/g, '\\r')
                          .replace(/\\t/g, '\\t')
                          .replace(/\\b/g, '\\b')
                          .replace(/\\f/g, '\\f');
                        const role_name = `KOR-TEMPLATE-${Date.now()}`;
                        const roleInfo = {
                          role_name,
                          role_policy: awsRolePolicyReplace,
                          habitat_id: habitat.id,
                          policy_document: awsPolicyDocumentReplace,
                        };

                        createAWSRole(roleInfo)
                          .then((response) => {
                            const role = response.data;
                            rollBackInfo.rol_id = role.id;
                            getDomain(habitat.id)
                              .then((response) => {
                                const payload = {
                                  role,
                                  name,
                                  awsCred,
                                  habitat,
                                  domains: response.data,
                                };
                                dispatch({
                                  type: ADD_HABITAT_AND_CREDS_TEMPLATE_SUCCCESS,
                                  payload,
                                });
                              })
                              .catch((error) => {
                                dispatch({
                                  type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR,
                                  error: error.response.data.error,
                                });
                                rollBack(rollBackInfo);
                              });
                          })
                          .catch((error) => {
                            dispatch({
                              type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR,
                              error: error.response.data.error,
                            });
                            rollBack(rollBackInfo);
                          });
                      })
                      .catch((error) => {
                        dispatch({
                          type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR,
                          error: error.response.data.error,
                        });
                        rollBack(rollBackInfo);
                      });
                  })
                  .catch((error) => {
                    dispatch({
                      type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR,
                      error: error.response.data.error,
                    });
                    rollBack(rollBackInfo);
                  });
              })
              .catch((error) => {
                dispatch({
                  type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR,
                  error: error.response.data.error,
                });
                rollBack(rollBackInfo);
              });
          })
          .catch((error) => {
            dispatch({
              type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR,
              error: error.response.data.error,
            });
            rollBack(rollBackInfo);
          });
      })
      .catch((error) =>
        dispatch({
          type: ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR,
          error: error.response.data.error,
        })
      );
  };
};

export const deployTemplate = (
  uri,
  name,
  prefix,
  region,
  team_id,
  habitat_id,
  domain_name,
  git_credential_id
) => {
  return (dispatch) => {
    let bucket;
    if (prefix) {
      bucket = `${prefix}.${domain_name}`;
    } else {
      bucket = domain_name;
    }
    const s3Static = {
      bucket,
      habitat_id,
      region,
      public: 1,
    };
    const project_name = `kor-static-template-${Date.now()}`;
    let rollBackInfo = {
      habitat_id,
      git_site: null,
    };
    dispatch({
      type: CREATE_STATIC_TEMPLATE_BEGIN,
      loading_message: 'Loading deploy template...',
    });
    addStaticBucket(s3Static)
      .then((response) => {
        const gitCloneInfo = {
          uri,
          project_name,
          team_id,
          git_credential_id: '',
        };
        gitClone(gitCloneInfo)
          .then((response) => {
            const repository_id = response.data.repo.id;
            rollBackInfo.git_site = repository_id;
            const git_hash = response.git_hash;
            const upload = {
              habitat_id,
              file_name: project_name,
              bucket,
              team_id,
            };
            uploadFolder(upload)
              .then((response) => {
                const templateInfo = {
                  name: project_name,
                  domain_name: bucket,
                  repository_id,
                  habitat_id,
                  git_hash,
                  category: 'static site',
                };
                createTemplate(templateInfo)
                  .then((response) => {
                    const template = response.data.template;
                    const requestInfo = {
                      site_id: template.id,
                      domain_name,
                      prefix,
                      habitat_id,
                    };
                    requestDomain(requestInfo)
                      .then((response) => {
                        const distributionInfo = {
                          site_id: template.id,
                          distribution_name: project_name,
                          end_point: `${bucket}.s3-website.${region}.amazonaws.com`,
                          domain_name: bucket,
                          repository_id,
                          habitat_id,
                          root_object: 'index.html',
                        };
                        contentDistribution(distributionInfo)
                          .then((response) => {
                            const dns_name = response.data.cloud_front_domain;
                            let domainPrefix;
                            prefix.length > 0
                              ? (domainPrefix = `${prefix}.`)
                              : (domainPrefix = null);
                            const domainInfo = {
                              dns_name,
                              domain_name,
                              type: 'A',
                              prefix: domainPrefix,
                              cf_hosted_zone_id: 'Z2FDTNDATAQYW2',
                              habitat_id,
                            };
                            addDomian(domainInfo)
                              .then((reponse) => {
                                const repoData = {
                                  credential_id: git_credential_id,
                                  name: project_name
                                }
                                gitCreateRepo(repoData)
                                .then(response => {
                                  let uri;
                                  if (response.data.repository.http_url_to_repo) {
                                    uri = response.data.repository.http_url_to_repo;
                                  } else {
                                    uri = `${response.data.repository.html_url}.git`;
                                  }
                                  const remoteData = {
                                    id: repository_id,
                                    git_credential_id,
                                    uri
                                  }
                                  gitRemote(remoteData)
                                  .then(response => {
                                    const hookTemplateInfo = {
                                      git_credential_id,
                                      clone_id: repository_id
                                    }
                                    gitCreateHook(hookTemplateInfo)
                                    .then(response => {
                                      dispatch({
                                        type: CREATE_STATIC_TEMPLATE_SUCCESS,
                                      });
                                    })
                                    .catch((error) => {
                                      const error_message = 'Error at Add Domain.';
                                      dispatch({
                                        type: CREATE_STATIC_TEMPLATE_ERROR,
                                        error: error_message,
                                      });
                                      rollBack(rollBackInfo).catch((error) => {
                                        dispatch({
                                          type: CREATE_STATIC_TEMPLATE_ERROR,
                                          error: `${error_message} rollback faild, please delete resources left`,
                                        });
                                      });
                                    });
                                  })
                                  .catch((error) => {
                                    const error_message = 'Error at Add Domain.';
                                    dispatch({
                                      type: CREATE_STATIC_TEMPLATE_ERROR,
                                      error: error_message,
                                    });
                                    rollBack(rollBackInfo).catch((error) => {
                                      dispatch({
                                        type: CREATE_STATIC_TEMPLATE_ERROR,
                                        error: `${error_message} rollback faild, please delete resources left`,
                                      });
                                    });
                                  });
                                })
                                .catch((error) => {
                                  const error_message = 'Error at Add Domain.';
                                  dispatch({
                                    type: CREATE_STATIC_TEMPLATE_ERROR,
                                    error: error_message,
                                  });
                                  rollBack(rollBackInfo).catch((error) => {
                                    dispatch({
                                      type: CREATE_STATIC_TEMPLATE_ERROR,
                                      error: `${error_message} rollback faild, please delete resources left`,
                                    });
                                  });
                                });
                              })
                              .catch((error) => {
                                const error_message = 'Error at Add Domain.';
                                dispatch({
                                  type: CREATE_STATIC_TEMPLATE_ERROR,
                                  error: error_message,
                                });
                                rollBack(rollBackInfo).catch((error) => {
                                  dispatch({
                                    type: CREATE_STATIC_TEMPLATE_ERROR,
                                    error: `${error_message} rollback faild, please delete resources left`,
                                  });
                                });
                              });
                          })
                          .catch((error) => {
                            const error_message =
                              error.response.data.error;
                            dispatch({
                              type: CREATE_STATIC_TEMPLATE_ERROR,
                              error: error_message,
                            });
                            rollBack(rollBackInfo);
                          });
                      })
                      .catch((error) => {
                        const error_message = 'Error at Request Domain.';
                        dispatch({
                          type: CREATE_STATIC_TEMPLATE_ERROR,
                          error: error_message,
                        });
                        rollBack(rollBackInfo);
                      });
                  })
                  .catch((error) => {
                    const error_message = 'Error at Create Template.';
                    dispatch({
                      type: CREATE_STATIC_TEMPLATE_ERROR,
                      error: error_message,
                    });
                    rollBack(rollBackInfo);
                  });
              })
              .catch((error) => {
                const error_message = 'Error at Upload Folder.';
                dispatch({
                  type: CREATE_STATIC_TEMPLATE_ERROR,
                  error: error_message,
                });
                rollBack(rollBackInfo);
              });
          })
          .catch((error) => {
            const error_message = 'Error at Git Clone.';
            dispatch({
              type: CREATE_STATIC_TEMPLATE_ERROR,
              error: error_message,
            });
            rollBack(rollBackInfo);
          });
      })
      .catch((error) => {
        const error_message = 'Error at S3 Bucket Creation.';
        dispatch({
          type: CREATE_STATIC_TEMPLATE_ERROR,
          error: error_message,
        });
        rollBack(rollBackInfo);
      });
  };
};

export const deployTemplateWithoutDomain = (
  uri,
  name,
  prefix,
  region,
  team_id,
  habitat_id,
  domain_name,
  git_credential_id
) => {
  return (dispatch) => {
    let bucket = `static-site-${Date.now()}`;
    const s3Static = {
      bucket,
      habitat_id,
      region,
      public: 1,
    };

    let rollBackInfo = {
      habitat_id,
      git_site: null,
    };
    dispatch({
      type: CREATE_STATIC_WITHOUTDOMAIN_BEGIN,
      loading_message: 'Loading deploy template...',
    });
    addStaticBucket(s3Static)
      .then((response) => {
        const gitCloneInfo = {
          uri,
          project_name: bucket,
          team_id,
          git_credential_id: '',
        };
        gitClone(gitCloneInfo)
          .then((response) => {
            const repository_id = response.data.repo.id;
            rollBackInfo.git_site = repository_id;
            const git_hash = response.git_hash;
            const upload = {
              habitat_id,
              file_name: bucket,
              bucket,
              team_id,
            };
            uploadFolder(upload)
              .then((response) => {
                const templateInfo = {
                  name: bucket,
                  domain_name: '',
                  repository_id,
                  habitat_id,
                  git_hash,
                  category: 'static site',
                };
                createTemplate(templateInfo)
                  .then((response) => {
                    const template = response.data.template;
                    const requestInfo = {
                      site_id: template.id,
                      domain_name,
                      prefix,
                      habitat_id,
                    };
                    const distributionInfo = {
                      site_id: template.id,
                      distribution_name: bucket,
                      end_point: `${bucket}.s3-website.${region}.amazonaws.com`,
                      domain_name: '',
                      repository_id,
                      habitat_id,
                      root_object: 'index.html',
                    };
                    contentDistribution(distributionInfo)
                      .then((response) => {
                        const payload = response.data;
                        const repoData = {
                          credential_id: git_credential_id,
                          name: bucket
                        }
                        gitCreateRepo(repoData)
                        .then(response => {
                          let uri;
                          if (response.data.repository.http_url_to_repo) {
                            uri = response.data.repository.http_url_to_repo;
                          } else {
                            uri = `${response.data.repository.html_url}.git`;
                          }
                          const remoteData = {
                            id: repository_id,
                            git_credential_id,
                            uri
                          }
                          gitRemote(remoteData)
                          .then(response => {
                            const hookTemplateInfo = {
                              git_credential_id,
                              clone_id: repository_id
                            }
                            gitCreateHook(hookTemplateInfo)
                            .then(response => {
                              dispatch({
                                type: CREATE_STATIC_WITHOUTDOMAIN_SUCCESS,
                                payload
                              });
                            })
                            .catch((error) => {
                              const error_message = error.response.data.error;
                              dispatch({
                                type: CREATE_STATIC_WITHOUTDOMAIN_ERROR,
                                error: error_message,
                              });
                              rollBack(rollBackInfo);
                            });
                          })
                          .catch((error) => {
                            const error_message = error.response.data.error;
                            dispatch({
                              type: CREATE_STATIC_WITHOUTDOMAIN_ERROR,
                              error: error_message,
                            });
                            rollBack(rollBackInfo);
                          });
                        })
                        .catch((error) => {
                          const error_message = error.response.data.error;
                          dispatch({
                            type: CREATE_STATIC_WITHOUTDOMAIN_ERROR,
                            error: error_message,
                          });
                          rollBack(rollBackInfo);
                        });
                      })
                      .catch((error) => {
                        const error_message = error.response.data.error;
                        dispatch({
                          type: CREATE_STATIC_WITHOUTDOMAIN_ERROR,
                          error: error_message,
                        });
                        rollBack(rollBackInfo);
                      });
                  })
                  .catch((error) => {
                    const error_message = error.response.data.error;
                    dispatch({
                      type: CREATE_STATIC_TEMPLATE_ERROR,
                      error: error_message,
                    });
                    rollBack(rollBackInfo);
                  });
              })
              .catch((error) => {
                const error_message = 'Error at Upload Folder.';
                dispatch({
                  type: CREATE_STATIC_TEMPLATE_ERROR,
                  error: error_message,
                });
                rollBack(rollBackInfo);
              });
          })
          .catch((error) => {
            const error_message = 'Error at Git Clone.';
            dispatch({
              type: CREATE_STATIC_TEMPLATE_ERROR,
              error: error_message,
            });
            rollBack(rollBackInfo);
          });
      })
      .catch((error) => {
        const error_message = 'Error at S3 Bucket Creation.';
        dispatch({
          type: CREATE_STATIC_TEMPLATE_ERROR,
          error: error_message,
        });
        rollBack(rollBackInfo);
      });
  };
};

export const deployNewWebApp = (
  team_id,
  nameCred,
  apiKeyCred,
  apiSecretCred,
  regionCred,
  uri,
  prefix,
  domain_name,
  name,
  git_credential_id,
  cognitoName,
  apiName,
  pathApi
) => {
  return (dispatch) => {
    let bucket;
    let staticBucket;
    let endpoint;
    let userPoolId;
    let userPoolClientId;
    const roleName = `KOR-ROLE-${name}-${Date.now()}`;
    const lambdaName = `KOR-LAMBDA-${name}-${Date.now()}`;
    const runtimeLambda = 'nodejs12.x';
    const handlerLambda = 'index.handler';
    const habitat = {
      name,
      team_id: team_id,
    };

    if (prefix) {
      bucket = `${prefix}.${domain_name}`;
      staticBucket = `${prefix}.${domain_name}`;
    } else {
      bucket = domain_name;
      staticBucket = domain_name;
    }

    const algorithm = 'aes-256-cfb';
    let key = '';
    const iv = '0000000000000000';

    dispatch({
      type: CREATE_WEB_APP_TEMPLATE_BEGIN,
      loading_message: 'Creating template...',
    });
    createHabitat(habitat)
      .then((response) => {
        let habitat_id = response.data.id;
        let rollBackInfo = {
          habitat_id,
          rol_id: null,
          git_site: null,
          git_lambda: null,
        };
        const payloadHabitat = response.data;

        requestEncryptKey(habitat_id)
          .then((response) => {
            key = response.data.key;

            var cipherApiKey = crypto.createCipheriv(algorithm, key, iv);
            var cryptedApiKey = cipherApiKey.update(
              apiKeyCred,
              'utf-8',
              'base64'
            );
            cryptedApiKey += cipherApiKey.final('base64');

            var cipherApiSecret = crypto.createCipheriv(algorithm, key, iv);
            var cryptedApiSecret = cipherApiSecret.update(
              apiSecretCred,
              'utf-8',
              'base64'
            );
            cryptedApiSecret += cipherApiSecret.final('base64');

            let awsCreds = awsCredModel(
              nameCred,
              cryptedApiKey,
              cryptedApiSecret,
              regionCred,
              habitat_id,
              key
            );

            createAWSCredential(awsCreds)
              .then((response) => {
                const credId = response.data.id;
                let newHabCredId = { aws_credential_id: credId };

                verifyCreds(credId)
                  .then((response) => {
                    attachCredHabitat(habitat_id, newHabCredId)
                      .then((response) => {
                        const s3Static = {
                          bucket,
                          habitat_id,
                          regionCred,
                          public: 1,
                        };
                        addStaticBucket(s3Static)
                          .then((response) => {
                            let awsPolicyDocument = {
                              Version: '2012-10-17',
                              Statement: [
                                {
                                  Effect: 'Allow',
                                  Action: [
                                    'logs:CreateLogGroup',
                                    'logs:CreateLogStream',
                                    'logs:DescribeLogGroups',
                                    'logs:DescribeLogStreams',
                                    'logs:PutLogEvents',
                                    'logs:GetLogEvents',
                                    'logs:FilterLogEvents',
                                  ],
                                  Resource: '*',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: 'states:*',
                                  Resource: '*',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: 'logs:CreateLogGroup',
                                  Resource:
                                    'arn:aws:logs:us-east-1:973651203340:*',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: [
                                    'logs:CreateLogStream',
                                    'logs:PutLogEvents',
                                  ],
                                  Resource: [
                                    'arn:aws:logs:us-east-1:973651203340:log-group:/aws/lambda/my-app-lambda:*',
                                    'arn:aws:logs:us-east-1:973651203340:log-group:/aws/codebuild/*',
                                  ],
                                },
                                {
                                  Effect: 'Allow',
                                  Action: ['ecr:*', 'cloudtrail:LookupEvents'],
                                  Resource: '*',
                                },
                                {
                                  Sid: 'CloudWatchEventsFullAccess',
                                  Effect: 'Allow',
                                  Action: 'events:*',
                                  Resource: '*',
                                },
                                {
                                  Action: ['iam:PassRole'],
                                  Resource: '*',
                                  Effect: 'Allow',
                                  Condition: {
                                    StringEqualsIfExists: {
                                      'iam:PassedToService': [
                                        'cloudformation.amazonaws.com',
                                        'elasticbeanstalk.amazonaws.com',
                                        'ec2.amazonaws.com',
                                        'ecs-tasks.amazonaws.com',
                                      ],
                                    },
                                  },
                                },
                                {
                                  Action: [
                                    'codecommit:CancelUploadArchive',
                                    'codecommit:GetBranch',
                                    'codecommit:GetCommit',
                                    'codecommit:GetUploadArchiveStatus',
                                    'codecommit:UploadArchive',
                                  ],
                                  Resource: '*',
                                  Effect: 'Allow',
                                },
                                {
                                  Action: [
                                    'codedeploy:CreateDeployment',
                                    'codedeploy:GetApplication',
                                    'codedeploy:GetApplicationRevision',
                                    'codedeploy:GetDeployment',
                                    'codedeploy:GetDeploymentConfig',
                                    'codedeploy:RegisterApplicationRevision',
                                  ],
                                  Resource: '*',
                                  Effect: 'Allow',
                                },
                                {
                                  Action: [
                                    'codestar-connections:UseConnection',
                                  ],
                                  Resource: '*',
                                  Effect: 'Allow',
                                },
                                {
                                  Action: [
                                    'elasticbeanstalk:*',
                                    'ec2:*',
                                    'elasticloadbalancing:*',
                                    'autoscaling:*',
                                    'cloudwatch:*',
                                    's3:*',
                                    'sns:*',
                                    'cloudformation:*',
                                    'rds:*',
                                    'sqs:*',
                                    'ecs:*',
                                  ],
                                  Resource: '*',
                                  Effect: 'Allow',
                                },
                                {
                                  Action: [
                                    'lambda:InvokeFunction',
                                    'lambda:ListFunctions',
                                  ],
                                  Resource: '*',
                                  Effect: 'Allow',
                                },
                                {
                                  Action: [
                                    'opsworks:CreateDeployment',
                                    'opsworks:DescribeApps',
                                    'opsworks:DescribeCommands',
                                    'opsworks:DescribeDeployments',
                                    'opsworks:DescribeInstances',
                                    'opsworks:DescribeStacks',
                                    'opsworks:UpdateApp',
                                    'opsworks:UpdateStack',
                                  ],
                                  Resource: '*',
                                  Effect: 'Allow',
                                },
                                {
                                  Action: [
                                    'cloudformation:CreateStack',
                                    'cloudformation:DeleteStack',
                                    'cloudformation:DescribeStacks',
                                    'cloudformation:UpdateStack',
                                    'cloudformation:CreateChangeSet',
                                    'cloudformation:DeleteChangeSet',
                                    'cloudformation:DescribeChangeSet',
                                    'cloudformation:ExecuteChangeSet',
                                    'cloudformation:SetStackPolicy',
                                    'cloudformation:ValidateTemplate',
                                  ],
                                  Resource: '*',
                                  Effect: 'Allow',
                                },
                                {
                                  Action: [
                                    'codebuild:BatchGetBuilds',
                                    'codebuild:StartBuild',
                                    'codebuild:BatchGetBuildBatches',
                                    'codebuild:StartBuildBatch',
                                  ],
                                  Resource: '*',
                                  Effect: 'Allow',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: [
                                    'devicefarm:ListProjects',
                                    'devicefarm:ListDevicePools',
                                    'devicefarm:GetRun',
                                    'devicefarm:GetUpload',
                                    'devicefarm:CreateUpload',
                                    'devicefarm:ScheduleRun',
                                  ],
                                  Resource: '*',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: [
                                    'servicecatalog:ListProvisioningArtifacts',
                                    'servicecatalog:CreateProvisioningArtifact',
                                    'servicecatalog:DescribeProvisioningArtifact',
                                    'servicecatalog:DeleteProvisioningArtifact',
                                    'servicecatalog:UpdateProduct',
                                  ],
                                  Resource: '*',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: ['cloudformation:ValidateTemplate'],
                                  Resource: '*',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: ['ecr:DescribeImages'],
                                  Resource: '*',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: [
                                    'states:DescribeExecution',
                                    'states:DescribeStateMachine',
                                    'states:StartExecution',
                                  ],
                                  Resource: '*',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: [
                                    'appconfig:StartDeployment',
                                    'appconfig:StopDeployment',
                                    'appconfig:GetDeployment',
                                  ],
                                  Resource: '*',
                                },
                                {
                                  Sid: 'IAMPassRoleForCloudWatchEvents',
                                  Effect: 'Allow',
                                  Action: 'iam:PassRole',
                                  Resource:
                                    'arn:aws:iam::*:role/AWS_Events_Invoke_Targets',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: [
                                    'xray:PutTraceSegments',
                                    'xray:PutTelemetryRecords',
                                    'xray:GetSamplingRules',
                                    'xray:GetSamplingTargets',
                                  ],
                                  Resource: ['*'],
                                },
                                {
                                  Effect: 'Allow',
                                  Action: [
                                    'cloudformation:DescribeChangeSet',
                                    'cloudformation:DescribeStackResources',
                                    'cloudformation:DescribeStacks',
                                    'cloudformation:GetTemplate',
                                    'cloudformation:ListStackResources',
                                    'cloudwatch:*',
                                    'cognito-identity:ListIdentityPools',
                                    'cognito-sync:GetCognitoEvents',
                                    'cognito-sync:SetCognitoEvents',
                                    'dynamodb:*',
                                    'ec2:DescribeSecurityGroups',
                                    'ec2:DescribeSubnets',
                                    'ec2:DescribeVpcs',
                                    'events:*',
                                    'iam:GetPolicy',
                                    'iam:GetPolicyVersion',
                                    'iam:GetRole',
                                    'iam:GetRolePolicy',
                                    'iam:ListAttachedRolePolicies',
                                    'iam:ListRolePolicies',
                                    'iam:ListRoles',
                                    'iam:PassRole',
                                    'iot:AttachPrincipalPolicy',
                                    'iot:AttachThingPrincipal',
                                    'iot:CreateKeysAndCertificate',
                                    'iot:CreatePolicy',
                                    'iot:CreateThing',
                                    'iot:CreateTopicRule',
                                    'iot:DescribeEndpoint',
                                    'iot:GetTopicRule',
                                    'iot:ListPolicies',
                                    'iot:ListThings',
                                    'iot:ListTopicRules',
                                    'iot:ReplaceTopicRule',
                                    'kinesis:DescribeStream',
                                    'kinesis:ListStreams',
                                    'kinesis:PutRecord',
                                    'kms:ListAliases',
                                    'codebuild:*',
                                    'lambda:*',
                                    'logs:*',
                                    's3:*',
                                    'sns:ListSubscriptions',
                                    'sns:ListSubscriptionsByTopic',
                                    'sns:ListTopics',
                                    'sns:Publish',
                                    'sns:Subscribe',
                                    'sns:Unsubscribe',
                                    'sqs:ListQueues',
                                    'sqs:SendMessage',
                                    'tag:GetResources',
                                    'xray:PutTelemetryRecords',
                                    'xray:PutTraceSegments',
                                  ],
                                  Resource: '*',
                                },
                              ],
                            };

                            let awsRolePolicy = {
                              Version: '2012-10-17',
                              Statement: {
                                Effect: 'Allow',
                                Principal: {
                                  Service: [
                                    'lambda.amazonaws.com',
                                    'apigateway.amazonaws.com',
                                    'codebuild.amazonaws.com',
                                  ],
                                },
                                Action: 'sts:AssumeRole',
                              },
                            };

                            const jsonPolicyDocument = JSON.stringify(
                              awsPolicyDocument
                            );
                            const awsPolicyDocumentReplace = jsonPolicyDocument
                              .replace(/\\n/g, '\\n')
                              .replace(/\\'/g, "\\'")
                              .replace(/\\"/g, '\\"')
                              .replace(/\\&/g, '\\&')
                              .replace(/\\r/g, '\\r')
                              .replace(/\\t/g, '\\t')
                              .replace(/\\b/g, '\\b')
                              .replace(/\\f/g, '\\f');

                            const jsonAwsRolePolicy = JSON.stringify(
                              awsRolePolicy
                            );
                            const awsRolePolicyReplace = jsonAwsRolePolicy
                              .replace(/\\n/g, '\\n')
                              .replace(/\\'/g, "\\'")
                              .replace(/\\"/g, '\\"')
                              .replace(/\\&/g, '\\&')
                              .replace(/\\r/g, '\\r')
                              .replace(/\\t/g, '\\t')
                              .replace(/\\b/g, '\\b')
                              .replace(/\\f/g, '\\f');
                            const role_name = roleName.replace(/\s/g, '-');
                            const roleData = {
                              role_name,
                              role_policy: awsRolePolicyReplace,
                              habitat_id: habitat_id,
                              policy_document: awsPolicyDocumentReplace,
                            };
                            createAWSRole(roleData)
                              .then((response) => {
                                const roleArn = response.data[0].arn;
                                rollBackInfo.rol_id = response.data[0].id;
                                const gitCloneInfo = {
                                  uri,
                                  project_name: name.replace(
                                    /[^A-Z0-9]/gi,
                                    '-'
                                  ),
                                  team_id,
                                  git_credential_id,
                                };
                                gitClone(gitCloneInfo)
                                  .then((response) => {
                                    const repo = response.data.repo;
                                    const repository_id = repo.id;
                                    const directory_name = repo.directory_name;
                                    const funcCloneInfo = {
                                      uri:
                                        'https://gitlab.com/kor-comunity/kor-crud-lambda.git',
                                      project_name: lambdaName,
                                      team_id,
                                      git_credential_id,
                                    };
                                    rollBackInfo.git_site = repository_id;
                                    gitClone(funcCloneInfo)
                                      .then((response) => {
                                        getBuckets(habitat_id)
                                          .then((response) => {
                                            const s3Buckets = response.data;
                                            const bucketName = `kor-lambda-${habitat_id}`;
                                            let bucket;
                                            if (s3Buckets) {
                                              bucket = s3Buckets.find(
                                                (bucket) =>
                                                  bucket.bucket === bucketName
                                              );
                                            }
                                            if (!bucket) {
                                              addBucket(
                                                bucketName,
                                                habitat_id,
                                                awsCreds.region
                                              )
                                                .then((response) => {
                                                  const s3Data = {
                                                    file_name: lambdaName,
                                                    team_id,
                                                    habitat_id,
                                                  };
                                                  uploadClone(s3Data)
                                                    .then((response) => {
                                                      let data = {
                                                        function_name: lambdaName,
                                                        runtime: runtimeLambda,
                                                        handler: handlerLambda,
                                                        role: roleArn,
                                                        habitat_id,
                                                        memory_size: 1024,
                                                        timeout: 25,
                                                        code_s3_bucket: bucketName,
                                                        code_s3_key: `${lambdaName}.zip`,
                                                      };
                                                      setFunc(data)
                                                        .then((response) => {
                                                          const func =
                                                            response.data
                                                              .aws_lambda;
                                                          const buildData = {
                                                            habitat_id:
                                                              func.habitat_id,
                                                            project_name:
                                                              func.function_name,
                                                            artifac_name: `${func.function_name}.zip`,
                                                            buildspec:
                                                              'buildspec.yml',
                                                            source_location:
                                                              func.function_name,
                                                            artifac_location:
                                                              func.code_s3_bucket,
                                                            service_role: roleArn,
                                                            zip: 'ZIP',
                                                            lambda_id: func.id,
                                                          };

                                                          createProject(
                                                            buildData
                                                          )
                                                            .then(
                                                              (response) => {
                                                                const buildInfo = {
                                                                  habitat_id:
                                                                    func.habitat_id,
                                                                  build_id:
                                                                    response
                                                                      .data
                                                                      .params
                                                                      .id,
                                                                };
                                                                createBuild(
                                                                  buildInfo
                                                                )
                                                                  .then(
                                                                    (
                                                                      response
                                                                    ) => {
                                                                      const table_name = name;
                                                                      const schema = {
                                                                        attribute_definitions: [
                                                                          {
                                                                            attribute_name:
                                                                              'id',
                                                                            attribute_type:
                                                                              'S',
                                                                          },
                                                                        ],
                                                                        key_schema: [
                                                                          {
                                                                            attribute_name:
                                                                              'id',
                                                                            key_type:
                                                                              'HASH',
                                                                          },
                                                                        ],
                                                                        provisioned_throughput: {
                                                                          read_capacity_units: 5,
                                                                          write_capacity_units: 5,
                                                                        },
                                                                        table_name: table_name,
                                                                      };

                                                                      const definition = JSON.stringify(
                                                                        schema
                                                                      )
                                                                        .replace(
                                                                          /\\n/g,
                                                                          '\\n'
                                                                        )
                                                                        .replace(
                                                                          /\\'/g,
                                                                          "\\'"
                                                                        )
                                                                        .replace(
                                                                          /\\"/g,
                                                                          '\\"'
                                                                        )
                                                                        .replace(
                                                                          /\\&/g,
                                                                          '\\&'
                                                                        )
                                                                        .replace(
                                                                          /\\r/g,
                                                                          '\\r'
                                                                        )
                                                                        .replace(
                                                                          /\\t/g,
                                                                          '\\t'
                                                                        )
                                                                        .replace(
                                                                          /\\b/g,
                                                                          '\\b'
                                                                        )
                                                                        .replace(
                                                                          /\\f/g,
                                                                          '\\f'
                                                                        );

                                                                      const dynamoData = {
                                                                        habitat_id,
                                                                        table_name,
                                                                        definition,
                                                                      };

                                                                      createDB(
                                                                        dynamoData
                                                                      )
                                                                        .then(
                                                                          (
                                                                            response
                                                                          ) => {
                                                                            newCognito(
                                                                              name,
                                                                              habitat_id
                                                                            )
                                                                              .then(
                                                                                (
                                                                                  response
                                                                                ) => {
                                                                                  const cognitoArn =
                                                                                    response
                                                                                      .data
                                                                                      .arn;
                                                                                  userPoolId =
                                                                                    response
                                                                                      .data
                                                                                      .user_pool_id;
                                                                                  const cognitoID =
                                                                                    response
                                                                                      .data
                                                                                      .id;
                                                                                  createApiGateway(
                                                                                    lambdaName,
                                                                                    habitat_id
                                                                                  )
                                                                                    .then(
                                                                                      (
                                                                                        response
                                                                                      ) => {
                                                                                        const apiId =
                                                                                          response
                                                                                            .data
                                                                                            .api_gateway
                                                                                            .id;
                                                                                        const api_id =
                                                                                          response
                                                                                            .data
                                                                                            .api_gateway
                                                                                            .api_id;
                                                                                        authorizeCognito(
                                                                                          habitat_id,
                                                                                          cognitoArn,
                                                                                          apiId
                                                                                        )
                                                                                          .then(
                                                                                            (
                                                                                              response
                                                                                            ) => {
                                                                                              const api_authorizer =
                                                                                                response
                                                                                                  .data
                                                                                                  .id;
                                                                                              createResource(
                                                                                                apiId,
                                                                                                habitat_id
                                                                                              )
                                                                                                .then(
                                                                                                  (
                                                                                                    response
                                                                                                  ) => {
                                                                                                    deployApi(
                                                                                                      apiId,
                                                                                                      habitat_id
                                                                                                    )
                                                                                                      .then(
                                                                                                        (
                                                                                                          response
                                                                                                        ) => {
                                                                                                          const preEnv = {
                                                                                                            TABLE: table_name,
                                                                                                          };
                                                                                                          const env = JSON.stringify(
                                                                                                            preEnv
                                                                                                          )
                                                                                                            .replace(
                                                                                                              /\\n/g,
                                                                                                              '\\n'
                                                                                                            )
                                                                                                            .replace(
                                                                                                              /\\'/g,
                                                                                                              "\\'"
                                                                                                            )
                                                                                                            .replace(
                                                                                                              /\\"/g,
                                                                                                              '\\"'
                                                                                                            )
                                                                                                            .replace(
                                                                                                              /\\&/g,
                                                                                                              '\\&'
                                                                                                            )
                                                                                                            .replace(
                                                                                                              /\\r/g,
                                                                                                              '\\r'
                                                                                                            )
                                                                                                            .replace(
                                                                                                              /\\t/g,
                                                                                                              '\\t'
                                                                                                            )
                                                                                                            .replace(
                                                                                                              /\\b/g,
                                                                                                              '\\b'
                                                                                                            )
                                                                                                            .replace(
                                                                                                              /\\f/g,
                                                                                                              '\\f'
                                                                                                            );
                                                                                                          deployFunc(
                                                                                                            func.id,
                                                                                                            env
                                                                                                          )
                                                                                                            .then(
                                                                                                              (
                                                                                                                response
                                                                                                              ) => {
                                                                                                                let existing_path_id = null;
                                                                                                                const lambdas =
                                                                                                                  response.data;
                                                                                                                const lambda_arn =
                                                                                                                  lambdas[
                                                                                                                    lambdas.length -
                                                                                                                      1
                                                                                                                  ]
                                                                                                                    .function_arn;
                                                                                                                const postData = {
                                                                                                                  habitat_id,
                                                                                                                  method:
                                                                                                                    'POST',
                                                                                                                  type:
                                                                                                                    'AWS_PROXY',
                                                                                                                  lambda_arn,
                                                                                                                  rol: roleArn,
                                                                                                                  path_part:
                                                                                                                    'v1',
                                                                                                                  api_authorizer,
                                                                                                                };
                                                                                                                createMethod(
                                                                                                                  apiId,
                                                                                                                  postData
                                                                                                                )
                                                                                                                  .then(
                                                                                                                    (
                                                                                                                      response
                                                                                                                    ) => {
                                                                                                                      const methodInfo =
                                                                                                                        response
                                                                                                                          .data
                                                                                                                          .method;
                                                                                                                      endpoint = `https://${api_id}.execute-api.${awsCreds.region}.amazonaws.com/production${methodInfo.resource}`;
                                                                                                                      listResources(
                                                                                                                        apiId,
                                                                                                                        habitat_id
                                                                                                                      )
                                                                                                                        .then(
                                                                                                                          (
                                                                                                                            response
                                                                                                                          ) => {
                                                                                                                            const resources =
                                                                                                                              response
                                                                                                                                .data
                                                                                                                                .resources;
                                                                                                                            resources.forEach(
                                                                                                                              (
                                                                                                                                resource
                                                                                                                              ) => {
                                                                                                                                if (
                                                                                                                                  resource.path_part ===
                                                                                                                                  'v1'
                                                                                                                                ) {
                                                                                                                                  existing_path_id =
                                                                                                                                    resource.id;
                                                                                                                                }
                                                                                                                              }
                                                                                                                            );
                                                                                                                            const getData = {
                                                                                                                              habitat_id,
                                                                                                                              method:
                                                                                                                                'GET',
                                                                                                                              type:
                                                                                                                                'AWS_PROXY',
                                                                                                                              lambda_arn,
                                                                                                                              rol: roleArn,
                                                                                                                              existing_path_id,
                                                                                                                              path_part:
                                                                                                                                '/v1',
                                                                                                                              api_authorizer,
                                                                                                                            };
                                                                                                                            createMethod(
                                                                                                                              apiId,
                                                                                                                              getData
                                                                                                                            )
                                                                                                                              .then(
                                                                                                                                (
                                                                                                                                  response
                                                                                                                                ) => {
                                                                                                                                  const putData = {
                                                                                                                                    habitat_id,
                                                                                                                                    method:
                                                                                                                                      'PUT',
                                                                                                                                    type:
                                                                                                                                      'AWS_PROXY',
                                                                                                                                    lambda_arn,
                                                                                                                                    rol: roleArn,
                                                                                                                                    existing_path_id,
                                                                                                                                    path_part:
                                                                                                                                      '/v1',
                                                                                                                                    api_authorizer,
                                                                                                                                  };
                                                                                                                                  createMethod(
                                                                                                                                    apiId,
                                                                                                                                    putData
                                                                                                                                  )
                                                                                                                                    .then(
                                                                                                                                      (
                                                                                                                                        response
                                                                                                                                      ) => {
                                                                                                                                        const deleteData = {
                                                                                                                                          habitat_id,
                                                                                                                                          method:
                                                                                                                                            'DELETE',
                                                                                                                                          type:
                                                                                                                                            'AWS_PROXY',
                                                                                                                                          lambda_arn,
                                                                                                                                          rol: roleArn,
                                                                                                                                          existing_path_id,
                                                                                                                                          path_part:
                                                                                                                                            '/v1',
                                                                                                                                          api_authorizer,
                                                                                                                                        };
                                                                                                                                        createMethod(
                                                                                                                                          apiId,
                                                                                                                                          deleteData
                                                                                                                                        )
                                                                                                                                          .then(
                                                                                                                                            (
                                                                                                                                              response
                                                                                                                                            ) => {
                                                                                                                                              deployApi(
                                                                                                                                                apiId,
                                                                                                                                                habitat_id,
                                                                                                                                                'v1'
                                                                                                                                              )
                                                                                                                                                .then(
                                                                                                                                                  (
                                                                                                                                                    response
                                                                                                                                                  ) => {
                                                                                                                                                    const responseData = {
                                                                                                                                                      habitat_id,
                                                                                                                                                    };
                                                                                                                                                    createRespose(
                                                                                                                                                      methodInfo.id,
                                                                                                                                                      responseData
                                                                                                                                                    )
                                                                                                                                                      .then(
                                                                                                                                                        (
                                                                                                                                                          response
                                                                                                                                                        ) => {
                                                                                                                                                          const templateInfo = {
                                                                                                                                                            name: name.replace(
                                                                                                                                                              /[^A-Z0-9]/gi,
                                                                                                                                                              '-'
                                                                                                                                                            ),
                                                                                                                                                            domain_name: bucket,
                                                                                                                                                            repository_id,
                                                                                                                                                            habitat_id,
                                                                                                                                                            category:
                                                                                                                                                              'web app',
                                                                                                                                                          };
                                                                                                                                                          createTemplate(
                                                                                                                                                            templateInfo
                                                                                                                                                          )
                                                                                                                                                            .then(
                                                                                                                                                              (
                                                                                                                                                                response
                                                                                                                                                              ) => {
                                                                                                                                                                const payload = {
                                                                                                                                                                  templates:
                                                                                                                                                                    response
                                                                                                                                                                      .data
                                                                                                                                                                      .templates,
                                                                                                                                                                  habitat: payloadHabitat,
                                                                                                                                                                };
                                                                                                                                                                const template =
                                                                                                                                                                  response
                                                                                                                                                                    .data
                                                                                                                                                                    .template;
                                                                                                                                                                const requestInfo = {
                                                                                                                                                                  site_id:
                                                                                                                                                                    template.id,
                                                                                                                                                                  domain_name,
                                                                                                                                                                  prefix,
                                                                                                                                                                  habitat_id,
                                                                                                                                                                };
                                                                                                                                                                requestDomain(
                                                                                                                                                                  requestInfo
                                                                                                                                                                )
                                                                                                                                                                  .then(
                                                                                                                                                                    (
                                                                                                                                                                      response
                                                                                                                                                                    ) => {
                                                                                                                                                                      const cognitoUserInfo = {
                                                                                                                                                                        habitat_id,
                                                                                                                                                                        name: prefix,
                                                                                                                                                                        id: cognitoID,
                                                                                                                                                                      };
                                                                                                                                                                      createCognitoUser(
                                                                                                                                                                        cognitoUserInfo
                                                                                                                                                                      )
                                                                                                                                                                        .then(
                                                                                                                                                                          (
                                                                                                                                                                            response
                                                                                                                                                                          ) => {
                                                                                                                                                                            userPoolClientId =
                                                                                                                                                                              response
                                                                                                                                                                                .data
                                                                                                                                                                                .identity_pool_id;
                                                                                                                                                                            const config = `window._config = {
  cognito: {
      userPoolId: '${userPoolId}',
      clientId: '${userPoolClientId}',
      region: '${regionCred}'
  },
  api: {
      invokeUrl: '${endpoint}',
  }
};`;
                                                                                                                                                                            const envInfo = {
                                                                                                                                                                              file:
                                                                                                                                                                                'config.js',
                                                                                                                                                                              bucket: staticBucket,
                                                                                                                                                                              habitat_id,
                                                                                                                                                                              directory_name,
                                                                                                                                                                              content: config,
                                                                                                                                                                            };
                                                                                                                                                                            addEnv(
                                                                                                                                                                              envInfo
                                                                                                                                                                            )
                                                                                                                                                                              .then(
                                                                                                                                                                                (
                                                                                                                                                                                  response
                                                                                                                                                                                ) => {
                                                                                                                                                                                  const distributionInfo = {
                                                                                                                                                                                    site_id:
                                                                                                                                                                                      template.id,
                                                                                                                                                                                    distribution_name: name.replace(
                                                                                                                                                                                      /[^A-Z0-9]/gi,
                                                                                                                                                                                      '-'
                                                                                                                                                                                    ),
                                                                                                                                                                                    end_point: `${staticBucket}.s3-website.${regionCred}.amazonaws.com`,
                                                                                                                                                                                    domain_name: staticBucket,
                                                                                                                                                                                    repository_id,
                                                                                                                                                                                    habitat_id,
                                                                                                                                                                                    root_object:
                                                                                                                                                                                      'index.html',
                                                                                                                                                                                  };
                                                                                                                                                                                  contentDistribution(
                                                                                                                                                                                    distributionInfo
                                                                                                                                                                                  )
                                                                                                                                                                                    .then(
                                                                                                                                                                                      (
                                                                                                                                                                                        response
                                                                                                                                                                                      ) => {
                                                                                                                                                                                        let domainPrefix;
                                                                                                                                                                                        const cloudFrontDns =
                                                                                                                                                                                          response
                                                                                                                                                                                            .data
                                                                                                                                                                                            .cloud_front_domain;
                                                                                                                                                                                        prefix.length >
                                                                                                                                                                                        0
                                                                                                                                                                                          ? (domainPrefix = `${prefix}.`)
                                                                                                                                                                                          : (domainPrefix = null);
                                                                                                                                                                                        const domainInfo = {
                                                                                                                                                                                          dns_name: cloudFrontDns,
                                                                                                                                                                                          domain_name,
                                                                                                                                                                                          type:
                                                                                                                                                                                            'A',
                                                                                                                                                                                          prefix: domainPrefix,
                                                                                                                                                                                          cf_hosted_zone_id:
                                                                                                                                                                                            'Z2FDTNDATAQYW2',
                                                                                                                                                                                          habitat_id,
                                                                                                                                                                                        };
                                                                                                                                                                                        addDomian(
                                                                                                                                                                                          domainInfo
                                                                                                                                                                                        )
                                                                                                                                                                                          .then(
                                                                                                                                                                                            (
                                                                                                                                                                                              response
                                                                                                                                                                                            ) => {
                                                                                                                                                                                              const upload = {
                                                                                                                                                                                                team_id,
                                                                                                                                                                                                file_name: name.replace(
                                                                                                                                                                                                  /[^A-Z0-9]/gi,
                                                                                                                                                                                                  '-'
                                                                                                                                                                                                ),
                                                                                                                                                                                                bucket: staticBucket,
                                                                                                                                                                                                habitat_id,
                                                                                                                                                                                              };
                                                                                                                                                                                              uploadFolder(
                                                                                                                                                                                                upload
                                                                                                                                                                                              )
                                                                                                                                                                                                .then(
                                                                                                                                                                                                  (
                                                                                                                                                                                                    response
                                                                                                                                                                                                  ) => {
                                                                                                                                                                                                    dispatch(
                                                                                                                                                                                                      {
                                                                                                                                                                                                        type: CREATE_WEB_APP_TEMPLATE_SUCCESS,
                                                                                                                                                                                                        payload,
                                                                                                                                                                                                      }
                                                                                                                                                                                                    );
                                                                                                                                                                                                  }
                                                                                                                                                                                                )
                                                                                                                                                                                                .catch(
                                                                                                                                                                                                  (
                                                                                                                                                                                                    error
                                                                                                                                                                                                  ) => {
                                                                                                                                                                                                    const error_message =
                                                                                                                                                                                                      'Error at upload folder.';
                                                                                                                                                                                                    dispatch(
                                                                                                                                                                                                      {
                                                                                                                                                                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                                                        error: error_message,
                                                                                                                                                                                                      }
                                                                                                                                                                                                    );
                                                                                                                                                                                                    rollBack(
                                                                                                                                                                                                      rollBackInfo
                                                                                                                                                                                                    );
                                                                                                                                                                                                  }
                                                                                                                                                                                                );
                                                                                                                                                                                            }
                                                                                                                                                                                          )
                                                                                                                                                                                          .catch(
                                                                                                                                                                                            (
                                                                                                                                                                                              error
                                                                                                                                                                                            ) => {
                                                                                                                                                                                              const error_message =
                                                                                                                                                                                                'Error at add env file.';
                                                                                                                                                                                              dispatch(
                                                                                                                                                                                                {
                                                                                                                                                                                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                                                  error: error_message,
                                                                                                                                                                                                }
                                                                                                                                                                                              );
                                                                                                                                                                                              rollBack(
                                                                                                                                                                                                rollBackInfo
                                                                                                                                                                                              );
                                                                                                                                                                                            }
                                                                                                                                                                                          );
                                                                                                                                                                                      }
                                                                                                                                                                                    )
                                                                                                                                                                                    .catch(
                                                                                                                                                                                      (
                                                                                                                                                                                        error
                                                                                                                                                                                      ) => {
                                                                                                                                                                                        const error_message =
                                                                                                                                                                                          'Error at add env file.';
                                                                                                                                                                                        dispatch(
                                                                                                                                                                                          {
                                                                                                                                                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                                            error: error_message,
                                                                                                                                                                                          }
                                                                                                                                                                                        );
                                                                                                                                                                                        rollBack(
                                                                                                                                                                                          rollBackInfo
                                                                                                                                                                                        );
                                                                                                                                                                                      }
                                                                                                                                                                                    );
                                                                                                                                                                                }
                                                                                                                                                                              )
                                                                                                                                                                              .catch(
                                                                                                                                                                                (
                                                                                                                                                                                  error
                                                                                                                                                                                ) => {
                                                                                                                                                                                  const error_message =
                                                                                                                                                                                    'Error at add env file.';
                                                                                                                                                                                  dispatch(
                                                                                                                                                                                    {
                                                                                                                                                                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                                      error: error_message,
                                                                                                                                                                                    }
                                                                                                                                                                                  );
                                                                                                                                                                                  rollBack(
                                                                                                                                                                                    rollBackInfo
                                                                                                                                                                                  );
                                                                                                                                                                                }
                                                                                                                                                                              );
                                                                                                                                                                          }
                                                                                                                                                                        )
                                                                                                                                                                        .catch(
                                                                                                                                                                          (
                                                                                                                                                                            error
                                                                                                                                                                          ) => {
                                                                                                                                                                            const error_message =
                                                                                                                                                                              'Error at create Cognito user.';
                                                                                                                                                                            dispatch(
                                                                                                                                                                              {
                                                                                                                                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                                error: error_message,
                                                                                                                                                                              }
                                                                                                                                                                            );
                                                                                                                                                                            rollBack(
                                                                                                                                                                              rollBackInfo
                                                                                                                                                                            );
                                                                                                                                                                          }
                                                                                                                                                                        );
                                                                                                                                                                    }
                                                                                                                                                                  )
                                                                                                                                                                  .catch(
                                                                                                                                                                    (
                                                                                                                                                                      error
                                                                                                                                                                    ) => {
                                                                                                                                                                      const error_message =
                                                                                                                                                                        'Error at create request domain.';
                                                                                                                                                                      dispatch(
                                                                                                                                                                        {
                                                                                                                                                                          type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                          error: error_message,
                                                                                                                                                                        }
                                                                                                                                                                      );
                                                                                                                                                                      rollBack(
                                                                                                                                                                        rollBackInfo
                                                                                                                                                                      );
                                                                                                                                                                    }
                                                                                                                                                                  );
                                                                                                                                                              }
                                                                                                                                                            )
                                                                                                                                                            .catch(
                                                                                                                                                              (
                                                                                                                                                                error
                                                                                                                                                              ) => {
                                                                                                                                                                const error_message =
                                                                                                                                                                  'Error at create template.';
                                                                                                                                                                dispatch(
                                                                                                                                                                  {
                                                                                                                                                                    type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                    error: error_message,
                                                                                                                                                                  }
                                                                                                                                                                );
                                                                                                                                                                rollBack(
                                                                                                                                                                  rollBackInfo
                                                                                                                                                                );
                                                                                                                                                              }
                                                                                                                                                            );
                                                                                                                                                        }
                                                                                                                                                      )
                                                                                                                                                      .catch(
                                                                                                                                                        (
                                                                                                                                                          error
                                                                                                                                                        ) => {
                                                                                                                                                          const error_message =
                                                                                                                                                            'Error at create response API.';
                                                                                                                                                          dispatch(
                                                                                                                                                            {
                                                                                                                                                              type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                              error: error_message,
                                                                                                                                                            }
                                                                                                                                                          );
                                                                                                                                                          rollBack(
                                                                                                                                                            rollBackInfo
                                                                                                                                                          );
                                                                                                                                                        }
                                                                                                                                                      );
                                                                                                                                                  }
                                                                                                                                                )
                                                                                                                                                .catch(
                                                                                                                                                  (
                                                                                                                                                    error
                                                                                                                                                  ) => {
                                                                                                                                                    const error_message =
                                                                                                                                                      'Error at deploy API.';
                                                                                                                                                    dispatch(
                                                                                                                                                      {
                                                                                                                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                        error: error_message,
                                                                                                                                                      }
                                                                                                                                                    );
                                                                                                                                                    rollBack(
                                                                                                                                                      rollBackInfo
                                                                                                                                                    );
                                                                                                                                                  }
                                                                                                                                                );
                                                                                                                                            }
                                                                                                                                          )
                                                                                                                                          .catch(
                                                                                                                                            (
                                                                                                                                              error
                                                                                                                                            ) => {
                                                                                                                                              const error_message =
                                                                                                                                                'Error at create DELETE method.';
                                                                                                                                              dispatch(
                                                                                                                                                {
                                                                                                                                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                  error: error_message,
                                                                                                                                                }
                                                                                                                                              );
                                                                                                                                              rollBack(
                                                                                                                                                rollBackInfo
                                                                                                                                              );
                                                                                                                                            }
                                                                                                                                          );
                                                                                                                                      }
                                                                                                                                    )
                                                                                                                                    .catch(
                                                                                                                                      (
                                                                                                                                        error
                                                                                                                                      ) => {
                                                                                                                                        const error_message =
                                                                                                                                          'Error at create PUT method.';
                                                                                                                                        dispatch(
                                                                                                                                          {
                                                                                                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                            error: error_message,
                                                                                                                                          }
                                                                                                                                        );
                                                                                                                                        rollBack(
                                                                                                                                          rollBackInfo
                                                                                                                                        );
                                                                                                                                      }
                                                                                                                                    );
                                                                                                                                }
                                                                                                                              )
                                                                                                                              .catch(
                                                                                                                                (
                                                                                                                                  error
                                                                                                                                ) => {
                                                                                                                                  const error_message =
                                                                                                                                    'Error at create GET method.';
                                                                                                                                  dispatch(
                                                                                                                                    {
                                                                                                                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                      error: error_message,
                                                                                                                                    }
                                                                                                                                  );
                                                                                                                                  rollBack(
                                                                                                                                    rollBackInfo
                                                                                                                                  );
                                                                                                                                }
                                                                                                                              );
                                                                                                                          }
                                                                                                                        )
                                                                                                                        .catch(
                                                                                                                          (
                                                                                                                            error
                                                                                                                          ) => {
                                                                                                                            const error_message =
                                                                                                                              'Error at list resource.';
                                                                                                                            dispatch(
                                                                                                                              {
                                                                                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                error: error_message,
                                                                                                                              }
                                                                                                                            );
                                                                                                                            rollBack(
                                                                                                                              rollBackInfo
                                                                                                                            );
                                                                                                                          }
                                                                                                                        );
                                                                                                                    }
                                                                                                                  )
                                                                                                                  .catch(
                                                                                                                    (
                                                                                                                      error
                                                                                                                    ) => {
                                                                                                                      const error_message =
                                                                                                                        'Error at create POST method.';
                                                                                                                      dispatch(
                                                                                                                        {
                                                                                                                          type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                          error: error_message,
                                                                                                                        }
                                                                                                                      );
                                                                                                                      rollBack(
                                                                                                                        rollBackInfo
                                                                                                                      );
                                                                                                                    }
                                                                                                                  );
                                                                                                              }
                                                                                                            )
                                                                                                            .catch(
                                                                                                              (
                                                                                                                error
                                                                                                              ) => {
                                                                                                                const error_message =
                                                                                                                  'Error at deploy function.';
                                                                                                                dispatch(
                                                                                                                  {
                                                                                                                    type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                    error: error_message,
                                                                                                                  }
                                                                                                                );
                                                                                                                rollBack(
                                                                                                                  rollBackInfo
                                                                                                                );
                                                                                                              }
                                                                                                            );
                                                                                                        }
                                                                                                      )
                                                                                                      .catch(
                                                                                                        (
                                                                                                          error
                                                                                                        ) => {
                                                                                                          const error_message =
                                                                                                            'Error at deploy API.';
                                                                                                          dispatch(
                                                                                                            {
                                                                                                              type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                              error: error_message,
                                                                                                            }
                                                                                                          );
                                                                                                          rollBack(
                                                                                                            rollBackInfo
                                                                                                          );
                                                                                                        }
                                                                                                      );
                                                                                                  }
                                                                                                )
                                                                                                .catch(
                                                                                                  (
                                                                                                    error
                                                                                                  ) => {
                                                                                                    const error_message =
                                                                                                      'Error at create resource.';
                                                                                                    dispatch(
                                                                                                      {
                                                                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                        error: error_message,
                                                                                                      }
                                                                                                    );
                                                                                                    rollBack(
                                                                                                      rollBackInfo
                                                                                                    );
                                                                                                  }
                                                                                                );
                                                                                            }
                                                                                          )
                                                                                          .catch(
                                                                                            (
                                                                                              error
                                                                                            ) => {
                                                                                              const error_message =
                                                                                                'Error at create Cognito pool.';
                                                                                              dispatch(
                                                                                                {
                                                                                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                  error: error_message,
                                                                                                }
                                                                                              );
                                                                                              rollBack(
                                                                                                rollBackInfo
                                                                                              );
                                                                                            }
                                                                                          );
                                                                                      }
                                                                                    )
                                                                                    .catch(
                                                                                      (
                                                                                        error
                                                                                      ) => {
                                                                                        const error_message =
                                                                                          'Error at create API Gateway.';
                                                                                        dispatch(
                                                                                          {
                                                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                            error: error_message,
                                                                                          }
                                                                                        );
                                                                                        rollBack(
                                                                                          rollBackInfo
                                                                                        );
                                                                                      }
                                                                                    );
                                                                                }
                                                                              )
                                                                              .catch(
                                                                                (
                                                                                  error
                                                                                ) => {
                                                                                  const error_message =
                                                                                    'Error at create Cognito.';
                                                                                  dispatch(
                                                                                    {
                                                                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                      error: error_message,
                                                                                    }
                                                                                  );
                                                                                  rollBack(
                                                                                    rollBackInfo
                                                                                  );
                                                                                }
                                                                              );
                                                                          }
                                                                        )
                                                                        .catch(
                                                                          (
                                                                            error
                                                                          ) => {
                                                                            const error_message =
                                                                              'Error at create Dynamodb.';
                                                                            dispatch(
                                                                              {
                                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                error: error_message,
                                                                              }
                                                                            );
                                                                            rollBack(
                                                                              rollBackInfo
                                                                            );
                                                                          }
                                                                        );
                                                                    }
                                                                  )
                                                                  .catch(
                                                                    (error) => {
                                                                      const error_message =
                                                                        'Error at build project.';
                                                                      dispatch({
                                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                        error: error_message,
                                                                      });
                                                                      rollBack(
                                                                        rollBackInfo
                                                                      );
                                                                    }
                                                                  );
                                                              }
                                                            )
                                                            .catch((error) => {
                                                              const error_message =
                                                                'Error at create build project.';
                                                              dispatch({
                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                error: error_message,
                                                              });
                                                              rollBack(
                                                                rollBackInfo
                                                              );
                                                            });
                                                        })
                                                        .catch((error) => {
                                                          const error_message =
                                                            'Error at create function.';
                                                          dispatch({
                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                            error: error_message,
                                                          });
                                                          rollBack(
                                                            rollBackInfo
                                                          );
                                                        });
                                                    })
                                                    .catch((error) => {
                                                      const error_message =
                                                        'Error at upload clone to s3 buckets.';
                                                      dispatch({
                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                        error: error_message,
                                                      });
                                                      rollBack(rollBackInfo);
                                                    });
                                                })
                                                .catch((error) => {
                                                  const error_message =
                                                    'Error at create s3 buckets.';
                                                  dispatch({
                                                    type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                    error: error_message,
                                                  });
                                                  rollBack(rollBackInfo);
                                                });
                                            } else {
                                              const s3Data = {
                                                file_name: lambdaName,
                                                team_id,
                                                habitat_id,
                                              };
                                              uploadClone(s3Data)
                                                .then((response) => {
                                                  let data = {
                                                    lambdaName,
                                                    runtime: runtimeLambda,
                                                    handler: handlerLambda,
                                                    role: roleArn,
                                                    habitat_id,
                                                    memory_size: null,
                                                    timeout: null,
                                                    code_s3_bucket: bucketName,
                                                    code_s3_key: `${lambdaName}.zip`,
                                                  };
                                                  setFunc(data)
                                                    .then((response) => {
                                                      const func =
                                                        response.data
                                                          .aws_lambda;
                                                      const buildData = {
                                                        habitat_id:
                                                          func.habitat_id,
                                                        project_name:
                                                          func.function_name,
                                                        artifac_name: `${func.function_name}.zip`,
                                                        buildspec:
                                                          'buildspec.yml',
                                                        source_location:
                                                          func.function_name,
                                                        artifac_location:
                                                          func.code_s3_bucket,
                                                        service_role: roleArn,
                                                        zip: 'ZIP',
                                                        lambda_id: func.id,
                                                      };

                                                      createProject(buildData)
                                                        .then((response) => {
                                                          const buildInfo = {
                                                            habitat_id:
                                                              func.habitat_id,
                                                            build_id:
                                                              response.data
                                                                .params.id,
                                                          };
                                                          createBuild(buildInfo)
                                                            .then(
                                                              (response) => {
                                                                const table_name = name;
                                                                const schema = {
                                                                  attribute_definitions: [
                                                                    {
                                                                      attribute_name:
                                                                        'id',
                                                                      attribute_type:
                                                                        'S',
                                                                    },
                                                                  ],
                                                                  key_schema: [
                                                                    {
                                                                      attribute_name:
                                                                        'id',
                                                                      key_type:
                                                                        'HASH',
                                                                    },
                                                                  ],
                                                                  provisioned_throughput: {
                                                                    read_capacity_units: 5,
                                                                    write_capacity_units: 5,
                                                                  },
                                                                  table_name: table_name,
                                                                };

                                                                const definition = JSON.stringify(
                                                                  schema
                                                                )
                                                                  .replace(
                                                                    /\\n/g,
                                                                    '\\n'
                                                                  )
                                                                  .replace(
                                                                    /\\'/g,
                                                                    "\\'"
                                                                  )
                                                                  .replace(
                                                                    /\\"/g,
                                                                    '\\"'
                                                                  )
                                                                  .replace(
                                                                    /\\&/g,
                                                                    '\\&'
                                                                  )
                                                                  .replace(
                                                                    /\\r/g,
                                                                    '\\r'
                                                                  )
                                                                  .replace(
                                                                    /\\t/g,
                                                                    '\\t'
                                                                  )
                                                                  .replace(
                                                                    /\\b/g,
                                                                    '\\b'
                                                                  )
                                                                  .replace(
                                                                    /\\f/g,
                                                                    '\\f'
                                                                  );

                                                                const dynamoData = {
                                                                  habitat_id,
                                                                  table_name,
                                                                  definition,
                                                                };

                                                                createDB(
                                                                  dynamoData
                                                                )
                                                                  .then(
                                                                    (
                                                                      response
                                                                    ) => {
                                                                      newCognito(
                                                                        name,
                                                                        habitat_id
                                                                      )
                                                                        .then(
                                                                          (
                                                                            response
                                                                          ) => {
                                                                            const cognitoArn =
                                                                              response
                                                                                .data
                                                                                .arn;
                                                                            userPoolId =
                                                                              response
                                                                                .data
                                                                                .user_pool_id;
                                                                            const cognitoID =
                                                                              response
                                                                                .data
                                                                                .id;
                                                                            createApiGateway(
                                                                              lambdaName,
                                                                              habitat_id
                                                                            )
                                                                              .then(
                                                                                (
                                                                                  response
                                                                                ) => {
                                                                                  const apiId =
                                                                                    response
                                                                                      .data
                                                                                      .api_gateway
                                                                                      .id;
                                                                                  const api_id =
                                                                                    response
                                                                                      .data
                                                                                      .api_gateway
                                                                                      .api_id;
                                                                                  authorizeCognito(
                                                                                    habitat_id,
                                                                                    cognitoArn,
                                                                                    apiId
                                                                                  )
                                                                                    .then(
                                                                                      (
                                                                                        response
                                                                                      ) => {
                                                                                        const api_authorizer =
                                                                                          response
                                                                                            .data
                                                                                            .id;
                                                                                        createResource(
                                                                                          apiId,
                                                                                          habitat_id
                                                                                        )
                                                                                          .then(
                                                                                            (
                                                                                              response
                                                                                            ) => {
                                                                                              deployApi(
                                                                                                apiId,
                                                                                                habitat_id
                                                                                              )
                                                                                                .then(
                                                                                                  (
                                                                                                    response
                                                                                                  ) => {
                                                                                                    const preEnv = {
                                                                                                      TABLE: table_name,
                                                                                                    };
                                                                                                    const env = JSON.stringify(
                                                                                                      preEnv
                                                                                                    )
                                                                                                      .replace(
                                                                                                        /\\n/g,
                                                                                                        '\\n'
                                                                                                      )
                                                                                                      .replace(
                                                                                                        /\\'/g,
                                                                                                        "\\'"
                                                                                                      )
                                                                                                      .replace(
                                                                                                        /\\"/g,
                                                                                                        '\\"'
                                                                                                      )
                                                                                                      .replace(
                                                                                                        /\\&/g,
                                                                                                        '\\&'
                                                                                                      )
                                                                                                      .replace(
                                                                                                        /\\r/g,
                                                                                                        '\\r'
                                                                                                      )
                                                                                                      .replace(
                                                                                                        /\\t/g,
                                                                                                        '\\t'
                                                                                                      )
                                                                                                      .replace(
                                                                                                        /\\b/g,
                                                                                                        '\\b'
                                                                                                      )
                                                                                                      .replace(
                                                                                                        /\\f/g,
                                                                                                        '\\f'
                                                                                                      );
                                                                                                    deployFunc(
                                                                                                      func.id,
                                                                                                      env
                                                                                                    )
                                                                                                      .then(
                                                                                                        (
                                                                                                          response
                                                                                                        ) => {
                                                                                                          let existing_path_id = null;
                                                                                                          const lambdas =
                                                                                                            response.data;
                                                                                                          const lambda_arn =
                                                                                                            lambdas[
                                                                                                              lambdas.length -
                                                                                                                1
                                                                                                            ]
                                                                                                              .function_arn;
                                                                                                          const postData = {
                                                                                                            habitat_id,
                                                                                                            method:
                                                                                                              'POST',
                                                                                                            type:
                                                                                                              'AWS_PROXY',
                                                                                                            lambda_arn,
                                                                                                            rol: roleArn,
                                                                                                            path_part:
                                                                                                              'v1',
                                                                                                            api_authorizer,
                                                                                                          };
                                                                                                          createMethod(
                                                                                                            apiId,
                                                                                                            postData
                                                                                                          )
                                                                                                            .then(
                                                                                                              (
                                                                                                                response
                                                                                                              ) => {
                                                                                                                const methodInfo =
                                                                                                                  response
                                                                                                                    .data
                                                                                                                    .method;
                                                                                                                endpoint = `https://${api_id}.execute-api.${awsCreds.region}.amazonaws.com/production${methodInfo.resource}`;
                                                                                                                listResources(
                                                                                                                  apiId,
                                                                                                                  habitat_id
                                                                                                                )
                                                                                                                  .then(
                                                                                                                    (
                                                                                                                      response
                                                                                                                    ) => {
                                                                                                                      const resources =
                                                                                                                        response
                                                                                                                          .data
                                                                                                                          .resources;
                                                                                                                      resources.forEach(
                                                                                                                        (
                                                                                                                          resource
                                                                                                                        ) => {
                                                                                                                          if (
                                                                                                                            resource.path_part ===
                                                                                                                            'v1'
                                                                                                                          ) {
                                                                                                                            existing_path_id =
                                                                                                                              resource.id;
                                                                                                                          }
                                                                                                                        }
                                                                                                                      );
                                                                                                                      const getData = {
                                                                                                                        habitat_id,
                                                                                                                        method:
                                                                                                                          'GET',
                                                                                                                        type:
                                                                                                                          'AWS_PROXY',
                                                                                                                        lambda_arn,
                                                                                                                        rol: roleArn,
                                                                                                                        existing_path_id,
                                                                                                                        path_part:
                                                                                                                          '/v1',
                                                                                                                        api_authorizer,
                                                                                                                      };
                                                                                                                      createMethod(
                                                                                                                        apiId,
                                                                                                                        getData
                                                                                                                      )
                                                                                                                        .then(
                                                                                                                          (
                                                                                                                            response
                                                                                                                          ) => {
                                                                                                                            const putData = {
                                                                                                                              habitat_id,
                                                                                                                              method:
                                                                                                                                'PUT',
                                                                                                                              type:
                                                                                                                                'AWS_PROXY',
                                                                                                                              lambda_arn,
                                                                                                                              rol: roleArn,
                                                                                                                              existing_path_id,
                                                                                                                              path_part:
                                                                                                                                '/v1',
                                                                                                                              api_authorizer,
                                                                                                                            };
                                                                                                                            createMethod(
                                                                                                                              apiId,
                                                                                                                              putData
                                                                                                                            )
                                                                                                                              .then(
                                                                                                                                (
                                                                                                                                  response
                                                                                                                                ) => {
                                                                                                                                  const deleteData = {
                                                                                                                                    habitat_id,
                                                                                                                                    method:
                                                                                                                                      'DELETE',
                                                                                                                                    type:
                                                                                                                                      'AWS_PROXY',
                                                                                                                                    lambda_arn,
                                                                                                                                    rol: roleArn,
                                                                                                                                    existing_path_id,
                                                                                                                                    path_part:
                                                                                                                                      '/v1',
                                                                                                                                    api_authorizer,
                                                                                                                                  };
                                                                                                                                  createMethod(
                                                                                                                                    apiId,
                                                                                                                                    deleteData
                                                                                                                                  )
                                                                                                                                    .then(
                                                                                                                                      (
                                                                                                                                        response
                                                                                                                                      ) => {
                                                                                                                                        deployApi(
                                                                                                                                          apiId,
                                                                                                                                          habitat_id,
                                                                                                                                          'v1'
                                                                                                                                        )
                                                                                                                                          .then(
                                                                                                                                            (
                                                                                                                                              response
                                                                                                                                            ) => {
                                                                                                                                              const responseData = {
                                                                                                                                                habitat_id,
                                                                                                                                              };
                                                                                                                                              createRespose(
                                                                                                                                                methodInfo.id,
                                                                                                                                                responseData
                                                                                                                                              )
                                                                                                                                                .then(
                                                                                                                                                  (
                                                                                                                                                    response
                                                                                                                                                  ) => {
                                                                                                                                                    const templateInfo = {
                                                                                                                                                      name: name.replace(
                                                                                                                                                        /[^A-Z0-9]/gi,
                                                                                                                                                        '-'
                                                                                                                                                      ),
                                                                                                                                                      domain_name: bucket,
                                                                                                                                                      repository_id,
                                                                                                                                                      habitat_id,
                                                                                                                                                      category:
                                                                                                                                                        'web app',
                                                                                                                                                    };
                                                                                                                                                    createTemplate(
                                                                                                                                                      templateInfo
                                                                                                                                                    )
                                                                                                                                                      .then(
                                                                                                                                                        (
                                                                                                                                                          response
                                                                                                                                                        ) => {
                                                                                                                                                          const payload = {
                                                                                                                                                            templates:
                                                                                                                                                              response
                                                                                                                                                                .data
                                                                                                                                                                .templates,
                                                                                                                                                            habitat: payloadHabitat,
                                                                                                                                                          };
                                                                                                                                                          const template =
                                                                                                                                                            response
                                                                                                                                                              .data
                                                                                                                                                              .template;
                                                                                                                                                          const requestInfo = {
                                                                                                                                                            site_id:
                                                                                                                                                              template.id,
                                                                                                                                                            domain_name,
                                                                                                                                                            prefix,
                                                                                                                                                            habitat_id,
                                                                                                                                                          };
                                                                                                                                                          requestDomain(
                                                                                                                                                            requestInfo
                                                                                                                                                          )
                                                                                                                                                            .then(
                                                                                                                                                              (
                                                                                                                                                                response
                                                                                                                                                              ) => {
                                                                                                                                                                const cognitoUserInfo = {
                                                                                                                                                                  habitat_id,
                                                                                                                                                                  name: prefix,
                                                                                                                                                                  id: cognitoID,
                                                                                                                                                                };
                                                                                                                                                                createCognitoUser(
                                                                                                                                                                  cognitoUserInfo
                                                                                                                                                                )
                                                                                                                                                                  .then(
                                                                                                                                                                    (
                                                                                                                                                                      response
                                                                                                                                                                    ) => {
                                                                                                                                                                      userPoolClientId =
                                                                                                                                                                        response
                                                                                                                                                                          .data
                                                                                                                                                                          .identity_pool_id;
                                                                                                                                                                      const config = `window._config = {
cognito: {
    userPoolId: '${userPoolId}',
    clientId: '${userPoolClientId}',
    region: '${regionCred}'
},
api: {
    invokeUrl: '${endpoint}',
}
};`;
                                                                                                                                                                      const envInfo = {
                                                                                                                                                                        file:
                                                                                                                                                                          'config.js',
                                                                                                                                                                        bucket: staticBucket,
                                                                                                                                                                        habitat_id,
                                                                                                                                                                        directory_name,
                                                                                                                                                                        content: config,
                                                                                                                                                                      };
                                                                                                                                                                      addEnv(
                                                                                                                                                                        envInfo
                                                                                                                                                                      )
                                                                                                                                                                        .then(
                                                                                                                                                                          (
                                                                                                                                                                            response
                                                                                                                                                                          ) => {
                                                                                                                                                                            const distributionInfo = {
                                                                                                                                                                              site_id:
                                                                                                                                                                                template.id,
                                                                                                                                                                              distribution_name: name.replace(
                                                                                                                                                                                /[^A-Z0-9]/gi,
                                                                                                                                                                                '-'
                                                                                                                                                                              ),
                                                                                                                                                                              end_point: `${staticBucket}.s3-website.${regionCred}.amazonaws.com`,
                                                                                                                                                                              domain_name: staticBucket,
                                                                                                                                                                              repository_id,
                                                                                                                                                                              habitat_id,
                                                                                                                                                                              root_object:
                                                                                                                                                                                'index.html',
                                                                                                                                                                            };
                                                                                                                                                                            contentDistribution(
                                                                                                                                                                              distributionInfo
                                                                                                                                                                            )
                                                                                                                                                                              .then(
                                                                                                                                                                                (
                                                                                                                                                                                  response
                                                                                                                                                                                ) => {
                                                                                                                                                                                  let domainPrefix;
                                                                                                                                                                                  const cloudFrontDns =
                                                                                                                                                                                    response
                                                                                                                                                                                      .data
                                                                                                                                                                                      .cloud_front_domain;
                                                                                                                                                                                  prefix.length >
                                                                                                                                                                                  0
                                                                                                                                                                                    ? (domainPrefix = `${prefix}.`)
                                                                                                                                                                                    : (domainPrefix = null);
                                                                                                                                                                                  const domainInfo = {
                                                                                                                                                                                    dns_name: cloudFrontDns,
                                                                                                                                                                                    domain_name,
                                                                                                                                                                                    type:
                                                                                                                                                                                      'A',
                                                                                                                                                                                    prefix: domainPrefix,
                                                                                                                                                                                    cf_hosted_zone_id:
                                                                                                                                                                                      'Z2FDTNDATAQYW2',
                                                                                                                                                                                    habitat_id,
                                                                                                                                                                                  };
                                                                                                                                                                                  addDomian(
                                                                                                                                                                                    domainInfo
                                                                                                                                                                                  )
                                                                                                                                                                                    .then(
                                                                                                                                                                                      (
                                                                                                                                                                                        response
                                                                                                                                                                                      ) => {
                                                                                                                                                                                        const upload = {
                                                                                                                                                                                          team_id,
                                                                                                                                                                                          file_name: name.replace(
                                                                                                                                                                                            /[^A-Z0-9]/gi,
                                                                                                                                                                                            '-'
                                                                                                                                                                                          ),
                                                                                                                                                                                          bucket: staticBucket,
                                                                                                                                                                                          habitat_id,
                                                                                                                                                                                        };
                                                                                                                                                                                        uploadFolder(
                                                                                                                                                                                          upload
                                                                                                                                                                                        )
                                                                                                                                                                                          .then(
                                                                                                                                                                                            (
                                                                                                                                                                                              response
                                                                                                                                                                                            ) => {
                                                                                                                                                                                              dispatch(
                                                                                                                                                                                                {
                                                                                                                                                                                                  type: CREATE_WEB_APP_TEMPLATE_SUCCESS,
                                                                                                                                                                                                  payload,
                                                                                                                                                                                                }
                                                                                                                                                                                              );
                                                                                                                                                                                            }
                                                                                                                                                                                          )
                                                                                                                                                                                          .catch(
                                                                                                                                                                                            (
                                                                                                                                                                                              error
                                                                                                                                                                                            ) => {
                                                                                                                                                                                              const error_message =
                                                                                                                                                                                                'Error at upload folder.';
                                                                                                                                                                                              dispatch(
                                                                                                                                                                                                {
                                                                                                                                                                                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                                                  error: error_message,
                                                                                                                                                                                                }
                                                                                                                                                                                              );
                                                                                                                                                                                              rollBack(
                                                                                                                                                                                                rollBackInfo
                                                                                                                                                                                              );
                                                                                                                                                                                            }
                                                                                                                                                                                          );
                                                                                                                                                                                      }
                                                                                                                                                                                    )
                                                                                                                                                                                    .catch(
                                                                                                                                                                                      (
                                                                                                                                                                                        error
                                                                                                                                                                                      ) => {
                                                                                                                                                                                        const error_message =
                                                                                                                                                                                          'Error at add env file.';
                                                                                                                                                                                        dispatch(
                                                                                                                                                                                          {
                                                                                                                                                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                                            error: error_message,
                                                                                                                                                                                          }
                                                                                                                                                                                        );
                                                                                                                                                                                        rollBack(
                                                                                                                                                                                          rollBackInfo
                                                                                                                                                                                        );
                                                                                                                                                                                      }
                                                                                                                                                                                    );
                                                                                                                                                                                }
                                                                                                                                                                              )
                                                                                                                                                                              .catch(
                                                                                                                                                                                (
                                                                                                                                                                                  error
                                                                                                                                                                                ) => {
                                                                                                                                                                                  const error_message =
                                                                                                                                                                                    'Error at add env file.';
                                                                                                                                                                                  dispatch(
                                                                                                                                                                                    {
                                                                                                                                                                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                                      error: error_message,
                                                                                                                                                                                    }
                                                                                                                                                                                  );
                                                                                                                                                                                  rollBack(
                                                                                                                                                                                    rollBackInfo
                                                                                                                                                                                  );
                                                                                                                                                                                }
                                                                                                                                                                              );
                                                                                                                                                                          }
                                                                                                                                                                        )
                                                                                                                                                                        .catch(
                                                                                                                                                                          (
                                                                                                                                                                            error
                                                                                                                                                                          ) => {
                                                                                                                                                                            const error_message =
                                                                                                                                                                              'Error at add env file.';
                                                                                                                                                                            dispatch(
                                                                                                                                                                              {
                                                                                                                                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                                error: error_message,
                                                                                                                                                                              }
                                                                                                                                                                            );
                                                                                                                                                                            rollBack(
                                                                                                                                                                              rollBackInfo
                                                                                                                                                                            );
                                                                                                                                                                          }
                                                                                                                                                                        );
                                                                                                                                                                    }
                                                                                                                                                                  )
                                                                                                                                                                  .catch(
                                                                                                                                                                    (
                                                                                                                                                                      error
                                                                                                                                                                    ) => {
                                                                                                                                                                      const error_message =
                                                                                                                                                                        'Error at create Cognito user.';
                                                                                                                                                                      dispatch(
                                                                                                                                                                        {
                                                                                                                                                                          type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                          error: error_message,
                                                                                                                                                                        }
                                                                                                                                                                      );
                                                                                                                                                                      rollBack(
                                                                                                                                                                        rollBackInfo
                                                                                                                                                                      );
                                                                                                                                                                    }
                                                                                                                                                                  );
                                                                                                                                                              }
                                                                                                                                                            )
                                                                                                                                                            .catch(
                                                                                                                                                              (
                                                                                                                                                                error
                                                                                                                                                              ) => {
                                                                                                                                                                const error_message =
                                                                                                                                                                  'Error at create request domain.';
                                                                                                                                                                dispatch(
                                                                                                                                                                  {
                                                                                                                                                                    type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                    error: error_message,
                                                                                                                                                                  }
                                                                                                                                                                );
                                                                                                                                                                rollBack(
                                                                                                                                                                  rollBackInfo
                                                                                                                                                                );
                                                                                                                                                              }
                                                                                                                                                            );
                                                                                                                                                        }
                                                                                                                                                      )
                                                                                                                                                      .catch(
                                                                                                                                                        (
                                                                                                                                                          error
                                                                                                                                                        ) => {
                                                                                                                                                          const error_message =
                                                                                                                                                            'Error at create template.';
                                                                                                                                                          dispatch(
                                                                                                                                                            {
                                                                                                                                                              type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                              error: error_message,
                                                                                                                                                            }
                                                                                                                                                          );
                                                                                                                                                          rollBack(
                                                                                                                                                            rollBackInfo
                                                                                                                                                          );
                                                                                                                                                        }
                                                                                                                                                      );
                                                                                                                                                  }
                                                                                                                                                )
                                                                                                                                                .catch(
                                                                                                                                                  (
                                                                                                                                                    error
                                                                                                                                                  ) => {
                                                                                                                                                    const error_message =
                                                                                                                                                      'Error at create response API.';
                                                                                                                                                    dispatch(
                                                                                                                                                      {
                                                                                                                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                        error: error_message,
                                                                                                                                                      }
                                                                                                                                                    );
                                                                                                                                                    rollBack(
                                                                                                                                                      rollBackInfo
                                                                                                                                                    );
                                                                                                                                                  }
                                                                                                                                                );
                                                                                                                                            }
                                                                                                                                          )
                                                                                                                                          .catch(
                                                                                                                                            (
                                                                                                                                              error
                                                                                                                                            ) => {
                                                                                                                                              const error_message =
                                                                                                                                                'Error at deploy API.';
                                                                                                                                              dispatch(
                                                                                                                                                {
                                                                                                                                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                  error: error_message,
                                                                                                                                                }
                                                                                                                                              );
                                                                                                                                              rollBack(
                                                                                                                                                rollBackInfo
                                                                                                                                              );
                                                                                                                                            }
                                                                                                                                          );
                                                                                                                                      }
                                                                                                                                    )
                                                                                                                                    .catch(
                                                                                                                                      (
                                                                                                                                        error
                                                                                                                                      ) => {
                                                                                                                                        const error_message =
                                                                                                                                          'Error at create DELETE method.';
                                                                                                                                        dispatch(
                                                                                                                                          {
                                                                                                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                            error: error_message,
                                                                                                                                          }
                                                                                                                                        );
                                                                                                                                        rollBack(
                                                                                                                                          rollBackInfo
                                                                                                                                        );
                                                                                                                                      }
                                                                                                                                    );
                                                                                                                                }
                                                                                                                              )
                                                                                                                              .catch(
                                                                                                                                (
                                                                                                                                  error
                                                                                                                                ) => {
                                                                                                                                  const error_message =
                                                                                                                                    'Error at create PUT method.';
                                                                                                                                  dispatch(
                                                                                                                                    {
                                                                                                                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                      error: error_message,
                                                                                                                                    }
                                                                                                                                  );
                                                                                                                                  rollBack(
                                                                                                                                    rollBackInfo
                                                                                                                                  );
                                                                                                                                }
                                                                                                                              );
                                                                                                                          }
                                                                                                                        )
                                                                                                                        .catch(
                                                                                                                          (
                                                                                                                            error
                                                                                                                          ) => {
                                                                                                                            const error_message =
                                                                                                                              'Error at create GET method.';
                                                                                                                            dispatch(
                                                                                                                              {
                                                                                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                error: error_message,
                                                                                                                              }
                                                                                                                            );
                                                                                                                            rollBack(
                                                                                                                              rollBackInfo
                                                                                                                            );
                                                                                                                          }
                                                                                                                        );
                                                                                                                    }
                                                                                                                  )
                                                                                                                  .catch(
                                                                                                                    (
                                                                                                                      error
                                                                                                                    ) => {
                                                                                                                      const error_message =
                                                                                                                        'Error at list resource.';
                                                                                                                      dispatch(
                                                                                                                        {
                                                                                                                          type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                          error: error_message,
                                                                                                                        }
                                                                                                                      );
                                                                                                                      rollBack(
                                                                                                                        rollBackInfo
                                                                                                                      );
                                                                                                                    }
                                                                                                                  );
                                                                                                              }
                                                                                                            )
                                                                                                            .catch(
                                                                                                              (
                                                                                                                error
                                                                                                              ) => {
                                                                                                                const error_message =
                                                                                                                  'Error at create POST method.';
                                                                                                                dispatch(
                                                                                                                  {
                                                                                                                    type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                    error: error_message,
                                                                                                                  }
                                                                                                                );
                                                                                                                rollBack(
                                                                                                                  rollBackInfo
                                                                                                                );
                                                                                                              }
                                                                                                            );
                                                                                                        }
                                                                                                      )
                                                                                                      .catch(
                                                                                                        (
                                                                                                          error
                                                                                                        ) => {
                                                                                                          const error_message =
                                                                                                            'Error at deploy function.';
                                                                                                          dispatch(
                                                                                                            {
                                                                                                              type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                              error: error_message,
                                                                                                            }
                                                                                                          );
                                                                                                          rollBack(
                                                                                                            rollBackInfo
                                                                                                          );
                                                                                                        }
                                                                                                      );
                                                                                                  }
                                                                                                )
                                                                                                .catch(
                                                                                                  (
                                                                                                    error
                                                                                                  ) => {
                                                                                                    const error_message =
                                                                                                      'Error at deploy API.';
                                                                                                    dispatch(
                                                                                                      {
                                                                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                        error: error_message,
                                                                                                      }
                                                                                                    );
                                                                                                    rollBack(
                                                                                                      rollBackInfo
                                                                                                    );
                                                                                                  }
                                                                                                );
                                                                                            }
                                                                                          )
                                                                                          .catch(
                                                                                            (
                                                                                              error
                                                                                            ) => {
                                                                                              const error_message =
                                                                                                'Error at create resource.';
                                                                                              dispatch(
                                                                                                {
                                                                                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                  error: error_message,
                                                                                                }
                                                                                              );
                                                                                              rollBack(
                                                                                                rollBackInfo
                                                                                              );
                                                                                            }
                                                                                          );
                                                                                      }
                                                                                    )
                                                                                    .catch(
                                                                                      (
                                                                                        error
                                                                                      ) => {
                                                                                        const error_message =
                                                                                          'Error at create Cognito pool.';
                                                                                        dispatch(
                                                                                          {
                                                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                            error: error_message,
                                                                                          }
                                                                                        );
                                                                                        rollBack(
                                                                                          rollBackInfo
                                                                                        );
                                                                                      }
                                                                                    );
                                                                                }
                                                                              )
                                                                              .catch(
                                                                                (
                                                                                  error
                                                                                ) => {
                                                                                  const error_message =
                                                                                    'Error at create API Gateway.';
                                                                                  dispatch(
                                                                                    {
                                                                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                      error: error_message,
                                                                                    }
                                                                                  );
                                                                                  rollBack(
                                                                                    rollBackInfo
                                                                                  );
                                                                                }
                                                                              );
                                                                          }
                                                                        )
                                                                        .catch(
                                                                          (
                                                                            error
                                                                          ) => {
                                                                            const error_message =
                                                                              'Error at create Cognito.';
                                                                            dispatch(
                                                                              {
                                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                error: error_message,
                                                                              }
                                                                            );
                                                                            rollBack(
                                                                              rollBackInfo
                                                                            );
                                                                          }
                                                                        );
                                                                    }
                                                                  )
                                                                  .catch(
                                                                    (error) => {
                                                                      const error_message =
                                                                        'Error at create Dynamodb.';
                                                                      dispatch({
                                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                        error: error_message,
                                                                      });
                                                                      rollBack(
                                                                        rollBackInfo
                                                                      );
                                                                    }
                                                                  );
                                                              }
                                                            )
                                                            .catch((error) => {
                                                              const error_message =
                                                                'Error at build project.';
                                                              dispatch({
                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                error: error_message,
                                                              });
                                                              rollBack(
                                                                rollBackInfo
                                                              );
                                                            });
                                                        })
                                                        .catch((error) => {
                                                          const error_message =
                                                            'Error at create build project.';
                                                          dispatch({
                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                            error: error_message,
                                                          });
                                                          rollBack(
                                                            rollBackInfo
                                                          );
                                                        });
                                                    })
                                                    .catch((error) => {
                                                      const error_message =
                                                        'Error at create function.';
                                                      dispatch({
                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                        error: error_message,
                                                      });
                                                      rollBack(rollBackInfo);
                                                    });
                                                })
                                                .catch((error) => {
                                                  const error_message =
                                                    'Error at upload clone to s3 buckets.';
                                                  dispatch({
                                                    type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                    error: error_message,
                                                  });
                                                  rollBack(rollBackInfo);
                                                });
                                            }
                                          })
                                          .catch((error) => {
                                            const error_message =
                                              'Error at get s3 buckets.';
                                            dispatch({
                                              type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                              error: error_message,
                                            });
                                            rollBack(rollBackInfo);
                                          });
                                      })
                                      .catch((error) => {
                                        const error_message =
                                          'Error at clone lambda function.';
                                        dispatch({
                                          type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                          error: error_message,
                                        });
                                        rollBack(rollBackInfo);
                                      });
                                  })
                                  .catch((error) => {
                                    const error_message =
                                      'Error at clone static site.';
                                    dispatch({
                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                      error: error_message,
                                    });
                                    rollBack(rollBackInfo);
                                  });
                              })
                              .catch((error) => {
                                const error_message =
                                  'Error at add static bucket.';
                                dispatch({
                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                  error: error_message,
                                });
                                rollBack(rollBackInfo);
                              });
                          })
                          .catch((error) => {
                            const error_message = 'Error at add static bucket.';
                            dispatch({
                              type: CREATE_WEB_APP_TEMPLATE_ERROR,
                              error: error_message,
                            });
                            rollBack(rollBackInfo);
                          });
                      })
                      .catch((error) => {
                        const error_message =
                          'Error at attach AWS credentials.';
                        dispatch({
                          type: CREATE_WEB_APP_TEMPLATE_ERROR,
                          error: error_message,
                        });
                        rollBack(rollBackInfo);
                      });
                  })
                  .catch((error) => {
                    const error_message = 'Error at verify AWS credentials.';
                    dispatch({
                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                      error: error_message,
                    });
                    rollBack(rollBackInfo);
                  });
              })
              .catch((error) => {
                const error_message = 'Error at add AWS credentials.';
                dispatch({
                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                  error: error_message,
                });
                rollBack(rollBackInfo);
              });
          })
          .catch((error) => {
            const error_message = 'Error at request enccrypt key.';
            dispatch({
              type: CREATE_WEB_APP_TEMPLATE_ERROR,
              error: error_message,
            });
            rollBack(rollBackInfo);
          });
      })
      .catch((error) => {
        const error_message = 'Error at Habitat creation.';
        dispatch({
          type: CREATE_WEB_APP_TEMPLATE_ERROR,
          error: error_message,
        });
      });
  };
};

export const deployTemplateStrapi = (
  prefix,
  region,
  team_id,
  awsCreds,
  habitat_id,
  domain_name,
  git_credential_id
) => {
  return (dispatch) => {
    let bucket;

    if (prefix) {
      bucket = `${prefix}.${domain_name}`;
    } else {
      bucket = domain_name;
    }

    const s3Static = {
      bucket,
      region,
      public: 1,
      habitat_id,
    };

    let rollBackInfo = {
      habitat_id,
      rol_id: null,
      git_site: null,
      git_lambda: null,
    };

    const runtimeLambda = 'nodejs12.x';
    const handlerLambda = 'app.handler';
    const rdsData = {
      habitat_id,
      port: 5432,
      storage: 5,
      db_name: 'strapi',
      engine: 'postgres',
      engine_version: '12.5',
      master_username: 'postgres',
      name: `${prefix}-strapi-${Date.now()}`,
      db_instance_class: 'db.t2.micro',
      password: passwordGenerator(18, false),
    };
    let env = `DATABASE_PASSWORD=${rdsData.password}\n`;
    env += `DATABASE_PORT=${rdsData.port}\n`;
    env += `DATABASE_NAME=${rdsData.db_name}\n`;
    env += `DATABASE_USERNAME=${rdsData.master_username}\n`;
    env += `HOST=${bucket}\n`;
    env += 'PORT=80\n';
    env += 'NODE_ENV=develop';
    const hash = randomHash({ length: 32 });
    env += `ADMIN_JWT_SECRET=${hash}\n`;
    dispatch({
      type: CREATE_STRAPI_TEMPLATE_BEGIN,
      loading_message: 'Loading deploy Strapi...',
    });
    getAWSRoles(habitat_id)
      .then((response) => {
        const awsRoles = response.data;
        const roleArn = awsRoles[awsRoles.length - 1].arn;
        rollBackInfo.rol_id = awsRoles[awsRoles.length - 1].id;
        createRds(rdsData)
          .then((response) => {
            const dbHost = response.data.db.endpoint;
            addStaticBucket(s3Static)
              .then((response) => {
                env += `DATABASE_HOST=${dbHost}\n`;
                createApiGateway(rdsData.name, habitat_id)
                  .then((response) => {
                    const apigateway = response.data.api_gateway;
                    const apiId = response.data.api_gateway.id;
                    const endpoint = `https://${apigateway.api_id}.execute-api.${awsCreds.region}.amazonaws.com/production`;
                    env += `BACKEND_URL=${endpoint}`;
                    const preEnv = {
                      DATABASE_PASSWORD: rdsData.password,
                      DATABASE_PORT: `${rdsData.port}`,
                      DATABASE_NAME: rdsData.db_name,
                      DATABASE_USERNAME: rdsData.master_username,
                      HOST: bucket,
                      PORT: '80',
                      DATABASE_HOST: dbHost,
                      BACKEND_URL: endpoint,
                      ADMIN_JWT_SECRET: hash,
                      NODE_ENV: 'develop',
                    };
                    createResource(apigateway.id, habitat_id)
                      .then((response) => {
                        const funcCloneInfo = {
                          uri:
                            'https://gitlab.com/kor-comunity/kor-strapi-lambda.git',
                          project_name: `lambda-${rdsData.name}`,
                          team_id,
                          git_credential_id,
                        };
                        gitClone(funcCloneInfo)
                          .then((response) => {
                            const funcClone = response.data.repo;
                            rollBackInfo.git_lambda = funcClone.id;
                            const frontCloneInfo = {
                              uri:
                                'https://gitlab.com/kor-comunity/KOR-STRAPI.git',
                              project_name: `frontend-${rdsData.name}`,
                              team_id,
                              git_credential_id,
                            };
                            gitClone(frontCloneInfo)
                              .then((response) => {
                                const frontRepo = response.data.repo;
                                rollBackInfo.git_site = frontRepo.id;
                                const directory_name =
                                  response.data.repo.directory_name;
                                const envInfo = {
                                  file: '.env',
                                  habitat_id,
                                  directory_name,
                                  content: env,
                                };
                                envRepo(envInfo)
                                  .then((response) => {
                                    const LambdaBucket = `kor-lambda-${habitat_id}`;
                                    addBucket(LambdaBucket, habitat_id, region)
                                      .then((response) => {
                                        const s3Data = {
                                          file_name: `lambda-${rdsData.name}`,
                                          team_id,
                                          habitat_id,
                                        };
                                        uploadClone(s3Data)
                                          .then((response) => {
                                            let data = {
                                              function_name: `lambda-${rdsData.name}`,
                                              runtime: runtimeLambda,
                                              handler: handlerLambda,
                                              role: roleArn,
                                              habitat_id,
                                              git_hash: funcClone.git_hash,
                                              memory_size: 1024,
                                              timeout: 25,
                                              code_s3_bucket: LambdaBucket,
                                              code_s3_key: `lambda-${rdsData.name}.zip`,
                                            };
                                            setFunc(data)
                                              .then((response) => {
                                                const func =
                                                  response.data.aws_lambda;
                                                const buildData = {
                                                  habitat_id: func.habitat_id,
                                                  project_name:
                                                    func.function_name,
                                                  artifac_name: `${func.function_name}.zip`,
                                                  buildspec: 'buildspec.yml',
                                                  source_location:
                                                    func.function_name,
                                                  artifac_location:
                                                    func.code_s3_bucket,
                                                  service_role: roleArn,
                                                  zip: 'ZIP',
                                                  lambda_id: func.id,
                                                };
                                                createProject(buildData)
                                                  .then((response) => {
                                                    const buildInfo = {
                                                      habitat_id:
                                                        func.habitat_id,
                                                      build_id:
                                                        response.data.params.id,
                                                    };
                                                    createBuild(buildInfo)
                                                      .then((response) => {
                                                        const templateInfo = {
                                                          name:
                                                            frontRepo.project_name,
                                                          domain_name: bucket,
                                                          repository_id:
                                                            frontRepo.id,
                                                          habitat_id,
                                                          git_hash:
                                                            frontRepo.git_hash,
                                                          category:
                                                            'static site',
                                                        };
                                                        createTemplate(
                                                          templateInfo
                                                        )
                                                          .then((response) => {
                                                            const template =
                                                              response.data
                                                                .template;
                                                            const requestInfo = {
                                                              site_id:
                                                                template.id,
                                                              domain_name,
                                                              prefix,
                                                              habitat_id,
                                                            };
                                                            const s3Data = {
                                                              file_name:
                                                                template.name,
                                                              habitat_id,
                                                              team_id,
                                                            };
                                                            uploadClone(s3Data)
                                                              .then(
                                                                (response) => {
                                                                  const staticBuild = {
                                                                    habitat_id,
                                                                    project_name:
                                                                      template.name,
                                                                    artifac_name:
                                                                      '/',
                                                                    buildspec:
                                                                      'buildspec.yml',
                                                                    source_location:
                                                                      template.name,
                                                                    artifac_location:
                                                                      template.domain_name,
                                                                    service_role: roleArn,
                                                                    zip: 'NONE',
                                                                    site_id:
                                                                      template.id,
                                                                  };
                                                                  createProject(
                                                                    staticBuild
                                                                  )
                                                                    .then(
                                                                      (
                                                                        response
                                                                      ) => {
                                                                        const buildInfo = {
                                                                          habitat_id,
                                                                          build_id:
                                                                            response
                                                                              .data
                                                                              .params
                                                                              .id,
                                                                          site_id:
                                                                            template.id,
                                                                        };
                                                                        createBuild(
                                                                          buildInfo
                                                                        )
                                                                          .then(
                                                                            (
                                                                              response
                                                                            ) => {
                                                                              requestDomain(
                                                                                requestInfo
                                                                              )
                                                                                .then(
                                                                                  (
                                                                                    response
                                                                                  ) => {
                                                                                    const distributionInfo = {
                                                                                      site_id:
                                                                                        template.id,
                                                                                      distribution_name:
                                                                                        frontRepo.project_name,
                                                                                      end_point: `${bucket}.s3-website.${region}.amazonaws.com`,
                                                                                      domain_name: bucket,
                                                                                      repository_id:
                                                                                        frontRepo.id,
                                                                                      habitat_id,
                                                                                      root_object:
                                                                                        'index.html',
                                                                                    };
                                                                                    contentDistribution(
                                                                                      distributionInfo
                                                                                    )
                                                                                      .then(
                                                                                        (
                                                                                          response
                                                                                        ) => {
                                                                                          const dns_name =
                                                                                            response
                                                                                              .data
                                                                                              .cloud_front_domain;
                                                                                          let domainPrefix;
                                                                                          prefix.length >
                                                                                          0
                                                                                            ? (domainPrefix = `${prefix}.`)
                                                                                            : (domainPrefix = null);
                                                                                          const domainInfo = {
                                                                                            dns_name,
                                                                                            domain_name,
                                                                                            type:
                                                                                              'A',
                                                                                            prefix: domainPrefix,
                                                                                            cf_hosted_zone_id:
                                                                                              'Z2FDTNDATAQYW2',
                                                                                            habitat_id,
                                                                                          };
                                                                                          addDomian(
                                                                                            domainInfo
                                                                                          )
                                                                                            .then(
                                                                                              (
                                                                                                response
                                                                                              ) => {
                                                                                                const env = JSON.stringify(
                                                                                                  preEnv
                                                                                                )
                                                                                                  .replace(
                                                                                                    /\\n/g,
                                                                                                    '\\n'
                                                                                                  )
                                                                                                  .replace(
                                                                                                    /\\'/g,
                                                                                                    "\\'"
                                                                                                  )
                                                                                                  .replace(
                                                                                                    /\\"/g,
                                                                                                    '\\"'
                                                                                                  )
                                                                                                  .replace(
                                                                                                    /\\&/g,
                                                                                                    '\\&'
                                                                                                  )
                                                                                                  .replace(
                                                                                                    /\\r/g,
                                                                                                    '\\r'
                                                                                                  )
                                                                                                  .replace(
                                                                                                    /\\t/g,
                                                                                                    '\\t'
                                                                                                  )
                                                                                                  .replace(
                                                                                                    /\\b/g,
                                                                                                    '\\b'
                                                                                                  )
                                                                                                  .replace(
                                                                                                    /\\f/g,
                                                                                                    '\\f'
                                                                                                  );
                                                                                                deployFunc(
                                                                                                  func.id,
                                                                                                  env
                                                                                                )
                                                                                                  .then(
                                                                                                    (
                                                                                                      response
                                                                                                    ) => {
                                                                                                      const lambdas =
                                                                                                        response.data;
                                                                                                      const lambda_arn =
                                                                                                        lambdas[
                                                                                                          lambdas.length -
                                                                                                            1
                                                                                                        ]
                                                                                                          .function_arn;
                                                                                                      const methodData = {
                                                                                                        habitat_id,
                                                                                                        method:
                                                                                                          'ANY',
                                                                                                        type:
                                                                                                          'AWS_PROXY',
                                                                                                        lambda_arn,
                                                                                                        rol: roleArn,
                                                                                                        path_part: null,
                                                                                                      };
                                                                                                      createMethod(
                                                                                                        apiId,
                                                                                                        methodData
                                                                                                      )
                                                                                                        .then(
                                                                                                          (
                                                                                                            response
                                                                                                          ) => {
                                                                                                            const methodInfo =
                                                                                                              response
                                                                                                                .data
                                                                                                                .method;
                                                                                                            const methodProxyData = {
                                                                                                              habitat_id,
                                                                                                              method:
                                                                                                                'ANY',
                                                                                                              type:
                                                                                                                'AWS_PROXY',
                                                                                                              lambda_arn,
                                                                                                              rol: roleArn,
                                                                                                              path_part:
                                                                                                                '{proxy+}',
                                                                                                            };
                                                                                                            createMethod(
                                                                                                              apiId,
                                                                                                              methodProxyData
                                                                                                            )
                                                                                                              .then(
                                                                                                                (
                                                                                                                  response
                                                                                                                ) => {
                                                                                                                  const responseData = {
                                                                                                                    habitat_id,
                                                                                                                  };
                                                                                                                  createRespose(
                                                                                                                    methodInfo.id,
                                                                                                                    responseData
                                                                                                                  )
                                                                                                                    .then(
                                                                                                                      (
                                                                                                                        response
                                                                                                                      ) => {
                                                                                                                        deployApi(
                                                                                                                          apiId,
                                                                                                                          habitat_id
                                                                                                                        )
                                                                                                                          .then(
                                                                                                                            (
                                                                                                                              response
                                                                                                                            ) => {
                                                                                                                              const s3EbsInfo = {
                                                                                                                                habitat_id,
                                                                                                                                bucket:
                                                                                                                                  template.domain_name,
                                                                                                                                directory_name,
                                                                                                                              };
                                                                                                                              s3ToEbs(
                                                                                                                                s3EbsInfo
                                                                                                                              )
                                                                                                                                .then(
                                                                                                                                  (
                                                                                                                                    response
                                                                                                                                  ) => {
                                                                                                                                    dispatch(
                                                                                                                                      {
                                                                                                                                        type: CREATE_STRAPI_TEMPLATE_SUCCESS,
                                                                                                                                      }
                                                                                                                                    );
                                                                                                                                  }
                                                                                                                                )
                                                                                                                                .catch(
                                                                                                                                  (
                                                                                                                                    error
                                                                                                                                  ) =>
                                                                                                                                    dispatch(
                                                                                                                                      {
                                                                                                                                        type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                                        error:
                                                                                                                                          error
                                                                                                                                            .response
                                                                                                                                            .data
                                                                                                                                            .error,
                                                                                                                                      }
                                                                                                                                    )
                                                                                                                                );
                                                                                                                            }
                                                                                                                          )
                                                                                                                          .catch(
                                                                                                                            (
                                                                                                                              error
                                                                                                                            ) =>
                                                                                                                              dispatch(
                                                                                                                                {
                                                                                                                                  type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                                  error:
                                                                                                                                    error
                                                                                                                                      .response
                                                                                                                                      .data
                                                                                                                                      .error,
                                                                                                                                }
                                                                                                                              )
                                                                                                                          );
                                                                                                                      }
                                                                                                                    )
                                                                                                                    .catch(
                                                                                                                      (
                                                                                                                        error
                                                                                                                      ) => {
                                                                                                                        dispatch(
                                                                                                                          {
                                                                                                                            type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                            error:
                                                                                                                              error
                                                                                                                                .response
                                                                                                                                .data
                                                                                                                                .error,
                                                                                                                          }
                                                                                                                        );
                                                                                                                        rollBack(
                                                                                                                          rollBackInfo
                                                                                                                        );
                                                                                                                      }
                                                                                                                    );
                                                                                                                }
                                                                                                              )
                                                                                                              .catch(
                                                                                                                (
                                                                                                                  error
                                                                                                                ) => {
                                                                                                                  dispatch(
                                                                                                                    {
                                                                                                                      type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                      error:
                                                                                                                        error
                                                                                                                          .response
                                                                                                                          .data
                                                                                                                          .error,
                                                                                                                    }
                                                                                                                  );
                                                                                                                  rollBack(
                                                                                                                    rollBackInfo
                                                                                                                  );
                                                                                                                }
                                                                                                              );
                                                                                                          }
                                                                                                        )
                                                                                                        .catch(
                                                                                                          (
                                                                                                            error
                                                                                                          ) => {
                                                                                                            dispatch(
                                                                                                              {
                                                                                                                type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                error:
                                                                                                                  error
                                                                                                                    .response
                                                                                                                    .data
                                                                                                                    .error,
                                                                                                              }
                                                                                                            );
                                                                                                            rollBack(
                                                                                                              rollBackInfo
                                                                                                            );
                                                                                                          }
                                                                                                        );
                                                                                                    }
                                                                                                  )
                                                                                                  .catch(
                                                                                                    (
                                                                                                      error
                                                                                                    ) => {
                                                                                                      dispatch(
                                                                                                        {
                                                                                                          type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                          error:
                                                                                                            error
                                                                                                              .response
                                                                                                              .data
                                                                                                              .error,
                                                                                                        }
                                                                                                      );
                                                                                                      rollBack(
                                                                                                        rollBackInfo
                                                                                                      );
                                                                                                    }
                                                                                                  );
                                                                                              }
                                                                                            )
                                                                                            .catch(
                                                                                              (
                                                                                                error
                                                                                              ) => {
                                                                                                dispatch(
                                                                                                  {
                                                                                                    type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                    error:
                                                                                                      error
                                                                                                        .response
                                                                                                        .data
                                                                                                        .error,
                                                                                                  }
                                                                                                );
                                                                                                rollBack(
                                                                                                  rollBackInfo
                                                                                                );
                                                                                              }
                                                                                            );
                                                                                        }
                                                                                      )
                                                                                      .catch(
                                                                                        (
                                                                                          error
                                                                                        ) => {
                                                                                          dispatch(
                                                                                            {
                                                                                              type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                              error:
                                                                                                error
                                                                                                  .response
                                                                                                  .data
                                                                                                  .error,
                                                                                            }
                                                                                          );
                                                                                          rollBack(
                                                                                            rollBackInfo
                                                                                          );
                                                                                        }
                                                                                      );
                                                                                  }
                                                                                )
                                                                                .catch(
                                                                                  (
                                                                                    error
                                                                                  ) => {
                                                                                    dispatch(
                                                                                      {
                                                                                        type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                        error:
                                                                                          error
                                                                                            .response
                                                                                            .data
                                                                                            .error,
                                                                                      }
                                                                                    );
                                                                                    rollBack(
                                                                                      rollBackInfo
                                                                                    );
                                                                                  }
                                                                                );
                                                                            }
                                                                          )
                                                                          .catch(
                                                                            (
                                                                              error
                                                                            ) => {
                                                                              dispatch(
                                                                                {
                                                                                  type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                  error:
                                                                                    error
                                                                                      .response
                                                                                      .data
                                                                                      .error,
                                                                                }
                                                                              );
                                                                              rollBack(
                                                                                rollBackInfo
                                                                              );
                                                                            }
                                                                          );
                                                                      }
                                                                    )
                                                                    .catch(
                                                                      (
                                                                        error
                                                                      ) => {
                                                                        dispatch(
                                                                          {
                                                                            type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                            error:
                                                                              error
                                                                                .response
                                                                                .data
                                                                                .error,
                                                                          }
                                                                        );
                                                                        rollBack(
                                                                          rollBackInfo
                                                                        );
                                                                      }
                                                                    );
                                                                }
                                                              )
                                                              .catch(
                                                                (error) => {
                                                                  dispatch({
                                                                    type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                    error:
                                                                      error
                                                                        .response
                                                                        .data
                                                                        .error,
                                                                  });
                                                                  rollBack(
                                                                    rollBackInfo
                                                                  );
                                                                }
                                                              );
                                                          })
                                                          .catch((error) => {
                                                            dispatch({
                                                              type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                              error:
                                                                error.response
                                                                  .data.error,
                                                            });
                                                            rollBack(
                                                              rollBackInfo
                                                            );
                                                          });
                                                      })
                                                      .catch((error) => {
                                                        dispatch({
                                                          type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                          error:
                                                            error.response.data
                                                              .error,
                                                        });
                                                        rollBack(rollBackInfo);
                                                      });
                                                  })
                                                  .catch((error) => {
                                                    dispatch({
                                                      type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                      error:
                                                        error.response.data
                                                          .error,
                                                    });
                                                    rollBack(rollBackInfo);
                                                  });
                                              })
                                              .catch((error) => {
                                                dispatch({
                                                  type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                  error:
                                                    error.response.data.error,
                                                });
                                                rollBack(rollBackInfo);
                                              });
                                          })
                                          .catch((error) => {
                                            dispatch({
                                              type: CREATE_STRAPI_TEMPLATE_ERROR,
                                              error: error.response.data.error,
                                            });
                                            rollBack(rollBackInfo);
                                          });
                                      })
                                      .catch((error) => {
                                        dispatch({
                                          type: CREATE_STRAPI_TEMPLATE_ERROR,
                                          error: error.response.data.error,
                                        });
                                        rollBack(rollBackInfo);
                                      });
                                  })
                                  .catch((error) => {
                                    dispatch({
                                      type: CREATE_STRAPI_TEMPLATE_ERROR,
                                      error: error.response.data.error,
                                    });
                                    rollBack(rollBackInfo);
                                  });
                              })
                              .catch((error) => {
                                dispatch({
                                  type: CREATE_STRAPI_TEMPLATE_ERROR,
                                  error: error.response.data.error,
                                });
                                rollBack(rollBackInfo);
                              });
                          })
                          .catch((error) => {
                            dispatch({
                              type: CREATE_STRAPI_TEMPLATE_ERROR,
                              error: error.response.data.error,
                            });
                            rollBack(rollBackInfo);
                          });
                      })
                      .catch((error) => {
                        dispatch({
                          type: CREATE_STRAPI_TEMPLATE_ERROR,
                          error: error.response.data.error,
                        });
                        rollBack(rollBackInfo);
                      });
                  })
                  .catch((error) => {
                    dispatch({
                      type: CREATE_STRAPI_TEMPLATE_ERROR,
                      error: error.response.data.error,
                    });
                    rollBack(rollBackInfo);
                  });
              })
              .catch((error) => {
                dispatch({
                  type: CREATE_STRAPI_TEMPLATE_ERROR,
                  error: error.response.data.error,
                });
                rollBack(rollBackInfo);
              });
          })
          .catch((error) => {
            dispatch({
              type: CREATE_STRAPI_TEMPLATE_ERROR,
              error: error.response.data.error,
            });
            rollBack(rollBackInfo);
          });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_STRAPI_TEMPLATE_ERROR,
          error: error.response.data.error,
        });
        rollBack(rollBackInfo);
      });
  };
};

export const deployTemplateWebApp = (
  team_id,
  nameCred,
  apiKeyCred,
  apiSecretCred,
  regionCred,
  uri,
  prefix,
  domain_name,
  name,
  git_credential_id,
  cognitoName,
  apiName,
  pathApi
) => {
  return (dispatch) => {
    let bucket;
    let staticBucket;
    let repoLambda;
    let endpoint;
    let userPoolId;
    let userPoolClientId;
    const roleName = `KOR-ROLE-${name}-${Date.now()}`;
    const lambdaName = `KOR-LAMBDA-${name}-${Date.now()}`;
    const runtimeLambda = 'nodejs12.x';
    const handlerLambda = 'index.handler';
    const habitat = {
      name,
      team_id: team_id,
    };

    if (prefix) {
      bucket = `${prefix}.${domain_name}`;
      staticBucket = `${prefix}.${domain_name}`;
    } else {
      bucket = domain_name;
      staticBucket = domain_name;
    }

    const algorithm = 'aes-256-cfb';
    let key = '';
    const iv = '0000000000000000';

    dispatch({
      type: CREATE_WEB_APP_TEMPLATE_BEGIN,
      loading_message: 'Creating template...',
    });
    //Create habitat
    createHabitat(habitat)
      .then((response) => {
        let habitat_id = response.data.id;
        let rollBackInfo = {
          habitat_id,
          rol_id: null,
          git_site: null,
          git_lambda: null,
        };
        const payloadHabitat = response.data;
        //Request Encrypt Key
        requestEncryptKey(habitat_id)
          .then((response) => {
            key = response.data.key;

            var cipherApiKey = crypto.createCipheriv(algorithm, key, iv);
            var cryptedApiKey = cipherApiKey.update(
              apiKeyCred,
              'utf-8',
              'base64'
            );
            cryptedApiKey += cipherApiKey.final('base64');

            var cipherApiSecret = crypto.createCipheriv(algorithm, key, iv);
            var cryptedApiSecret = cipherApiSecret.update(
              apiSecretCred,
              'utf-8',
              'base64'
            );
            cryptedApiSecret += cipherApiSecret.final('base64');

            let awsCreds = awsCredModel(
              nameCred,
              cryptedApiKey,
              cryptedApiSecret,
              regionCred,
              habitat_id,
              key
            );
            //Create AWS Creds
            createAWSCredential(awsCreds)
              .then((response) => {
                const credId = response.data.id;
                let newHabCredId = { aws_credential_id: credId };
                verifyCreds(credId)
                  .then((response) => {
                    //Attach Creds to Habitat
                    attachCredHabitat(habitat_id, newHabCredId)
                      .then((response) => {
                        const s3Static = {
                          bucket,
                          habitat_id,
                          regionCred,
                          public: 1,
                        };
                        //Add Static Bucket
                        addStaticBucket(s3Static)
                          .then((response) => {
                            let awsPolicyDocument = {
                              Version: '2012-10-17',
                              Statement: [
                                {
                                  Effect: 'Allow',
                                  Action: [
                                    'logs:CreateLogGroup',
                                    'logs:CreateLogStream',
                                    'logs:DescribeLogGroups',
                                    'logs:DescribeLogStreams',
                                    'logs:PutLogEvents',
                                    'logs:GetLogEvents',
                                    'logs:FilterLogEvents',
                                  ],
                                  Resource: '*',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: 'states:*',
                                  Resource: '*',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: 'logs:CreateLogGroup',
                                  Resource:
                                    'arn:aws:logs:us-east-1:973651203340:*',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: [
                                    'logs:CreateLogStream',
                                    'logs:PutLogEvents',
                                  ],
                                  Resource: [
                                    'arn:aws:logs:us-east-1:973651203340:log-group:/aws/lambda/my-app-lambda:*',
                                    'arn:aws:logs:us-east-1:973651203340:log-group:/aws/codebuild/*',
                                  ],
                                },
                                {
                                  Effect: 'Allow',
                                  Action: ['ecr:*', 'cloudtrail:LookupEvents'],
                                  Resource: '*',
                                },
                                {
                                  Sid: 'CloudWatchEventsFullAccess',
                                  Effect: 'Allow',
                                  Action: 'events:*',
                                  Resource: '*',
                                },
                                {
                                  Action: ['iam:PassRole'],
                                  Resource: '*',
                                  Effect: 'Allow',
                                  Condition: {
                                    StringEqualsIfExists: {
                                      'iam:PassedToService': [
                                        'cloudformation.amazonaws.com',
                                        'elasticbeanstalk.amazonaws.com',
                                        'ec2.amazonaws.com',
                                        'ecs-tasks.amazonaws.com',
                                      ],
                                    },
                                  },
                                },
                                {
                                  Action: [
                                    'codecommit:CancelUploadArchive',
                                    'codecommit:GetBranch',
                                    'codecommit:GetCommit',
                                    'codecommit:GetUploadArchiveStatus',
                                    'codecommit:UploadArchive',
                                  ],
                                  Resource: '*',
                                  Effect: 'Allow',
                                },
                                {
                                  Action: [
                                    'codedeploy:CreateDeployment',
                                    'codedeploy:GetApplication',
                                    'codedeploy:GetApplicationRevision',
                                    'codedeploy:GetDeployment',
                                    'codedeploy:GetDeploymentConfig',
                                    'codedeploy:RegisterApplicationRevision',
                                  ],
                                  Resource: '*',
                                  Effect: 'Allow',
                                },
                                {
                                  Action: [
                                    'codestar-connections:UseConnection',
                                  ],
                                  Resource: '*',
                                  Effect: 'Allow',
                                },
                                {
                                  Action: [
                                    'elasticbeanstalk:*',
                                    'ec2:*',
                                    'elasticloadbalancing:*',
                                    'autoscaling:*',
                                    'cloudwatch:*',
                                    's3:*',
                                    'sns:*',
                                    'cloudformation:*',
                                    'rds:*',
                                    'sqs:*',
                                    'ecs:*',
                                  ],
                                  Resource: '*',
                                  Effect: 'Allow',
                                },
                                {
                                  Action: [
                                    'lambda:InvokeFunction',
                                    'lambda:ListFunctions',
                                  ],
                                  Resource: '*',
                                  Effect: 'Allow',
                                },
                                {
                                  Action: [
                                    'opsworks:CreateDeployment',
                                    'opsworks:DescribeApps',
                                    'opsworks:DescribeCommands',
                                    'opsworks:DescribeDeployments',
                                    'opsworks:DescribeInstances',
                                    'opsworks:DescribeStacks',
                                    'opsworks:UpdateApp',
                                    'opsworks:UpdateStack',
                                  ],
                                  Resource: '*',
                                  Effect: 'Allow',
                                },
                                {
                                  Action: [
                                    'cloudformation:CreateStack',
                                    'cloudformation:DeleteStack',
                                    'cloudformation:DescribeStacks',
                                    'cloudformation:UpdateStack',
                                    'cloudformation:CreateChangeSet',
                                    'cloudformation:DeleteChangeSet',
                                    'cloudformation:DescribeChangeSet',
                                    'cloudformation:ExecuteChangeSet',
                                    'cloudformation:SetStackPolicy',
                                    'cloudformation:ValidateTemplate',
                                  ],
                                  Resource: '*',
                                  Effect: 'Allow',
                                },
                                {
                                  Action: [
                                    'codebuild:BatchGetBuilds',
                                    'codebuild:StartBuild',
                                    'codebuild:BatchGetBuildBatches',
                                    'codebuild:StartBuildBatch',
                                  ],
                                  Resource: '*',
                                  Effect: 'Allow',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: [
                                    'devicefarm:ListProjects',
                                    'devicefarm:ListDevicePools',
                                    'devicefarm:GetRun',
                                    'devicefarm:GetUpload',
                                    'devicefarm:CreateUpload',
                                    'devicefarm:ScheduleRun',
                                  ],
                                  Resource: '*',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: [
                                    'servicecatalog:ListProvisioningArtifacts',
                                    'servicecatalog:CreateProvisioningArtifact',
                                    'servicecatalog:DescribeProvisioningArtifact',
                                    'servicecatalog:DeleteProvisioningArtifact',
                                    'servicecatalog:UpdateProduct',
                                  ],
                                  Resource: '*',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: ['cloudformation:ValidateTemplate'],
                                  Resource: '*',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: ['ecr:DescribeImages'],
                                  Resource: '*',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: [
                                    'states:DescribeExecution',
                                    'states:DescribeStateMachine',
                                    'states:StartExecution',
                                  ],
                                  Resource: '*',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: [
                                    'appconfig:StartDeployment',
                                    'appconfig:StopDeployment',
                                    'appconfig:GetDeployment',
                                  ],
                                  Resource: '*',
                                },
                                {
                                  Sid: 'IAMPassRoleForCloudWatchEvents',
                                  Effect: 'Allow',
                                  Action: 'iam:PassRole',
                                  Resource:
                                    'arn:aws:iam::*:role/AWS_Events_Invoke_Targets',
                                },
                                {
                                  Effect: 'Allow',
                                  Action: [
                                    'xray:PutTraceSegments',
                                    'xray:PutTelemetryRecords',
                                    'xray:GetSamplingRules',
                                    'xray:GetSamplingTargets',
                                  ],
                                  Resource: ['*'],
                                },
                                {
                                  Effect: 'Allow',
                                  Action: [
                                    'cloudformation:DescribeChangeSet',
                                    'cloudformation:DescribeStackResources',
                                    'cloudformation:DescribeStacks',
                                    'cloudformation:GetTemplate',
                                    'cloudformation:ListStackResources',
                                    'cloudwatch:*',
                                    'cognito-identity:ListIdentityPools',
                                    'cognito-sync:GetCognitoEvents',
                                    'cognito-sync:SetCognitoEvents',
                                    'dynamodb:*',
                                    'ec2:DescribeSecurityGroups',
                                    'ec2:DescribeSubnets',
                                    'ec2:DescribeVpcs',
                                    'events:*',
                                    'iam:GetPolicy',
                                    'iam:GetPolicyVersion',
                                    'iam:GetRole',
                                    'iam:GetRolePolicy',
                                    'iam:ListAttachedRolePolicies',
                                    'iam:ListRolePolicies',
                                    'iam:ListRoles',
                                    'iam:PassRole',
                                    'iot:AttachPrincipalPolicy',
                                    'iot:AttachThingPrincipal',
                                    'iot:CreateKeysAndCertificate',
                                    'iot:CreatePolicy',
                                    'iot:CreateThing',
                                    'iot:CreateTopicRule',
                                    'iot:DescribeEndpoint',
                                    'iot:GetTopicRule',
                                    'iot:ListPolicies',
                                    'iot:ListThings',
                                    'iot:ListTopicRules',
                                    'iot:ReplaceTopicRule',
                                    'kinesis:DescribeStream',
                                    'kinesis:ListStreams',
                                    'kinesis:PutRecord',
                                    'kms:ListAliases',
                                    'codebuild:*',
                                    'lambda:*',
                                    'logs:*',
                                    's3:*',
                                    'sns:ListSubscriptions',
                                    'sns:ListSubscriptionsByTopic',
                                    'sns:ListTopics',
                                    'sns:Publish',
                                    'sns:Subscribe',
                                    'sns:Unsubscribe',
                                    'sqs:ListQueues',
                                    'sqs:SendMessage',
                                    'tag:GetResources',
                                    'xray:PutTelemetryRecords',
                                    'xray:PutTraceSegments',
                                  ],
                                  Resource: '*',
                                },
                              ],
                            };

                            let awsRolePolicy = {
                              Version: '2012-10-17',
                              Statement: {
                                Effect: 'Allow',
                                Principal: {
                                  Service: [
                                    'lambda.amazonaws.com',
                                    'apigateway.amazonaws.com',
                                    'codebuild.amazonaws.com',
                                  ],
                                },
                                Action: 'sts:AssumeRole',
                              },
                            };

                            const jsonPolicyDocument = JSON.stringify(
                              awsPolicyDocument
                            );
                            const awsPolicyDocumentReplace = jsonPolicyDocument
                              .replace(/\\n/g, '\\n')
                              .replace(/\\'/g, "\\'")
                              .replace(/\\"/g, '\\"')
                              .replace(/\\&/g, '\\&')
                              .replace(/\\r/g, '\\r')
                              .replace(/\\t/g, '\\t')
                              .replace(/\\b/g, '\\b')
                              .replace(/\\f/g, '\\f');

                            const jsonAwsRolePolicy = JSON.stringify(
                              awsRolePolicy
                            );
                            const awsRolePolicyReplace = jsonAwsRolePolicy
                              .replace(/\\n/g, '\\n')
                              .replace(/\\'/g, "\\'")
                              .replace(/\\"/g, '\\"')
                              .replace(/\\&/g, '\\&')
                              .replace(/\\r/g, '\\r')
                              .replace(/\\t/g, '\\t')
                              .replace(/\\b/g, '\\b')
                              .replace(/\\f/g, '\\f');
                            const role_name = roleName.replace(/\s/g, '-');
                            const data = {
                              role_name,
                              role_policy: awsRolePolicyReplace,
                              habitat_id: habitat_id,
                              policy_document: awsPolicyDocumentReplace,
                            };
                            //Creata AWS Role
                            createAWSRole(data)
                              .then((response) => {
                                const roleArn = response.data[0].arn;
                                rollBackInfo.rol_id = response.data[0].id;
                                const gitCloneInfo = {
                                  uri,
                                  project_name: name.replace(
                                    /[^A-Z0-9]/gi,
                                    '-'
                                  ),
                                  team_id,
                                  git_credential_id,
                                };
                                //Clone Repository for web app
                                gitClone(gitCloneInfo)
                                  .then((response) => {
                                    const repo = response.data.repo;
                                    const repository_id = repo.id;
                                    const directory_name = repo.directory_name;
                                    const funcCloneInfo = {
                                      uri:
                                        'https://gitlab.com/kor-comunity/kor-crud-lambda.git',
                                      project_name: lambdaName,
                                      team_id,
                                      git_credential_id,
                                    };
                                    rollBackInfo.git_site = repository_id;
                                    gitClone(funcCloneInfo)
                                      .then((response) => {
                                        const lambda = response.data.repo;
                                        repoLambda = lambda.id;
                                        const upload = {
                                          team_id,
                                          file_name: name.replace(
                                            /[^A-Z0-9]/gi,
                                            '-'
                                          ),
                                          bucket,
                                          habitat_id,
                                        };
                                        rollBackInfo.git_lambda = repoLambda;
                                        //Upload Repository to s3
                                        uploadFolder(upload)
                                          .then((response) => {
                                            //Get s3 Buckets
                                            getBuckets(habitat_id)
                                              .then((response) => {
                                                const s3Buckets = response.data;
                                                //Get Git Clones
                                                gitClonesList(team_id)
                                                  .then((response) => {
                                                    const gitClones =
                                                      response.data;
                                                    const clone = gitClones.find(
                                                      (clone) =>
                                                        clone.id === repoLambda
                                                    );
                                                    const func_name =
                                                      clone.project_name;
                                                    const git_hash =
                                                      clone.git_hash;
                                                    let data = {
                                                      function_name: func_name,
                                                      runtime: runtimeLambda,
                                                      handler: handlerLambda,
                                                      role: roleArn,
                                                      habitat_id,
                                                      git_hash,
                                                      memory_size: null,
                                                      timeout: null,
                                                      code_s3_bucket: null,
                                                      code_s3_key: null,
                                                    };
                                                    const bucketName = `kor-lambda-${habitat_id}`;
                                                    data.code_s3_bucket = bucketName;
                                                    data.code_s3_key = `${func_name}.zip`;
                                                    let bucket;
                                                    if (s3Buckets) {
                                                      bucket = s3Buckets.find(
                                                        (bucket) =>
                                                          bucket.bucket ===
                                                          bucketName
                                                      );
                                                    }

                                                    if (!bucket) {
                                                      addBucket(
                                                        bucketName,
                                                        habitat_id,
                                                        regionCred
                                                      )
                                                        .then((response) => {
                                                          //Create Function
                                                          setFunc(data)
                                                            .then(
                                                              (response) => {
                                                                const fileName = func_name;
                                                                const funcS3Bucket = bucketName;
                                                                const funcId =
                                                                  response
                                                                    .data[0].id;
                                                                const zipInfo = {
                                                                  file_name: fileName,
                                                                  habitat_id,
                                                                };
                                                                zipFile(zipInfo)
                                                                  .then(
                                                                    (
                                                                      response
                                                                    ) => {
                                                                      const file =
                                                                        response
                                                                          .data
                                                                          .path;
                                                                      const file_name = `${fileName}.zip`;
                                                                      uploadFile(
                                                                        file,
                                                                        funcS3Bucket,
                                                                        habitat_id,
                                                                        file_name
                                                                      )
                                                                        .then(
                                                                          (
                                                                            response
                                                                          ) => {
                                                                            zipRemove(
                                                                              file
                                                                            )
                                                                              .then(
                                                                                (
                                                                                  response
                                                                                ) => {
                                                                                  deployFunc(
                                                                                    funcId
                                                                                  )
                                                                                    .then(
                                                                                      (
                                                                                        response
                                                                                      ) => {
                                                                                        const lambdaArn =
                                                                                          response
                                                                                            .data[0]
                                                                                            .function_arn;
                                                                                        newCognito(
                                                                                          cognitoName,
                                                                                          habitat_id
                                                                                        )
                                                                                          .then(
                                                                                            (
                                                                                              response
                                                                                            ) => {
                                                                                              const cognitoArn =
                                                                                                response
                                                                                                  .data
                                                                                                  .arn;
                                                                                              userPoolId =
                                                                                                response
                                                                                                  .data
                                                                                                  .user_pool_id;
                                                                                              const cognitoID =
                                                                                                response
                                                                                                  .data
                                                                                                  .id;
                                                                                              createApiGateway(
                                                                                                apiName,
                                                                                                habitat_id
                                                                                              )
                                                                                                .then(
                                                                                                  (
                                                                                                    response
                                                                                                  ) => {
                                                                                                    const apiId =
                                                                                                      response
                                                                                                        .data
                                                                                                        .api_gateway
                                                                                                        .id;
                                                                                                    authorizeCognito(
                                                                                                      habitat_id,
                                                                                                      cognitoArn,
                                                                                                      apiId
                                                                                                    )
                                                                                                      .then(
                                                                                                        (
                                                                                                          response
                                                                                                        ) => {
                                                                                                          createResource(
                                                                                                            apiId,
                                                                                                            habitat_id,
                                                                                                            pathApi
                                                                                                          )
                                                                                                            .then(
                                                                                                              (
                                                                                                                response
                                                                                                              ) => {
                                                                                                                const method =
                                                                                                                  'POST';
                                                                                                                createMethod(
                                                                                                                  habitat_id,
                                                                                                                  method,
                                                                                                                  lambdaArn,
                                                                                                                  apiId,
                                                                                                                  roleArn
                                                                                                                )
                                                                                                                  .then(
                                                                                                                    (
                                                                                                                      response
                                                                                                                    ) => {
                                                                                                                      const templateInfo = {
                                                                                                                        name: name.replace(
                                                                                                                          /[^A-Z0-9]/gi,
                                                                                                                          '-'
                                                                                                                        ),
                                                                                                                        domain_name: bucket,
                                                                                                                        repository_id,
                                                                                                                        habitat_id,
                                                                                                                        git_hash,
                                                                                                                        category:
                                                                                                                          'web app',
                                                                                                                      };
                                                                                                                      createTemplate(
                                                                                                                        templateInfo
                                                                                                                      )
                                                                                                                        .then(
                                                                                                                          (
                                                                                                                            response
                                                                                                                          ) => {
                                                                                                                            const payload = {
                                                                                                                              templates:
                                                                                                                                response
                                                                                                                                  .data
                                                                                                                                  .templates,
                                                                                                                              habitat: payloadHabitat,
                                                                                                                            };
                                                                                                                            const template =
                                                                                                                              response
                                                                                                                                .data
                                                                                                                                .template;
                                                                                                                            const requestInfo = {
                                                                                                                              site_id:
                                                                                                                                template.id,
                                                                                                                              domain_name,
                                                                                                                              prefix,
                                                                                                                              habitat_id,
                                                                                                                            };
                                                                                                                            requestDomain(
                                                                                                                              requestInfo
                                                                                                                            )
                                                                                                                              .then(
                                                                                                                                (
                                                                                                                                  response
                                                                                                                                ) => {
                                                                                                                                  deployApi(
                                                                                                                                    apiId,
                                                                                                                                    habitat_id,
                                                                                                                                    'v1'
                                                                                                                                  )
                                                                                                                                    .then(
                                                                                                                                      (
                                                                                                                                        response
                                                                                                                                      ) => {
                                                                                                                                        endpoint =
                                                                                                                                          response
                                                                                                                                            .data
                                                                                                                                            .endpoint;
                                                                                                                                        const cognitoUserInfo = {
                                                                                                                                          habitat_id,
                                                                                                                                          name: prefix,
                                                                                                                                          id: cognitoID,
                                                                                                                                        };
                                                                                                                                        createCognitoUser(
                                                                                                                                          cognitoUserInfo
                                                                                                                                        )
                                                                                                                                          .then(
                                                                                                                                            (
                                                                                                                                              response
                                                                                                                                            ) => {
                                                                                                                                              userPoolClientId =
                                                                                                                                                response
                                                                                                                                                  .data
                                                                                                                                                  .identity_pool_id;
                                                                                                                                              const config = `window._config = {
  cognito: {
      userPoolId: '${userPoolId}',
      clientId: '${userPoolClientId}',
      region: '${regionCred}'
  },
  api: {
      invokeUrl: '${endpoint}',
  }
};`;
                                                                                                                                              const envInfo = {
                                                                                                                                                file:
                                                                                                                                                  'config.js',
                                                                                                                                                bucket: staticBucket,
                                                                                                                                                habitat_id,
                                                                                                                                                directory_name,
                                                                                                                                                content: config,
                                                                                                                                              };
                                                                                                                                              addEnv(
                                                                                                                                                envInfo
                                                                                                                                              )
                                                                                                                                                .then(
                                                                                                                                                  (
                                                                                                                                                    response
                                                                                                                                                  ) => {
                                                                                                                                                    const distributionInfo = {
                                                                                                                                                      site_id:
                                                                                                                                                        template.id,
                                                                                                                                                      distribution_name: name.replace(
                                                                                                                                                        /[^A-Z0-9]/gi,
                                                                                                                                                        '-'
                                                                                                                                                      ),
                                                                                                                                                      end_point: `${staticBucket}.s3-website.${regionCred}.amazonaws.com`,
                                                                                                                                                      domain_name: staticBucket,
                                                                                                                                                      repository_id,
                                                                                                                                                      habitat_id,
                                                                                                                                                      root_object:
                                                                                                                                                        'index.html',
                                                                                                                                                    };
                                                                                                                                                    contentDistribution(
                                                                                                                                                      distributionInfo
                                                                                                                                                    )
                                                                                                                                                      .then(
                                                                                                                                                        (
                                                                                                                                                          response
                                                                                                                                                        ) => {
                                                                                                                                                          let domainPrefix;
                                                                                                                                                          const cloudFrontDns =
                                                                                                                                                            response
                                                                                                                                                              .data
                                                                                                                                                              .cloud_front_domain;
                                                                                                                                                          prefix.length >
                                                                                                                                                          0
                                                                                                                                                            ? (domainPrefix = `${prefix}.`)
                                                                                                                                                            : (domainPrefix = null);
                                                                                                                                                          const domainInfo = {
                                                                                                                                                            dns_name: cloudFrontDns,
                                                                                                                                                            domain_name,
                                                                                                                                                            type:
                                                                                                                                                              'A',
                                                                                                                                                            prefix: domainPrefix,
                                                                                                                                                            cf_hosted_zone_id:
                                                                                                                                                              'Z2FDTNDATAQYW2',
                                                                                                                                                            habitat_id,
                                                                                                                                                          };
                                                                                                                                                          addDomian(
                                                                                                                                                            domainInfo
                                                                                                                                                          )
                                                                                                                                                            .then(
                                                                                                                                                              (
                                                                                                                                                                response
                                                                                                                                                              ) => {
                                                                                                                                                                dispatch(
                                                                                                                                                                  {
                                                                                                                                                                    type: CREATE_WEB_APP_TEMPLATE_SUCCESS,
                                                                                                                                                                    payload,
                                                                                                                                                                  }
                                                                                                                                                                );
                                                                                                                                                              }
                                                                                                                                                            )
                                                                                                                                                            .catch(
                                                                                                                                                              (
                                                                                                                                                                error
                                                                                                                                                              ) => {
                                                                                                                                                                const error_message =
                                                                                                                                                                  'Error at Create Template.';
                                                                                                                                                                dispatch(
                                                                                                                                                                  {
                                                                                                                                                                    type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                    error: error_message,
                                                                                                                                                                  }
                                                                                                                                                                );
                                                                                                                                                                rollBack(
                                                                                                                                                                  rollBackInfo
                                                                                                                                                                );
                                                                                                                                                              }
                                                                                                                                                            );
                                                                                                                                                        }
                                                                                                                                                      )
                                                                                                                                                      .catch(
                                                                                                                                                        (
                                                                                                                                                          error
                                                                                                                                                        ) => {
                                                                                                                                                          const error_message =
                                                                                                                                                            'Error at Add Domain.';
                                                                                                                                                          dispatch(
                                                                                                                                                            {
                                                                                                                                                              type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                              error: error_message,
                                                                                                                                                            }
                                                                                                                                                          );
                                                                                                                                                          rollBack(
                                                                                                                                                            rollBackInfo
                                                                                                                                                          );
                                                                                                                                                        }
                                                                                                                                                      );
                                                                                                                                                  }
                                                                                                                                                )
                                                                                                                                                .catch(
                                                                                                                                                  (
                                                                                                                                                    error
                                                                                                                                                  ) => {
                                                                                                                                                    const error_message =
                                                                                                                                                      error.response.data.error;
                                                                                                                                                    dispatch(
                                                                                                                                                      {
                                                                                                                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                        error: error_message,
                                                                                                                                                      }
                                                                                                                                                    );
                                                                                                                                                    rollBack(
                                                                                                                                                      rollBackInfo
                                                                                                                                                    );
                                                                                                                                                  }
                                                                                                                                                );
                                                                                                                                            }
                                                                                                                                          )
                                                                                                                                          .catch(
                                                                                                                                            (
                                                                                                                                              error
                                                                                                                                            ) => {
                                                                                                                                              const error_message =
                                                                                                                                                'Error at Env addition.';
                                                                                                                                              dispatch(
                                                                                                                                                {
                                                                                                                                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                  error: error_message,
                                                                                                                                                }
                                                                                                                                              );
                                                                                                                                              rollBack(
                                                                                                                                                rollBackInfo
                                                                                                                                              );
                                                                                                                                            }
                                                                                                                                          );
                                                                                                                                      }
                                                                                                                                    )
                                                                                                                                    .catch(
                                                                                                                                      (
                                                                                                                                        error
                                                                                                                                      ) => {
                                                                                                                                        const error_message =
                                                                                                                                          'Error at Cognito User Pool creation.';
                                                                                                                                        dispatch(
                                                                                                                                          {
                                                                                                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                            error: error_message,
                                                                                                                                          }
                                                                                                                                        );
                                                                                                                                        rollBack(
                                                                                                                                          rollBackInfo
                                                                                                                                        );
                                                                                                                                      }
                                                                                                                                    );
                                                                                                                                }
                                                                                                                              )
                                                                                                                              .catch(
                                                                                                                                (
                                                                                                                                  error
                                                                                                                                ) => {
                                                                                                                                  const error_message =
                                                                                                                                    'Error at Deploy API.';
                                                                                                                                  dispatch(
                                                                                                                                    {
                                                                                                                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                      error: error_message,
                                                                                                                                    }
                                                                                                                                  );
                                                                                                                                  rollBack(
                                                                                                                                    rollBackInfo
                                                                                                                                  );
                                                                                                                                }
                                                                                                                              );
                                                                                                                          }
                                                                                                                        )
                                                                                                                        .catch(
                                                                                                                          (
                                                                                                                            error
                                                                                                                          ) => {
                                                                                                                            const error_message =
                                                                                                                              'Error at Request Domain.';
                                                                                                                            dispatch(
                                                                                                                              {
                                                                                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                error: error_message,
                                                                                                                              }
                                                                                                                            );
                                                                                                                          }
                                                                                                                        );
                                                                                                                    }
                                                                                                                  )
                                                                                                                  .catch(
                                                                                                                    (
                                                                                                                      error
                                                                                                                    ) => {
                                                                                                                      const error_message =
                                                                                                                        'Error at Template Creation.';
                                                                                                                      dispatch(
                                                                                                                        {
                                                                                                                          type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                          error: error_message,
                                                                                                                        }
                                                                                                                      );
                                                                                                                      rollBack(
                                                                                                                        rollBackInfo
                                                                                                                      );
                                                                                                                    }
                                                                                                                  );
                                                                                                              }
                                                                                                            )
                                                                                                            .catch(
                                                                                                              (
                                                                                                                error
                                                                                                              ) => {
                                                                                                                const error_message =
                                                                                                                  'Error at Method Creation.';
                                                                                                                dispatch(
                                                                                                                  {
                                                                                                                    type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                    error: error_message,
                                                                                                                  }
                                                                                                                );
                                                                                                                rollBack(
                                                                                                                  rollBackInfo
                                                                                                                );
                                                                                                              }
                                                                                                            );
                                                                                                        }
                                                                                                      )
                                                                                                      .catch(
                                                                                                        (
                                                                                                          error
                                                                                                        ) => {
                                                                                                          const error_message =
                                                                                                            'Error at Resource Creation.';
                                                                                                          dispatch(
                                                                                                            {
                                                                                                              type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                              error: error_message,
                                                                                                            }
                                                                                                          );
                                                                                                          rollBack(
                                                                                                            rollBackInfo
                                                                                                          );
                                                                                                        }
                                                                                                      );
                                                                                                  }
                                                                                                )
                                                                                                .catch(
                                                                                                  (
                                                                                                    error
                                                                                                  ) => {
                                                                                                    const error_message =
                                                                                                      'Error at Cognito Authorization.';
                                                                                                    dispatch(
                                                                                                      {
                                                                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                        error: error_message,
                                                                                                      }
                                                                                                    );
                                                                                                    rollBack(
                                                                                                      rollBackInfo
                                                                                                    );
                                                                                                  }
                                                                                                );
                                                                                            }
                                                                                          )
                                                                                          .catch(
                                                                                            (
                                                                                              error
                                                                                            ) => {
                                                                                              const error_message =
                                                                                                'Error at Cognito Creation.';
                                                                                              dispatch(
                                                                                                {
                                                                                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                  error: error_message,
                                                                                                }
                                                                                              );
                                                                                              rollBack(
                                                                                                rollBackInfo
                                                                                              );
                                                                                            }
                                                                                          );
                                                                                      }
                                                                                    )
                                                                                    .catch(
                                                                                      (
                                                                                        error
                                                                                      ) => {
                                                                                        const error_message =
                                                                                          'Error at Deploy Lambda Function.';
                                                                                        dispatch(
                                                                                          {
                                                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                            error: error_message,
                                                                                          }
                                                                                        );
                                                                                        rollBack(
                                                                                          rollBackInfo
                                                                                        );
                                                                                      }
                                                                                    );
                                                                                }
                                                                              )
                                                                              .catch(
                                                                                (
                                                                                  error
                                                                                ) => {
                                                                                  const error_message =
                                                                                    'Error at Zip Remove.';
                                                                                  dispatch(
                                                                                    {
                                                                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                      error: error_message,
                                                                                    }
                                                                                  );
                                                                                  rollBack(
                                                                                    rollBackInfo
                                                                                  );
                                                                                }
                                                                              );
                                                                          }
                                                                        )
                                                                        .catch(
                                                                          (
                                                                            error
                                                                          ) => {
                                                                            const error_message =
                                                                              'Error at Upload File.';
                                                                            dispatch(
                                                                              {
                                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                error: error_message,
                                                                              }
                                                                            );
                                                                            rollBack(
                                                                              rollBackInfo
                                                                            );
                                                                          }
                                                                        );
                                                                    }
                                                                  )
                                                                  .catch(
                                                                    (error) => {
                                                                      const error_message =
                                                                        'Error at Create Zip File.';
                                                                      dispatch({
                                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                        error: error_message,
                                                                      });
                                                                      rollBack(
                                                                        rollBackInfo
                                                                      );
                                                                    }
                                                                  );
                                                              }
                                                            )
                                                            .catch((error) => {
                                                              const error_message =
                                                                'Error at Lambda Function Creation.';
                                                              dispatch({
                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                error: error_message,
                                                              });
                                                              rollBack(
                                                                rollBackInfo
                                                              );
                                                            });
                                                        })
                                                        .catch((error) => {
                                                          const error_message =
                                                            'Error at S3 Bucket Creation.';
                                                          dispatch({
                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                            error: error_message,
                                                          });
                                                          rollBack(
                                                            rollBackInfo
                                                          );
                                                        });
                                                    } else {
                                                      setFunc(data)
                                                        //Create Function
                                                        .then((response) => {
                                                          const fileName = func_name;
                                                          const funcS3Bucket = bucketName;
                                                          const funcId =
                                                            response.data[0].id;
                                                          const zipInfo = {
                                                            file_name: fileName,
                                                            habitat_id,
                                                          };
                                                          zipFile(zipInfo)
                                                            .then(
                                                              (response) => {
                                                                const file =
                                                                  response.data
                                                                    .path;
                                                                const file_name = `${fileName}.zip`;
                                                                uploadFile(
                                                                  file,
                                                                  funcS3Bucket,
                                                                  habitat_id,
                                                                  file_name
                                                                )
                                                                  .then(
                                                                    (
                                                                      response
                                                                    ) => {
                                                                      zipRemove(
                                                                        file
                                                                      )
                                                                        .then(
                                                                          (
                                                                            response
                                                                          ) => {
                                                                            deployFunc(
                                                                              funcId
                                                                            )
                                                                              .then(
                                                                                (
                                                                                  response
                                                                                ) => {
                                                                                  const lambdaArn =
                                                                                    response
                                                                                      .data[0]
                                                                                      .function_arn;
                                                                                  newCognito(
                                                                                    cognitoName,
                                                                                    habitat_id
                                                                                  )
                                                                                    .then(
                                                                                      (
                                                                                        response
                                                                                      ) => {
                                                                                        const cognitoArn =
                                                                                          response
                                                                                            .data
                                                                                            .arn;
                                                                                        userPoolId =
                                                                                          response
                                                                                            .data
                                                                                            .user_pool_id;
                                                                                        const cognitoID =
                                                                                          response
                                                                                            .data
                                                                                            .id;
                                                                                        createApiGateway(
                                                                                          apiName,
                                                                                          habitat_id
                                                                                        )
                                                                                          .then(
                                                                                            (
                                                                                              response
                                                                                            ) => {
                                                                                              const apiId =
                                                                                                response
                                                                                                  .data
                                                                                                  .api_gateway
                                                                                                  .id;
                                                                                              authorizeCognito(
                                                                                                habitat_id,
                                                                                                cognitoArn,
                                                                                                apiId
                                                                                              )
                                                                                                .then(
                                                                                                  (
                                                                                                    response
                                                                                                  ) => {
                                                                                                    createResource(
                                                                                                      apiId,
                                                                                                      habitat_id,
                                                                                                      pathApi
                                                                                                    )
                                                                                                      .then(
                                                                                                        (
                                                                                                          response
                                                                                                        ) => {
                                                                                                          const method =
                                                                                                            'POST';
                                                                                                          createMethod(
                                                                                                            habitat_id,
                                                                                                            method,
                                                                                                            lambdaArn,
                                                                                                            apiId,
                                                                                                            roleArn
                                                                                                          )
                                                                                                            .then(
                                                                                                              (
                                                                                                                response
                                                                                                              ) => {
                                                                                                                const templateInfo = {
                                                                                                                  name: name.replace(
                                                                                                                    /[^A-Z0-9]/gi,
                                                                                                                    '-'
                                                                                                                  ),
                                                                                                                  domain_name: bucket,
                                                                                                                  repository_id,
                                                                                                                  habitat_id,
                                                                                                                  git_hash,
                                                                                                                  category:
                                                                                                                    'web app',
                                                                                                                };
                                                                                                                createTemplate(
                                                                                                                  templateInfo
                                                                                                                )
                                                                                                                  .then(
                                                                                                                    (
                                                                                                                      response
                                                                                                                    ) => {
                                                                                                                      const payload =
                                                                                                                        response
                                                                                                                          .data
                                                                                                                          .templates;
                                                                                                                      const template =
                                                                                                                        response
                                                                                                                          .data
                                                                                                                          .template;
                                                                                                                      const requestInfo = {
                                                                                                                        site_id:
                                                                                                                          template.id,
                                                                                                                        domain_name,
                                                                                                                        prefix,
                                                                                                                        habitat_id,
                                                                                                                      };
                                                                                                                      requestDomain(
                                                                                                                        requestInfo
                                                                                                                      )
                                                                                                                        .then(
                                                                                                                          (
                                                                                                                            response
                                                                                                                          ) => {
                                                                                                                            deployApi(
                                                                                                                              apiId,
                                                                                                                              habitat_id,
                                                                                                                              'v1'
                                                                                                                            )
                                                                                                                              .then(
                                                                                                                                (
                                                                                                                                  response
                                                                                                                                ) => {
                                                                                                                                  endpoint =
                                                                                                                                    response
                                                                                                                                      .data
                                                                                                                                      .endpoint;
                                                                                                                                  const cognitoUserInfo = {
                                                                                                                                    habitat_id,
                                                                                                                                    name: prefix,
                                                                                                                                    id: cognitoID,
                                                                                                                                  };
                                                                                                                                  createCognitoUser(
                                                                                                                                    cognitoUserInfo
                                                                                                                                  )
                                                                                                                                    .then(
                                                                                                                                      (
                                                                                                                                        response
                                                                                                                                      ) => {
                                                                                                                                        userPoolClientId =
                                                                                                                                          response
                                                                                                                                            .data
                                                                                                                                            .identity_pool_id;
                                                                                                                                        const config = `window._config = {
cognito: {
  userPoolId: '${userPoolId}',
  clientId: '${userPoolClientId}',
  region: '${regionCred}'
},
api: {
  invokeUrl: '${endpoint}',
}
};`;
                                                                                                                                        const envInfo = {
                                                                                                                                          file:
                                                                                                                                            'config.js',
                                                                                                                                          bucket: staticBucket,
                                                                                                                                          habitat_id,
                                                                                                                                          directory_name,
                                                                                                                                          content: config,
                                                                                                                                        };
                                                                                                                                        addEnv(
                                                                                                                                          envInfo
                                                                                                                                        )
                                                                                                                                          .then(
                                                                                                                                            (
                                                                                                                                              response
                                                                                                                                            ) => {
                                                                                                                                              const distributionInfo = {
                                                                                                                                                site_id:
                                                                                                                                                  template.id,
                                                                                                                                                distribution_name: name.replace(
                                                                                                                                                  /[^A-Z0-9]/gi,
                                                                                                                                                  '-'
                                                                                                                                                ),
                                                                                                                                                end_point: `${staticBucket}.s3-website.${regionCred}.amazonaws.com`,
                                                                                                                                                domain_name: staticBucket,
                                                                                                                                                repository_id,
                                                                                                                                                habitat_id,
                                                                                                                                                root_object:
                                                                                                                                                  'index.html',
                                                                                                                                              };
                                                                                                                                              contentDistribution(
                                                                                                                                                distributionInfo
                                                                                                                                              )
                                                                                                                                                .then(
                                                                                                                                                  (
                                                                                                                                                    response
                                                                                                                                                  ) => {
                                                                                                                                                    let domainPrefix;
                                                                                                                                                    const cloudFrontDns =
                                                                                                                                                      response
                                                                                                                                                        .data
                                                                                                                                                        .cloud_front_domain;
                                                                                                                                                    prefix.length >
                                                                                                                                                    0
                                                                                                                                                      ? (domainPrefix = `${prefix}.`)
                                                                                                                                                      : (domainPrefix = null);
                                                                                                                                                    const domainInfo = {
                                                                                                                                                      dns_name: cloudFrontDns,
                                                                                                                                                      domain_name,
                                                                                                                                                      type:
                                                                                                                                                        'A',
                                                                                                                                                      prefix: domainPrefix,
                                                                                                                                                      cf_hosted_zone_id:
                                                                                                                                                        'Z2FDTNDATAQYW2',
                                                                                                                                                      habitat_id,
                                                                                                                                                    };
                                                                                                                                                    addDomian(
                                                                                                                                                      domainInfo
                                                                                                                                                    )
                                                                                                                                                      .then(
                                                                                                                                                        (
                                                                                                                                                          response
                                                                                                                                                        ) => {
                                                                                                                                                          dispatch(
                                                                                                                                                            {
                                                                                                                                                              type: CREATE_WEB_APP_TEMPLATE_SUCCESS,
                                                                                                                                                              payload,
                                                                                                                                                            }
                                                                                                                                                          );
                                                                                                                                                        }
                                                                                                                                                      )
                                                                                                                                                      .catch(
                                                                                                                                                        (
                                                                                                                                                          error
                                                                                                                                                        ) => {
                                                                                                                                                          const error_message =
                                                                                                                                                            'Error at Create Template';
                                                                                                                                                          dispatch(
                                                                                                                                                            {
                                                                                                                                                              type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                              error: error_message,
                                                                                                                                                            }
                                                                                                                                                          );
                                                                                                                                                          rollBack(
                                                                                                                                                            rollBackInfo
                                                                                                                                                          );
                                                                                                                                                        }
                                                                                                                                                      );
                                                                                                                                                  }
                                                                                                                                                )
                                                                                                                                                .catch(
                                                                                                                                                  (
                                                                                                                                                    error
                                                                                                                                                  ) => {
                                                                                                                                                    const error_message =
                                                                                                                                                      'Error at Add Domain.';
                                                                                                                                                    dispatch(
                                                                                                                                                      {
                                                                                                                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                        error: error_message,
                                                                                                                                                      }
                                                                                                                                                    );
                                                                                                                                                    rollBack(
                                                                                                                                                      rollBackInfo
                                                                                                                                                    );
                                                                                                                                                  }
                                                                                                                                                );
                                                                                                                                            }
                                                                                                                                          )
                                                                                                                                          .catch(
                                                                                                                                            (
                                                                                                                                              error
                                                                                                                                            ) => {
                                                                                                                                              const error_message =
                                                                                                                                                error.response.data.error;
                                                                                                                                              dispatch(
                                                                                                                                                {
                                                                                                                                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                  error: error_message,
                                                                                                                                                }
                                                                                                                                              );
                                                                                                                                              rollBack(
                                                                                                                                                rollBackInfo
                                                                                                                                              );
                                                                                                                                            }
                                                                                                                                          );
                                                                                                                                      }
                                                                                                                                    )
                                                                                                                                    .catch(
                                                                                                                                      (
                                                                                                                                        error
                                                                                                                                      ) => {
                                                                                                                                        const error_message =
                                                                                                                                          'Error at Env Addition.';
                                                                                                                                        dispatch(
                                                                                                                                          {
                                                                                                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                            error: error_message,
                                                                                                                                          }
                                                                                                                                        );
                                                                                                                                        rollBack(
                                                                                                                                          rollBackInfo
                                                                                                                                        );
                                                                                                                                      }
                                                                                                                                    );
                                                                                                                                }
                                                                                                                              )
                                                                                                                              .catch(
                                                                                                                                (
                                                                                                                                  error
                                                                                                                                ) => {
                                                                                                                                  const error_message =
                                                                                                                                    'Error at Create Cognito User.';
                                                                                                                                  dispatch(
                                                                                                                                    {
                                                                                                                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                      error: error_message,
                                                                                                                                    }
                                                                                                                                  );
                                                                                                                                  rollBack(
                                                                                                                                    rollBackInfo
                                                                                                                                  );
                                                                                                                                }
                                                                                                                              );
                                                                                                                          }
                                                                                                                        )
                                                                                                                        .catch(
                                                                                                                          (
                                                                                                                            error
                                                                                                                          ) => {
                                                                                                                            const error_message =
                                                                                                                              'Error at Request Domain.';
                                                                                                                            dispatch(
                                                                                                                              {
                                                                                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                error: error_message,
                                                                                                                              }
                                                                                                                            );
                                                                                                                            rollBack(
                                                                                                                              rollBackInfo
                                                                                                                            );
                                                                                                                          }
                                                                                                                        );
                                                                                                                    }
                                                                                                                  )
                                                                                                                  .catch(
                                                                                                                    (
                                                                                                                      error
                                                                                                                    ) => {
                                                                                                                      const error_message =
                                                                                                                        'Error at Template Creation.';
                                                                                                                      dispatch(
                                                                                                                        {
                                                                                                                          type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                          error: error_message,
                                                                                                                        }
                                                                                                                      );
                                                                                                                      rollBack(
                                                                                                                        rollBackInfo
                                                                                                                      );
                                                                                                                    }
                                                                                                                  );
                                                                                                              }
                                                                                                            )
                                                                                                            .catch(
                                                                                                              (
                                                                                                                error
                                                                                                              ) => {
                                                                                                                const error_message =
                                                                                                                  'Error at Method Creation.';
                                                                                                                dispatch(
                                                                                                                  {
                                                                                                                    type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                    error: error_message,
                                                                                                                  }
                                                                                                                );
                                                                                                                rollBack(
                                                                                                                  rollBackInfo
                                                                                                                );
                                                                                                              }
                                                                                                            );
                                                                                                        }
                                                                                                      )
                                                                                                      .catch(
                                                                                                        (
                                                                                                          error
                                                                                                        ) => {
                                                                                                          const error_message =
                                                                                                            'Error at Resource Creation.';
                                                                                                          dispatch(
                                                                                                            {
                                                                                                              type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                              error: error_message,
                                                                                                            }
                                                                                                          );
                                                                                                          rollBack(
                                                                                                            rollBackInfo
                                                                                                          );
                                                                                                        }
                                                                                                      );
                                                                                                  }
                                                                                                )
                                                                                                .catch(
                                                                                                  (
                                                                                                    error
                                                                                                  ) => {
                                                                                                    const error_message =
                                                                                                      'Error at Cognito Authorization.';
                                                                                                    dispatch(
                                                                                                      {
                                                                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                        error: error_message,
                                                                                                      }
                                                                                                    );
                                                                                                    rollBack(
                                                                                                      rollBackInfo
                                                                                                    );
                                                                                                  }
                                                                                                );
                                                                                            }
                                                                                          )
                                                                                          .catch(
                                                                                            (
                                                                                              error
                                                                                            ) => {
                                                                                              const error_message =
                                                                                                'Error at API Gateway Creation.';
                                                                                              dispatch(
                                                                                                {
                                                                                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                  error: error_message,
                                                                                                }
                                                                                              );
                                                                                              rollBack(
                                                                                                rollBackInfo
                                                                                              );
                                                                                            }
                                                                                          );
                                                                                      }
                                                                                    )
                                                                                    .catch(
                                                                                      (
                                                                                        error
                                                                                      ) => {
                                                                                        const error_message =
                                                                                          'Error at Cognito User Pool Creation.';
                                                                                        dispatch(
                                                                                          {
                                                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                            error: error_message,
                                                                                          }
                                                                                        );
                                                                                        rollBack(
                                                                                          rollBackInfo
                                                                                        );
                                                                                      }
                                                                                    );
                                                                                }
                                                                              )
                                                                              .catch(
                                                                                (
                                                                                  error
                                                                                ) => {
                                                                                  const error_message =
                                                                                    'Error at Deploy Lambda Function.';
                                                                                  dispatch(
                                                                                    {
                                                                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                      error: error_message,
                                                                                    }
                                                                                  );
                                                                                  rollBack(
                                                                                    rollBackInfo
                                                                                  );
                                                                                }
                                                                              );
                                                                          }
                                                                        )
                                                                        .catch(
                                                                          (
                                                                            error
                                                                          ) => {
                                                                            const error_message =
                                                                              'Error at Zip Remove.';
                                                                            dispatch(
                                                                              {
                                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                error: error_message,
                                                                              }
                                                                            );
                                                                            rollBack(
                                                                              rollBackInfo
                                                                            );
                                                                          }
                                                                        );
                                                                    }
                                                                  )
                                                                  .catch(
                                                                    (error) => {
                                                                      const error_message =
                                                                        'Error at Upload File.';
                                                                      dispatch({
                                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                        error: error_message,
                                                                      });
                                                                      rollBack(
                                                                        rollBackInfo
                                                                      );
                                                                    }
                                                                  );
                                                              }
                                                            )
                                                            .catch((error) => {
                                                              const error_message =
                                                                'Error at Zip File.';
                                                              dispatch({
                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                error: error_message,
                                                              });
                                                              rollBack(
                                                                rollBackInfo
                                                              );
                                                            });
                                                        })
                                                        .catch((error) => {
                                                          const error_message =
                                                            'Error at Lambda Function Creation.';
                                                          dispatch({
                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                            error: error_message,
                                                          });
                                                          rollBack(
                                                            rollBackInfo
                                                          );
                                                        });
                                                    }
                                                  })
                                                  .catch((error) => {
                                                    const error_message =
                                                      'Error at Get Git Clone List.';
                                                    dispatch({
                                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                      error: error_message,
                                                    });
                                                    rollBack(rollBackInfo);
                                                  });
                                              })
                                              .catch((error) => {
                                                const error_message =
                                                  'Error at Get Git Clone List.';
                                                dispatch({
                                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                  error: error_message,
                                                });
                                                rollBack(rollBackInfo);
                                              });
                                          })
                                          .catch((error) => {
                                            const error_message =
                                              'Error at Get S3 Buckets.';
                                            dispatch({
                                              type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                              error: error_message,
                                            });
                                            rollBack(rollBackInfo);
                                          });
                                      })
                                      .catch((error) => {
                                        const error_message =
                                          'Error at Upload Folder.';
                                        dispatch({
                                          type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                          error: error_message,
                                        });
                                        rollBack(rollBackInfo);
                                      });
                                  })
                                  .catch((error) => {
                                    const error_message =
                                      'Error at Function clone.';
                                    dispatch({
                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                      error: error_message,
                                    });
                                    rollBack(rollBackInfo);
                                  });
                              })
                              .catch((error) => {
                                const error_message =
                                  'Error at Clone Git Repository.';
                                dispatch({
                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                  error: error_message,
                                });
                                rollBack(rollBackInfo);
                              });
                          })
                          .catch((error) => {
                            const error_message = 'Error at Role Creation.';
                            dispatch({
                              type: CREATE_WEB_APP_TEMPLATE_ERROR,
                              error: error_message,
                            });
                            rollBack(rollBackInfo);
                          });
                      })
                      .catch((error) => {
                        const error_message = 'Error at S3 Bucket Creation.';
                        dispatch({
                          type: CREATE_WEB_APP_TEMPLATE_ERROR,
                          error: error_message,
                        });
                        rollBack(rollBackInfo);
                      });
                  })
                  .catch((error) => {
                    const error_message =
                      'Error at Attach AWS Creds to Habitat.';
                    dispatch({
                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                      error: error_message,
                    });
                    rollBack(rollBackInfo);
                  });
              })
              .catch((error) => {
                const error_message = 'Error at Verify AWS Credentials.';
                dispatch({
                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                  error: error_message,
                });
                rollBack(rollBackInfo);
              });
          })
          .catch((error) => {
            const error_message = 'Error at Habitat AWS Credentials Creation.';
            dispatch({
              type: CREATE_WEB_APP_TEMPLATE_ERROR,
              error: error_message,
            });
            rollBack(rollBackInfo);
          });
      })
      .catch((error) => {
        const error_message = 'Error at Habitat creation.';
        dispatch({
          type: CREATE_WEB_APP_TEMPLATE_ERROR,
          error: error_message,
        });
      });
  };
};

export const createStatic = (
  prefix,
  region,
  gitClone,
  habitat_id,
  domain_name
) => {
  return (dispatch) => {
    let bucket;

    if (prefix) {
      bucket = `${prefix}.${domain_name}`;
    } else {
      bucket = domain_name;
    }

    const s3Static = {
      bucket,
      habitat_id,
      region,
      public: 1,
    };

    dispatch({
      type: CREATE_STATIC_BEGIN,
      loading_message: 'Creating static site...',
    });
    addStaticBucket(s3Static)
      .then((response) => {
        const templateInfo = {
          name: gitClone.project_name,
          domain_name: bucket,
          repository_id: gitClone.id,
          habitat_id,
          git_hash: gitClone.git_hash,
          category: 'static site',
        };
        createTemplate(templateInfo)
          .then((response) => {
            const payload = response.data.templates;
            const template = response.data.template;
            const requestInfo = {
              site_id: template.id,
              domain_name,
              prefix,
              habitat_id,
            };
            requestDomain(requestInfo)
              .then((response) => {
                const distributionInfo = {
                  site_id: template.id,
                  distribution_name: gitClone.project_name,
                  end_point: `${bucket}.s3-website.${region}.amazonaws.com`,
                  domain_name: bucket,
                  repository_id: gitClone.id,
                  habitat_id,
                  root_object: 'index.html',
                };
                contentDistribution(distributionInfo)
                  .then((response) => {
                    const dns_name = response.data.cloud_front_domain;
                    let domainPrefix;
                    prefix.length > 0
                      ? (domainPrefix = `${prefix}.`)
                      : (domainPrefix = null);
                    const domainInfo = {
                      dns_name,
                      domain_name,
                      type: 'A',
                      prefix: domainPrefix,
                      cf_hosted_zone_id: 'Z2FDTNDATAQYW2',
                      habitat_id,
                    };
                    addDomian(domainInfo)
                      .then((response) => {
                        dispatch({
                          type: CREATE_STATIC_SUCCESS,
                          payload,
                        });
                      })
                      .catch((error) =>
                        dispatch({
                          type: CREATE_STATIC_ERROR,
                          errorCreateStatic: 'Error at add domain',
                          error,
                        })
                      );
                  })
                  .catch((error) =>
                    dispatch({
                      type: CREATE_STATIC_ERROR,
                      errorCreateStatic: 'Error at content distribution',
                      error,
                    })
                  );
              })
              .catch((error) =>
                dispatch({
                  type: CREATE_STATIC_ERROR,
                  errorCreateStatic: 'Error at request domain',
                  error,
                })
              );
          })
          .catch((error) =>
            dispatch({
              type: CREATE_STATIC_ERROR,
              errorCreateStatic: 'Error at create static site',
              error,
            })
          );
      })
      .catch((error) =>
        dispatch({
          type: CREATE_STATIC_ERROR,
          errorCreateStatic: 'Error at create S3 Bucket',
          error,
        })
      );
  };
};

export const createStaticWithoutDomain = (
  prefix,
  region,
  gitClone,
  habitat_id,
  domain_name
) => {
  return (dispatch) => {
    let bucket = `${gitClone.project_name.toLowerCase()}`;
    const s3Static = {
      bucket,
      habitat_id,
      region,
      public: 1,
    };

    dispatch({
      type: CREATE_STATIC_BEGIN,
      loading_message: 'Creating static site...',
    });
    addStaticBucket(s3Static)
      .then((response) => {
        const templateInfo = {
          name: gitClone.project_name,
          domain_name: '',
          repository_id: gitClone.id,
          habitat_id,
          git_hash: gitClone.git_hash,
          category: 'static site',
        };
        createTemplate(templateInfo)
          .then((response) => {
            const payload = response.data.templates;
            const template = response.data.template;
            const distributionInfo = {
              site_id: template.id,
              distribution_name: gitClone.project_name,
              end_point: `${bucket}.s3-website.${region}.amazonaws.com`,
              domain_name: '',
              repository_id: gitClone.id,
              habitat_id,
              root_object: 'index.html',
              certificate_arn: null,
            };
            contentDistribution(distributionInfo)
              .then((response) => {
                dispatch({
                  type: CREATE_STATIC_SUCCESS,
                  payload: response.data.habitat_templates,
                });
              })
              .catch((error) =>
                dispatch({
                  type: CREATE_STATIC_ERROR,
                  errorCreateStatic: 'Error at content distribution',
                  error,
                })
              );
          })
          .catch((error) =>
            dispatch({
              type: CREATE_STATIC_ERROR,
              errorCreateStatic: 'Error at create template',
              error,
            })
          );
      })
      .catch((error) =>
        dispatch({
          type: CREATE_STATIC_ERROR,
          errorCreateStatic: 'Error at create S3 Bucket',
          error,
        })
      );
  };
};

export const deployStatic = (template, team_id) => {
  let bucket;
  return (dispatch) => {
    if (template.domain_name.includes('cloudfront.net')) {
      bucket = template.name.toLowerCase();
    } else {
      bucket = template.domain_name
    }
    const upload = {
      habitat_id: template.habitat_id,
      file_name: template.name,
      bucket,
      team_id,
    };
    dispatch({
      type: DEPLOY_STATIC_BEGIN,
      loading_message: 'Loading deployment static...',
    });
    uploadFolder(upload)
      .then((response) => {
        dispatch({
          type: DEPLOY_STATIC_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: DEPLOY_STATIC_ERROR,
          error: error.response.error.data.error,
        })
      );
  };
};

export const updateStatic = (template, team, git_hash) => {
  return (dispatch) => {
    let bucket;
    if (template.domain_name.includes('cloudfront.net')) {
      bucket = template.name.toLowerCase();
    } else {
      bucket = template.domain_name
    }
    const upload = {
      habitat_id: template.habitat_id,
      file_name: template.name,
      bucket,
      team_id: team.id,
    };
    dispatch({ type: DEPLOY_STATIC_BEGIN });
    uploadFolder(upload)
      .then((response) => {
        const hashInfo = {
          site_id: template.id,
          git_hash,
        };
        update_hash(hashInfo)
          .then((response) => {
            dispatch({
              type: DEPLOY_STATIC_SUCCESS,
              payload: response.data,
            });
          })
          .catch((error) => dispatch({ type: DEPLOY_STATIC_ERROR }));
      })
      .catch((error) => dispatch({ type: DEPLOY_STATIC_ERROR }));
  };
};

export const buildAndDeployStatic = (
  awsRoles,
  template,
  buildspec,
  habitat_id,
  team_id
) => {
  const s3Data = {
    file_name: template.name,
    habitat_id,
    team_id,
  };
  return (dispatch) => {
    let data;
    if (!buildspec) {
      data = {
        habitat_id,
        project_name: `${template.name}-${Date.now()}`,
        artifac_name: '/',
        buildspec: 'buildspec.yml',
        source_location: template.name,
        artifac_location: template.domain_name,
        service_role: awsRoles[awsRoles.length - 1].arn,
        zip: 'NONE',
        site_id: template.id,
      };
    } else {
      data = {
        habitat_id,
        project_name: `${template.name}-${Date.now()}`,
        artifac_name: '/',
        buildspec: buildspec,
        source_location: template.name,
        artifac_location: template.domain_name,
        service_role: awsRoles[awsRoles.length - 1].arn,
        zip: 'NONE',
        site_id: template.id,
      };
    }
    dispatch({
      type: DEPLOY_STATIC_BEGIN,
      loading_message: 'Loading build and deploy static...',
    });
    uploadClone(s3Data)
      .then((reponse) => {
        createProject(data)
          .then((response) => {
            const buildInfo = {
              habitat_id,
              build_id: response.data.params.id,
              site_id: template.id,
            };
            createBuild(buildInfo)
              .then((response) => {
                dispatch({
                  type: DEPLOY_STATIC_SUCCESS,
                  payload: response.data,
                });
              })
              .catch((error) => dispatch({ type: DEPLOY_STATIC_ERROR, error }));
          })
          .catch((error) => dispatch({ type: DEPLOY_STATIC_ERROR, error }));
      })
      .catch((error) => dispatch({ type: DEPLOY_STATIC_ERROR, error }));
  };
};

export const buildAndUpdate = (template, habitat_id, git_hash, team_id) => {
  const s3Data = {
    file_name: template.name,
    habitat_id,
    team_id,
  };
  return (dispatch) => {
    dispatch({
      type: BUILD_AND_UPDATE_BEGIN,
      loading_message: 'Loading build and update site...',
    });
    uploadClone(s3Data)
      .then((reponse) => {
        const buildInfo = {
          habitat_id,
          build_id: template.build_id,
        };
        createBuild(buildInfo)
          .then((response) => {
            const hashInfo = {
              site_id: template.id,
              git_hash,
            };
            update_hash(hashInfo)
              .then((response) => {
                dispatch({
                  type: BUILD_AND_UPDATE_SUCCESS,
                  payload: response.data,
                });
              })
              .catch((error) =>
                dispatch({ type: BUILD_AND_UPDATE_ERROR, error })
              );
          })
          .catch((error) => dispatch({ type: BUILD_AND_UPDATE_ERROR, error }));
      })
      .catch((error) => dispatch({ type: BUILD_AND_UPDATE_ERROR, error }));
  };
};

export const removeTemplate = (id) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_STATIC_TEMPLATE_BEGIN,
      loading_message: 'Deleting template...',
    });
    deleteTemplates(id)
      .then((response) => {
        dispatch({
          type: REMOVE_STATIC_TEMPLATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: REMOVE_STATIC_TEMPLATE_ERROR,
          errorRemoveTemplate: 'Remove template fail',
          error,
        })
      );
  };
};

export const cloudFrontStatus = (habitat_id, site_id, distribution_status) => {
  return (dispatch) => {
    dispatch({
      type: MANAGE_CLOUDFRONT_BEGIN,
      loading_message: 'Changing Cloudfront Status...',
    });
    manageCloudfront(habitat_id, site_id, distribution_status)
      .then((response) => {
        dispatch({
          type: MANAGE_CLOUDFRONT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: MANAGE_CLOUDFRONT_ERROR,
          error: error.response.data.error,
        })
      );
  };
};

export const siteStatus = (site_id, habitat_id, status) => {
  return (dispatch) => {
    const data = {
      site_id,
      habitat_id,
      status,
    };
    dispatch({
      type: CHANGE_SITE_STATUS_BEGIN,
      loading_message: 'Update site status...',
    });
    changeSiteStatus(data)
      .then((response) => {
        dispatch({
          type: CHANGE_SITE_STATUS_SUCCESS,
          payload: response.data.historic,
        });
      })
      .catch((error) => dispatch({ type: CHANGE_SITE_STATUS_ERROR, error }));
  };
};

export const fetchInvalidations = (habitat_id, site_id) => {
  return (dispatch) => {
    dispatch({
      type: GET_INVALIDATIONS_BEGIN,
      loading_message: 'Fetching invalidations...',
    });
    getIndexInvalidations(habitat_id, site_id)
      .then((response) => {
        const payload = {
          items: response.data.invalidations.items,
          templateId: site_id,
        };
        dispatch({
          type: GET_INVALIDATIONS_SUCCESS,
          payload,
        });
      })
      .catch((error) =>
        dispatch({
          type: GET_INVALIDATIONS_ERROR,
          error: error.response.data.error,
        })
      );
  };
};

export const newInvalidation = (habitat_id, site_id, invalidation) => {
  return (dispatch) => {
    dispatch({
      type: CREATE_INVALIDATIONS_BEGIN,
      loading_message: 'Creating Invalidation...',
    });
    distributionValidation(habitat_id, site_id, invalidation)
      .then((response) => {
        const payload = {
          items: response.data.invalidations.items,
          templateId: site_id,
        };
        dispatch({
          type: CREATE_INVALIDATIONS_SUCCESS,
          payload,
        });
      })
      .catch((error) =>
        dispatch({
          type: CREATE_INVALIDATIONS_ERROR,
          error: error.response.data.error,
        })
      );
  };
};

export const getValidation = (habitat_id, invalidation, site_id) => {
  return (dispatch) => {
    dispatch({
      type: GET_INDEX_INVALIDATION_BEGIN,
      loading_message: 'Getting Invalidation...',
    });
    getInvalidations(habitat_id, invalidation, site_id)
      .then((response) => {
        dispatch({
          type: GET_INDEX_INVALIDATION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: GET_INDEX_INVALIDATION_ERROR,
          error: error.response.data.error,
        })
      );
  };
};

export const deployNewTemplateStrapi = (
  prefix,
  region,
  team_id,
  awsCreds,
  habitat_id,
  domain_name,
  git_credential_id
) => {
  return (dispatch) => {
    let bucket;
    let adminBucket;
    let adminPrexi;

    if (prefix) {
      bucket = `${prefix}.${domain_name}`;
      adminBucket = `admin-${prefix}.${domain_name}`;
      adminPrexi = `admin-${prefix}`;
    } else {
      bucket = domain_name;
      adminBucket = `admin.${domain_name}`;
      adminPrexi = 'admin';
    }

    const s3Static = {
      bucket,
      region,
      public: 1,
      habitat_id,
    };

    const s3Admin = {
      bucket: adminBucket,
      region,
      public: 1,
      habitat_id,
    };

    let rollBackInfo = {
      habitat_id,
      rol_id: null,
      git_site: null,
      git_lambda: null,
    };

    const runtimeLambda = 'nodejs12.x';
    const handlerLambda = 'app.handler';
    let rd_name;
    if (prefix) {
      rd_name = `${prefix}-strapi-${Date.now()}`;
    } else {
      rd_name = `strapi-${Date.now()}`;
    }
    const rdsData = {
      habitat_id,
      port: 5432,
      storage: 5,
      db_name: 'strapi',
      engine: 'postgres',
      engine_version: '12.5',
      master_username: 'postgres',
      name: rd_name,
      db_instance_class: 'db.t2.micro',
      password: passwordGenerator(18, false),
    };
    let env = `DATABASE_PASSWORD=${rdsData.password}\n`;
    env += `BUCKET_NAME=${bucket}\n`;
    env += `DATABASE_PORT=${rdsData.port}\n`;
    env += `DATABASE_NAME=${rdsData.db_name}\n`;
    env += `DATABASE_USERNAME=${rdsData.master_username}\n`;
    env += `HOST=${adminBucket}\n`;
    env += 'PORT=80\n';
    const hash = randomHash({ length: 32 });
    env += `ADMIN_JWT_SECRET=${hash}\n`;
    dispatch({
      type: CREATE_STRAPI_TEMPLATE_BEGIN,
      loading_message: 'Loading deploy Strapi...',
    });
    getAWSRoles(habitat_id)
      .then((response) => {
        const awsRoles = response.data;
        const roleArn = awsRoles[awsRoles.length - 1].arn;
        rollBackInfo.rol_id = awsRoles[awsRoles.length - 1].id;
        createRds(rdsData)
          .then((response) => {
            const dbHost = response.data.db.endpoint;
            addStaticBucket(s3Admin)
              .then((response) => {
                addStaticBucket(s3Static)
                  .then((response) => {
                    env += `DATABASE_HOST=${dbHost}\n`;
                    createApiGateway(rdsData.name, habitat_id)
                      .then((response) => {
                        const apigateway = response.data.api_gateway;
                        const apiId = response.data.api_gateway.id;
                        const endpoint = `https://${apigateway.api_id}.execute-api.${awsCreds.region}.amazonaws.com/production`;
                        env += `BACKEND_URL=${endpoint}\n`;
                        env += `REACT_APP_BACKEND_URL=${endpoint}`;
                        const preEnv = {
                          DATABASE_PASSWORD: rdsData.password,
                          BUCKET_NAME: bucket,
                          DATABASE_PORT: `${rdsData.port}`,
                          DATABASE_NAME: rdsData.db_name,
                          DATABASE_USERNAME: rdsData.master_username,
                          HOST: adminBucket,
                          PORT: '80',
                          DATABASE_HOST: dbHost,
                          BACKEND_URL: endpoint,
                          ADMIN_JWT_SECRET: hash,
                        };
                        createResource(apigateway.id, habitat_id)
                          .then((response) => {
                            const funcCloneInfo = {
                              uri:
                                'https://gitlab.com/kor-comunity/kor-strapi-app.git',
                              project_name: `lambda-${rdsData.name}`,
                              team_id,
                              git_credential_id: '',
                            };
                            gitClone(funcCloneInfo)
                              .then((response) => {
                                const funcClone = response.data.repo;
                                const funcDirectory =
                                  response.data.repo.directory_name;
                                rollBackInfo.git_lambda = funcClone.id;
                                const envFunc = {
                                  file: '.env',
                                  habitat_id,
                                  directory_name: funcDirectory,
                                  content: env,
                                };
                                envRepo(envFunc)
                                  .then((response) => {
                                    const frontCloneInfo = {
                                      uri:
                                        'https://gitlab.com/kor-comunity/strapi-template-kor.git',
                                      project_name: `frontend-${rdsData.name}`,
                                      team_id,
                                      git_credential_id: '',
                                    };
                                    gitClone(frontCloneInfo)
                                      .then((response) => {
                                        const frontRepo = response.data.repo;
                                        rollBackInfo.git_site = frontRepo.id;
                                        const directory_name =
                                          response.data.repo.directory_name;
                                        const envInfo = {
                                          file: '.env',
                                          habitat_id,
                                          directory_name,
                                          content: env,
                                        };
                                        envRepo(envInfo)
                                          .then((response) => {
                                            const LambdaBucket = `kor-lambda-${habitat_id}`;
                                            addBucket(
                                              LambdaBucket,
                                              habitat_id,
                                              region
                                            )
                                              .then((response) => {
                                                const s3Data = {
                                                  file_name: `lambda-${rdsData.name}`,
                                                  team_id,
                                                  habitat_id,
                                                };
                                                uploadClone(s3Data)
                                                  .then((response) => {
                                                    let data = {
                                                      function_name: `lambda-${rdsData.name}`,
                                                      runtime: runtimeLambda,
                                                      handler: handlerLambda,
                                                      role: roleArn,
                                                      habitat_id,
                                                      git_hash:
                                                        funcClone.git_hash,
                                                      memory_size: 1024,
                                                      timeout: 25,
                                                      code_s3_bucket: LambdaBucket,
                                                      code_s3_key: `lambda-${rdsData.name}.zip`,
                                                    };
                                                    setFunc(data)
                                                      .then((response) => {
                                                        const func =
                                                          response.data
                                                            .aws_lambda;
                                                        const buildData = {
                                                          habitat_id:
                                                            func.habitat_id,
                                                          project_name:
                                                            func.function_name,
                                                          artifac_name: `${func.function_name}.zip`,
                                                          buildspec:
                                                            'backend-buildspec.yml',
                                                          source_location:
                                                            func.function_name,
                                                          artifac_location:
                                                            func.code_s3_bucket,
                                                          service_role: roleArn,
                                                          zip: 'ZIP',
                                                          lambda_id: func.id,
                                                        };
                                                        createProject(buildData)
                                                          .then((response) => {
                                                            const buildInfo = {
                                                              habitat_id:
                                                                func.habitat_id,
                                                              build_id:
                                                                response.data
                                                                  .params.id,
                                                            };
                                                            createBuild(
                                                              buildInfo
                                                            )
                                                              .then(
                                                                (response) => {
                                                                  const templateInfo = {
                                                                    name:
                                                                      frontRepo.project_name,
                                                                    domain_name: bucket,
                                                                    repository_id:
                                                                      frontRepo.id,
                                                                    habitat_id,
                                                                    git_hash:
                                                                      frontRepo.git_hash,
                                                                    category:
                                                                      'static site',
                                                                  };
                                                                  createTemplate(
                                                                    templateInfo
                                                                  )
                                                                    .then(
                                                                      (
                                                                        response
                                                                      ) => {
                                                                        const template =
                                                                          response
                                                                            .data
                                                                            .template;
                                                                        const requestInfo = {
                                                                          site_id:
                                                                            template.id,
                                                                          domain_name,
                                                                          prefix,
                                                                          habitat_id,
                                                                        };
                                                                        const s3Data = {
                                                                          file_name:
                                                                            template.name,
                                                                          habitat_id,
                                                                          team_id,
                                                                        };
                                                                        uploadClone(
                                                                          s3Data
                                                                        )
                                                                          .then(
                                                                            (
                                                                              response
                                                                            ) => {
                                                                              const staticBuild = {
                                                                                habitat_id,
                                                                                project_name:
                                                                                  template.name,
                                                                                artifac_name:
                                                                                  '/',
                                                                                buildspec:
                                                                                  'buildspec.yml',
                                                                                source_location:
                                                                                  template.name,
                                                                                artifac_location:
                                                                                  template.domain_name,
                                                                                service_role: roleArn,
                                                                                zip:
                                                                                  'NONE',
                                                                                site_id:
                                                                                  template.id,
                                                                              };
                                                                              createProject(
                                                                                staticBuild
                                                                              )
                                                                                .then(
                                                                                  (
                                                                                    response
                                                                                  ) => {
                                                                                    const buildInfo = {
                                                                                      habitat_id,
                                                                                      build_id:
                                                                                        response
                                                                                          .data
                                                                                          .params
                                                                                          .id,
                                                                                      site_id:
                                                                                        template.id,
                                                                                    };
                                                                                    createBuild(
                                                                                      buildInfo
                                                                                    )
                                                                                      .then(
                                                                                        (
                                                                                          response
                                                                                        ) => {
                                                                                          requestDomain(
                                                                                            requestInfo
                                                                                          )
                                                                                            .then(
                                                                                              (
                                                                                                response
                                                                                              ) => {
                                                                                                const distributionInfo = {
                                                                                                  site_id:
                                                                                                    template.id,
                                                                                                  distribution_name:
                                                                                                    frontRepo.project_name,
                                                                                                  end_point: `${bucket}.s3-website.${region}.amazonaws.com`,
                                                                                                  domain_name: bucket,
                                                                                                  repository_id:
                                                                                                    frontRepo.id,
                                                                                                  habitat_id,
                                                                                                  root_object:
                                                                                                    'index.html',
                                                                                                };
                                                                                                contentDistribution(
                                                                                                  distributionInfo
                                                                                                )
                                                                                                  .then(
                                                                                                    (
                                                                                                      response
                                                                                                    ) => {
                                                                                                      const dns_name =
                                                                                                        response
                                                                                                          .data
                                                                                                          .cloud_front_domain;
                                                                                                      let domainPrefix;
                                                                                                      prefix.length >
                                                                                                      0
                                                                                                        ? (domainPrefix = `${prefix}.`)
                                                                                                        : (domainPrefix = null);
                                                                                                      const domainInfo = {
                                                                                                        dns_name,
                                                                                                        domain_name,
                                                                                                        type:
                                                                                                          'A',
                                                                                                        prefix: domainPrefix,
                                                                                                        cf_hosted_zone_id:
                                                                                                          'Z2FDTNDATAQYW2',
                                                                                                        habitat_id,
                                                                                                      };
                                                                                                      addDomian(
                                                                                                        domainInfo
                                                                                                      )
                                                                                                        .then(
                                                                                                          (
                                                                                                            response
                                                                                                          ) => {
                                                                                                            const adminInfo = {
                                                                                                              name:
                                                                                                                funcClone.project_name,
                                                                                                              domain_name: adminBucket,
                                                                                                              repository_id:
                                                                                                                funcClone.id,
                                                                                                              habitat_id,
                                                                                                              git_hash:
                                                                                                                funcClone.git_hash,
                                                                                                              category:
                                                                                                                'static site',
                                                                                                            };
                                                                                                            createTemplate(
                                                                                                              adminInfo
                                                                                                            )
                                                                                                              .then(
                                                                                                                (
                                                                                                                  response
                                                                                                                ) => {
                                                                                                                  const adminTemplate =
                                                                                                                    response
                                                                                                                      .data
                                                                                                                      .template;
                                                                                                                  const adminS3Data = {
                                                                                                                    file_name: `lambda-${rdsData.name}`,
                                                                                                                    habitat_id,
                                                                                                                    team_id,
                                                                                                                  };
                                                                                                                  uploadClone(
                                                                                                                    adminS3Data
                                                                                                                  )
                                                                                                                    .then(
                                                                                                                      (
                                                                                                                        response
                                                                                                                      ) => {
                                                                                                                        const adminBuild = {
                                                                                                                          habitat_id,
                                                                                                                          project_name: `admin-${adminTemplate.name}`,
                                                                                                                          artifac_name:
                                                                                                                            '/',
                                                                                                                          buildspec:
                                                                                                                            'frontend-buildspec.yml',
                                                                                                                          source_location:
                                                                                                                            func.function_name,
                                                                                                                          artifac_location:
                                                                                                                            adminTemplate.domain_name,
                                                                                                                          service_role: roleArn,
                                                                                                                          zip:
                                                                                                                            'NONE',
                                                                                                                          site_id:
                                                                                                                            adminTemplate.id,
                                                                                                                        };
                                                                                                                        createProject(
                                                                                                                          adminBuild
                                                                                                                        )
                                                                                                                          .then(
                                                                                                                            (
                                                                                                                              response
                                                                                                                            ) => {
                                                                                                                              const adminBuildInfo = {
                                                                                                                                habitat_id,
                                                                                                                                build_id:
                                                                                                                                  response
                                                                                                                                    .data
                                                                                                                                    .params
                                                                                                                                    .id,
                                                                                                                                site_id:
                                                                                                                                  adminTemplate.id,
                                                                                                                              };
                                                                                                                              createBuild(
                                                                                                                                adminBuildInfo
                                                                                                                              )
                                                                                                                                .then(
                                                                                                                                  (
                                                                                                                                    response
                                                                                                                                  ) => {
                                                                                                                                    const adminRequestInfo = {
                                                                                                                                      site_id:
                                                                                                                                        adminTemplate.id,
                                                                                                                                      domain_name,
                                                                                                                                      prefix: adminPrexi,
                                                                                                                                      habitat_id,
                                                                                                                                    };
                                                                                                                                    requestDomain(
                                                                                                                                      adminRequestInfo
                                                                                                                                    )
                                                                                                                                      .then(
                                                                                                                                        (
                                                                                                                                          response
                                                                                                                                        ) => {
                                                                                                                                          const adminDistributionInfo = {
                                                                                                                                            site_id:
                                                                                                                                              adminTemplate.id,
                                                                                                                                            distribution_name:
                                                                                                                                              adminTemplate.name,
                                                                                                                                            end_point: `${adminBucket}.s3-website.${region}.amazonaws.com`,
                                                                                                                                            domain_name: adminBucket,
                                                                                                                                            repository_id:
                                                                                                                                              funcClone.id,
                                                                                                                                            habitat_id,
                                                                                                                                            root_object:
                                                                                                                                              'index.html',
                                                                                                                                          };
                                                                                                                                          contentDistribution(
                                                                                                                                            adminDistributionInfo
                                                                                                                                          )
                                                                                                                                            .then(
                                                                                                                                              (
                                                                                                                                                response
                                                                                                                                              ) => {
                                                                                                                                                const admin_dns_name =
                                                                                                                                                  response
                                                                                                                                                    .data
                                                                                                                                                    .cloud_front_domain;
                                                                                                                                                const adminDomainInfo = {
                                                                                                                                                  dns_name: admin_dns_name,
                                                                                                                                                  domain_name,
                                                                                                                                                  type:
                                                                                                                                                    'A',
                                                                                                                                                  prefix: `${adminPrexi}.`,
                                                                                                                                                  cf_hosted_zone_id:
                                                                                                                                                    'Z2FDTNDATAQYW2',
                                                                                                                                                  habitat_id,
                                                                                                                                                };
                                                                                                                                                addDomian(
                                                                                                                                                  adminDomainInfo
                                                                                                                                                )
                                                                                                                                                  .then(
                                                                                                                                                    (
                                                                                                                                                      response
                                                                                                                                                    ) => {
                                                                                                                                                      const env = JSON.stringify(
                                                                                                                                                        preEnv
                                                                                                                                                      )
                                                                                                                                                        .replace(
                                                                                                                                                          /\\n/g,
                                                                                                                                                          '\\n'
                                                                                                                                                        )
                                                                                                                                                        .replace(
                                                                                                                                                          /\\'/g,
                                                                                                                                                          "\\'"
                                                                                                                                                        )
                                                                                                                                                        .replace(
                                                                                                                                                          /\\"/g,
                                                                                                                                                          '\\"'
                                                                                                                                                        )
                                                                                                                                                        .replace(
                                                                                                                                                          /\\&/g,
                                                                                                                                                          '\\&'
                                                                                                                                                        )
                                                                                                                                                        .replace(
                                                                                                                                                          /\\r/g,
                                                                                                                                                          '\\r'
                                                                                                                                                        )
                                                                                                                                                        .replace(
                                                                                                                                                          /\\t/g,
                                                                                                                                                          '\\t'
                                                                                                                                                        )
                                                                                                                                                        .replace(
                                                                                                                                                          /\\b/g,
                                                                                                                                                          '\\b'
                                                                                                                                                        )
                                                                                                                                                        .replace(
                                                                                                                                                          /\\f/g,
                                                                                                                                                          '\\f'
                                                                                                                                                        );
                                                                                                                                                      deployFunc(
                                                                                                                                                        func.id,
                                                                                                                                                        env
                                                                                                                                                      )
                                                                                                                                                        .then(
                                                                                                                                                          (
                                                                                                                                                            response
                                                                                                                                                          ) => {
                                                                                                                                                            const lambdas =
                                                                                                                                                              response.data;
                                                                                                                                                            const lambda_arn =
                                                                                                                                                              lambdas[
                                                                                                                                                                lambdas.length -
                                                                                                                                                                  1
                                                                                                                                                              ]
                                                                                                                                                                .function_arn;
                                                                                                                                                            const methodData = {
                                                                                                                                                              habitat_id,
                                                                                                                                                              method:
                                                                                                                                                                'ANY',
                                                                                                                                                              type:
                                                                                                                                                                'AWS_PROXY',
                                                                                                                                                              lambda_arn,
                                                                                                                                                              rol: roleArn,
                                                                                                                                                              path_part: null,
                                                                                                                                                            };
                                                                                                                                                            createMethod(
                                                                                                                                                              apiId,
                                                                                                                                                              methodData
                                                                                                                                                            )
                                                                                                                                                              .then(
                                                                                                                                                                (
                                                                                                                                                                  response
                                                                                                                                                                ) => {
                                                                                                                                                                  const methodInfo =
                                                                                                                                                                    response
                                                                                                                                                                      .data
                                                                                                                                                                      .method;
                                                                                                                                                                  const methodProxyData = {
                                                                                                                                                                    habitat_id,
                                                                                                                                                                    method:
                                                                                                                                                                      'ANY',
                                                                                                                                                                    type:
                                                                                                                                                                      'AWS_PROXY',
                                                                                                                                                                    lambda_arn,
                                                                                                                                                                    rol: roleArn,
                                                                                                                                                                    path_part:
                                                                                                                                                                      '{proxy+}',
                                                                                                                                                                  };
                                                                                                                                                                  createMethod(
                                                                                                                                                                    apiId,
                                                                                                                                                                    methodProxyData
                                                                                                                                                                  )
                                                                                                                                                                    .then(
                                                                                                                                                                      (
                                                                                                                                                                        response
                                                                                                                                                                      ) => {
                                                                                                                                                                        const responseData = {
                                                                                                                                                                          habitat_id,
                                                                                                                                                                        };
                                                                                                                                                                        createRespose(
                                                                                                                                                                          methodInfo.id,
                                                                                                                                                                          responseData
                                                                                                                                                                        )
                                                                                                                                                                          .then(
                                                                                                                                                                            (
                                                                                                                                                                              response
                                                                                                                                                                            ) => {
                                                                                                                                                                              deployApi(
                                                                                                                                                                                apiId,
                                                                                                                                                                                habitat_id
                                                                                                                                                                              )
                                                                                                                                                                                .then(
                                                                                                                                                                                  (
                                                                                                                                                                                    response
                                                                                                                                                                                  ) => {
                                                                                                                                                                                    const lambdaRepo = {
                                                                                                                                                                                      credential_id: git_credential_id,
                                                                                                                                                                                      name: `lambda-${rdsData.name}`
                                                                                                                                                                                    }
                                                                                                                                                                                    gitCreateRepo(lambdaRepo)
                                                                                                                                                                                    .then(response => {
                                                                                                                                                                                      let gitLambaURL;
                                                                                                                                                                                      if (response.data.repository.http_url_to_repo) {
                                                                                                                                                                                        gitLambaURL = response.data.repository.http_url_to_repo;
                                                                                                                                                                                      } else {
                                                                                                                                                                                        gitLambaURL = `${response.data.repository.html_url}.git`;
                                                                                                                                                                                      }
                                                                                                                                                                                      const lambdaRemote = {
                                                                                                                                                                                        id: funcClone.id,
                                                                                                                                                                                        git_credential_id,
                                                                                                                                                                                        uri: gitLambaURL
                                                                                                                                                                                      }
                                                                                                                                                                                      gitRemote(lambdaRemote)
                                                                                                                                                                                      .then(response => {
                                                                                                                                                                                        const templateRepo = {
                                                                                                                                                                                          credential_id: git_credential_id,
                                                                                                                                                                                          name: `frontend-${rdsData.name}`
                                                                                                                                                                                        }
                                                                                                                                                                                        gitCreateRepo(templateRepo)
                                                                                                                                                                                        .then(response => {
                                                                                                                                                                                          let gitTemplateURL;
                                                                                                                                                                                          if (response.data.repository.http_url_to_repo) {
                                                                                                                                                                                            gitTemplateURL = response.data.repository.http_url_to_repo;
                                                                                                                                                                                          } else {
                                                                                                                                                                                            gitTemplateURL = `${response.data.repository.html_url}.git`;
                                                                                                                                                                                          }
                                                                                                                                                                                          const templateRemote = {
                                                                                                                                                                                            id: frontRepo.id,
                                                                                                                                                                                            git_credential_id,
                                                                                                                                                                                            uri: gitTemplateURL
                                                                                                                                                                                          }
                                                                                                                                                                                          gitRemote(templateRemote)
                                                                                                                                                                                          .then(response => {
                                                                                                                                                                                            dispatch(
                                                                                                                                                                                              {
                                                                                                                                                                                                type: CREATE_STRAPI_TEMPLATE_SUCCESS,
                                                                                                                                                                                              }
                                                                                                                                                                                            );
                                                                                                                                                                                          })
                                                                                                                                                                                          .catch(
                                                                                                                                                                                            (
                                                                                                                                                                                              error
                                                                                                                                                                                            ) => {
                                                                                                                                                                                              dispatch(
                                                                                                                                                                                                {
                                                                                                                                                                                                  type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                                                                                                  error:
                                                                                                                                                                                                    error
                                                                                                                                                                                                      .response
                                                                                                                                                                                                      .data
                                                                                                                                                                                                      .error,
                                                                                                                                                                                                }
                                                                                                                                                                                              );
                                                                                                                                                                                            }
                                                                                                                                                                                          );
                                                                                                                                                                                        })
                                                                                                                                                                                        .catch(
                                                                                                                                                                                          (
                                                                                                                                                                                            error
                                                                                                                                                                                          ) => {
                                                                                                                                                                                            dispatch(
                                                                                                                                                                                              {
                                                                                                                                                                                                type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                                                                                                error:
                                                                                                                                                                                                  error
                                                                                                                                                                                                    .response
                                                                                                                                                                                                    .data
                                                                                                                                                                                                    .error,
                                                                                                                                                                                              }
                                                                                                                                                                                            );
                                                                                                                                                                                          }
                                                                                                                                                                                        );
                                                                                                                                                                                      })
                                                                                                                                                                                      .catch(
                                                                                                                                                                                        (
                                                                                                                                                                                          error
                                                                                                                                                                                        ) => {
                                                                                                                                                                                          dispatch(
                                                                                                                                                                                            {
                                                                                                                                                                                              type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                                                                                              error:
                                                                                                                                                                                                error
                                                                                                                                                                                                  .response
                                                                                                                                                                                                  .data
                                                                                                                                                                                                  .error,
                                                                                                                                                                                            }
                                                                                                                                                                                          );
                                                                                                                                                                                        }
                                                                                                                                                                                      );
                                                                                                                                                                                    })
                                                                                                                                                                                    .catch(
                                                                                                                                                                                      (
                                                                                                                                                                                        error
                                                                                                                                                                                      ) => {
                                                                                                                                                                                        dispatch(
                                                                                                                                                                                          {
                                                                                                                                                                                            type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                                                                                            error:
                                                                                                                                                                                              error
                                                                                                                                                                                                .response
                                                                                                                                                                                                .data
                                                                                                                                                                                                .error,
                                                                                                                                                                                          }
                                                                                                                                                                                        );
                                                                                                                                                                                      }
                                                                                                                                                                                    );
                                                                                                                                                                                  }
                                                                                                                                                                                )
                                                                                                                                                                                .catch(
                                                                                                                                                                                  (
                                                                                                                                                                                    error
                                                                                                                                                                                  ) => {
                                                                                                                                                                                    dispatch(
                                                                                                                                                                                      {
                                                                                                                                                                                        type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                                                                                        error:
                                                                                                                                                                                          error
                                                                                                                                                                                            .response
                                                                                                                                                                                            .data
                                                                                                                                                                                            .error,
                                                                                                                                                                                      }
                                                                                                                                                                                    );
                                                                                                                                                                                  }
                                                                                                                                                                                );
                                                                                                                                                                            }
                                                                                                                                                                          )
                                                                                                                                                                          .catch(
                                                                                                                                                                            (
                                                                                                                                                                              error
                                                                                                                                                                            ) => {
                                                                                                                                                                              dispatch(
                                                                                                                                                                                {
                                                                                                                                                                                  type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                                                                                  error:
                                                                                                                                                                                    error
                                                                                                                                                                                      .response
                                                                                                                                                                                      .data
                                                                                                                                                                                      .error,
                                                                                                                                                                                }
                                                                                                                                                                              );
                                                                                                                                                                              rollBack(
                                                                                                                                                                                rollBackInfo
                                                                                                                                                                              );
                                                                                                                                                                            }
                                                                                                                                                                          );
                                                                                                                                                                      }
                                                                                                                                                                    )
                                                                                                                                                                    .catch(
                                                                                                                                                                      (
                                                                                                                                                                        error
                                                                                                                                                                      ) => {
                                                                                                                                                                        dispatch(
                                                                                                                                                                          {
                                                                                                                                                                            type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                                                                            error:
                                                                                                                                                                              error
                                                                                                                                                                                .response
                                                                                                                                                                                .data
                                                                                                                                                                                .error,
                                                                                                                                                                          }
                                                                                                                                                                        );
                                                                                                                                                                        rollBack(
                                                                                                                                                                          rollBackInfo
                                                                                                                                                                        );
                                                                                                                                                                      }
                                                                                                                                                                    );
                                                                                                                                                                }
                                                                                                                                                              )
                                                                                                                                                              .catch(
                                                                                                                                                                (
                                                                                                                                                                  error
                                                                                                                                                                ) => {
                                                                                                                                                                  dispatch(
                                                                                                                                                                    {
                                                                                                                                                                      type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                                                                      error:
                                                                                                                                                                        error
                                                                                                                                                                          .response
                                                                                                                                                                          .data
                                                                                                                                                                          .error,
                                                                                                                                                                    }
                                                                                                                                                                  );
                                                                                                                                                                  rollBack(
                                                                                                                                                                    rollBackInfo
                                                                                                                                                                  );
                                                                                                                                                                }
                                                                                                                                                              );
                                                                                                                                                          }
                                                                                                                                                        )
                                                                                                                                                        .catch(
                                                                                                                                                          (
                                                                                                                                                            error
                                                                                                                                                          ) => {
                                                                                                                                                            dispatch(
                                                                                                                                                              {
                                                                                                                                                                type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                                                                error:
                                                                                                                                                                  error
                                                                                                                                                                    .response
                                                                                                                                                                    .data
                                                                                                                                                                    .error,
                                                                                                                                                              }
                                                                                                                                                            );
                                                                                                                                                            rollBack(
                                                                                                                                                              rollBackInfo
                                                                                                                                                            );
                                                                                                                                                          }
                                                                                                                                                        );
                                                                                                                                                    }
                                                                                                                                                  )
                                                                                                                                                  .catch(
                                                                                                                                                    (
                                                                                                                                                      error
                                                                                                                                                    ) => {
                                                                                                                                                      dispatch(
                                                                                                                                                        {
                                                                                                                                                          type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                                                          error:
                                                                                                                                                            error
                                                                                                                                                              .response
                                                                                                                                                              .data
                                                                                                                                                              .error,
                                                                                                                                                        }
                                                                                                                                                      );
                                                                                                                                                      rollBack(
                                                                                                                                                        rollBackInfo
                                                                                                                                                      );
                                                                                                                                                    }
                                                                                                                                                  );
                                                                                                                                              }
                                                                                                                                            )
                                                                                                                                            .catch(
                                                                                                                                              (
                                                                                                                                                error
                                                                                                                                              ) => {
                                                                                                                                                dispatch(
                                                                                                                                                  {
                                                                                                                                                    type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                                                    error:
                                                                                                                                                      error
                                                                                                                                                        .response
                                                                                                                                                        .data
                                                                                                                                                        .error,
                                                                                                                                                  }
                                                                                                                                                );
                                                                                                                                                rollBack(
                                                                                                                                                  rollBackInfo
                                                                                                                                                );
                                                                                                                                              }
                                                                                                                                            );
                                                                                                                                        }
                                                                                                                                      )
                                                                                                                                      .catch(
                                                                                                                                        (
                                                                                                                                          error
                                                                                                                                        ) => {
                                                                                                                                          dispatch(
                                                                                                                                            {
                                                                                                                                              type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                                              error:
                                                                                                                                                error
                                                                                                                                                  .response
                                                                                                                                                  .data
                                                                                                                                                  .error,
                                                                                                                                            }
                                                                                                                                          );
                                                                                                                                          rollBack(
                                                                                                                                            rollBackInfo
                                                                                                                                          );
                                                                                                                                        }
                                                                                                                                      );
                                                                                                                                  }
                                                                                                                                )
                                                                                                                                .catch(
                                                                                                                                  (
                                                                                                                                    error
                                                                                                                                  ) => {
                                                                                                                                    dispatch(
                                                                                                                                      {
                                                                                                                                        type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                                        error:
                                                                                                                                          error
                                                                                                                                            .response
                                                                                                                                            .data
                                                                                                                                            .error,
                                                                                                                                      }
                                                                                                                                    );
                                                                                                                                    rollBack(
                                                                                                                                      rollBackInfo
                                                                                                                                    );
                                                                                                                                  }
                                                                                                                                );
                                                                                                                            }
                                                                                                                          )
                                                                                                                          .catch(
                                                                                                                            (
                                                                                                                              error
                                                                                                                            ) => {
                                                                                                                              dispatch(
                                                                                                                                {
                                                                                                                                  type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                                  error:
                                                                                                                                    error
                                                                                                                                      .response
                                                                                                                                      .data
                                                                                                                                      .error,
                                                                                                                                }
                                                                                                                              );
                                                                                                                              rollBack(
                                                                                                                                rollBackInfo
                                                                                                                              );
                                                                                                                            }
                                                                                                                          );
                                                                                                                      }
                                                                                                                    )
                                                                                                                    .catch(
                                                                                                                      (
                                                                                                                        error
                                                                                                                      ) => {
                                                                                                                        dispatch(
                                                                                                                          {
                                                                                                                            type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                            error:
                                                                                                                              error
                                                                                                                                .response
                                                                                                                                .data
                                                                                                                                .error,
                                                                                                                          }
                                                                                                                        );
                                                                                                                        rollBack(
                                                                                                                          rollBackInfo
                                                                                                                        );
                                                                                                                      }
                                                                                                                    );
                                                                                                                }
                                                                                                              )
                                                                                                              .catch(
                                                                                                                (
                                                                                                                  error
                                                                                                                ) => {
                                                                                                                  dispatch(
                                                                                                                    {
                                                                                                                      type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                      error:
                                                                                                                        error
                                                                                                                          .response
                                                                                                                          .data
                                                                                                                          .error,
                                                                                                                    }
                                                                                                                  );
                                                                                                                  rollBack(
                                                                                                                    rollBackInfo
                                                                                                                  );
                                                                                                                }
                                                                                                              );
                                                                                                          }
                                                                                                        )
                                                                                                        .catch(
                                                                                                          (
                                                                                                            error
                                                                                                          ) => {
                                                                                                            dispatch(
                                                                                                              {
                                                                                                                type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                                error:
                                                                                                                  error
                                                                                                                    .response
                                                                                                                    .data
                                                                                                                    .error,
                                                                                                              }
                                                                                                            );
                                                                                                            rollBack(
                                                                                                              rollBackInfo
                                                                                                            );
                                                                                                          }
                                                                                                        );
                                                                                                    }
                                                                                                  )
                                                                                                  .catch(
                                                                                                    (
                                                                                                      error
                                                                                                    ) => {
                                                                                                      dispatch(
                                                                                                        {
                                                                                                          type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                          error:
                                                                                                            error
                                                                                                              .response
                                                                                                              .data
                                                                                                              .error,
                                                                                                        }
                                                                                                      );
                                                                                                      rollBack(
                                                                                                        rollBackInfo
                                                                                                      );
                                                                                                    }
                                                                                                  );
                                                                                              }
                                                                                            )
                                                                                            .catch(
                                                                                              (
                                                                                                error
                                                                                              ) => {
                                                                                                dispatch(
                                                                                                  {
                                                                                                    type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                                    error:
                                                                                                      error
                                                                                                        .response
                                                                                                        .data
                                                                                                        .error,
                                                                                                  }
                                                                                                );
                                                                                                rollBack(
                                                                                                  rollBackInfo
                                                                                                );
                                                                                              }
                                                                                            );
                                                                                        }
                                                                                      )
                                                                                      .catch(
                                                                                        (
                                                                                          error
                                                                                        ) => {
                                                                                          dispatch(
                                                                                            {
                                                                                              type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                              error:
                                                                                                error
                                                                                                  .response
                                                                                                  .data
                                                                                                  .error,
                                                                                            }
                                                                                          );
                                                                                          rollBack(
                                                                                            rollBackInfo
                                                                                          );
                                                                                        }
                                                                                      );
                                                                                  }
                                                                                )
                                                                                .catch(
                                                                                  (
                                                                                    error
                                                                                  ) => {
                                                                                    dispatch(
                                                                                      {
                                                                                        type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                        error:
                                                                                          error
                                                                                            .response
                                                                                            .data
                                                                                            .error,
                                                                                      }
                                                                                    );
                                                                                    rollBack(
                                                                                      rollBackInfo
                                                                                    );
                                                                                  }
                                                                                );
                                                                            }
                                                                          )
                                                                          .catch(
                                                                            (
                                                                              error
                                                                            ) => {
                                                                              dispatch(
                                                                                {
                                                                                  type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                                  error:
                                                                                    error
                                                                                      .response
                                                                                      .data
                                                                                      .error,
                                                                                }
                                                                              );
                                                                              rollBack(
                                                                                rollBackInfo
                                                                              );
                                                                            }
                                                                          );
                                                                      }
                                                                    )
                                                                    .catch(
                                                                      (
                                                                        error
                                                                      ) => {
                                                                        dispatch(
                                                                          {
                                                                            type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                            error:
                                                                              error
                                                                                .response
                                                                                .data
                                                                                .error,
                                                                          }
                                                                        );
                                                                        rollBack(
                                                                          rollBackInfo
                                                                        );
                                                                      }
                                                                    );
                                                                }
                                                              )
                                                              .catch(
                                                                (error) => {
                                                                  dispatch({
                                                                    type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                                    error:
                                                                      error
                                                                        .response
                                                                        .data
                                                                        .error,
                                                                  });
                                                                  rollBack(
                                                                    rollBackInfo
                                                                  );
                                                                }
                                                              );
                                                          })
                                                          .catch((error) => {
                                                            dispatch({
                                                              type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                              error:
                                                                error.response
                                                                  .data.error,
                                                            });
                                                            rollBack(
                                                              rollBackInfo
                                                            );
                                                          });
                                                      })
                                                      .catch((error) => {
                                                        dispatch({
                                                          type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                          error:
                                                            error.response.data
                                                              .error,
                                                        });
                                                        rollBack(rollBackInfo);
                                                      });
                                                  })
                                                  .catch((error) => {
                                                    dispatch({
                                                      type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                      error:
                                                        error.response.data
                                                          .error,
                                                    });
                                                    rollBack(rollBackInfo);
                                                  });
                                              })
                                              .catch((error) => {
                                                dispatch({
                                                  type: CREATE_STRAPI_TEMPLATE_ERROR,
                                                  error:
                                                    error.response.data.error,
                                                });
                                                rollBack(rollBackInfo);
                                              });
                                          })
                                          .catch((error) => {
                                            dispatch({
                                              type: CREATE_STRAPI_TEMPLATE_ERROR,
                                              error: error.response.data.error,
                                            });
                                            rollBack(rollBackInfo);
                                          });
                                      })
                                      .catch((error) => {
                                        dispatch({
                                          type: CREATE_STRAPI_TEMPLATE_ERROR,
                                          error: error.response.data.error,
                                        });
                                        rollBack(rollBackInfo);
                                      });
                                  })
                                  .catch((error) => {
                                    dispatch({
                                      type: CREATE_STRAPI_TEMPLATE_ERROR,
                                      error: error.response.data.error,
                                    });
                                    rollBack(rollBackInfo);
                                  });
                              })
                              .catch((error) => {
                                dispatch({
                                  type: CREATE_STRAPI_TEMPLATE_ERROR,
                                  error: error.response.data.error,
                                });
                                rollBack(rollBackInfo);
                              });
                          })
                          .catch((error) => {
                            dispatch({
                              type: CREATE_STRAPI_TEMPLATE_ERROR,
                              error: error.response.data.error,
                            });
                            rollBack(rollBackInfo);
                          });
                      })
                      .catch((error) => {
                        dispatch({
                          type: CREATE_STRAPI_TEMPLATE_ERROR,
                          error: error.response.data.error,
                        });
                        rollBack(rollBackInfo);
                      });
                  })
                  .catch((error) => {
                    dispatch({
                      type: CREATE_STRAPI_TEMPLATE_ERROR,
                      error: error.response.data.error,
                    });
                    rollBack(rollBackInfo);
                  });
              })
              .catch((error) => {
                dispatch({
                  type: CREATE_STRAPI_TEMPLATE_ERROR,
                  error: error.response.data.error,
                });
                rollBack(rollBackInfo);
              });
          })
          .catch((error) => {
            dispatch({
              type: CREATE_STRAPI_TEMPLATE_ERROR,
              error: error.response.data.error,
            });
            rollBack(rollBackInfo);
          });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_STRAPI_TEMPLATE_ERROR,
          error: error.response.data.error,
        });
        rollBack(rollBackInfo);
      });
  };
};

export const deployNewTemplateStrapiWithoutDomain = (
  prefix,
  region,
  team_id,
  awsCreds,
  habitat_id,
  domain_name,
  git_credential_id
) => {
  return dispatch => {
    const bucket = `strapi-site-${Date.now()}`;
    const adminBucket = `admin-strapi-${Date.now()}`;

    const s3Static = {
      bucket,
      region,
      public: 1,
      habitat_id
    }

    const s3Admin = {
      bucket: adminBucket,
      region,
      public: 1,
      habitat_id
    }

    let rollBackInfo = {
      habitat_id,
      rol_id: null,
      git_site: null,
      git_lambda: null
    }

    const runtimeLambda = 'nodejs12.x';
    const handlerLambda = 'app.handler';
    const rdsData = {
      habitat_id,
      port: 5432,
      storage: 5,
      db_name: 'strapi',
      engine: 'postgres',
      engine_version: '12.5',
      master_username: 'postgres',
      name: `${adminBucket}-strapi-${Date.now()}`,
      db_instance_class: 'db.t2.micro',
      password: passwordGenerator(18, false)
    }
    let env = `DATABASE_PASSWORD=${rdsData.password}\n`;
    env += `BUCKET_NAME=${bucket}\n`;
    env += `DATABASE_PORT=${rdsData.port}\n`;
    env += `DATABASE_NAME=${rdsData.db_name}\n`;
    env += `DATABASE_USERNAME=${rdsData.master_username}\n`;
    env += `HOST=${adminBucket}\n`;
    env += 'PORT=80\n';
    const hash = randomHash({ length: 32 });
    env += `ADMIN_JWT_SECRET=${hash}\n`;
    dispatch({
      type: CREATE_STRAPI_WITHOUT_DOMAIN_BEGIN,
      loading_message: 'Loading deploy Strapi...'
    });
    getAWSRoles(habitat_id)
      .then(response => {
        const awsRoles = response.data;
        const roleArn = awsRoles[awsRoles.length - 1].arn;
        rollBackInfo.rol_id = awsRoles[awsRoles.length - 1].id;
        createRds(rdsData)
          .then(response => {
            const dbHost = response.data.db.endpoint;
            addStaticBucket(s3Admin)
              .then(response => {
                addStaticBucket(s3Static)
                  .then(response => {
                    env += `DATABASE_HOST=${dbHost}\n`;
                    createApiGateway(rdsData.name, habitat_id)
                      .then(response => {
                        const apigateway = response.data.api_gateway;
                        const apiId = response.data.api_gateway.id;
                        const endpoint = `https://${apigateway.api_id}.execute-api.${awsCreds.region}.amazonaws.com/production`;
                        env += `BACKEND_URL=${endpoint}`;
                        const reactEnv = `REACT_APP_BACKEND_URL=${endpoint}`;
                        const preEnv = {
                          BUCKET_NAME: bucket,
                          DATABASE_PASSWORD: rdsData.password,
                          DATABASE_PORT: `${rdsData.port}`,
                          DATABASE_NAME: rdsData.db_name,
                          DATABASE_USERNAME: rdsData.master_username,
                          HOST: adminBucket,
                          PORT: '80',
                          DATABASE_HOST: dbHost,
                          BACKEND_URL: endpoint,
                          ADMIN_JWT_SECRET: hash
                        }
                        createResource(apigateway.id, habitat_id)
                          .then(response => {
                            const funcCloneInfo = {
                              uri: 'https://gitlab.com/kor-comunity/kor-strapi-app.git',
                              project_name: `lambda-${rdsData.name}`,
                              team_id,
                              git_credential_id: ''
                            }
                            gitClone(funcCloneInfo)
                              .then(response => {
                                const funcClone = response.data.repo;
                                const funcDirectory = response.data.repo.directory_name;
                                rollBackInfo.git_lambda = funcClone.id;
                                const envFunc = {
                                  file: '.env',
                                  habitat_id,
                                  directory_name: funcDirectory,
                                  content: env
                                }
                                envRepo(envFunc)
                                  .then(response => {
                                    const frontCloneInfo = {
                                      uri: 'https://gitlab.com/kor-comunity/strapi-template-kor.git',
                                      project_name: `frontend-${rdsData.name}`,
                                      team_id,
                                      git_credential_id: ''
                                    }
                                    gitClone(frontCloneInfo)
                                      .then(response => {
                                        const frontRepo = response.data.repo;
                                        rollBackInfo.git_site = frontRepo.id;
                                        const directory_name = response.data.repo.directory_name;
                                        const envInfo = {
                                          file: '.env',
                                          habitat_id,
                                          directory_name,
                                          content: reactEnv
                                        }
                                        envRepo(envInfo)
                                          .then(response => {
                                            const LambdaBucket = `kor-lambda-${habitat_id}`;
                                            addBucket(LambdaBucket, habitat_id, region)
                                              .then(response => {
                                                const s3Data = {
                                                  file_name: `lambda-${rdsData.name}`,
                                                  team_id,
                                                  habitat_id
                                                }
                                                uploadClone(s3Data)
                                                  .then(response => {
                                                    let data = {
                                                      function_name: `lambda-${rdsData.name}`,
                                                      runtime: runtimeLambda,
                                                      handler: handlerLambda,
                                                      role: roleArn,
                                                      habitat_id,
                                                      git_hash: funcClone.git_hash,
                                                      memory_size: 1024,
                                                      timeout: 25,
                                                      code_s3_bucket: LambdaBucket,
                                                      code_s3_key: `lambda-${rdsData.name}.zip`
                                                    }
                                                    setFunc(data)
                                                      .then(response => {
                                                        const func = response.data.aws_lambda;
                                                        const buildData = {
                                                          habitat_id: func.habitat_id,
                                                          project_name: func.function_name,
                                                          artifac_name: `${func.function_name}.zip`,
                                                          buildspec: 'backend-buildspec.yml',
                                                          source_location: func.function_name,
                                                          artifac_location: func.code_s3_bucket,
                                                          service_role: roleArn,
                                                          zip: 'ZIP',
                                                          lambda_id: func.id
                                                        }
                                                        createProject(buildData)
                                                          .then(response => {
                                                            const buildInfo = {
                                                              habitat_id: func.habitat_id,
                                                              build_id: response.data.params.id
                                                            }
                                                            createBuild(buildInfo)
                                                              .then(response => {
                                                                const templateInfo = {
                                                                  name: frontRepo.project_name,
                                                                  domain_name: '',
                                                                  repository_id: frontRepo.id,
                                                                  habitat_id,
                                                                  git_hash: frontRepo.git_hash,
                                                                  category: 'static site'
                                                                }
                                                                createTemplate(templateInfo)
                                                                  .then(response => {
                                                                    const template = response.data.template;
                                                                    const requestInfo = {
                                                                      site_id: template.id,
                                                                      domain_name: '',
                                                                      prefix: '',
                                                                      habitat_id
                                                                    }
                                                                    const s3Data = {
                                                                      file_name: template.name,
                                                                      habitat_id,
                                                                      team_id
                                                                    }
                                                                    uploadClone(s3Data)
                                                                      .then(response => {
                                                                        const staticBuild = {
                                                                          habitat_id,
                                                                          project_name: template.name,
                                                                          artifac_name: '/',
                                                                          buildspec: 'buildspec.yml',
                                                                          source_location: template.name,
                                                                          artifac_location: bucket,
                                                                          service_role: roleArn,
                                                                          zip: 'NONE',
                                                                          site_id: template.id
                                                                        }
                                                                        createProject(staticBuild)
                                                                          .then(response => {
                                                                            const buildInfo = {
                                                                              habitat_id,
                                                                              build_id: response.data.params.id,
                                                                              site_id: template.id
                                                                            }
                                                                            createBuild(buildInfo)
                                                                              .then(response => {
                                                                                const distributionInfo = {
                                                                                  site_id: template.id,
                                                                                  distribution_name: frontRepo.project_name,
                                                                                  end_point: `${bucket}.s3-website.${region}.amazonaws.com`,
                                                                                  domain_name: '',
                                                                                  repository_id: frontRepo.id,
                                                                                  habitat_id,
                                                                                  root_object: 'index.html'
                                                                                }
                                                                                contentDistribution(distributionInfo)
                                                                                  .then(response => {
                                                                                    const frontendEndpoint = response.data.cloud_front_domain;
                                                                                    const adminInfo = {
                                                                                      name: funcClone.project_name,
                                                                                      domain_name: '',
                                                                                      repository_id: funcClone.id,
                                                                                      habitat_id,
                                                                                      git_hash: funcClone.git_hash,
                                                                                      category: 'static site'
                                                                                    }
                                                                                    createTemplate(adminInfo)
                                                                                      .then(response => {
                                                                                        const adminTemplate = response.data.template;
                                                                                        const adminS3Data = {
                                                                                          file_name: `lambda-${rdsData.name}`,
                                                                                          habitat_id,
                                                                                          team_id
                                                                                        }
                                                                                        uploadClone(adminS3Data)
                                                                                          .then(response => {
                                                                                            const adminBuild = {
                                                                                              habitat_id,
                                                                                              project_name: `admin-${adminTemplate.name}`,
                                                                                              artifac_name: '/',
                                                                                              buildspec: 'frontend-buildspec.yml',
                                                                                              source_location: func.function_name,
                                                                                              artifac_location: adminBucket,
                                                                                              service_role: roleArn,
                                                                                              zip: 'NONE',
                                                                                              site_id: adminTemplate.id
                                                                                            }
                                                                                            createProject(adminBuild)
                                                                                              .then(response => {
                                                                                                const adminBuildInfo = {
                                                                                                  habitat_id,
                                                                                                  build_id: response.data.params.id,
                                                                                                  site_id: adminTemplate.id
                                                                                                }
                                                                                                createBuild(adminBuildInfo)
                                                                                                  .then(response => {
                                                                                                    const adminDistributionInfo = {
                                                                                                      site_id: adminTemplate.id,
                                                                                                      distribution_name: adminTemplate.name,
                                                                                                      end_point: `${adminBucket}.s3-website.${region}.amazonaws.com`,
                                                                                                      domain_name: '',
                                                                                                      repository_id: funcClone.id,
                                                                                                      habitat_id,
                                                                                                      root_object: 'index.html'
                                                                                                    }
                                                                                                    contentDistribution(adminDistributionInfo)
                                                                                                      .then(response => {
                                                                                                        const adminEndpoint = response.data.cloud_front_domain;
                                                                                                        const env = JSON.stringify(preEnv)
                                                                                                          .replace(/\\n/g, '\\n')
                                                                                                          .replace(/\\'/g, '\\\'')
                                                                                                          .replace(/\\"/g, '\\"')
                                                                                                          .replace(/\\&/g, '\\&')
                                                                                                          .replace(/\\r/g, '\\r')
                                                                                                          .replace(/\\t/g, '\\t')
                                                                                                          .replace(/\\b/g, '\\b')
                                                                                                          .replace(/\\f/g, '\\f');
                                                                                                        deployFunc(func.id, env)
                                                                                                          .then(response => {
                                                                                                            const lambdas = response.data;
                                                                                                            const lambda_arn = lambdas[lambdas.length - 1].function_arn;
                                                                                                            const methodData = {
                                                                                                              habitat_id,
                                                                                                              method: 'ANY',
                                                                                                              type: 'AWS_PROXY',
                                                                                                              lambda_arn,
                                                                                                              rol: roleArn,
                                                                                                              path_part: null,
                                                                                                            }
                                                                                                            createMethod(apiId, methodData)
                                                                                                              .then(response => {
                                                                                                                const methodInfo = response.data.method;
                                                                                                                const methodProxyData = {
                                                                                                                  habitat_id,
                                                                                                                  method: 'ANY',
                                                                                                                  type: 'AWS_PROXY',
                                                                                                                  lambda_arn,
                                                                                                                  rol: roleArn,
                                                                                                                  path_part: '{proxy+}',
                                                                                                                }
                                                                                                                createMethod(apiId, methodProxyData)
                                                                                                                  .then(response => {
                                                                                                                    const responseData = {
                                                                                                                      habitat_id
                                                                                                                    }
                                                                                                                    createRespose(methodInfo.id, responseData)
                                                                                                                      .then(response => {
                                                                                                                        deployApi(apiId, habitat_id)
                                                                                                                          .then(response => {
                                                                                                                            const lambdaRepo = {
                                                                                                                              credential_id: git_credential_id,
                                                                                                                              name: `lambda-${rdsData.name}`
                                                                                                                            }
                                                                                                                            gitCreateRepo(lambdaRepo)
                                                                                                                            .then(response => {
                                                                                                                              let gitLambaURL;
                                                                                                                              if (response.data.repository.http_url_to_repo) {
                                                                                                                                gitLambaURL = response.data.repository.http_url_to_repo;
                                                                                                                              } else {
                                                                                                                                gitLambaURL = `${response.data.repository.html_url}.git`;
                                                                                                                              }
                                                                                                                              const lambdaRemote = {
                                                                                                                                id: funcClone.id,
                                                                                                                                git_credential_id,
                                                                                                                                uri: gitLambaURL
                                                                                                                              }
                                                                                                                              gitRemote(lambdaRemote)
                                                                                                                              .then(response => {
                                                                                                                                const templateRepo = {
                                                                                                                                  credential_id: git_credential_id,
                                                                                                                                  name: `frontend-${rdsData.name}`
                                                                                                                                }
                                                                                                                                gitCreateRepo(templateRepo)
                                                                                                                                .then(response => {
                                                                                                                                  let gitTemplateURL;
                                                                                                                                  if (response.data.repository.http_url_to_repo) {
                                                                                                                                    gitTemplateURL = response.data.repository.http_url_to_repo;
                                                                                                                                  } else {
                                                                                                                                    gitTemplateURL = `${response.data.repository.html_url}.git`;
                                                                                                                                  }
                                                                                                                                  const templateRemote = {
                                                                                                                                    id: frontRepo.id,
                                                                                                                                    git_credential_id,
                                                                                                                                    uri: gitTemplateURL
                                                                                                                                  }
                                                                                                                                  gitRemote(templateRemote)
                                                                                                                                  .then(response => {
                                                                                                                                    const payload = {
                                                                                                                                      frontendEndpoint,
                                                                                                                                      gitTemplateURL,
                                                                                                                                      adminEndpoint,
                                                                                                                                      gitLambaURL,
                                                                                                                                    }
                                                                                                                                    dispatch({
                                                                                                                                      type: CREATE_STRAPI_WITHOUT_DOMAIN_SUCCESS,
                                                                                                                                      payload
                                                                                                                                    });
                                                                                                                                  })
                                                                                                                                  .catch(error => {
                                                                                                                                    dispatch({
                                                                                                                                      type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                                                                                      error: error.response.data.error
                                                                                                                                    });
                                                                                                                                    rollBack(rollBackInfo);
                                                                                                                                  });
                                                                                                                                })
                                                                                                                                .catch(error => {
                                                                                                                                  dispatch({
                                                                                                                                    type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                                                                                    error: error.response.data.error
                                                                                                                                  });
                                                                                                                                  rollBack(rollBackInfo);
                                                                                                                                });
                                                                                                                              })
                                                                                                                              .catch(error => {
                                                                                                                                dispatch({
                                                                                                                                  type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                                                                                  error: error.response.data.error
                                                                                                                                });
                                                                                                                                rollBack(rollBackInfo);
                                                                                                                              });
                                                                                                                            })
                                                                                                                            .catch(error => {
                                                                                                                              dispatch({
                                                                                                                                type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                                                                                error: error.response.data.error
                                                                                                                              });
                                                                                                                              rollBack(rollBackInfo);
                                                                                                                            });
                                                                                                                          })
                                                                                                                          .catch(error => {
                                                                                                                            dispatch({
                                                                                                                              type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                                                                              error: error.response.data.error
                                                                                                                            });
                                                                                                                            rollBack(rollBackInfo);
                                                                                                                          });
                                                                                                                      })
                                                                                                                      .catch(error => {
                                                                                                                        dispatch({
                                                                                                                          type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                                                                          error: error.response.data.error
                                                                                                                        });
                                                                                                                        rollBack(rollBackInfo);
                                                                                                                      });
                                                                                                                  })
                                                                                                                  .catch(error => {
                                                                                                                    dispatch({
                                                                                                                      type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                                                                      error: error.response.data.error
                                                                                                                    });
                                                                                                                    rollBack(rollBackInfo);
                                                                                                                  });
                                                                                                              })
                                                                                                              .catch(error => {
                                                                                                                dispatch({
                                                                                                                  type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                                                                  error: error.response.data.error
                                                                                                                });
                                                                                                                rollBack(rollBackInfo);
                                                                                                              });
                                                                                                          })
                                                                                                          .catch(error => {
                                                                                                            dispatch({
                                                                                                              type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                                                              error: error.response.data.error
                                                                                                            });
                                                                                                            rollBack(rollBackInfo);
                                                                                                          });
                                                                                                      })
                                                                                                      .catch(error => {
                                                                                                        dispatch({
                                                                                                          type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                                                          error: error.response.data.error
                                                                                                        });
                                                                                                        rollBack(rollBackInfo);
                                                                                                      });
                                                                                                  })
                                                                                                  .catch(error => {
                                                                                                    dispatch({
                                                                                                      type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                                                      error: error.response.data.error
                                                                                                    });
                                                                                                    rollBack(rollBackInfo);
                                                                                                  });
                                                                                              })
                                                                                              .catch(error => {
                                                                                                dispatch({
                                                                                                  type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                                                  error: error.response.data.error
                                                                                                });
                                                                                                rollBack(rollBackInfo);
                                                                                              });
                                                                                          })
                                                                                          .catch(error => {
                                                                                            dispatch({
                                                                                              type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                                              error: error.response.data.error
                                                                                            });
                                                                                            rollBack(rollBackInfo);
                                                                                          });
                                                                                      })
                                                                                      .catch(error => {
                                                                                        dispatch({
                                                                                          type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                                          error: error.response.data.error
                                                                                        });
                                                                                        rollBack(rollBackInfo);
                                                                                      });
                                                                                  })
                                                                                  .catch(error => {
                                                                                    dispatch({
                                                                                      type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                                      error: error.response.data.error
                                                                                    });
                                                                                    rollBack(rollBackInfo);
                                                                                  });
                                                                              })
                                                                              .catch(error => {
                                                                                dispatch({
                                                                                  type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                                  error: error.response.data.error
                                                                                });
                                                                                rollBack(rollBackInfo);
                                                                              });
                                                                          })
                                                                          .catch(error => {
                                                                            dispatch({
                                                                              type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                              error: error.response.data.error
                                                                            });
                                                                            rollBack(rollBackInfo);
                                                                          });
                                                                      })
                                                                      .catch(error => {
                                                                        dispatch({
                                                                          type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                          error: error.response.data.error
                                                                        });
                                                                        rollBack(rollBackInfo);
                                                                      });
                                                                  })
                                                                  .catch(error => {
                                                                    dispatch({
                                                                      type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                      error: error.response.data.error
                                                                    });
                                                                    rollBack(rollBackInfo);
                                                                  });
                                                              })
                                                              .catch(error => {
                                                                dispatch({
                                                                  type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                                  error: error.response.data.error
                                                                });
                                                                rollBack(rollBackInfo);
                                                              });
                                                          })
                                                          .catch(error => {
                                                            dispatch({
                                                              type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                              error: error.response.data.error
                                                            });
                                                            rollBack(rollBackInfo);
                                                          });
                                                      })
                                                      .catch(error => {
                                                        dispatch({
                                                          type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                          error: error.response.data.error
                                                        });
                                                        rollBack(rollBackInfo);
                                                      });
                                                  })
                                                  .catch(error => {
                                                    dispatch({
                                                      type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                      error: error.response.data.error
                                                    });
                                                    rollBack(rollBackInfo);
                                                  });
                                              })
                                              .catch(error => {
                                                dispatch({
                                                  type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                                  error: error.response.data.error
                                                });
                                                rollBack(rollBackInfo);
                                              });
                                          })
                                          .catch(error => {
                                            dispatch({
                                              type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                              error: error.response.data.error
                                            });
                                            rollBack(rollBackInfo);
                                          });
                                      })
                                      .catch(error => {
                                        dispatch({
                                          type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                          error: error.response.data.error
                                        });
                                        rollBack(rollBackInfo);
                                      });
                                  })
                                  .catch(error => {
                                    dispatch({
                                      type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                      error: error.response.data.error
                                    });
                                    rollBack(rollBackInfo);
                                  });
                              })
                              .catch(error => {
                                dispatch({
                                  type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                                  error: error.response.data.error
                                });
                                rollBack(rollBackInfo);
                              });
                          })
                          .catch(error => {
                            dispatch({
                              type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                              error: error.response.data.error
                            });
                            rollBack(rollBackInfo);
                          });
                      })
                      .catch(error => {
                        dispatch({
                          type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                          error: error.response.data.error
                        });
                        rollBack(rollBackInfo);
                      });
                  })
                  .catch(error => {
                    dispatch({
                      type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                      error: error.response.data.error
                    });
                    rollBack(rollBackInfo);
                  });
              })
              .catch(error => {
                dispatch({
                  type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
                  error: error.response.data.error
                });
                rollBack(rollBackInfo);
              });
          })
          .catch(error => {
            dispatch({
              type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
              error: error.response.data.error
            });
            rollBack(rollBackInfo);
          });
      })
      .catch(error => {
        dispatch({
          type: CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
          error: error.response.data.error
        });
        rollBack(rollBackInfo);
      });
  }
}

export const deployWebAppWithCreds = (
  uri,
  name,
  prefix,
  team_id,
  awsCred,
  habitat_id,
  domain_name,
  git_credential_id
) => {
  return (dispatch) => {
    let bucket;
    let staticBucket;
    let endpoint;
    let userPoolId;
    let userPoolClientId;
    const lambdaName = `KOR-LAMBDA-TEMPLATE-${Date.now()}`;
    const runtimeLambda = 'nodejs12.x';
    const handlerLambda = 'index.handler';

    if (prefix) {
      bucket = `${prefix}.${domain_name}`;
      staticBucket = `${prefix}.${domain_name}`;
    } else {
      bucket = domain_name;
      staticBucket = domain_name;
    }

    dispatch({
      type: CREATE_WEB_APP_TEMPLATE_BEGIN,
      loading_message: 'Creating template...',
    });

    let rollBackInfo = {
      habitat_id,
      rol_id: null,
      git_site: null,
      git_lambda: null,
    };
    

    const s3Static = {
      bucket,
      habitat_id,
      region: awsCred.region,
      public: 1,
    };

    getAWSRoles(habitat_id)
    .then(response => {
      const awsRoles = response.data;
      const roleArn = awsRoles[awsRoles.length - 1].arn;
      rollBackInfo.rol_id = awsRoles[awsRoles.length - 1].id;
      addStaticBucket(s3Static)
      .then((response) => {
        const gitCloneInfo = {
          uri,
          project_name: `KOR-FRONTEND-TEMPLATE-${Date.now()}`,
          team_id,
          git_credential_id: '',
        };
        gitClone(gitCloneInfo)
          .then((response) => {
            const repo = response.data.repo;
            const repository_id = repo.id;
            const directory_name = repo.directory_name;
            const funcCloneInfo = {
              uri:
                'https://gitlab.com/kor-comunity/kor-crud-lambda.git',
              project_name: lambdaName,
              team_id,
              git_credential_id: '',
            };
            rollBackInfo.git_site = repository_id;
            gitClone(funcCloneInfo)
              .then((response) => {
                const funcClone = response.data.repo;
                getBuckets(habitat_id)
                  .then((response) => {
                    const s3Buckets = response.data;
                    const bucketName = `kor-lambda-${habitat_id}`;
                    let bucket;
                    if (s3Buckets) {
                      bucket = s3Buckets.find(
                        (bucket) => bucket.bucket === bucketName
                      );
                    }
  
                    if (!bucket) {
                      addBucket(
                        bucketName,
                        habitat_id,
                        awsCred.region
                      )
                        .then((response) => {
                          const s3Data = {
                            file_name: lambdaName,
                            team_id,
                            habitat_id,
                          };
                          uploadClone(s3Data)
                            .then((response) => {
                              let data = {
                                function_name: lambdaName,
                                runtime: runtimeLambda,
                                handler: handlerLambda,
                                role: roleArn,
                                habitat_id,
                                memory_size: 1024,
                                timeout: 25,
                                code_s3_bucket: bucketName,
                                code_s3_key: `${lambdaName}.zip`,
                              };
                              setFunc(data)
                                .then((response) => {
                                  const func =
                                    response.data.aws_lambda;
                                  const buildData = {
                                    habitat_id: func.habitat_id,
                                    project_name:
                                      func.function_name,
                                    artifac_name: `${func.function_name}.zip`,
                                    buildspec: 'buildspec.yml',
                                    source_location:
                                      func.function_name,
                                    artifac_location:
                                      func.code_s3_bucket,
                                    service_role: roleArn,
                                    zip: 'ZIP',
                                    lambda_id: func.id,
                                  };
  
                                  createProject(buildData)
                                    .then((response) => {
                                      const buildInfo = {
                                        habitat_id:
                                          func.habitat_id,
                                        build_id:
                                          response.data.params
                                            .id,
                                      };
                                      createBuild(buildInfo)
                                        .then((response) => {
                                          const table_name = lambdaName;
                                          const schema = {
                                            attribute_definitions: [
                                              {
                                                attribute_name:
                                                  'id',
                                                attribute_type:
                                                  'S',
                                              },
                                            ],
                                            key_schema: [
                                              {
                                                attribute_name:
                                                  'id',
                                                key_type:
                                                  'HASH',
                                              },
                                            ],
                                            provisioned_throughput: {
                                              read_capacity_units: 5,
                                              write_capacity_units: 5,
                                            },
                                            table_name: table_name,
                                          };
  
                                          const definition = JSON.stringify(
                                            schema
                                          )
                                            .replace(
                                              /\\n/g,
                                              '\\n'
                                            )
                                            .replace(
                                              /\\'/g,
                                              "\\'"
                                            )
                                            .replace(
                                              /\\"/g,
                                              '\\"'
                                            )
                                            .replace(
                                              /\\&/g,
                                              '\\&'
                                            )
                                            .replace(
                                              /\\r/g,
                                              '\\r'
                                            )
                                            .replace(
                                              /\\t/g,
                                              '\\t'
                                            )
                                            .replace(
                                              /\\b/g,
                                              '\\b'
                                            )
                                            .replace(
                                              /\\f/g,
                                              '\\f'
                                            );
  
                                          const dynamoData = {
                                            habitat_id,
                                            table_name,
                                            definition,
                                          };
  
                                          createDB(dynamoData)
                                            .then(
                                              (response) => {
                                                newCognito(
                                                  name,
                                                  habitat_id
                                                )
                                                  .then(
                                                    (
                                                      response
                                                    ) => {
                                                      const cognitoArn =
                                                        response
                                                          .data
                                                          .arn;
                                                      userPoolId =
                                                        response
                                                          .data
                                                          .user_pool_id;
                                                      const cognitoID =
                                                        response
                                                          .data
                                                          .id;
                                                      createApiGateway(
                                                        `API-GATEWAY-${lambdaName}`,
                                                        habitat_id
                                                      )
                                                        .then(
                                                          (
                                                            response
                                                          ) => {
                                                            const apiId =
                                                              response
                                                                .data
                                                                .api_gateway
                                                                .id;
                                                            const api_id =
                                                              response
                                                                .data
                                                                .api_gateway
                                                                .api_id;
                                                            authorizeCognito(
                                                              habitat_id,
                                                              cognitoArn,
                                                              apiId
                                                            )
                                                              .then(
                                                                (
                                                                  response
                                                                ) => {
                                                                  const api_authorizer =
                                                                    response
                                                                      .data
                                                                      .id;
                                                                  createResource(
                                                                    apiId,
                                                                    habitat_id
                                                                  )
                                                                    .then(
                                                                      (
                                                                        response
                                                                      ) => {
                                                                        deployApi(
                                                                          apiId,
                                                                          habitat_id
                                                                        )
                                                                          .then(
                                                                            (
                                                                              response
                                                                            ) => {
                                                                              const preEnv = {
                                                                                TABLE: table_name,
                                                                              };
                                                                              const env = JSON.stringify(
                                                                                preEnv
                                                                              )
                                                                                .replace(
                                                                                  /\\n/g,
                                                                                  '\\n'
                                                                                )
                                                                                .replace(
                                                                                  /\\'/g,
                                                                                  "\\'"
                                                                                )
                                                                                .replace(
                                                                                  /\\"/g,
                                                                                  '\\"'
                                                                                )
                                                                                .replace(
                                                                                  /\\&/g,
                                                                                  '\\&'
                                                                                )
                                                                                .replace(
                                                                                  /\\r/g,
                                                                                  '\\r'
                                                                                )
                                                                                .replace(
                                                                                  /\\t/g,
                                                                                  '\\t'
                                                                                )
                                                                                .replace(
                                                                                  /\\b/g,
                                                                                  '\\b'
                                                                                )
                                                                                .replace(
                                                                                  /\\f/g,
                                                                                  '\\f'
                                                                                );
                                                                              deployFunc(
                                                                                func.id,
                                                                                env
                                                                              )
                                                                                .then(
                                                                                  (
                                                                                    response
                                                                                  ) => {
                                                                                    let existing_path_id = null;
                                                                                    const lambdas =
                                                                                      response.data;
                                                                                    const lambda_arn =
                                                                                      lambdas[
                                                                                        lambdas.length -
                                                                                          1
                                                                                      ]
                                                                                        .function_arn;
                                                                                    const postData = {
                                                                                      habitat_id,
                                                                                      method:
                                                                                        'POST',
                                                                                      type:
                                                                                        'AWS_PROXY',
                                                                                      lambda_arn,
                                                                                      rol: roleArn,
                                                                                      path_part:
                                                                                        'v1',
                                                                                      api_authorizer,
                                                                                    };
                                                                                    createMethod(
                                                                                      apiId,
                                                                                      postData
                                                                                    )
                                                                                      .then(
                                                                                        (
                                                                                          response
                                                                                        ) => {
                                                                                          const methodInfo =
                                                                                            response
                                                                                              .data
                                                                                              .method;
                                                                                          endpoint = `https://${api_id}.execute-api.${awsCred.region}.amazonaws.com/production${methodInfo.resource}`;
                                                                                          listResources(
                                                                                            apiId,
                                                                                            habitat_id
                                                                                          )
                                                                                            .then(
                                                                                              (
                                                                                                response
                                                                                              ) => {
                                                                                                const resources =
                                                                                                  response
                                                                                                    .data
                                                                                                    .resources;
                                                                                                resources.forEach(
                                                                                                  (
                                                                                                    resource
                                                                                                  ) => {
                                                                                                    if (
                                                                                                      resource.path_part ===
                                                                                                      'v1'
                                                                                                    ) {
                                                                                                      existing_path_id =
                                                                                                        resource.id;
                                                                                                    }
                                                                                                  }
                                                                                                );
                                                                                                const getData = {
                                                                                                  habitat_id,
                                                                                                  method:
                                                                                                    'GET',
                                                                                                  type:
                                                                                                    'AWS_PROXY',
                                                                                                  lambda_arn,
                                                                                                  rol: roleArn,
                                                                                                  existing_path_id,
                                                                                                  path_part:
                                                                                                    '/v1',
                                                                                                  api_authorizer,
                                                                                                };
                                                                                                createMethod(
                                                                                                  apiId,
                                                                                                  getData
                                                                                                )
                                                                                                  .then(
                                                                                                    (
                                                                                                      response
                                                                                                    ) => {
                                                                                                      const putData = {
                                                                                                        habitat_id,
                                                                                                        method:
                                                                                                          'PUT',
                                                                                                        type:
                                                                                                          'AWS_PROXY',
                                                                                                        lambda_arn,
                                                                                                        rol: roleArn,
                                                                                                        existing_path_id,
                                                                                                        path_part:
                                                                                                          '/v1',
                                                                                                        api_authorizer,
                                                                                                      };
                                                                                                      createMethod(
                                                                                                        apiId,
                                                                                                        putData
                                                                                                      )
                                                                                                        .then(
                                                                                                          (
                                                                                                            response
                                                                                                          ) => {
                                                                                                            const deleteData = {
                                                                                                              habitat_id,
                                                                                                              method:
                                                                                                                'DELETE',
                                                                                                              type:
                                                                                                                'AWS_PROXY',
                                                                                                              lambda_arn,
                                                                                                              rol: roleArn,
                                                                                                              existing_path_id,
                                                                                                              path_part:
                                                                                                                '/v1',
                                                                                                              api_authorizer,
                                                                                                            };
                                                                                                            createMethod(
                                                                                                              apiId,
                                                                                                              deleteData
                                                                                                            )
                                                                                                              .then(
                                                                                                                (
                                                                                                                  response
                                                                                                                ) => {
                                                                                                                  deployApi(
                                                                                                                    apiId,
                                                                                                                    habitat_id,
                                                                                                                    'v1'
                                                                                                                  )
                                                                                                                    .then(
                                                                                                                      (
                                                                                                                        response
                                                                                                                      ) => {
                                                                                                                        const responseData = {
                                                                                                                          habitat_id,
                                                                                                                        };
                                                                                                                        createRespose(
                                                                                                                          methodInfo.id,
                                                                                                                          responseData
                                                                                                                        )
                                                                                                                          .then(
                                                                                                                            (
                                                                                                                              response
                                                                                                                            ) => {
                                                                                                                              const templateInfo = {
                                                                                                                                name: name.replace(
                                                                                                                                  /[^A-Z0-9]/gi,
                                                                                                                                  '-'
                                                                                                                                ),
                                                                                                                                domain_name: bucket,
                                                                                                                                repository_id,
                                                                                                                                habitat_id,
                                                                                                                                category:
                                                                                                                                  'web app',
                                                                                                                              };
                                                                                                                              createTemplate(
                                                                                                                                templateInfo
                                                                                                                              )
                                                                                                                                .then(
                                                                                                                                  (
                                                                                                                                    response
                                                                                                                                  ) => {
                                                                                                                                    const payload = {
                                                                                                                                      templates:
                                                                                                                                        response
                                                                                                                                          .data
                                                                                                                                          .templates,
                                                                                                                                    };
                                                                                                                                    const template =
                                                                                                                                      response
                                                                                                                                        .data
                                                                                                                                        .template;
                                                                                                                                    const requestInfo = {
                                                                                                                                      site_id:
                                                                                                                                        template.id,
                                                                                                                                      domain_name,
                                                                                                                                      prefix,
                                                                                                                                      habitat_id,
                                                                                                                                    };
                                                                                                                                    requestDomain(
                                                                                                                                      requestInfo
                                                                                                                                    )
                                                                                                                                      .then(
                                                                                                                                        (
                                                                                                                                          response
                                                                                                                                        ) => {
                                                                                                                                          const cognitoUserInfo = {
                                                                                                                                            habitat_id,
                                                                                                                                            name: prefix,
                                                                                                                                            id: cognitoID,
                                                                                                                                          };
                                                                                                                                          createCognitoUser(
                                                                                                                                            cognitoUserInfo
                                                                                                                                          )
                                                                                                                                            .then(
                                                                                                                                              (
                                                                                                                                                response
                                                                                                                                              ) => {
                                                                                                                                                userPoolClientId =
                                                                                                                                                  response
                                                                                                                                                    .data
                                                                                                                                                    .identity_pool_id;
                                                                                                                                                const config = `window._config = {
  cognito: {
  userPoolId: '${userPoolId}',
  clientId: '${userPoolClientId}',
  region: '${awsCred.region}'
  },
  api: {
  invokeUrl: '${endpoint}',
  }
  };`;
                                                                                                                                                const envInfo = {
                                                                                                                                                  file:
                                                                                                                                                    'config.js',
                                                                                                                                                  bucket: staticBucket,
                                                                                                                                                  habitat_id,
                                                                                                                                                  directory_name,
                                                                                                                                                  content: config,
                                                                                                                                                };
                                                                                                                                                addEnv(
                                                                                                                                                  envInfo
                                                                                                                                                )
                                                                                                                                                  .then(
                                                                                                                                                    (
                                                                                                                                                      response
                                                                                                                                                    ) => {
                                                                                                                                                      const distributionInfo = {
                                                                                                                                                        site_id:
                                                                                                                                                          template.id,
                                                                                                                                                        distribution_name: name.replace(
                                                                                                                                                          /[^A-Z0-9]/gi,
                                                                                                                                                          '-'
                                                                                                                                                        ),
                                                                                                                                                        end_point: `${staticBucket}.s3-website.${awsCred.region}.amazonaws.com`,
                                                                                                                                                        domain_name: staticBucket,
                                                                                                                                                        repository_id,
                                                                                                                                                        habitat_id,
                                                                                                                                                        root_object:
                                                                                                                                                          'index.html',
                                                                                                                                                      };
                                                                                                                                                      contentDistribution(
                                                                                                                                                        distributionInfo
                                                                                                                                                      )
                                                                                                                                                        .then(
                                                                                                                                                          (
                                                                                                                                                            response
                                                                                                                                                          ) => {
                                                                                                                                                            let domainPrefix;
                                                                                                                                                            const cloudFrontDns =
                                                                                                                                                              response
                                                                                                                                                                .data
                                                                                                                                                                .cloud_front_domain;
                                                                                                                                                            prefix.length >
                                                                                                                                                            0
                                                                                                                                                              ? (domainPrefix = `${prefix}.`)
                                                                                                                                                              : (domainPrefix = null);
                                                                                                                                                            const domainInfo = {
                                                                                                                                                              dns_name: cloudFrontDns,
                                                                                                                                                              domain_name,
                                                                                                                                                              type:
                                                                                                                                                                'A',
                                                                                                                                                              prefix: domainPrefix,
                                                                                                                                                              cf_hosted_zone_id:
                                                                                                                                                                'Z2FDTNDATAQYW2',
                                                                                                                                                              habitat_id,
                                                                                                                                                            };
                                                                                                                                                            addDomian(
                                                                                                                                                              domainInfo
                                                                                                                                                            )
                                                                                                                                                              .then(
                                                                                                                                                                (
                                                                                                                                                                  response
                                                                                                                                                                ) => {
                                                                                                                                                                  const upload = {
                                                                                                                                                                    team_id,
                                                                                                                                                                    file_name: repo.project_name,
                                                                                                                                                                    bucket: staticBucket,
                                                                                                                                                                    habitat_id,
                                                                                                                                                                  };
                                                                                                                                                                  uploadFolder(
                                                                                                                                                                    upload
                                                                                                                                                                  )
                                                                                                                                                                    .then(
                                                                                                                                                                      (
                                                                                                                                                                        response
                                                                                                                                                                      ) => {
                                                                                                                                                                        const lambdaRepo = {
                                                                                                                                                                          credential_id: git_credential_id,
                                                                                                                                                                          name: lambdaName
                                                                                                                                                                        }
                                                                                                                                                                        gitCreateRepo(lambdaRepo)
                                                                                                                                                                        .then(response => {
                                                                                                                                                                          let gitLambaURL;
                                                                                                                                                                          if (response.data.repository.http_url_to_repo) {
                                                                                                                                                                            gitLambaURL = response.data.repository.http_url_to_repo;
                                                                                                                                                                          } else {
                                                                                                                                                                            gitLambaURL = `${response.data.repository.html_url}.git`;
                                                                                                                                                                          }
                                                                                                                                                                          const lambdaRemote = {
                                                                                                                                                                            id: funcClone.id,
                                                                                                                                                                            git_credential_id,
                                                                                                                                                                            uri: gitLambaURL
                                                                                                                                                                          }
                                                                                                                                                                          gitRemote(lambdaRemote)
                                                                                                                                                                          .then(response => {
                                                                                                                                                                            const templateRepo = {
                                                                                                                                                                              credential_id: git_credential_id,
                                                                                                                                                                              name: repo.project_name
                                                                                                                                                                            }
                                                                                                                                                                            gitCreateRepo(templateRepo)
                                                                                                                                                                            .then(response => {
                                                                                                                                                                              let gitTemplateURL;
                                                                                                                                                                              if (response.data.repository.http_url_to_repo) {
                                                                                                                                                                                gitTemplateURL = response.data.repository.http_url_to_repo;
                                                                                                                                                                              } else {
                                                                                                                                                                                gitTemplateURL = `${response.data.repository.html_url}.git`;
                                                                                                                                                                              }
                                                                                                                                                                              const templateRemote = {
                                                                                                                                                                                id: repo.id,
                                                                                                                                                                                git_credential_id,
                                                                                                                                                                                uri: gitTemplateURL
                                                                                                                                                                              }
                                                                                                                                                                              gitRemote(templateRemote)
                                                                                                                                                                              .then(response => {
                                                                                                                                                                                const funcHook = {
                                                                                                                                                                                  git_credential_id,
                                                                                                                                                                                  clone_id: funcClone.id
                                                                                                                                                                                }
                                                                                                                                                                                gitCreateHook(funcHook)
                                                                                                                                                                                .then(response => {
                                                                                                                                                                                  const siteHook = {
                                                                                                                                                                                    git_credential_id,
                                                                                                                                                                                    clone_id: repo.id
                                                                                                                                                                                  }
                                                                                                                                                                                  gitCreateHook(siteHook)
                                                                                                                                                                                  .then(response => {
                                                                                                                                                                                    dispatch(
                                                                                                                                                                                      {
                                                                                                                                                                                        type: CREATE_WEB_APP_TEMPLATE_SUCCESS,
                                                                                                                                                                                        payload,
                                                                                                                                                                                      }
                                                                                                                                                                                    );
                                                                                                                                                                                  })
                                                                                                                                                                                  .catch(
                                                                                                                                                                                    (
                                                                                                                                                                                      error
                                                                                                                                                                                    ) => {
                                                                                                                                                                                      const error_message =
                                                                                                                                                                                        'Error at upload folder.';
                                                                                                                                                                                      dispatch(
                                                                                                                                                                                        {
                                                                                                                                                                                          type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                                          error: error.response.data.error,
                                                                                                                                                                                        }
                                                                                                                                                                                      );
                                                                                                                                                                                      rollBack(
                                                                                                                                                                                        rollBackInfo
                                                                                                                                                                                      );
                                                                                                                                                                                    }
                                                                                                                                                                                  );
                                                                                                                                                                                })
                                                                                                                                                                                .catch(
                                                                                                                                                                                  (
                                                                                                                                                                                    error
                                                                                                                                                                                  ) => {
                                                                                                                                                                                    const error_message =
                                                                                                                                                                                      'Error at upload folder.';
                                                                                                                                                                                    dispatch(
                                                                                                                                                                                      {
                                                                                                                                                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                                        error: error.response.data.error,
                                                                                                                                                                                      }
                                                                                                                                                                                    );
                                                                                                                                                                                    rollBack(
                                                                                                                                                                                      rollBackInfo
                                                                                                                                                                                    );
                                                                                                                                                                                  }
                                                                                                                                                                                );
                                                                                                                                                                              })
                                                                                                                                                                              .catch(
                                                                                                                                                                                (
                                                                                                                                                                                  error
                                                                                                                                                                                ) => {
                                                                                                                                                                                  const error_message =
                                                                                                                                                                                    'Error at upload folder.';
                                                                                                                                                                                  dispatch(
                                                                                                                                                                                    {
                                                                                                                                                                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                                      error: error.response.data.error,
                                                                                                                                                                                    }
                                                                                                                                                                                  );
                                                                                                                                                                                  rollBack(
                                                                                                                                                                                    rollBackInfo
                                                                                                                                                                                  );
                                                                                                                                                                                }
                                                                                                                                                                              );
                                                                                                                                                                            })
                                                                                                                                                                            .catch(
                                                                                                                                                                              (
                                                                                                                                                                                error
                                                                                                                                                                              ) => {
                                                                                                                                                                                const error_message =
                                                                                                                                                                                  'Error at upload folder.';
                                                                                                                                                                                dispatch(
                                                                                                                                                                                  {
                                                                                                                                                                                    type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                                    error: error.response.data.error,
                                                                                                                                                                                  }
                                                                                                                                                                                );
                                                                                                                                                                                rollBack(
                                                                                                                                                                                  rollBackInfo
                                                                                                                                                                                );
                                                                                                                                                                              }
                                                                                                                                                                            );
                                                                                                                                                                          })
                                                                                                                                                                          .catch(
                                                                                                                                                                            (
                                                                                                                                                                              error
                                                                                                                                                                            ) => {
                                                                                                                                                                              const error_message =
                                                                                                                                                                                'Error at upload folder.';
                                                                                                                                                                              dispatch(
                                                                                                                                                                                {
                                                                                                                                                                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                                  error: error.response.data.error,
                                                                                                                                                                                }
                                                                                                                                                                              );
                                                                                                                                                                              rollBack(
                                                                                                                                                                                rollBackInfo
                                                                                                                                                                              );
                                                                                                                                                                            }
                                                                                                                                                                          );
                                                                                                                                                                        })
                                                                                                                                                                        .catch(
                                                                                                                                                                          (
                                                                                                                                                                            error
                                                                                                                                                                          ) => {
                                                                                                                                                                            const error_message =
                                                                                                                                                                              'Error at upload folder.';
                                                                                                                                                                            dispatch(
                                                                                                                                                                              {
                                                                                                                                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                                error: error.response.data.error,
                                                                                                                                                                              }
                                                                                                                                                                            );
                                                                                                                                                                            rollBack(
                                                                                                                                                                              rollBackInfo
                                                                                                                                                                            );
                                                                                                                                                                          }
                                                                                                                                                                        );
                                                                                                                                                                      }
                                                                                                                                                                    )
                                                                                                                                                                    .catch(
                                                                                                                                                                      (
                                                                                                                                                                        error
                                                                                                                                                                      ) => {
                                                                                                                                                                        const error_message =
                                                                                                                                                                          'Error at upload folder.';
                                                                                                                                                                        dispatch(
                                                                                                                                                                          {
                                                                                                                                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                            error: error_message,
                                                                                                                                                                          }
                                                                                                                                                                        );
                                                                                                                                                                        rollBack(
                                                                                                                                                                          rollBackInfo
                                                                                                                                                                        );
                                                                                                                                                                      }
                                                                                                                                                                    );
                                                                                                                                                                }
                                                                                                                                                              )
                                                                                                                                                              .catch(
                                                                                                                                                                (
                                                                                                                                                                  error
                                                                                                                                                                ) => {
                                                                                                                                                                  const error_message =
                                                                                                                                                                    'Error at add env file.';
                                                                                                                                                                  dispatch(
                                                                                                                                                                    {
                                                                                                                                                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                      error: error_message,
                                                                                                                                                                    }
                                                                                                                                                                  );
                                                                                                                                                                  rollBack(
                                                                                                                                                                    rollBackInfo
                                                                                                                                                                  );
                                                                                                                                                                }
                                                                                                                                                              );
                                                                                                                                                          }
                                                                                                                                                        )
                                                                                                                                                        .catch(
                                                                                                                                                          (
                                                                                                                                                            error
                                                                                                                                                          ) => {
                                                                                                                                                            const error_message =
                                                                                                                                                              'Error at add env file.';
                                                                                                                                                            dispatch(
                                                                                                                                                              {
                                                                                                                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                error: error_message,
                                                                                                                                                              }
                                                                                                                                                            );
                                                                                                                                                            rollBack(
                                                                                                                                                              rollBackInfo
                                                                                                                                                            );
                                                                                                                                                          }
                                                                                                                                                        );
                                                                                                                                                    }
                                                                                                                                                  )
                                                                                                                                                  .catch(
                                                                                                                                                    (
                                                                                                                                                      error
                                                                                                                                                    ) => {
                                                                                                                                                      const error_message =
                                                                                                                                                        'Error at add env file.';
                                                                                                                                                      dispatch(
                                                                                                                                                        {
                                                                                                                                                          type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                          error: error_message,
                                                                                                                                                        }
                                                                                                                                                      );
                                                                                                                                                      rollBack(
                                                                                                                                                        rollBackInfo
                                                                                                                                                      );
                                                                                                                                                    }
                                                                                                                                                  );
                                                                                                                                              }
                                                                                                                                            )
                                                                                                                                            .catch(
                                                                                                                                              (
                                                                                                                                                error
                                                                                                                                              ) => {
                                                                                                                                                const error_message =
                                                                                                                                                  'Error at create Cognito user.';
                                                                                                                                                dispatch(
                                                                                                                                                  {
                                                                                                                                                    type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                    error: error_message,
                                                                                                                                                  }
                                                                                                                                                );
                                                                                                                                                rollBack(
                                                                                                                                                  rollBackInfo
                                                                                                                                                );
                                                                                                                                              }
                                                                                                                                            );
                                                                                                                                        }
                                                                                                                                      )
                                                                                                                                      .catch(
                                                                                                                                        (
                                                                                                                                          error
                                                                                                                                        ) => {
                                                                                                                                          const error_message =
                                                                                                                                            'Error at create request domain.';
                                                                                                                                          dispatch(
                                                                                                                                            {
                                                                                                                                              type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                              error: error_message,
                                                                                                                                            }
                                                                                                                                          );
                                                                                                                                          rollBack(
                                                                                                                                            rollBackInfo
                                                                                                                                          );
                                                                                                                                        }
                                                                                                                                      );
                                                                                                                                  }
                                                                                                                                )
                                                                                                                                .catch(
                                                                                                                                  (
                                                                                                                                    error
                                                                                                                                  ) => {
                                                                                                                                    const error_message =
                                                                                                                                      'Error at create template.';
                                                                                                                                    dispatch(
                                                                                                                                      {
                                                                                                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                        error: error_message,
                                                                                                                                      }
                                                                                                                                    );
                                                                                                                                    rollBack(
                                                                                                                                      rollBackInfo
                                                                                                                                    );
                                                                                                                                  }
                                                                                                                                );
                                                                                                                            }
                                                                                                                          )
                                                                                                                          .catch(
                                                                                                                            (
                                                                                                                              error
                                                                                                                            ) => {
                                                                                                                              const error_message =
                                                                                                                                'Error at create response API.';
                                                                                                                              dispatch(
                                                                                                                                {
                                                                                                                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                  error: error_message,
                                                                                                                                }
                                                                                                                              );
                                                                                                                              rollBack(
                                                                                                                                rollBackInfo
                                                                                                                              );
                                                                                                                            }
                                                                                                                          );
                                                                                                                      }
                                                                                                                    )
                                                                                                                    .catch(
                                                                                                                      (
                                                                                                                        error
                                                                                                                      ) => {
                                                                                                                        const error_message =
                                                                                                                          'Error at deploy API.';
                                                                                                                        dispatch(
                                                                                                                          {
                                                                                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                            error: error_message,
                                                                                                                          }
                                                                                                                        );
                                                                                                                        rollBack(
                                                                                                                          rollBackInfo
                                                                                                                        );
                                                                                                                      }
                                                                                                                    );
                                                                                                                }
                                                                                                              )
                                                                                                              .catch(
                                                                                                                (
                                                                                                                  error
                                                                                                                ) => {
                                                                                                                  const error_message =
                                                                                                                    'Error at create DELETE method.';
                                                                                                                  dispatch(
                                                                                                                    {
                                                                                                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                      error: error_message,
                                                                                                                    }
                                                                                                                  );
                                                                                                                  rollBack(
                                                                                                                    rollBackInfo
                                                                                                                  );
                                                                                                                }
                                                                                                              );
                                                                                                          }
                                                                                                        )
                                                                                                        .catch(
                                                                                                          (
                                                                                                            error
                                                                                                          ) => {
                                                                                                            const error_message =
                                                                                                              'Error at create PUT method.';
                                                                                                            dispatch(
                                                                                                              {
                                                                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                error: error_message,
                                                                                                              }
                                                                                                            );
                                                                                                            rollBack(
                                                                                                              rollBackInfo
                                                                                                            );
                                                                                                          }
                                                                                                        );
                                                                                                    }
                                                                                                  )
                                                                                                  .catch(
                                                                                                    (
                                                                                                      error
                                                                                                    ) => {
                                                                                                      const error_message =
                                                                                                        'Error at create GET method.';
                                                                                                      dispatch(
                                                                                                        {
                                                                                                          type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                          error: error_message,
                                                                                                        }
                                                                                                      );
                                                                                                      rollBack(
                                                                                                        rollBackInfo
                                                                                                      );
                                                                                                    }
                                                                                                  );
                                                                                              }
                                                                                            )
                                                                                            .catch(
                                                                                              (
                                                                                                error
                                                                                              ) => {
                                                                                                const error_message =
                                                                                                  'Error at list resource.';
                                                                                                dispatch(
                                                                                                  {
                                                                                                    type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                    error: error_message,
                                                                                                  }
                                                                                                );
                                                                                                rollBack(
                                                                                                  rollBackInfo
                                                                                                );
                                                                                              }
                                                                                            );
                                                                                        }
                                                                                      )
                                                                                      .catch(
                                                                                        (
                                                                                          error
                                                                                        ) => {
                                                                                          const error_message =
                                                                                            'Error at create POST method.';
                                                                                          dispatch(
                                                                                            {
                                                                                              type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                              error: error_message,
                                                                                            }
                                                                                          );
                                                                                          rollBack(
                                                                                            rollBackInfo
                                                                                          );
                                                                                        }
                                                                                      );
                                                                                  }
                                                                                )
                                                                                .catch(
                                                                                  (
                                                                                    error
                                                                                  ) => {
                                                                                    const error_message =
                                                                                      'Error at deploy function.';
                                                                                    dispatch(
                                                                                      {
                                                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                        error: error_message,
                                                                                      }
                                                                                    );
                                                                                    rollBack(
                                                                                      rollBackInfo
                                                                                    );
                                                                                  }
                                                                                );
                                                                            }
                                                                          )
                                                                          .catch(
                                                                            (
                                                                              error
                                                                            ) => {
                                                                              const error_message =
                                                                                'Error at deploy API.';
                                                                              dispatch(
                                                                                {
                                                                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                  error: error_message,
                                                                                }
                                                                              );
                                                                              rollBack(
                                                                                rollBackInfo
                                                                              );
                                                                            }
                                                                          );
                                                                      }
                                                                    )
                                                                    .catch(
                                                                      (
                                                                        error
                                                                      ) => {
                                                                        const error_message =
                                                                          'Error at create resource.';
                                                                        dispatch(
                                                                          {
                                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                            error: error_message,
                                                                          }
                                                                        );
                                                                        rollBack(
                                                                          rollBackInfo
                                                                        );
                                                                      }
                                                                    );
                                                                }
                                                              )
                                                              .catch(
                                                                (
                                                                  error
                                                                ) => {
                                                                  const error_message =
                                                                    'Error at create Cognito pool.';
                                                                  dispatch(
                                                                    {
                                                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                      error: error_message,
                                                                    }
                                                                  );
                                                                  rollBack(
                                                                    rollBackInfo
                                                                  );
                                                                }
                                                              );
                                                          }
                                                        )
                                                        .catch(
                                                          (
                                                            error
                                                          ) => {
                                                            const error_message =
                                                              'Error at create API Gateway.';
                                                            dispatch(
                                                              {
                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                error: error_message,
                                                              }
                                                            );
                                                            rollBack(
                                                              rollBackInfo
                                                            );
                                                          }
                                                        );
                                                    }
                                                  )
                                                  .catch(
                                                    (error) => {
                                                      const error_message =
                                                        'Error at create Cognito.';
                                                      dispatch({
                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                        error: error_message,
                                                      });
                                                      rollBack(
                                                        rollBackInfo
                                                      );
                                                    }
                                                  );
                                              }
                                            )
                                            .catch((error) => {
                                              const error_message =
                                                'Error at create Dynamodb.';
                                              dispatch({
                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                error: error_message,
                                              });
                                              rollBack(
                                                rollBackInfo
                                              );
                                            });
                                        })
                                        .catch((error) => {
                                          const error_message =
                                            'Error at build project.';
                                          dispatch({
                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                            error: error_message,
                                          });
                                          rollBack(
                                            rollBackInfo
                                          );
                                        });
                                    })
                                    .catch((error) => {
                                      const error_message =
                                        'Error at create build project.';
                                      dispatch({
                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                        error: error_message,
                                      });
                                      rollBack(rollBackInfo);
                                    });
                                })
                                .catch((error) => {
                                  const error_message =
                                    'Error at create function.';
                                  dispatch({
                                    type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                    error: error_message,
                                  });
                                  rollBack(rollBackInfo);
                                });
                            })
                            .catch((error) => {
                              const error_message =
                                'Error at upload clone to s3 buckets.';
                              dispatch({
                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                error: error_message,
                              });
                              rollBack(rollBackInfo);
                            });
                        })
                        .catch((error) => {
                          const error_message =
                            'Error at create s3 buckets.';
                          dispatch({
                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                            error: error_message,
                          });
                          rollBack(rollBackInfo);
                        });
                    } else {
                      const s3Data = {
                        file_name: lambdaName,
                        team_id,
                        habitat_id,
                      };
                      uploadClone(s3Data)
                        .then((response) => {
                          let data = {
                            lambdaName,
                            runtime: runtimeLambda,
                            handler: handlerLambda,
                            role: roleArn,
                            habitat_id,
                            memory_size: null,
                            timeout: null,
                            code_s3_bucket: bucketName,
                            code_s3_key: `${lambdaName}.zip`,
                          };
                          setFunc(data)
                            .then((response) => {
                              const func =
                                response.data.aws_lambda;
                              const buildData = {
                                habitat_id: func.habitat_id,
                                project_name:
                                  func.function_name,
                                artifac_name: `${func.function_name}.zip`,
                                buildspec: 'buildspec.yml',
                                source_location:
                                  func.function_name,
                                artifac_location:
                                  func.code_s3_bucket,
                                service_role: roleArn,
                                zip: 'ZIP',
                                lambda_id: func.id,
                              };
  
                              createProject(buildData)
                                .then((response) => {
                                  const buildInfo = {
                                    habitat_id: func.habitat_id,
                                    build_id:
                                      response.data.params.id,
                                  };
                                  createBuild(buildInfo)
                                    .then((response) => {
                                      const table_name = lambdaName;
                                      const schema = {
                                        attribute_definitions: [
                                          {
                                            attribute_name:
                                              'id',
                                            attribute_type: 'S',
                                          },
                                        ],
                                        key_schema: [
                                          {
                                            attribute_name:
                                              'id',
                                            key_type: 'HASH',
                                          },
                                        ],
                                        provisioned_throughput: {
                                          read_capacity_units: 5,
                                          write_capacity_units: 5,
                                        },
                                        table_name: table_name,
                                      };
  
                                      const definition = JSON.stringify(
                                        schema
                                      )
                                        .replace(/\\n/g, '\\n')
                                        .replace(/\\'/g, '\\"')
                                        .replace(/\\"/g, '\\"')
                                        .replace(/\\&/g, '\\&')
                                        .replace(/\\r/g, '\\r')
                                        .replace(/\\t/g, '\\t')
                                        .replace(/\\b/g, '\\b')
                                        .replace(/\\f/g, '\\f');
  
                                      const dynamoData = {
                                        habitat_id,
                                        table_name,
                                        definition,
                                      };
  
                                      createDB(dynamoData)
                                        .then((response) => {
                                          newCognito(
                                            name,
                                            habitat_id
                                          )
                                            .then(
                                              (response) => {
                                                const cognitoArn =
                                                  response.data
                                                    .arn;
                                                userPoolId =
                                                  response.data
                                                    .user_pool_id;
                                                const cognitoID =
                                                  response.data
                                                    .id;
                                                createApiGateway(
                                                  `API-GATEWAY-${lambdaName}`,
                                                  habitat_id
                                                )
                                                  .then(
                                                    (
                                                      response
                                                    ) => {
                                                      const apiId =
                                                        response
                                                          .data
                                                          .api_gateway
                                                          .id;
                                                      const api_id =
                                                        response
                                                          .data
                                                          .api_gateway
                                                          .api_id;
                                                      authorizeCognito(
                                                        habitat_id,
                                                        cognitoArn,
                                                        apiId
                                                      )
                                                        .then(
                                                          (
                                                            response
                                                          ) => {
                                                            const api_authorizer =
                                                              response
                                                                .data
                                                                .id;
                                                            createResource(
                                                              apiId,
                                                              habitat_id
                                                            )
                                                              .then(
                                                                (
                                                                  response
                                                                ) => {
                                                                  deployApi(
                                                                    apiId,
                                                                    habitat_id
                                                                  )
                                                                    .then(
                                                                      (
                                                                        response
                                                                      ) => {
                                                                        const preEnv = {
                                                                          TABLE: table_name,
                                                                        };
                                                                        const env = JSON.stringify(
                                                                          preEnv
                                                                        )
                                                                          .replace(
                                                                            /\\n/g,
                                                                            '\\n'
                                                                          )
                                                                          .replace(
                                                                            /\\'/g,
                                                                            '\\"'
                                                                          )
                                                                          .replace(
                                                                            /\\"/g,
                                                                            '\\"'
                                                                          )
                                                                          .replace(
                                                                            /\\&/g,
                                                                            '\\&'
                                                                          )
                                                                          .replace(
                                                                            /\\r/g,
                                                                            '\\r'
                                                                          )
                                                                          .replace(
                                                                            /\\t/g,
                                                                            '\\t'
                                                                          )
                                                                          .replace(
                                                                            /\\b/g,
                                                                            '\\b'
                                                                          )
                                                                          .replace(
                                                                            /\\f/g,
                                                                            '\\f'
                                                                          );
                                                                        deployFunc(
                                                                          func.id,
                                                                          env
                                                                        )
                                                                          .then(
                                                                            (
                                                                              response
                                                                            ) => {
                                                                              let existing_path_id = null;
                                                                              const lambdas =
                                                                                response.data;
                                                                              const lambda_arn =
                                                                                lambdas[
                                                                                  lambdas.length -
                                                                                    1
                                                                                ]
                                                                                  .function_arn;
                                                                              const postData = {
                                                                                habitat_id,
                                                                                method:
                                                                                  'POST',
                                                                                type:
                                                                                  'AWS_PROXY',
                                                                                lambda_arn,
                                                                                rol: roleArn,
                                                                                path_part:
                                                                                  'v1',
                                                                                api_authorizer,
                                                                              };
                                                                              createMethod(
                                                                                apiId,
                                                                                postData
                                                                              )
                                                                                .then(
                                                                                  (
                                                                                    response
                                                                                  ) => {
                                                                                    const methodInfo =
                                                                                      response
                                                                                        .data
                                                                                        .method;
                                                                                    endpoint = `https://${api_id}.execute-api.${awsCred.region}.amazonaws.com/production${methodInfo.resource}`;
                                                                                    listResources(
                                                                                      apiId,
                                                                                      habitat_id
                                                                                    )
                                                                                      .then(
                                                                                        (
                                                                                          response
                                                                                        ) => {
                                                                                          const resources =
                                                                                            response
                                                                                              .data
                                                                                              .resources;
                                                                                          resources.forEach(
                                                                                            (
                                                                                              resource
                                                                                            ) => {
                                                                                              if (
                                                                                                resource.path_part ===
                                                                                                'v1'
                                                                                              ) {
                                                                                                existing_path_id =
                                                                                                  resource.id;
                                                                                              }
                                                                                            }
                                                                                          );
                                                                                          const getData = {
                                                                                            habitat_id,
                                                                                            method:
                                                                                              'GET',
                                                                                            type:
                                                                                              'AWS_PROXY',
                                                                                            lambda_arn,
                                                                                            rol: roleArn,
                                                                                            existing_path_id,
                                                                                            path_part:
                                                                                              '/v1',
                                                                                            api_authorizer,
                                                                                          };
                                                                                          createMethod(
                                                                                            apiId,
                                                                                            getData
                                                                                          )
                                                                                            .then(
                                                                                              (
                                                                                                response
                                                                                              ) => {
                                                                                                const putData = {
                                                                                                  habitat_id,
                                                                                                  method:
                                                                                                    'PUT',
                                                                                                  type:
                                                                                                    'AWS_PROXY',
                                                                                                  lambda_arn,
                                                                                                  rol: roleArn,
                                                                                                  existing_path_id,
                                                                                                  path_part:
                                                                                                    '/v1',
                                                                                                  api_authorizer,
                                                                                                };
                                                                                                createMethod(
                                                                                                  apiId,
                                                                                                  putData
                                                                                                )
                                                                                                  .then(
                                                                                                    (
                                                                                                      response
                                                                                                    ) => {
                                                                                                      const deleteData = {
                                                                                                        habitat_id,
                                                                                                        method:
                                                                                                          'DELETE',
                                                                                                        type:
                                                                                                          'AWS_PROXY',
                                                                                                        lambda_arn,
                                                                                                        rol: roleArn,
                                                                                                        existing_path_id,
                                                                                                        path_part:
                                                                                                          '/v1',
                                                                                                        api_authorizer,
                                                                                                      };
                                                                                                      createMethod(
                                                                                                        apiId,
                                                                                                        deleteData
                                                                                                      )
                                                                                                        .then(
                                                                                                          (
                                                                                                            response
                                                                                                          ) => {
                                                                                                            deployApi(
                                                                                                              apiId,
                                                                                                              habitat_id,
                                                                                                              'v1'
                                                                                                            )
                                                                                                              .then(
                                                                                                                (
                                                                                                                  response
                                                                                                                ) => {
                                                                                                                  const responseData = {
                                                                                                                    habitat_id,
                                                                                                                  };
                                                                                                                  createRespose(
                                                                                                                    methodInfo.id,
                                                                                                                    responseData
                                                                                                                  )
                                                                                                                    .then(
                                                                                                                      (
                                                                                                                        response
                                                                                                                      ) => {
                                                                                                                        const templateInfo = {
                                                                                                                          name: name.replace(
                                                                                                                            /[^A-Z0-9]/gi,
                                                                                                                            '-'
                                                                                                                          ),
                                                                                                                          domain_name: bucket,
                                                                                                                          repository_id,
                                                                                                                          habitat_id,
                                                                                                                          category:
                                                                                                                            'web app',
                                                                                                                        };
                                                                                                                        createTemplate(
                                                                                                                          templateInfo
                                                                                                                        )
                                                                                                                          .then(
                                                                                                                            (
                                                                                                                              response
                                                                                                                            ) => {
                                                                                                                              const payload = {
                                                                                                                                templates:
                                                                                                                                  response
                                                                                                                                    .data
                                                                                                                                    .templates
                                                                                                                              };
                                                                                                                              const template =
                                                                                                                                response
                                                                                                                                  .data
                                                                                                                                  .template;
                                                                                                                              const requestInfo = {
                                                                                                                                site_id:
                                                                                                                                  template.id,
                                                                                                                                domain_name,
                                                                                                                                prefix,
                                                                                                                                habitat_id,
                                                                                                                              };
                                                                                                                              requestDomain(
                                                                                                                                requestInfo
                                                                                                                              )
                                                                                                                                .then(
                                                                                                                                  (
                                                                                                                                    response
                                                                                                                                  ) => {
                                                                                                                                    const cognitoUserInfo = {
                                                                                                                                      habitat_id,
                                                                                                                                      name: prefix,
                                                                                                                                      id: cognitoID,
                                                                                                                                    };
                                                                                                                                    createCognitoUser(
                                                                                                                                      cognitoUserInfo
                                                                                                                                    )
                                                                                                                                      .then(
                                                                                                                                        (
                                                                                                                                          response
                                                                                                                                        ) => {
                                                                                                                                          userPoolClientId =
                                                                                                                                            response
                                                                                                                                              .data
                                                                                                                                              .identity_pool_id;
                                                                                                                                          const config = `window._config = {
  cognito: {
  userPoolId: '${userPoolId}',
  clientId: '${userPoolClientId}',
  region: '${awsCred.region}'
  },
  api: {
  invokeUrl: '${endpoint}',
  }
  };`;
                                                                                                                                          const envInfo = {
                                                                                                                                            file:
                                                                                                                                              'config.js',
                                                                                                                                            bucket: staticBucket,
                                                                                                                                            habitat_id,
                                                                                                                                            directory_name,
                                                                                                                                            content: config,
                                                                                                                                          };
                                                                                                                                          addEnv(
                                                                                                                                            envInfo
                                                                                                                                          )
                                                                                                                                            .then(
                                                                                                                                              (
                                                                                                                                                response
                                                                                                                                              ) => {
                                                                                                                                                const distributionInfo = {
                                                                                                                                                  site_id:
                                                                                                                                                    template.id,
                                                                                                                                                  distribution_name: name.replace(
                                                                                                                                                    /[^A-Z0-9]/gi,
                                                                                                                                                    '-'
                                                                                                                                                  ),
                                                                                                                                                  end_point: `${staticBucket}.s3-website.${awsCred.region}.amazonaws.com`,
                                                                                                                                                  domain_name: staticBucket,
                                                                                                                                                  repository_id,
                                                                                                                                                  habitat_id,
                                                                                                                                                  root_object:
                                                                                                                                                    'index.html',
                                                                                                                                                };
                                                                                                                                                contentDistribution(
                                                                                                                                                  distributionInfo
                                                                                                                                                )
                                                                                                                                                  .then(
                                                                                                                                                    (
                                                                                                                                                      response
                                                                                                                                                    ) => {
                                                                                                                                                      let domainPrefix;
                                                                                                                                                      const cloudFrontDns =
                                                                                                                                                        response
                                                                                                                                                          .data
                                                                                                                                                          .cloud_front_domain;
                                                                                                                                                      prefix.length >
                                                                                                                                                      0
                                                                                                                                                        ? (domainPrefix = `${prefix}.`)
                                                                                                                                                        : (domainPrefix = null);
                                                                                                                                                      const domainInfo = {
                                                                                                                                                        dns_name: cloudFrontDns,
                                                                                                                                                        domain_name,
                                                                                                                                                        type:
                                                                                                                                                          'A',
                                                                                                                                                        prefix: domainPrefix,
                                                                                                                                                        cf_hosted_zone_id:
                                                                                                                                                          'Z2FDTNDATAQYW2',
                                                                                                                                                        habitat_id,
                                                                                                                                                      };
                                                                                                                                                      addDomian(
                                                                                                                                                        domainInfo
                                                                                                                                                      )
                                                                                                                                                        .then(
                                                                                                                                                          (
                                                                                                                                                            response
                                                                                                                                                          ) => {
                                                                                                                                                            const upload = {
                                                                                                                                                              team_id,
                                                                                                                                                              file_name: repo.project_name,
                                                                                                                                                              bucket: staticBucket,
                                                                                                                                                              habitat_id,
                                                                                                                                                            };
                                                                                                                                                            uploadFolder(
                                                                                                                                                              upload
                                                                                                                                                            )
                                                                                                                                                              .then(
                                                                                                                                                                (
                                                                                                                                                                  response
                                                                                                                                                                ) => {
                                                                                                                                                                  gitCreateRepo(lambdaRepo)
                                                                                                                                                                  .then(response => {
                                                                                                                                                                    let gitLambaURL;
                                                                                                                                                                    if (response.data.repository.http_url_to_repo) {
                                                                                                                                                                      gitLambaURL = response.data.repository.http_url_to_repo;
                                                                                                                                                                    } else {
                                                                                                                                                                      gitLambaURL = `${response.data.repository.html_url}.git`;
                                                                                                                                                                    }
                                                                                                                                                                    const lambdaRemote = {
                                                                                                                                                                      id: funcClone.id,
                                                                                                                                                                      git_credential_id,
                                                                                                                                                                      uri: gitLambaURL
                                                                                                                                                                    }
                                                                                                                                                                    gitRemote(lambdaRemote)
                                                                                                                                                                    .then(response => {
                                                                                                                                                                      const templateRepo = {
                                                                                                                                                                        credential_id: git_credential_id,
                                                                                                                                                                        name: repo.project_name
                                                                                                                                                                      }
                                                                                                                                                                      gitCreateRepo(templateRepo)
                                                                                                                                                                      .then(response => {
                                                                                                                                                                        let gitTemplateURL;
                                                                                                                                                                        if (response.data.repository.http_url_to_repo) {
                                                                                                                                                                          gitTemplateURL = response.data.repository.http_url_to_repo;
                                                                                                                                                                        } else {
                                                                                                                                                                          gitTemplateURL = `${response.data.repository.html_url}.git`;
                                                                                                                                                                        }
                                                                                                                                                                        const templateRemote = {
                                                                                                                                                                          id: repo.id,
                                                                                                                                                                          git_credential_id,
                                                                                                                                                                          uri: gitTemplateURL
                                                                                                                                                                        }
                                                                                                                                                                        gitRemote(templateRemote)
                                                                                                                                                                        .then(response => {
                                                                                                                                                                          const funcHook = {
                                                                                                                                                                            git_credential_id,
                                                                                                                                                                            clone_id: funcClone.id
                                                                                                                                                                          }
                                                                                                                                                                          gitCreateHook(funcHook)
                                                                                                                                                                          .then(response => {
                                                                                                                                                                            const siteHook = {
                                                                                                                                                                              git_credential_id,
                                                                                                                                                                              clone_id: repo.id
                                                                                                                                                                            }
                                                                                                                                                                            gitCreateHook(siteHook)
                                                                                                                                                                            .then(response => {
                                                                                                                                                                              dispatch(
                                                                                                                                                                                {
                                                                                                                                                                                  type: CREATE_WEB_APP_TEMPLATE_SUCCESS,
                                                                                                                                                                                  payload,
                                                                                                                                                                                }
                                                                                                                                                                              );
                                                                                                                                                                            })
                                                                                                                                                                            .catch(
                                                                                                                                                                              (
                                                                                                                                                                                error
                                                                                                                                                                              ) => {
                                                                                                                                                                                const error_message =
                                                                                                                                                                                  'Error at upload folder.';
                                                                                                                                                                                dispatch(
                                                                                                                                                                                  {
                                                                                                                                                                                    type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                                    error: error.response.data.error,
                                                                                                                                                                                  }
                                                                                                                                                                                );
                                                                                                                                                                                rollBack(
                                                                                                                                                                                  rollBackInfo
                                                                                                                                                                                );
                                                                                                                                                                              }
                                                                                                                                                                            );
                                                                                                                                                                          })
                                                                                                                                                                          .catch(
                                                                                                                                                                            (
                                                                                                                                                                              error
                                                                                                                                                                            ) => {
                                                                                                                                                                              const error_message =
                                                                                                                                                                                'Error at upload folder.';
                                                                                                                                                                              dispatch(
                                                                                                                                                                                {
                                                                                                                                                                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                                  error: error.response.data.error,
                                                                                                                                                                                }
                                                                                                                                                                              );
                                                                                                                                                                              rollBack(
                                                                                                                                                                                rollBackInfo
                                                                                                                                                                              );
                                                                                                                                                                            }
                                                                                                                                                                          );
                                                                                                                                                                        })
                                                                                                                                                                        .catch(
                                                                                                                                                                          (
                                                                                                                                                                            error
                                                                                                                                                                          ) => {
                                                                                                                                                                            const error_message =
                                                                                                                                                                              'Error at upload folder.';
                                                                                                                                                                            dispatch(
                                                                                                                                                                              {
                                                                                                                                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                                error: error.response.data.error,
                                                                                                                                                                              }
                                                                                                                                                                            );
                                                                                                                                                                            rollBack(
                                                                                                                                                                              rollBackInfo
                                                                                                                                                                            );
                                                                                                                                                                          }
                                                                                                                                                                        );
                                                                                                                                                                      })
                                                                                                                                                                      .catch(
                                                                                                                                                                        (
                                                                                                                                                                          error
                                                                                                                                                                        ) => {
                                                                                                                                                                          const error_message =
                                                                                                                                                                            'Error at upload folder.';
                                                                                                                                                                          dispatch(
                                                                                                                                                                            {
                                                                                                                                                                              type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                              error: error.response.data.error,
                                                                                                                                                                            }
                                                                                                                                                                          );
                                                                                                                                                                          rollBack(
                                                                                                                                                                            rollBackInfo
                                                                                                                                                                          );
                                                                                                                                                                        }
                                                                                                                                                                      );
                                                                                                                                                                    })
                                                                                                                                                                    .catch(
                                                                                                                                                                      (
                                                                                                                                                                        error
                                                                                                                                                                      ) => {
                                                                                                                                                                        const error_message =
                                                                                                                                                                          'Error at upload folder.';
                                                                                                                                                                        dispatch(
                                                                                                                                                                          {
                                                                                                                                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                            error: error.response.data.error,
                                                                                                                                                                          }
                                                                                                                                                                        );
                                                                                                                                                                        rollBack(
                                                                                                                                                                          rollBackInfo
                                                                                                                                                                        );
                                                                                                                                                                      }
                                                                                                                                                                    );
                                                                                                                                                                  })
                                                                                                                                                                  .catch(
                                                                                                                                                                    (
                                                                                                                                                                      error
                                                                                                                                                                    ) => {
                                                                                                                                                                      const error_message =
                                                                                                                                                                        'Error at upload folder.';
                                                                                                                                                                      dispatch(
                                                                                                                                                                        {
                                                                                                                                                                          type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                          error: error.response.data.error,
                                                                                                                                                                        }
                                                                                                                                                                      );
                                                                                                                                                                      rollBack(
                                                                                                                                                                        rollBackInfo
                                                                                                                                                                      );
                                                                                                                                                                    }
                                                                                                                                                                  );
                                                                                                                                                                }
                                                                                                                                                              )
                                                                                                                                                              .catch(
                                                                                                                                                                (
                                                                                                                                                                  error
                                                                                                                                                                ) => {
                                                                                                                                                                  const error_message =
                                                                                                                                                                    'Error at upload folder.';
                                                                                                                                                                  dispatch(
                                                                                                                                                                    {
                                                                                                                                                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                      error: error_message,
                                                                                                                                                                    }
                                                                                                                                                                  );
                                                                                                                                                                  rollBack(
                                                                                                                                                                    rollBackInfo
                                                                                                                                                                  );
                                                                                                                                                                }
                                                                                                                                                              );
                                                                                                                                                          }
                                                                                                                                                        )
                                                                                                                                                        .catch(
                                                                                                                                                          (
                                                                                                                                                            error
                                                                                                                                                          ) => {
                                                                                                                                                            const error_message =
                                                                                                                                                              'Error at add env file.';
                                                                                                                                                            dispatch(
                                                                                                                                                              {
                                                                                                                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                                error: error_message,
                                                                                                                                                              }
                                                                                                                                                            );
                                                                                                                                                            rollBack(
                                                                                                                                                              rollBackInfo
                                                                                                                                                            );
                                                                                                                                                          }
                                                                                                                                                        );
                                                                                                                                                    }
                                                                                                                                                  )
                                                                                                                                                  .catch(
                                                                                                                                                    (
                                                                                                                                                      error
                                                                                                                                                    ) => {
                                                                                                                                                      const error_message =
                                                                                                                                                        'Error at add env file.';
                                                                                                                                                      dispatch(
                                                                                                                                                        {
                                                                                                                                                          type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                          error: error_message,
                                                                                                                                                        }
                                                                                                                                                      );
                                                                                                                                                      rollBack(
                                                                                                                                                        rollBackInfo
                                                                                                                                                      );
                                                                                                                                                    }
                                                                                                                                                  );
                                                                                                                                              }
                                                                                                                                            )
                                                                                                                                            .catch(
                                                                                                                                              (
                                                                                                                                                error
                                                                                                                                              ) => {
                                                                                                                                                const error_message =
                                                                                                                                                  'Error at add env file.';
                                                                                                                                                dispatch(
                                                                                                                                                  {
                                                                                                                                                    type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                                    error: error_message,
                                                                                                                                                  }
                                                                                                                                                );
                                                                                                                                                rollBack(
                                                                                                                                                  rollBackInfo
                                                                                                                                                );
                                                                                                                                              }
                                                                                                                                            );
                                                                                                                                        }
                                                                                                                                      )
                                                                                                                                      .catch(
                                                                                                                                        (
                                                                                                                                          error
                                                                                                                                        ) => {
                                                                                                                                          const error_message =
                                                                                                                                            'Error at create Cognito user.';
                                                                                                                                          dispatch(
                                                                                                                                            {
                                                                                                                                              type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                              error: error_message,
                                                                                                                                            }
                                                                                                                                          );
                                                                                                                                          rollBack(
                                                                                                                                            rollBackInfo
                                                                                                                                          );
                                                                                                                                        }
                                                                                                                                      );
                                                                                                                                  }
                                                                                                                                )
                                                                                                                                .catch(
                                                                                                                                  (
                                                                                                                                    error
                                                                                                                                  ) => {
                                                                                                                                    const error_message =
                                                                                                                                      'Error at create request domain.';
                                                                                                                                    dispatch(
                                                                                                                                      {
                                                                                                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                        error: error_message,
                                                                                                                                      }
                                                                                                                                    );
                                                                                                                                    rollBack(
                                                                                                                                      rollBackInfo
                                                                                                                                    );
                                                                                                                                  }
                                                                                                                                );
                                                                                                                            }
                                                                                                                          )
                                                                                                                          .catch(
                                                                                                                            (
                                                                                                                              error
                                                                                                                            ) => {
                                                                                                                              const error_message =
                                                                                                                                'Error at create template.';
                                                                                                                              dispatch(
                                                                                                                                {
                                                                                                                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                                  error: error_message,
                                                                                                                                }
                                                                                                                              );
                                                                                                                              rollBack(
                                                                                                                                rollBackInfo
                                                                                                                              );
                                                                                                                            }
                                                                                                                          );
                                                                                                                      }
                                                                                                                    )
                                                                                                                    .catch(
                                                                                                                      (
                                                                                                                        error
                                                                                                                      ) => {
                                                                                                                        const error_message =
                                                                                                                          'Error at create response API.';
                                                                                                                        dispatch(
                                                                                                                          {
                                                                                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                            error: error_message,
                                                                                                                          }
                                                                                                                        );
                                                                                                                        rollBack(
                                                                                                                          rollBackInfo
                                                                                                                        );
                                                                                                                      }
                                                                                                                    );
                                                                                                                }
                                                                                                              )
                                                                                                              .catch(
                                                                                                                (
                                                                                                                  error
                                                                                                                ) => {
                                                                                                                  const error_message =
                                                                                                                    'Error at deploy API.';
                                                                                                                  dispatch(
                                                                                                                    {
                                                                                                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                      error: error_message,
                                                                                                                    }
                                                                                                                  );
                                                                                                                  rollBack(
                                                                                                                    rollBackInfo
                                                                                                                  );
                                                                                                                }
                                                                                                              );
                                                                                                          }
                                                                                                        )
                                                                                                        .catch(
                                                                                                          (
                                                                                                            error
                                                                                                          ) => {
                                                                                                            const error_message =
                                                                                                              'Error at create DELETE method.';
                                                                                                            dispatch(
                                                                                                              {
                                                                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                                error: error_message,
                                                                                                              }
                                                                                                            );
                                                                                                            rollBack(
                                                                                                              rollBackInfo
                                                                                                            );
                                                                                                          }
                                                                                                        );
                                                                                                    }
                                                                                                  )
                                                                                                  .catch(
                                                                                                    (
                                                                                                      error
                                                                                                    ) => {
                                                                                                      const error_message =
                                                                                                        'Error at create PUT method.';
                                                                                                      dispatch(
                                                                                                        {
                                                                                                          type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                          error: error_message,
                                                                                                        }
                                                                                                      );
                                                                                                      rollBack(
                                                                                                        rollBackInfo
                                                                                                      );
                                                                                                    }
                                                                                                  );
                                                                                              }
                                                                                            )
                                                                                            .catch(
                                                                                              (
                                                                                                error
                                                                                              ) => {
                                                                                                const error_message =
                                                                                                  'Error at create GET method.';
                                                                                                dispatch(
                                                                                                  {
                                                                                                    type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                                    error: error_message,
                                                                                                  }
                                                                                                );
                                                                                                rollBack(
                                                                                                  rollBackInfo
                                                                                                );
                                                                                              }
                                                                                            );
                                                                                        }
                                                                                      )
                                                                                      .catch(
                                                                                        (
                                                                                          error
                                                                                        ) => {
                                                                                          const error_message =
                                                                                            'Error at list resource.';
                                                                                          dispatch(
                                                                                            {
                                                                                              type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                              error: error_message,
                                                                                            }
                                                                                          );
                                                                                          rollBack(
                                                                                            rollBackInfo
                                                                                          );
                                                                                        }
                                                                                      );
                                                                                  }
                                                                                )
                                                                                .catch(
                                                                                  (
                                                                                    error
                                                                                  ) => {
                                                                                    const error_message =
                                                                                      'Error at create POST method.';
                                                                                    dispatch(
                                                                                      {
                                                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                        error: error_message,
                                                                                      }
                                                                                    );
                                                                                    rollBack(
                                                                                      rollBackInfo
                                                                                    );
                                                                                  }
                                                                                );
                                                                            }
                                                                          )
                                                                          .catch(
                                                                            (
                                                                              error
                                                                            ) => {
                                                                              const error_message =
                                                                                'Error at deploy function.';
                                                                              dispatch(
                                                                                {
                                                                                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                                  error: error_message,
                                                                                }
                                                                              );
                                                                              rollBack(
                                                                                rollBackInfo
                                                                              );
                                                                            }
                                                                          );
                                                                      }
                                                                    )
                                                                    .catch(
                                                                      (
                                                                        error
                                                                      ) => {
                                                                        const error_message =
                                                                          'Error at deploy API.';
                                                                        dispatch(
                                                                          {
                                                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                            error: error_message,
                                                                          }
                                                                        );
                                                                        rollBack(
                                                                          rollBackInfo
                                                                        );
                                                                      }
                                                                    );
                                                                }
                                                              )
                                                              .catch(
                                                                (
                                                                  error
                                                                ) => {
                                                                  const error_message =
                                                                    'Error at create resource.';
                                                                  dispatch(
                                                                    {
                                                                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                      error: error_message,
                                                                    }
                                                                  );
                                                                  rollBack(
                                                                    rollBackInfo
                                                                  );
                                                                }
                                                              );
                                                          }
                                                        )
                                                        .catch(
                                                          (
                                                            error
                                                          ) => {
                                                            const error_message =
                                                              'Error at create Cognito pool.';
                                                            dispatch(
                                                              {
                                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                                error: error_message,
                                                              }
                                                            );
                                                            rollBack(
                                                              rollBackInfo
                                                            );
                                                          }
                                                        );
                                                    }
                                                  )
                                                  .catch(
                                                    (error) => {
                                                      const error_message =
                                                        'Error at create API Gateway.';
                                                      dispatch({
                                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                        error: error_message,
                                                      });
                                                      rollBack(
                                                        rollBackInfo
                                                      );
                                                    }
                                                  );
                                              }
                                            )
                                            .catch((error) => {
                                              const error_message =
                                                'Error at create Cognito.';
                                              dispatch({
                                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                                error: error_message,
                                              });
                                              rollBack(
                                                rollBackInfo
                                              );
                                            });
                                        })
                                        .catch((error) => {
                                          const error_message =
                                            'Error at create Dynamodb.';
                                          dispatch({
                                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                            error: error_message,
                                          });
                                          rollBack(
                                            rollBackInfo
                                          );
                                        });
                                    })
                                    .catch((error) => {
                                      const error_message =
                                        'Error at build project.';
                                      dispatch({
                                        type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                        error: error_message,
                                      });
                                      rollBack(rollBackInfo);
                                    });
                                })
                                .catch((error) => {
                                  const error_message =
                                    'Error at create build project.';
                                  dispatch({
                                    type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                    error: error_message,
                                  });
                                  rollBack(rollBackInfo);
                                });
                            })
                            .catch((error) => {
                              const error_message =
                                'Error at create function.';
                              dispatch({
                                type: CREATE_WEB_APP_TEMPLATE_ERROR,
                                error: error_message,
                              });
                              rollBack(rollBackInfo);
                            });
                        })
                        .catch((error) => {
                          const error_message =
                            'Error at upload clone to s3 buckets.';
                          dispatch({
                            type: CREATE_WEB_APP_TEMPLATE_ERROR,
                            error: error_message,
                          });
                          rollBack(rollBackInfo);
                        });
                    }
                  })
                  .catch((error) => {
                    const error_message =
                      'Error at get s3 buckets.';
                    dispatch({
                      type: CREATE_WEB_APP_TEMPLATE_ERROR,
                      error: error_message,
                    });
                    rollBack(rollBackInfo);
                  });
              })
              .catch((error) => {
                const error_message =
                  'Error at clone lambda function.';
                dispatch({
                  type: CREATE_WEB_APP_TEMPLATE_ERROR,
                  error: error_message,
                });
                rollBack(rollBackInfo);
              });
          })
          .catch((error) => {
            const error_message = 'Error at clone static site.';
            dispatch({
              type: CREATE_WEB_APP_TEMPLATE_ERROR,
              error: error_message,
            });
            rollBack(rollBackInfo);
          });
      })
      .catch((error) => {
        const error_message = 'Error at add static bucket.';
        dispatch({
          type: CREATE_WEB_APP_TEMPLATE_ERROR,
          error: error_message,
        });
        rollBack(rollBackInfo);
      });
    })
    .catch((error) => {
      const error_message = 'Error at add static bucket.';
      dispatch({
        type: CREATE_WEB_APP_TEMPLATE_ERROR,
        error: error.response.data.error,
      });
      rollBack(rollBackInfo);
    });
  };
};

export const deployWebAppWithCredsWithoutDomain = (
  uri,
  name,
  prefix,
  team_id,
  awsCred,
  habitat_id,
  domain_name,
  git_credential_id
) => {
  return (dispatch) => {
    const date = Date.now();
    const bucket = `frontend-web-app-${date}`;
    const staticBucket = bucket;
    let endpoint;
    let userPoolId;
    let userPoolClientId;
    const lambdaName = `KOR-LAMBDA-TEMPLATE-${date}`;
    const runtimeLambda = 'nodejs12.x';
    const handlerLambda = 'index.handler';
    const habitat = {
      name,
      team_id: team_id,
    };

    dispatch({
      type: CREATE_WEB_APP_WITHOUTDOMAIN_BEGIN,
      loading_message: 'Creating template...',
    });
    const s3Static = {
      bucket,
      habitat_id,
      region: awsCred.region,
      public: 1,
    };

    let rollBackInfo = {
      habitat_id,
      rol_id: null,
      git_site: null,
      git_lambda: null,
    };
    addStaticBucket(s3Static)
      .then((response) => {
        getAWSRoles(habitat_id)
          .then((response) => {
            const awsRoles = response.data;
            const roleArn = awsRoles[awsRoles.length - 1].arn;
            rollBackInfo.rol_id = awsRoles[awsRoles.length - 1].id;
            const gitCloneInfo = {
              uri,
              project_name: staticBucket,
              team_id,
              git_credential_id: '',
            };
            gitClone(gitCloneInfo)
              .then((response) => {
                const repo = response.data.repo;
                const repository_id = repo.id;
                const directory_name = repo.directory_name;
                const funcCloneInfo = {
                  uri:
                    'https://gitlab.com/kor-comunity/kor-crud-lambda.git',
                  project_name: lambdaName,
                  team_id,
                  git_credential_id: '',
                };
                rollBackInfo.git_site = repository_id;
                gitClone(funcCloneInfo)
                  .then((response) => {
                    const funcClone = response.data.repo;
                    getBuckets(habitat_id)
                      .then((response) => {
                        const s3Buckets = response.data;
                        const bucketName = `kor-lambda-${habitat_id}`;
                        let bucket;
                        if (s3Buckets) {
                          bucket = s3Buckets.find(
                            (bucket) => bucket.bucket === bucketName
                          );
                        }

                        if (!bucket) {
                          addBucket(
                            bucketName,
                            habitat_id,
                            awsCred.region
                          )
                            .then((response) => {
                              const s3Data = {
                                file_name: lambdaName,
                                team_id,
                                habitat_id,
                              };
                              uploadClone(s3Data)
                                .then((response) => {
                                  let data = {
                                    function_name: lambdaName,
                                    runtime: runtimeLambda,
                                    handler: handlerLambda,
                                    role: roleArn,
                                    habitat_id,
                                    memory_size: 1024,
                                    timeout: 25,
                                    code_s3_bucket: bucketName,
                                    code_s3_key: `${lambdaName}.zip`,
                                  };
                                  setFunc(data)
                                    .then((response) => {
                                      const func =
                                        response.data.aws_lambda;
                                      const buildData = {
                                        habitat_id: func.habitat_id,
                                        project_name:
                                          func.function_name,
                                        artifac_name: `${func.function_name}.zip`,
                                        buildspec: 'buildspec.yml',
                                        source_location:
                                          func.function_name,
                                        artifac_location:
                                          func.code_s3_bucket,
                                        service_role: roleArn,
                                        zip: 'ZIP',
                                        lambda_id: func.id,
                                      };

                                      createProject(buildData)
                                        .then((response) => {
                                          const buildInfo = {
                                            habitat_id:
                                              func.habitat_id,
                                            build_id:
                                              response.data.params
                                                .id,
                                          };
                                          createBuild(buildInfo)
                                            .then((response) => {
                                              const table_name = lambdaName;
                                              const schema = {
                                                attribute_definitions: [
                                                  {
                                                    attribute_name:
                                                      'id',
                                                    attribute_type:
                                                      'S',
                                                  },
                                                ],
                                                key_schema: [
                                                  {
                                                    attribute_name:
                                                      'id',
                                                    key_type:
                                                      'HASH',
                                                  },
                                                ],
                                                provisioned_throughput: {
                                                  read_capacity_units: 5,
                                                  write_capacity_units: 5,
                                                },
                                                table_name: table_name,
                                              };

                                              const definition = JSON.stringify(
                                                schema
                                              )
                                                .replace(
                                                  /\\n/g,
                                                  '\\n'
                                                )
                                                .replace(
                                                  /\\'/g,
                                                  "\\'"
                                                )
                                                .replace(
                                                  /\\"/g,
                                                  '\\"'
                                                )
                                                .replace(
                                                  /\\&/g,
                                                  '\\&'
                                                )
                                                .replace(
                                                  /\\r/g,
                                                  '\\r'
                                                )
                                                .replace(
                                                  /\\t/g,
                                                  '\\t'
                                                )
                                                .replace(
                                                  /\\b/g,
                                                  '\\b'
                                                )
                                                .replace(
                                                  /\\f/g,
                                                  '\\f'
                                                );

                                              const dynamoData = {
                                                habitat_id,
                                                table_name,
                                                definition,
                                              };

                                              createDB(dynamoData)
                                                .then(
                                                  (response) => {
                                                    newCognito(
                                                      name,
                                                      habitat_id
                                                    )
                                                      .then(
                                                        (
                                                          response
                                                        ) => {
                                                          const cognitoArn =
                                                            response
                                                              .data
                                                              .arn;
                                                          userPoolId =
                                                            response
                                                              .data
                                                              .user_pool_id;
                                                          const cognitoID =
                                                            response
                                                              .data
                                                              .id;
                                                          createApiGateway(
                                                            `API-GATEWAY-${lambdaName}`,
                                                            habitat_id
                                                          )
                                                            .then(
                                                              (
                                                                response
                                                              ) => {
                                                                const apiId =
                                                                  response
                                                                    .data
                                                                    .api_gateway
                                                                    .id;
                                                                const api_id =
                                                                  response
                                                                    .data
                                                                    .api_gateway
                                                                    .api_id;
                                                                authorizeCognito(
                                                                  habitat_id,
                                                                  cognitoArn,
                                                                  apiId
                                                                )
                                                                  .then(
                                                                    (
                                                                      response
                                                                    ) => {
                                                                      const api_authorizer =
                                                                        response
                                                                          .data
                                                                          .id;
                                                                      createResource(
                                                                        apiId,
                                                                        habitat_id
                                                                      )
                                                                        .then(
                                                                          (
                                                                            response
                                                                          ) => {
                                                                            deployApi(
                                                                              apiId,
                                                                              habitat_id
                                                                            )
                                                                              .then(
                                                                                (
                                                                                  response
                                                                                ) => {
                                                                                  const preEnv = {
                                                                                    TABLE: table_name,
                                                                                  };
                                                                                  const env = JSON.stringify(
                                                                                    preEnv
                                                                                  )
                                                                                    .replace(
                                                                                      /\\n/g,
                                                                                      '\\n'
                                                                                    )
                                                                                    .replace(
                                                                                      /\\'/g,
                                                                                      "\\'"
                                                                                    )
                                                                                    .replace(
                                                                                      /\\"/g,
                                                                                      '\\"'
                                                                                    )
                                                                                    .replace(
                                                                                      /\\&/g,
                                                                                      '\\&'
                                                                                    )
                                                                                    .replace(
                                                                                      /\\r/g,
                                                                                      '\\r'
                                                                                    )
                                                                                    .replace(
                                                                                      /\\t/g,
                                                                                      '\\t'
                                                                                    )
                                                                                    .replace(
                                                                                      /\\b/g,
                                                                                      '\\b'
                                                                                    )
                                                                                    .replace(
                                                                                      /\\f/g,
                                                                                      '\\f'
                                                                                    );
                                                                                  deployFunc(
                                                                                    func.id,
                                                                                    env
                                                                                  )
                                                                                    .then(
                                                                                      (
                                                                                        response
                                                                                      ) => {
                                                                                        let existing_path_id = null;
                                                                                        const lambdas =
                                                                                          response.data;
                                                                                        const lambda_arn =
                                                                                          lambdas[
                                                                                            lambdas.length -
                                                                                              1
                                                                                          ]
                                                                                            .function_arn;
                                                                                        const postData = {
                                                                                          habitat_id,
                                                                                          method:
                                                                                            'POST',
                                                                                          type:
                                                                                            'AWS_PROXY',
                                                                                          lambda_arn,
                                                                                          rol: roleArn,
                                                                                          path_part:
                                                                                            'v1',
                                                                                          api_authorizer,
                                                                                        };
                                                                                        createMethod(
                                                                                          apiId,
                                                                                          postData
                                                                                        )
                                                                                          .then(
                                                                                            (
                                                                                              response
                                                                                            ) => {
                                                                                              const methodInfo =
                                                                                                response
                                                                                                  .data
                                                                                                  .method;
                                                                                              endpoint = `https://${api_id}.execute-api.${awsCred.region}.amazonaws.com/production${methodInfo.resource}`;
                                                                                              listResources(
                                                                                                apiId,
                                                                                                habitat_id
                                                                                              )
                                                                                                .then(
                                                                                                  (
                                                                                                    response
                                                                                                  ) => {
                                                                                                    const resources =
                                                                                                      response
                                                                                                        .data
                                                                                                        .resources;
                                                                                                    resources.forEach(
                                                                                                      (
                                                                                                        resource
                                                                                                      ) => {
                                                                                                        if (
                                                                                                          resource.path_part ===
                                                                                                          'v1'
                                                                                                        ) {
                                                                                                          existing_path_id =
                                                                                                            resource.id;
                                                                                                        }
                                                                                                      }
                                                                                                    );
                                                                                                    const getData = {
                                                                                                      habitat_id,
                                                                                                      method:
                                                                                                        'GET',
                                                                                                      type:
                                                                                                        'AWS_PROXY',
                                                                                                      lambda_arn,
                                                                                                      rol: roleArn,
                                                                                                      existing_path_id,
                                                                                                      path_part:
                                                                                                        '/v1',
                                                                                                      api_authorizer,
                                                                                                    };
                                                                                                    createMethod(
                                                                                                      apiId,
                                                                                                      getData
                                                                                                    )
                                                                                                      .then(
                                                                                                        (
                                                                                                          response
                                                                                                        ) => {
                                                                                                          const putData = {
                                                                                                            habitat_id,
                                                                                                            method:
                                                                                                              'PUT',
                                                                                                            type:
                                                                                                              'AWS_PROXY',
                                                                                                            lambda_arn,
                                                                                                            rol: roleArn,
                                                                                                            existing_path_id,
                                                                                                            path_part:
                                                                                                              '/v1',
                                                                                                            api_authorizer,
                                                                                                          };
                                                                                                          createMethod(
                                                                                                            apiId,
                                                                                                            putData
                                                                                                          )
                                                                                                            .then(
                                                                                                              (
                                                                                                                response
                                                                                                              ) => {
                                                                                                                const deleteData = {
                                                                                                                  habitat_id,
                                                                                                                  method:
                                                                                                                    'DELETE',
                                                                                                                  type:
                                                                                                                    'AWS_PROXY',
                                                                                                                  lambda_arn,
                                                                                                                  rol: roleArn,
                                                                                                                  existing_path_id,
                                                                                                                  path_part:
                                                                                                                    '/v1',
                                                                                                                  api_authorizer,
                                                                                                                };
                                                                                                                createMethod(
                                                                                                                  apiId,
                                                                                                                  deleteData
                                                                                                                )
                                                                                                                  .then(
                                                                                                                    (
                                                                                                                      response
                                                                                                                    ) => {
                                                                                                                      deployApi(
                                                                                                                        apiId,
                                                                                                                        habitat_id,
                                                                                                                        'v1'
                                                                                                                      )
                                                                                                                        .then(
                                                                                                                          (
                                                                                                                            response
                                                                                                                          ) => {
                                                                                                                            const responseData = {
                                                                                                                              habitat_id,
                                                                                                                            };
                                                                                                                            createRespose(
                                                                                                                              methodInfo.id,
                                                                                                                              responseData
                                                                                                                            )
                                                                                                                              .then(
                                                                                                                                (
                                                                                                                                  response
                                                                                                                                ) => {
                                                                                                                                  const templateInfo = {
                                                                                                                                    name: staticBucket,
                                                                                                                                    domain_name:
                                                                                                                                      '',
                                                                                                                                    repository_id,
                                                                                                                                    habitat_id,
                                                                                                                                    category:
                                                                                                                                      'web app',
                                                                                                                                  };
                                                                                                                                  createTemplate(
                                                                                                                                    templateInfo
                                                                                                                                  )
                                                                                                                                    .then(
                                                                                                                                      (
                                                                                                                                        response
                                                                                                                                      ) => {
                                                                                                                                        const templatesWebApp =
                                                                                                                                          response
                                                                                                                                            .data
                                                                                                                                            .templates;
                                                                                                                                        const template =
                                                                                                                                          response
                                                                                                                                            .data
                                                                                                                                            .template;
                                                                                                                                        const cognitoUserInfo = {
                                                                                                                                          habitat_id,
                                                                                                                                          name:
                                                                                                                                          name.replace(
                                                                                                                                            /[^A-Z0-9]/gi,
                                                                                                                                            '-'
                                                                                                                                          ),
                                                                                                                                          id: cognitoID,
                                                                                                                                        };
                                                                                                                                        createCognitoUser(
                                                                                                                                          cognitoUserInfo
                                                                                                                                        )
                                                                                                                                          .then(
                                                                                                                                            (
                                                                                                                                              response
                                                                                                                                            ) => {
                                                                                                                                              userPoolClientId =
                                                                                                                                                response
                                                                                                                                                  .data
                                                                                                                                                  .identity_pool_id;
                                                                                                                                              const config = `window._config = {
cognito: {
userPoolId: '${userPoolId}',
clientId: '${userPoolClientId}',
region: '${awsCred.region}'
},
api: {
invokeUrl: '${endpoint}',
}
};`;
                                                                                                                                              const envInfo = {
                                                                                                                                                file:
                                                                                                                                                  'config.js',
                                                                                                                                                bucket: staticBucket,
                                                                                                                                                habitat_id,
                                                                                                                                                directory_name,
                                                                                                                                                content: config,
                                                                                                                                              };
                                                                                                                                              addEnv(
                                                                                                                                                envInfo
                                                                                                                                              )
                                                                                                                                                .then(
                                                                                                                                                  (
                                                                                                                                                    response
                                                                                                                                                  ) => {
                                                                                                                                                    const distributionInfo = {
                                                                                                                                                      site_id:
                                                                                                                                                        template.id,
                                                                                                                                                      distribution_name: name.replace(
                                                                                                                                                        /[^A-Z0-9]/gi,
                                                                                                                                                        '-'
                                                                                                                                                      ),
                                                                                                                                                      end_point: `${staticBucket}.s3-website.${awsCred.region}.amazonaws.com`,
                                                                                                                                                      domain_name:
                                                                                                                                                        '',
                                                                                                                                                      repository_id,
                                                                                                                                                      habitat_id,
                                                                                                                                                      root_object:
                                                                                                                                                        'index.html',
                                                                                                                                                    };
                                                                                                                                                    contentDistribution(
                                                                                                                                                      distributionInfo
                                                                                                                                                    )
                                                                                                                                                      .then(
                                                                                                                                                        (
                                                                                                                                                          response
                                                                                                                                                        ) => {
                                                                                                                                                          const domainCloufront =
                                                                                                                                                            response
                                                                                                                                                              .data
                                                                                                                                                              .cloud_front_domain;
                                                                                                                                                          const upload = {
                                                                                                                                                            team_id,
                                                                                                                                                            file_name: repo.project_name,
                                                                                                                                                            bucket: staticBucket,
                                                                                                                                                            habitat_id,
                                                                                                                                                          };
                                                                                                                                                          const payloadWebApp = {
                                                                                                                                                            endpoint: domainCloufront,
                                                                                                                                                            templates: templatesWebApp,
                                                                                                                                                          };
                                                                                                                                                          uploadFolder(
                                                                                                                                                            upload
                                                                                                                                                          )
                                                                                                                                                            .then(
                                                                                                                                                              (
                                                                                                                                                                response
                                                                                                                                                              ) => {
                                                                                                                                                                const lambdaRepo = {
                                                                                                                                                                  credential_id: git_credential_id,
                                                                                                                                                                  name: lambdaName
                                                                                                                                                                }
                                                                                                                                                                gitCreateRepo(lambdaRepo)
                                                                                                                                                                .then(response => {
                                                                                                                                                                  let gitLambaURL;
                                                                                                                                                                  if (response.data.repository.http_url_to_repo) {
                                                                                                                                                                    gitLambaURL = response.data.repository.http_url_to_repo;
                                                                                                                                                                  } else {
                                                                                                                                                                    gitLambaURL = `${response.data.repository.html_url}.git`;
                                                                                                                                                                  }
                                                                                                                                                                  const lambdaRemote = {
                                                                                                                                                                    id: funcClone.id,
                                                                                                                                                                    git_credential_id,
                                                                                                                                                                    uri: gitLambaURL
                                                                                                                                                                  }
                                                                                                                                                                  gitRemote(lambdaRemote)
                                                                                                                                                                  .then(response => {
                                                                                                                                                                    const templateRepo = {
                                                                                                                                                                      credential_id: git_credential_id,
                                                                                                                                                                      name: repo.project_name
                                                                                                                                                                    }
                                                                                                                                                                    gitCreateRepo(templateRepo)
                                                                                                                                                                    .then(response => {
                                                                                                                                                                      let gitTemplateURL;
                                                                                                                                                                      if (response.data.repository.http_url_to_repo) {
                                                                                                                                                                        gitTemplateURL = response.data.repository.http_url_to_repo;
                                                                                                                                                                      } else {
                                                                                                                                                                        gitTemplateURL = `${response.data.repository.html_url}.git`;
                                                                                                                                                                      }
                                                                                                                                                                      const templateRemote = {
                                                                                                                                                                        id: repo.id,
                                                                                                                                                                        git_credential_id,
                                                                                                                                                                        uri: gitTemplateURL
                                                                                                                                                                      }
                                                                                                                                                                      gitRemote(templateRemote)
                                                                                                                                                                      .then(response => {
                                                                                                                                                                        const funcHook = {
                                                                                                                                                                          git_credential_id,
                                                                                                                                                                          clone_id: funcClone.id
                                                                                                                                                                        }
                                                                                                                                                                        gitCreateHook(funcHook)
                                                                                                                                                                        .then(response => {
                                                                                                                                                                          const siteHook = {
                                                                                                                                                                            git_credential_id,
                                                                                                                                                                            clone_id: repo.id
                                                                                                                                                                          }
                                                                                                                                                                          gitCreateHook(siteHook)
                                                                                                                                                                          .then(response => {
                                                                                                                                                                            dispatch(
                                                                                                                                                                              {
                                                                                                                                                                                type: CREATE_WEB_APP_WITHOUTDOMAIN_SUCCESS,
                                                                                                                                                                                payload: payloadWebApp,
                                                                                                                                                                              }
                                                                                                                                                                            );
                                                                                                                                                                          })
                                                                                                                                                                          .catch(
                                                                                                                                                                            (
                                                                                                                                                                              error
                                                                                                                                                                            ) => {
                                                                                                                                                                              const error_message =
                                                                                                                                                                                'Error at upload folder.';
                                                                                                                                                                              dispatch(
                                                                                                                                                                                {
                                                                                                                                                                                  type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                                                  error: error_message,
                                                                                                                                                                                }
                                                                                                                                                                              );
                                                                                                                                                                              rollBack(
                                                                                                                                                                                rollBackInfo
                                                                                                                                                                              );
                                                                                                                                                                            }
                                                                                                                                                                          );
                                                                                                                                                                        })
                                                                                                                                                                        .catch(
                                                                                                                                                                          (
                                                                                                                                                                            error
                                                                                                                                                                          ) => {
                                                                                                                                                                            const error_message =
                                                                                                                                                                              'Error at upload folder.';
                                                                                                                                                                            dispatch(
                                                                                                                                                                              {
                                                                                                                                                                                type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                                                error: error_message,
                                                                                                                                                                              }
                                                                                                                                                                            );
                                                                                                                                                                            rollBack(
                                                                                                                                                                              rollBackInfo
                                                                                                                                                                            );
                                                                                                                                                                          }
                                                                                                                                                                        );
                                                                                                                                                                      })
                                                                                                                                                                      .catch(
                                                                                                                                                                        (
                                                                                                                                                                          error
                                                                                                                                                                        ) => {
                                                                                                                                                                          const error_message =
                                                                                                                                                                            'Error at upload folder.';
                                                                                                                                                                          dispatch(
                                                                                                                                                                            {
                                                                                                                                                                              type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                                              error: error_message,
                                                                                                                                                                            }
                                                                                                                                                                          );
                                                                                                                                                                          rollBack(
                                                                                                                                                                            rollBackInfo
                                                                                                                                                                          );
                                                                                                                                                                        }
                                                                                                                                                                      );
                                                                                                                                                                    })
                                                                                                                                                                    .catch(
                                                                                                                                                                      (
                                                                                                                                                                        error
                                                                                                                                                                      ) => {
                                                                                                                                                                        const error_message =
                                                                                                                                                                          'Error at upload folder.';
                                                                                                                                                                        dispatch(
                                                                                                                                                                          {
                                                                                                                                                                            type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                                            error: error_message,
                                                                                                                                                                          }
                                                                                                                                                                        );
                                                                                                                                                                        rollBack(
                                                                                                                                                                          rollBackInfo
                                                                                                                                                                        );
                                                                                                                                                                      }
                                                                                                                                                                    );
                                                                                                                                                                  })
                                                                                                                                                                  .catch(
                                                                                                                                                                    (
                                                                                                                                                                      error
                                                                                                                                                                    ) => {
                                                                                                                                                                      const error_message =
                                                                                                                                                                        'Error at upload folder.';
                                                                                                                                                                      dispatch(
                                                                                                                                                                        {
                                                                                                                                                                          type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                                          error: error_message,
                                                                                                                                                                        }
                                                                                                                                                                      );
                                                                                                                                                                      rollBack(
                                                                                                                                                                        rollBackInfo
                                                                                                                                                                      );
                                                                                                                                                                    }
                                                                                                                                                                  );
                                                                                                                                                                })
                                                                                                                                                                .catch(
                                                                                                                                                                  (
                                                                                                                                                                    error
                                                                                                                                                                  ) => {
                                                                                                                                                                    const error_message =
                                                                                                                                                                      'Error at upload folder.';
                                                                                                                                                                    dispatch(
                                                                                                                                                                      {
                                                                                                                                                                        type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                                        error: error_message,
                                                                                                                                                                      }
                                                                                                                                                                    );
                                                                                                                                                                    rollBack(
                                                                                                                                                                      rollBackInfo
                                                                                                                                                                    );
                                                                                                                                                                  }
                                                                                                                                                                );
                                                                                                                                                              }
                                                                                                                                                            )
                                                                                                                                                            .catch(
                                                                                                                                                              (
                                                                                                                                                                error
                                                                                                                                                              ) => {
                                                                                                                                                                const error_message =
                                                                                                                                                                  'Error at upload folder.';
                                                                                                                                                                dispatch(
                                                                                                                                                                  {
                                                                                                                                                                    type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                                    error: error_message,
                                                                                                                                                                  }
                                                                                                                                                                );
                                                                                                                                                                rollBack(
                                                                                                                                                                  rollBackInfo
                                                                                                                                                                );
                                                                                                                                                              }
                                                                                                                                                            );
                                                                                                                                                        }
                                                                                                                                                      )
                                                                                                                                                      .catch(
                                                                                                                                                        (
                                                                                                                                                          error
                                                                                                                                                        ) => {
                                                                                                                                                          const error_message =
                                                                                                                                                            'Error at add env file.';
                                                                                                                                                          dispatch(
                                                                                                                                                            {
                                                                                                                                                              type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                              error: error_message,
                                                                                                                                                            }
                                                                                                                                                          );
                                                                                                                                                          rollBack(
                                                                                                                                                            rollBackInfo
                                                                                                                                                          );
                                                                                                                                                        }
                                                                                                                                                      );
                                                                                                                                                  }
                                                                                                                                                )
                                                                                                                                                .catch(
                                                                                                                                                  (
                                                                                                                                                    error
                                                                                                                                                  ) => {
                                                                                                                                                    const error_message =
                                                                                                                                                      'Error at add env file.';
                                                                                                                                                    dispatch(
                                                                                                                                                      {
                                                                                                                                                        type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                        error: error_message,
                                                                                                                                                      }
                                                                                                                                                    );
                                                                                                                                                    rollBack(
                                                                                                                                                      rollBackInfo
                                                                                                                                                    );
                                                                                                                                                  }
                                                                                                                                                );
                                                                                                                                            }
                                                                                                                                          )
                                                                                                                                          .catch(
                                                                                                                                            (
                                                                                                                                              error
                                                                                                                                            ) => {
                                                                                                                                              const error_message =
                                                                                                                                                'Error at create Cognito user.';
                                                                                                                                              dispatch(
                                                                                                                                                {
                                                                                                                                                  type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                  error: error_message,
                                                                                                                                                }
                                                                                                                                              );
                                                                                                                                              rollBack(
                                                                                                                                                rollBackInfo
                                                                                                                                              );
                                                                                                                                            }
                                                                                                                                          );
                                                                                                                                      }
                                                                                                                                    )
                                                                                                                                    .catch(
                                                                                                                                      (
                                                                                                                                        error
                                                                                                                                      ) => {
                                                                                                                                        const error_message =
                                                                                                                                          'Error at create template.';
                                                                                                                                        dispatch(
                                                                                                                                          {
                                                                                                                                            type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                            error: error_message,
                                                                                                                                          }
                                                                                                                                        );
                                                                                                                                        rollBack(
                                                                                                                                          rollBackInfo
                                                                                                                                        );
                                                                                                                                      }
                                                                                                                                    );
                                                                                                                                }
                                                                                                                              )
                                                                                                                              .catch(
                                                                                                                                (
                                                                                                                                  error
                                                                                                                                ) => {
                                                                                                                                  const error_message =
                                                                                                                                    'Error at create response API.';
                                                                                                                                  dispatch(
                                                                                                                                    {
                                                                                                                                      type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                      error: error_message,
                                                                                                                                    }
                                                                                                                                  );
                                                                                                                                  rollBack(
                                                                                                                                    rollBackInfo
                                                                                                                                  );
                                                                                                                                }
                                                                                                                              );
                                                                                                                          }
                                                                                                                        )
                                                                                                                        .catch(
                                                                                                                          (
                                                                                                                            error
                                                                                                                          ) => {
                                                                                                                            const error_message =
                                                                                                                              'Error at deploy API.';
                                                                                                                            dispatch(
                                                                                                                              {
                                                                                                                                type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                error: error_message,
                                                                                                                              }
                                                                                                                            );
                                                                                                                            rollBack(
                                                                                                                              rollBackInfo
                                                                                                                            );
                                                                                                                          }
                                                                                                                        );
                                                                                                                    }
                                                                                                                  )
                                                                                                                  .catch(
                                                                                                                    (
                                                                                                                      error
                                                                                                                    ) => {
                                                                                                                      const error_message =
                                                                                                                        'Error at create DELETE method.';
                                                                                                                      dispatch(
                                                                                                                        {
                                                                                                                          type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                          error: error_message,
                                                                                                                        }
                                                                                                                      );
                                                                                                                      rollBack(
                                                                                                                        rollBackInfo
                                                                                                                      );
                                                                                                                    }
                                                                                                                  );
                                                                                                              }
                                                                                                            )
                                                                                                            .catch(
                                                                                                              (
                                                                                                                error
                                                                                                              ) => {
                                                                                                                const error_message =
                                                                                                                  'Error at create PUT method.';
                                                                                                                dispatch(
                                                                                                                  {
                                                                                                                    type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                    error: error_message,
                                                                                                                  }
                                                                                                                );
                                                                                                                rollBack(
                                                                                                                  rollBackInfo
                                                                                                                );
                                                                                                              }
                                                                                                            );
                                                                                                        }
                                                                                                      )
                                                                                                      .catch(
                                                                                                        (
                                                                                                          error
                                                                                                        ) => {
                                                                                                          const error_message =
                                                                                                            'Error at create GET method.';
                                                                                                          dispatch(
                                                                                                            {
                                                                                                              type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                              error: error_message,
                                                                                                            }
                                                                                                          );
                                                                                                          rollBack(
                                                                                                            rollBackInfo
                                                                                                          );
                                                                                                        }
                                                                                                      );
                                                                                                  }
                                                                                                )
                                                                                                .catch(
                                                                                                  (
                                                                                                    error
                                                                                                  ) => {
                                                                                                    const error_message =
                                                                                                      'Error at list resource.';
                                                                                                    dispatch(
                                                                                                      {
                                                                                                        type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                        error: error_message,
                                                                                                      }
                                                                                                    );
                                                                                                    rollBack(
                                                                                                      rollBackInfo
                                                                                                    );
                                                                                                  }
                                                                                                );
                                                                                            }
                                                                                          )
                                                                                          .catch(
                                                                                            (
                                                                                              error
                                                                                            ) => {
                                                                                              const error_message =
                                                                                                'Error at create POST method.';
                                                                                              dispatch(
                                                                                                {
                                                                                                  type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                  error: error_message,
                                                                                                }
                                                                                              );
                                                                                              rollBack(
                                                                                                rollBackInfo
                                                                                              );
                                                                                            }
                                                                                          );
                                                                                      }
                                                                                    )
                                                                                    .catch(
                                                                                      (
                                                                                        error
                                                                                      ) => {
                                                                                        const error_message =
                                                                                          'Error at deploy function.';
                                                                                        dispatch(
                                                                                          {
                                                                                            type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                            error: error_message,
                                                                                          }
                                                                                        );
                                                                                        rollBack(
                                                                                          rollBackInfo
                                                                                        );
                                                                                      }
                                                                                    );
                                                                                }
                                                                              )
                                                                              .catch(
                                                                                (
                                                                                  error
                                                                                ) => {
                                                                                  const error_message =
                                                                                    'Error at deploy API.';
                                                                                  dispatch(
                                                                                    {
                                                                                      type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                      error: error_message,
                                                                                    }
                                                                                  );
                                                                                  rollBack(
                                                                                    rollBackInfo
                                                                                  );
                                                                                }
                                                                              );
                                                                          }
                                                                        )
                                                                        .catch(
                                                                          (
                                                                            error
                                                                          ) => {
                                                                            const error_message =
                                                                              'Error at create resource.';
                                                                            dispatch(
                                                                              {
                                                                                type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                error: error_message,
                                                                              }
                                                                            );
                                                                            rollBack(
                                                                              rollBackInfo
                                                                            );
                                                                          }
                                                                        );
                                                                    }
                                                                  )
                                                                  .catch(
                                                                    (
                                                                      error
                                                                    ) => {
                                                                      const error_message =
                                                                        'Error at create Cognito pool.';
                                                                      dispatch(
                                                                        {
                                                                          type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                          error: error_message,
                                                                        }
                                                                      );
                                                                      rollBack(
                                                                        rollBackInfo
                                                                      );
                                                                    }
                                                                  );
                                                              }
                                                            )
                                                            .catch(
                                                              (
                                                                error
                                                              ) => {
                                                                const error_message =
                                                                  'Error at create API Gateway.';
                                                                dispatch(
                                                                  {
                                                                    type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                    error: error_message,
                                                                  }
                                                                );
                                                                rollBack(
                                                                  rollBackInfo
                                                                );
                                                              }
                                                            );
                                                        }
                                                      )
                                                      .catch(
                                                        (error) => {
                                                          const error_message =
                                                            'Error at create Cognito.';
                                                          dispatch({
                                                            type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                            error: error_message,
                                                          });
                                                          rollBack(
                                                            rollBackInfo
                                                          );
                                                        }
                                                      );
                                                  }
                                                )
                                                .catch((error) => {
                                                  const error_message =
                                                    'Error at create Dynamodb.';
                                                  dispatch({
                                                    type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                    error: error_message,
                                                  });
                                                  rollBack(
                                                    rollBackInfo
                                                  );
                                                });
                                            })
                                            .catch((error) => {
                                              const error_message =
                                                'Error at build project.';
                                              dispatch({
                                                type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                error: error_message,
                                              });
                                              rollBack(
                                                rollBackInfo
                                              );
                                            });
                                        })
                                        .catch((error) => {
                                          const error_message =
                                            'Error at create build project.';
                                          dispatch({
                                            type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                            error: error_message,
                                          });
                                          rollBack(rollBackInfo);
                                        });
                                    })
                                    .catch((error) => {
                                      const error_message =
                                        'Error at create function.';
                                      dispatch({
                                        type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                        error: error_message,
                                      });
                                      rollBack(rollBackInfo);
                                    });
                                })
                                .catch((error) => {
                                  const error_message =
                                    'Error at upload clone to s3 buckets.';
                                  dispatch({
                                    type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                    error: error_message,
                                  });
                                  rollBack(rollBackInfo);
                                });
                            })
                            .catch((error) => {
                              const error_message =
                                'Error at create s3 buckets.';
                              dispatch({
                                type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                error: error_message,
                              });
                              rollBack(rollBackInfo);
                            });
                        } else {
                          const s3Data = {
                            file_name: lambdaName,
                            team_id,
                            habitat_id,
                          };
                          uploadClone(s3Data)
                            .then((response) => {
                              let data = {
                                lambdaName,
                                runtime: runtimeLambda,
                                handler: handlerLambda,
                                role: roleArn,
                                habitat_id,
                                memory_size: null,
                                timeout: null,
                                code_s3_bucket: bucketName,
                                code_s3_key: `${lambdaName}.zip`,
                              };
                              setFunc(data)
                                .then((response) => {
                                  const func =
                                    response.data.aws_lambda;
                                  const buildData = {
                                    habitat_id: func.habitat_id,
                                    project_name:
                                      func.function_name,
                                    artifac_name: `${func.function_name}.zip`,
                                    buildspec: 'buildspec.yml',
                                    source_location:
                                      func.function_name,
                                    artifac_location:
                                      func.code_s3_bucket,
                                    service_role: roleArn,
                                    zip: 'ZIP',
                                    lambda_id: func.id,
                                  };

                                  createProject(buildData)
                                    .then((response) => {
                                      const buildInfo = {
                                        habitat_id: func.habitat_id,
                                        build_id:
                                          response.data.params.id,
                                      };
                                      createBuild(buildInfo)
                                        .then((response) => {
                                          const table_name = lambdaName;
                                          const schema = {
                                            attribute_definitions: [
                                              {
                                                attribute_name:
                                                  'id',
                                                attribute_type: 'S',
                                              },
                                            ],
                                            key_schema: [
                                              {
                                                attribute_name:
                                                  'id',
                                                key_type: 'HASH',
                                              },
                                            ],
                                            provisioned_throughput: {
                                              read_capacity_units: 5,
                                              write_capacity_units: 5,
                                            },
                                            table_name: table_name,
                                          };

                                          const definition = JSON.stringify(
                                            schema
                                          )
                                            .replace(/\\n/g, '\\n')
                                            .replace(/\\'/g, "\\'")
                                            .replace(/\\"/g, '\\"')
                                            .replace(/\\&/g, '\\&')
                                            .replace(/\\r/g, '\\r')
                                            .replace(/\\t/g, '\\t')
                                            .replace(/\\b/g, '\\b')
                                            .replace(/\\f/g, '\\f');

                                          const dynamoData = {
                                            habitat_id,
                                            table_name,
                                            definition,
                                          };

                                          createDB(dynamoData)
                                            .then((response) => {
                                              newCognito(
                                                name,
                                                habitat_id
                                              )
                                                .then(
                                                  (response) => {
                                                    const cognitoArn =
                                                      response.data
                                                        .arn;
                                                    userPoolId =
                                                      response.data
                                                        .user_pool_id;
                                                    const cognitoID =
                                                      response.data
                                                        .id;
                                                    createApiGateway(
                                                      `API-GATEWAY-${lambdaName}`,
                                                      habitat_id
                                                    )
                                                      .then(
                                                        (
                                                          response
                                                        ) => {
                                                          const apiId =
                                                            response
                                                              .data
                                                              .api_gateway
                                                              .id;
                                                          const api_id =
                                                            response
                                                              .data
                                                              .api_gateway
                                                              .api_id;
                                                          authorizeCognito(
                                                            habitat_id,
                                                            cognitoArn,
                                                            apiId
                                                          )
                                                            .then(
                                                              (
                                                                response
                                                              ) => {
                                                                const api_authorizer =
                                                                  response
                                                                    .data
                                                                    .id;
                                                                createResource(
                                                                  apiId,
                                                                  habitat_id
                                                                )
                                                                  .then(
                                                                    (
                                                                      response
                                                                    ) => {
                                                                      deployApi(
                                                                        apiId,
                                                                        habitat_id
                                                                      )
                                                                        .then(
                                                                          (
                                                                            response
                                                                          ) => {
                                                                            const preEnv = {
                                                                              TABLE: table_name,
                                                                            };
                                                                            const env = JSON.stringify(
                                                                              preEnv
                                                                            )
                                                                              .replace(
                                                                                /\\n/g,
                                                                                '\\n'
                                                                              )
                                                                              .replace(
                                                                                /\\'/g,
                                                                                "\\'"
                                                                              )
                                                                              .replace(
                                                                                /\\"/g,
                                                                                '\\"'
                                                                              )
                                                                              .replace(
                                                                                /\\&/g,
                                                                                '\\&'
                                                                              )
                                                                              .replace(
                                                                                /\\r/g,
                                                                                '\\r'
                                                                              )
                                                                              .replace(
                                                                                /\\t/g,
                                                                                '\\t'
                                                                              )
                                                                              .replace(
                                                                                /\\b/g,
                                                                                '\\b'
                                                                              )
                                                                              .replace(
                                                                                /\\f/g,
                                                                                '\\f'
                                                                              );
                                                                            deployFunc(
                                                                              func.id,
                                                                              env
                                                                            )
                                                                              .then(
                                                                                (
                                                                                  response
                                                                                ) => {
                                                                                  let existing_path_id = null;
                                                                                  const lambdas =
                                                                                    response.data;
                                                                                  const lambda_arn =
                                                                                    lambdas[
                                                                                      lambdas.length -
                                                                                        1
                                                                                    ]
                                                                                      .function_arn;
                                                                                  const postData = {
                                                                                    habitat_id,
                                                                                    method:
                                                                                      'POST',
                                                                                    type:
                                                                                      'AWS_PROXY',
                                                                                    lambda_arn,
                                                                                    rol: roleArn,
                                                                                    path_part:
                                                                                      'v1',
                                                                                    api_authorizer,
                                                                                  };
                                                                                  createMethod(
                                                                                    apiId,
                                                                                    postData
                                                                                  )
                                                                                    .then(
                                                                                      (
                                                                                        response
                                                                                      ) => {
                                                                                        const methodInfo =
                                                                                          response
                                                                                            .data
                                                                                            .method;
                                                                                        endpoint = `https://${api_id}.execute-api.${awsCred.region}.amazonaws.com/production${methodInfo.resource}`;
                                                                                        listResources(
                                                                                          apiId,
                                                                                          habitat_id
                                                                                        )
                                                                                          .then(
                                                                                            (
                                                                                              response
                                                                                            ) => {
                                                                                              const resources =
                                                                                                response
                                                                                                  .data
                                                                                                  .resources;
                                                                                              resources.forEach(
                                                                                                (
                                                                                                  resource
                                                                                                ) => {
                                                                                                  if (
                                                                                                    resource.path_part ===
                                                                                                    'v1'
                                                                                                  ) {
                                                                                                    existing_path_id =
                                                                                                      resource.id;
                                                                                                  }
                                                                                                }
                                                                                              );
                                                                                              const getData = {
                                                                                                habitat_id,
                                                                                                method:
                                                                                                  'GET',
                                                                                                type:
                                                                                                  'AWS_PROXY',
                                                                                                lambda_arn,
                                                                                                rol: roleArn,
                                                                                                existing_path_id,
                                                                                                path_part:
                                                                                                  '/v1',
                                                                                                api_authorizer,
                                                                                              };
                                                                                              createMethod(
                                                                                                apiId,
                                                                                                getData
                                                                                              )
                                                                                                .then(
                                                                                                  (
                                                                                                    response
                                                                                                  ) => {
                                                                                                    const putData = {
                                                                                                      habitat_id,
                                                                                                      method:
                                                                                                        'PUT',
                                                                                                      type:
                                                                                                        'AWS_PROXY',
                                                                                                      lambda_arn,
                                                                                                      rol: roleArn,
                                                                                                      existing_path_id,
                                                                                                      path_part:
                                                                                                        '/v1',
                                                                                                      api_authorizer,
                                                                                                    };
                                                                                                    createMethod(
                                                                                                      apiId,
                                                                                                      putData
                                                                                                    )
                                                                                                      .then(
                                                                                                        (
                                                                                                          response
                                                                                                        ) => {
                                                                                                          const deleteData = {
                                                                                                            habitat_id,
                                                                                                            method:
                                                                                                              'DELETE',
                                                                                                            type:
                                                                                                              'AWS_PROXY',
                                                                                                            lambda_arn,
                                                                                                            rol: roleArn,
                                                                                                            existing_path_id,
                                                                                                            path_part:
                                                                                                              '/v1',
                                                                                                            api_authorizer,
                                                                                                          };
                                                                                                          createMethod(
                                                                                                            apiId,
                                                                                                            deleteData
                                                                                                          )
                                                                                                            .then(
                                                                                                              (
                                                                                                                response
                                                                                                              ) => {
                                                                                                                deployApi(
                                                                                                                  apiId,
                                                                                                                  habitat_id,
                                                                                                                  'v1'
                                                                                                                )
                                                                                                                  .then(
                                                                                                                    (
                                                                                                                      response
                                                                                                                    ) => {
                                                                                                                      const responseData = {
                                                                                                                        habitat_id,
                                                                                                                      };
                                                                                                                      createRespose(
                                                                                                                        methodInfo.id,
                                                                                                                        responseData
                                                                                                                      )
                                                                                                                        .then(
                                                                                                                          (
                                                                                                                            response
                                                                                                                          ) => {
                                                                                                                            const templateInfo = {
                                                                                                                              name: staticBucket,
                                                                                                                              domain_name:
                                                                                                                                '',
                                                                                                                              repository_id,
                                                                                                                              habitat_id,
                                                                                                                              category:
                                                                                                                                'web app',
                                                                                                                            };
                                                                                                                            createTemplate(
                                                                                                                              templateInfo
                                                                                                                            )
                                                                                                                              .then(
                                                                                                                                (
                                                                                                                                  response
                                                                                                                                ) => {
                                                                                                                                  const payload = {
                                                                                                                                    templates:
                                                                                                                                      response
                                                                                                                                        .data
                                                                                                                                        .templates
                                                                                                                                  };
                                                                                                                                  const template =
                                                                                                                                    response
                                                                                                                                      .data
                                                                                                                                      .template;
                                                                                                                                  const cognitoUserInfo = {
                                                                                                                                    habitat_id,
                                                                                                                                    name: name.replace(
                                                                                                                                                  /[^A-Z0-9]/gi,
                                                                                                                                                  '-'
                                                                                                                                                ),
                                                                                                                                    id: cognitoID,
                                                                                                                                  };
                                                                                                                                  createCognitoUser(
                                                                                                                                    cognitoUserInfo
                                                                                                                                  )
                                                                                                                                    .then(
                                                                                                                                      (
                                                                                                                                        response
                                                                                                                                      ) => {
                                                                                                                                        userPoolClientId =
                                                                                                                                          response
                                                                                                                                            .data
                                                                                                                                            .identity_pool_id;
                                                                                                                                        const config = `window._config = {
cognito: {
userPoolId: '${userPoolId}',
clientId: '${userPoolClientId}',
region: '${awsCred.region}'
},
api: {
invokeUrl: '${endpoint}',
}
};`;
                                                                                                                                        const envInfo = {
                                                                                                                                          file:
                                                                                                                                            'config.js',
                                                                                                                                          bucket: staticBucket,
                                                                                                                                          habitat_id,
                                                                                                                                          directory_name,
                                                                                                                                          content: config,
                                                                                                                                        };
                                                                                                                                        addEnv(
                                                                                                                                          envInfo
                                                                                                                                        )
                                                                                                                                          .then(
                                                                                                                                            (
                                                                                                                                              response
                                                                                                                                            ) => {
                                                                                                                                              const distributionInfo = {
                                                                                                                                                site_id:
                                                                                                                                                  template.id,
                                                                                                                                                distribution_name: name.replace(
                                                                                                                                                  /[^A-Z0-9]/gi,
                                                                                                                                                  '-'
                                                                                                                                                ),
                                                                                                                                                end_point: `${staticBucket}.s3-website.${awsCred.region}.amazonaws.com`,
                                                                                                                                                domain_name:
                                                                                                                                                  '',
                                                                                                                                                repository_id,
                                                                                                                                                habitat_id,
                                                                                                                                                root_object:
                                                                                                                                                  'index.html',
                                                                                                                                              };
                                                                                                                                              contentDistribution(
                                                                                                                                                distributionInfo
                                                                                                                                              )
                                                                                                                                                .then(
                                                                                                                                                  (
                                                                                                                                                    response
                                                                                                                                                  ) => {
                                                                                                                                                    const upload = {
                                                                                                                                                      team_id,
                                                                                                                                                      file_name: repo.project_name,
                                                                                                                                                      bucket: staticBucket,
                                                                                                                                                      habitat_id,
                                                                                                                                                    };
                                                                                                                                                    uploadFolder(
                                                                                                                                                      upload
                                                                                                                                                    )
                                                                                                                                                      .then(
                                                                                                                                                        (
                                                                                                                                                          response
                                                                                                                                                        ) => {
                                                                                                                                                          const lambdaRepo = {
                                                                                                                                                            credential_id: git_credential_id,
                                                                                                                                                            name: lambdaName
                                                                                                                                                          }
                                                                                                                                                          gitCreateRepo(lambdaRepo)
                                                                                                                                                          .then(response => {
                                                                                                                                                            let gitLambaURL;
                                                                                                                                                            if (response.data.repository.http_url_to_repo) {
                                                                                                                                                              gitLambaURL = response.data.repository.http_url_to_repo;
                                                                                                                                                            } else {
                                                                                                                                                              gitLambaURL = `${response.data.repository.html_url}.git`;
                                                                                                                                                            }
                                                                                                                                                            const lambdaRemote = {
                                                                                                                                                              id: funcClone.id,
                                                                                                                                                              git_credential_id,
                                                                                                                                                              uri: gitLambaURL
                                                                                                                                                            }
                                                                                                                                                            gitRemote(lambdaRemote)
                                                                                                                                                            .then(response => {
                                                                                                                                                              const templateRepo = {
                                                                                                                                                                credential_id: git_credential_id,
                                                                                                                                                                name: repo.project_name
                                                                                                                                                              }
                                                                                                                                                              gitCreateRepo(templateRepo)
                                                                                                                                                              .then(response => {
                                                                                                                                                                let gitTemplateURL;
                                                                                                                                                                if (response.data.repository.http_url_to_repo) {
                                                                                                                                                                  gitTemplateURL = response.data.repository.http_url_to_repo;
                                                                                                                                                                } else {
                                                                                                                                                                  gitTemplateURL = `${response.data.repository.html_url}.git`;
                                                                                                                                                                }
                                                                                                                                                                const templateRemote = {
                                                                                                                                                                  id: repo.id,
                                                                                                                                                                  git_credential_id,
                                                                                                                                                                  uri: gitTemplateURL
                                                                                                                                                                }
                                                                                                                                                                gitRemote(templateRemote)
                                                                                                                                                                .then(response => {
                                                                                                                                                                  const funcHook = {
                                                                                                                                                                    git_credential_id,
                                                                                                                                                                    clone_id: funcClone.id
                                                                                                                                                                  }
                                                                                                                                                                  gitCreateHook(funcHook)
                                                                                                                                                                  .then(response => {
                                                                                                                                                                    const siteHook = {
                                                                                                                                                                      git_credential_id,
                                                                                                                                                                      clone_id: repo.id
                                                                                                                                                                    }
                                                                                                                                                                    gitCreateHook(siteHook)
                                                                                                                                                                    .then(response => {
                                                                                                                                                                      dispatch(
                                                                                                                                                                        {
                                                                                                                                                                          type: CREATE_WEB_APP_WITHOUTDOMAIN_SUCCESS,
                                                                                                                                                                          payload: payloadWebApp,
                                                                                                                                                                        }
                                                                                                                                                                      );
                                                                                                                                                                    })
                                                                                                                                                                    .catch(
                                                                                                                                                                      (
                                                                                                                                                                        error
                                                                                                                                                                      ) => {
                                                                                                                                                                        const error_message =
                                                                                                                                                                          'Error at upload folder.';
                                                                                                                                                                        dispatch(
                                                                                                                                                                          {
                                                                                                                                                                            type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                                            error: error_message,
                                                                                                                                                                          }
                                                                                                                                                                        );
                                                                                                                                                                        rollBack(
                                                                                                                                                                          rollBackInfo
                                                                                                                                                                        );
                                                                                                                                                                      }
                                                                                                                                                                    );
                                                                                                                                                                  })
                                                                                                                                                                  .catch(
                                                                                                                                                                    (
                                                                                                                                                                      error
                                                                                                                                                                    ) => {
                                                                                                                                                                      const error_message =
                                                                                                                                                                        'Error at upload folder.';
                                                                                                                                                                      dispatch(
                                                                                                                                                                        {
                                                                                                                                                                          type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                                          error: error_message,
                                                                                                                                                                        }
                                                                                                                                                                      );
                                                                                                                                                                      rollBack(
                                                                                                                                                                        rollBackInfo
                                                                                                                                                                      );
                                                                                                                                                                    }
                                                                                                                                                                  );
                                                                                                                                                                })
                                                                                                                                                                .catch(
                                                                                                                                                                  (
                                                                                                                                                                    error
                                                                                                                                                                  ) => {
                                                                                                                                                                    const error_message =
                                                                                                                                                                      'Error at upload folder.';
                                                                                                                                                                    dispatch(
                                                                                                                                                                      {
                                                                                                                                                                        type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                                        error: error_message,
                                                                                                                                                                      }
                                                                                                                                                                    );
                                                                                                                                                                    rollBack(
                                                                                                                                                                      rollBackInfo
                                                                                                                                                                    );
                                                                                                                                                                  }
                                                                                                                                                                );
                                                                                                                                                              })
                                                                                                                                                              .catch(
                                                                                                                                                                (
                                                                                                                                                                  error
                                                                                                                                                                ) => {
                                                                                                                                                                  const error_message =
                                                                                                                                                                    'Error at upload folder.';
                                                                                                                                                                  dispatch(
                                                                                                                                                                    {
                                                                                                                                                                      type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                                      error: error_message,
                                                                                                                                                                    }
                                                                                                                                                                  );
                                                                                                                                                                  rollBack(
                                                                                                                                                                    rollBackInfo
                                                                                                                                                                  );
                                                                                                                                                                }
                                                                                                                                                              );
                                                                                                                                                            })
                                                                                                                                                            .catch(
                                                                                                                                                              (
                                                                                                                                                                error
                                                                                                                                                              ) => {
                                                                                                                                                                const error_message =
                                                                                                                                                                  'Error at upload folder.';
                                                                                                                                                                dispatch(
                                                                                                                                                                  {
                                                                                                                                                                    type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                                    error: error_message,
                                                                                                                                                                  }
                                                                                                                                                                );
                                                                                                                                                                rollBack(
                                                                                                                                                                  rollBackInfo
                                                                                                                                                                );
                                                                                                                                                              }
                                                                                                                                                            );
                                                                                                                                                          })
                                                                                                                                                          .catch(
                                                                                                                                                            (
                                                                                                                                                              error
                                                                                                                                                            ) => {
                                                                                                                                                              const error_message =
                                                                                                                                                                'Error at upload folder.';
                                                                                                                                                              dispatch(
                                                                                                                                                                {
                                                                                                                                                                  type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                                  error: error_message,
                                                                                                                                                                }
                                                                                                                                                              );
                                                                                                                                                              rollBack(
                                                                                                                                                                rollBackInfo
                                                                                                                                                              );
                                                                                                                                                            }
                                                                                                                                                          );
                                                                                                                                                        }
                                                                                                                                                      )
                                                                                                                                                      .catch(
                                                                                                                                                        (
                                                                                                                                                          error
                                                                                                                                                        ) => {
                                                                                                                                                          const error_message =
                                                                                                                                                            'Error at upload folder.';
                                                                                                                                                          dispatch(
                                                                                                                                                            {
                                                                                                                                                              type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                              error: error_message,
                                                                                                                                                            }
                                                                                                                                                          );
                                                                                                                                                          rollBack(
                                                                                                                                                            rollBackInfo
                                                                                                                                                          );
                                                                                                                                                        }
                                                                                                                                                      );
                                                                                                                                                  }
                                                                                                                                                )
                                                                                                                                                .catch(
                                                                                                                                                  (
                                                                                                                                                    error
                                                                                                                                                  ) => {
                                                                                                                                                    const error_message =
                                                                                                                                                      'Error at add env file.';
                                                                                                                                                    dispatch(
                                                                                                                                                      {
                                                                                                                                                        type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                        error: error_message,
                                                                                                                                                      }
                                                                                                                                                    );
                                                                                                                                                    rollBack(
                                                                                                                                                      rollBackInfo
                                                                                                                                                    );
                                                                                                                                                  }
                                                                                                                                                );
                                                                                                                                            }
                                                                                                                                          )
                                                                                                                                          .catch(
                                                                                                                                            (
                                                                                                                                              error
                                                                                                                                            ) => {
                                                                                                                                              const error_message =
                                                                                                                                                'Error at add env file.';
                                                                                                                                              dispatch(
                                                                                                                                                {
                                                                                                                                                  type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                                  error: error_message,
                                                                                                                                                }
                                                                                                                                              );
                                                                                                                                              rollBack(
                                                                                                                                                rollBackInfo
                                                                                                                                              );
                                                                                                                                            }
                                                                                                                                          );
                                                                                                                                      }
                                                                                                                                    )
                                                                                                                                    .catch(
                                                                                                                                      (
                                                                                                                                        error
                                                                                                                                      ) => {
                                                                                                                                        const error_message =
                                                                                                                                          'Error at create Cognito user.';
                                                                                                                                        dispatch(
                                                                                                                                          {
                                                                                                                                            type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                            error: error_message,
                                                                                                                                          }
                                                                                                                                        );
                                                                                                                                        rollBack(
                                                                                                                                          rollBackInfo
                                                                                                                                        );
                                                                                                                                      }
                                                                                                                                    );
                                                                                                                                }
                                                                                                                              )
                                                                                                                              .catch(
                                                                                                                                (
                                                                                                                                  error
                                                                                                                                ) => {
                                                                                                                                  const error_message =
                                                                                                                                    'Error at create template.';
                                                                                                                                  dispatch(
                                                                                                                                    {
                                                                                                                                      type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                      error: error_message,
                                                                                                                                    }
                                                                                                                                  );
                                                                                                                                  rollBack(
                                                                                                                                    rollBackInfo
                                                                                                                                  );
                                                                                                                                }
                                                                                                                              );
                                                                                                                          }
                                                                                                                        )
                                                                                                                        .catch(
                                                                                                                          (
                                                                                                                            error
                                                                                                                          ) => {
                                                                                                                            const error_message =
                                                                                                                              'Error at create response API.';
                                                                                                                            dispatch(
                                                                                                                              {
                                                                                                                                type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                                error: error_message,
                                                                                                                              }
                                                                                                                            );
                                                                                                                            rollBack(
                                                                                                                              rollBackInfo
                                                                                                                            );
                                                                                                                          }
                                                                                                                        );
                                                                                                                    }
                                                                                                                  )
                                                                                                                  .catch(
                                                                                                                    (
                                                                                                                      error
                                                                                                                    ) => {
                                                                                                                      const error_message =
                                                                                                                        'Error at deploy API.';
                                                                                                                      dispatch(
                                                                                                                        {
                                                                                                                          type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                          error: error_message,
                                                                                                                        }
                                                                                                                      );
                                                                                                                      rollBack(
                                                                                                                        rollBackInfo
                                                                                                                      );
                                                                                                                    }
                                                                                                                  );
                                                                                                              }
                                                                                                            )
                                                                                                            .catch(
                                                                                                              (
                                                                                                                error
                                                                                                              ) => {
                                                                                                                const error_message =
                                                                                                                  'Error at create DELETE method.';
                                                                                                                dispatch(
                                                                                                                  {
                                                                                                                    type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                                    error: error_message,
                                                                                                                  }
                                                                                                                );
                                                                                                                rollBack(
                                                                                                                  rollBackInfo
                                                                                                                );
                                                                                                              }
                                                                                                            );
                                                                                                        }
                                                                                                      )
                                                                                                      .catch(
                                                                                                        (
                                                                                                          error
                                                                                                        ) => {
                                                                                                          const error_message =
                                                                                                            'Error at create PUT method.';
                                                                                                          dispatch(
                                                                                                            {
                                                                                                              type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                              error: error_message,
                                                                                                            }
                                                                                                          );
                                                                                                          rollBack(
                                                                                                            rollBackInfo
                                                                                                          );
                                                                                                        }
                                                                                                      );
                                                                                                  }
                                                                                                )
                                                                                                .catch(
                                                                                                  (
                                                                                                    error
                                                                                                  ) => {
                                                                                                    const error_message =
                                                                                                      'Error at create GET method.';
                                                                                                    dispatch(
                                                                                                      {
                                                                                                        type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                        error: error_message,
                                                                                                      }
                                                                                                    );
                                                                                                    rollBack(
                                                                                                      rollBackInfo
                                                                                                    );
                                                                                                  }
                                                                                                );
                                                                                            }
                                                                                          )
                                                                                          .catch(
                                                                                            (
                                                                                              error
                                                                                            ) => {
                                                                                              const error_message =
                                                                                                'Error at list resource.';
                                                                                              dispatch(
                                                                                                {
                                                                                                  type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                                  error: error_message,
                                                                                                }
                                                                                              );
                                                                                              rollBack(
                                                                                                rollBackInfo
                                                                                              );
                                                                                            }
                                                                                          );
                                                                                      }
                                                                                    )
                                                                                    .catch(
                                                                                      (
                                                                                        error
                                                                                      ) => {
                                                                                        const error_message =
                                                                                          'Error at create POST method.';
                                                                                        dispatch(
                                                                                          {
                                                                                            type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                            error: error_message,
                                                                                          }
                                                                                        );
                                                                                        rollBack(
                                                                                          rollBackInfo
                                                                                        );
                                                                                      }
                                                                                    );
                                                                                }
                                                                              )
                                                                              .catch(
                                                                                (
                                                                                  error
                                                                                ) => {
                                                                                  const error_message =
                                                                                    'Error at deploy function.';
                                                                                  dispatch(
                                                                                    {
                                                                                      type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                      error: error_message,
                                                                                    }
                                                                                  );
                                                                                  rollBack(
                                                                                    rollBackInfo
                                                                                  );
                                                                                }
                                                                              );
                                                                          }
                                                                        )
                                                                        .catch(
                                                                          (
                                                                            error
                                                                          ) => {
                                                                            const error_message =
                                                                              'Error at deploy API.';
                                                                            dispatch(
                                                                              {
                                                                                type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                                error: error_message,
                                                                              }
                                                                            );
                                                                            rollBack(
                                                                              rollBackInfo
                                                                            );
                                                                          }
                                                                        );
                                                                    }
                                                                  )
                                                                  .catch(
                                                                    (
                                                                      error
                                                                    ) => {
                                                                      const error_message =
                                                                        'Error at create resource.';
                                                                      dispatch(
                                                                        {
                                                                          type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                          error: error_message,
                                                                        }
                                                                      );
                                                                      rollBack(
                                                                        rollBackInfo
                                                                      );
                                                                    }
                                                                  );
                                                              }
                                                            )
                                                            .catch(
                                                              (
                                                                error
                                                              ) => {
                                                                const error_message =
                                                                  'Error at create Cognito pool.';
                                                                dispatch(
                                                                  {
                                                                    type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                                    error: error_message,
                                                                  }
                                                                );
                                                                rollBack(
                                                                  rollBackInfo
                                                                );
                                                              }
                                                            );
                                                        }
                                                      )
                                                      .catch(
                                                        (error) => {
                                                          const error_message =
                                                            'Error at create API Gateway.';
                                                          dispatch({
                                                            type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                            error: error_message,
                                                          });
                                                          rollBack(
                                                            rollBackInfo
                                                          );
                                                        }
                                                      );
                                                  }
                                                )
                                                .catch((error) => {
                                                  const error_message =
                                                    'Error at create Cognito.';
                                                  dispatch({
                                                    type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                    error: error_message,
                                                  });
                                                  rollBack(
                                                    rollBackInfo
                                                  );
                                                });
                                            })
                                            .catch((error) => {
                                              const error_message =
                                                'Error at create Dynamodb.';
                                              dispatch({
                                                type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                                error: error_message,
                                              });
                                              rollBack(
                                                rollBackInfo
                                              );
                                            });
                                        })
                                        .catch((error) => {
                                          const error_message =
                                            'Error at build project.';
                                          dispatch({
                                            type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                            error: error_message,
                                          });
                                          rollBack(rollBackInfo);
                                        });
                                    })
                                    .catch((error) => {
                                      const error_message =
                                        'Error at create build project.';
                                      dispatch({
                                        type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                        error: error_message,
                                      });
                                      rollBack(rollBackInfo);
                                    });
                                })
                                .catch((error) => {
                                  const error_message =
                                    'Error at create function.';
                                  dispatch({
                                    type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                    error: error_message,
                                  });
                                  rollBack(rollBackInfo);
                                });
                            })
                            .catch((error) => {
                              const error_message =
                                'Error at upload clone to s3 buckets.';
                              dispatch({
                                type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                                error: error_message,
                              });
                              rollBack(rollBackInfo);
                            });
                        }
                      })
                      .catch((error) => {
                        const error_message =
                          'Error at get s3 buckets.';
                        dispatch({
                          type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                          error: error_message,
                        });
                        rollBack(rollBackInfo);
                      });
                  })
                  .catch((error) => {
                    const error_message =
                      'Error at clone lambda function.';
                    dispatch({
                      type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                      error: error_message,
                    });
                    rollBack(rollBackInfo);
                  });
              })
              .catch((error) => {
                const error_message = 'Error at clone static site.';
                dispatch({
                  type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
                  error: error_message,
                });
                rollBack(rollBackInfo);
              });
          })
          .catch((error) => {
            const error_message = 'Error at add static bucket.';
            dispatch({
              type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
              error: error_message,
            });
            rollBack(rollBackInfo);
          });
      })
      .catch((error) => {
        const error_message = 'Error at add static bucket.';
        dispatch({
          type: CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
          error: error_message,
        });
        rollBack(rollBackInfo);
      });
  };
};

export const EC2StrapiWithDomain = (
  prefix,
  awsCred,
  habitat_id,
  domain_name
) => {
  return dispatch => {
    let fullDomain;
    const hash = randomHash({ length: 32 });
    const name = `STRAPI-TEMPLATE-${Date.now()}`;
    const ec2Data = {
      name,
      habitat_id,
      instance_type: 't3.small'
    }

    if (prefix) {
      fullDomain = `${prefix}.${domain_name}`;
    } else {
      fullDomain = domain_name;
    }

    let rollBackInfo = {
      habitat_id,
      rol_id: null,
    };

    dispatch({
      type: CREATE_EC2_STRAPI_WITHDOMAIN_BEGIN,
      loading_message: 'Creating template...'
    });
    
    getAWSRoles(habitat_id)
    .then(response => {
      const awsRoles = response.data;
      const roleArn = awsRoles[awsRoles.length - 1].arn;
      rollBackInfo.rol_id = awsRoles[awsRoles.length - 1].id;
      createEC2(ec2Data)
      .then(response => {
        const instance = response.data.instance;
        const templateData = {
          name: name.replace(/[^A-Z0-9]/gi, '-'),
          domain_name: fullDomain,
          habitat_id,
          category: 'strapi'
        }
        
        createTemplate(templateData)
        .then(response => {
          const template = response.data.template;
          const requestData = {
            site_id: template.id,
            domain_name,
            prefix,
            habitat_id
          }

          requestDomain(requestData)
          .then(response => {
            const elasticData = {
              habitat_id
            }
  
            addElasticIP(instance.id, elasticData)
            .then(response => {
              const publicDns = response.data.public_dns;
              const dbEndpoint = `http://${publicDns}:5050`;
              const devEndpoint = `http://${publicDns}:1337`;
              const distributionData = {
                site_id: template.id,
                distribution_name: name.replace(/[^A-Z0-9]/gi, '-'),
                end_point: publicDns,
                domain_name: fullDomain,
                habitat_id,
                root_object: 'index.html'
              }
              
              contentDistribution(distributionData)
              .then(response => {
                let domainPrefix;
                const cloudFrontDns = response.data.cloud_front_domain;
                prefix.length > 0
                  ? (domainPrefix = `${prefix}.`)
                  : (domainPrefix = null);
                const domainData = {
                  dns_name: cloudFrontDns,
                  domain_name,
                  type: 'A',
                  prefix: domainPrefix,
                  cf_hosted_zone_id: 'Z2FDTNDATAQYW2',
                  habitat_id,
                };
                const prodEndpoint = `http://${publicDns}`;
                addDomian(domainData)
                .then(response => {
                  const bucketName = `strapi-build-template-${Date.now()}`;
                   
                  addBucket(bucketName, habitat_id, awsCred.region)
                  .then(response => {
                    const addBuildData = {
                      bucket: bucketName,
                      file: 'buildspec.yml',
                      content: buildspec,
                      habitat_id
                    }
    
                    addBuildspec(addBuildData)
                    .then(response => {
                      const serverData = {
                        bucket: bucketName,
                        file: 'server.js',
                        content: serverStrapi,
                        habitat_id
                      }
                      addBuildspec(serverData)
                      .then(response => {
                        const buildData = {
                          habitat_id,
                          project_name: name.replace(/[^A-Z0-9]/gi, '-'),
                          artifac_name: bucketName,
                          buildspec,
                          source_location: bucketName,
                          artifac_location: bucketName,
                          service_role: roleArn,
                          zip: 'NONE',
                          site_id: template.id
                        }
                        
                        createProject(buildData)
                        .then(response => {
                          const build_id = response.data.params.id;
                          const sshData = {
                            instance_user: 'ubuntu',
                            instance_id: instance.id,
                            ec2_host: prodEndpoint,
                            jwt_secret: hash,
                            habitat_id,
                            build_id
                          }
                          DBPasswordGenerator(instance.id, elasticData)
                          .then(response => {
                            instanceBuild(sshData)
                            .then(response => {
                              const payload = {
                                dbEndpoint,
                                devEndpoint,
                                prodEndpoint
                              }
                              dispatch({
                                type: CREATE_EC2_STRAPI_WITHDOMAIN_SUCCESS,
                                payload
                              });
                            })
                            .catch(error => {
                              dispatch({
                                type: CREATE_EC2_STRAPI_WITHDOMAIN_ERROR,
                                error: error.response.data.error
                              });
                              rollBack(rollBackInfo);
                            });
                          })
                          .catch(error => {
                            dispatch({
                              type: CREATE_EC2_STRAPI_WITHDOMAIN_ERROR,
                              error: error.response.data.error
                            });
                            rollBack(rollBackInfo);
                          });                  
                        })
                        .catch(error => {
                          dispatch({
                            type: CREATE_EC2_STRAPI_WITHDOMAIN_ERROR,
                            error: error.response.data.error
                          });
                          rollBack(rollBackInfo);
                        });
                      })
                      .catch(error => {
                        dispatch({
                          type: CREATE_EC2_STRAPI_WITHDOMAIN_ERROR,
                          error: error.response.data.error
                        });
                        rollBack(rollBackInfo);
                      });
                    })
                    .catch(error => {
                      dispatch({
                        type: CREATE_EC2_STRAPI_WITHDOMAIN_ERROR,
                        error: error.response.data.error
                      });
                      rollBack(rollBackInfo);
                    });
                  })
                  .catch(error => {
                    dispatch({
                      type: CREATE_EC2_STRAPI_WITHDOMAIN_ERROR,
                      error: error.response.data.error
                    });
                    rollBack(rollBackInfo);
                  });
                })
                .catch(error => {
                  dispatch({
                    type: CREATE_EC2_STRAPI_WITHDOMAIN_ERROR,
                    error: error.response.data.error
                  });
                  rollBack(rollBackInfo);
                });
              })
              .catch(error => {
                dispatch({
                  type: CREATE_EC2_STRAPI_WITHDOMAIN_ERROR,
                  error: error.response.data.error
                });
                rollBack(rollBackInfo);
              });
            })
            .catch(error => {
              dispatch({
                type: CREATE_EC2_STRAPI_WITHDOMAIN_ERROR,
                error: error.response.data.error
              });
              rollBack(rollBackInfo);
            }); 
          })
          .catch(error => {
            dispatch({
              type: CREATE_EC2_STRAPI_WITHDOMAIN_ERROR,
              error: error.response.data.error
            });
            rollBack(rollBackInfo);
          });
        })
        .catch(error => {
          dispatch({
            type: CREATE_EC2_STRAPI_WITHDOMAIN_ERROR,
            error: error.response.data.error
          });
          rollBack(rollBackInfo);
        });
      })
      .catch(error => {
        dispatch({
          type: CREATE_EC2_STRAPI_WITHDOMAIN_ERROR,
          error: error.response.data.error
        });
        rollBack(rollBackInfo);
      });
    })
    .catch(error => {
      dispatch({
        type: CREATE_EC2_STRAPI_WITHDOMAIN_ERROR,
        error: error.response.data.error
      });
      rollBack(rollBackInfo);
    });
  }
};


export const EC2StrapiWithoutDomain = (
  awsCred,
  habitat_id
) => {
  return dispatch => {
    let directory_name;
    const hash = randomHash({ length: 32 });
    const name = `STRAPI-TEMPLATE-${Date.now()}`;
    const ec2Data = {
      name,
      habitat_id,
      instance_type: 't3.small'
    }

    let rollBackInfo = {
      habitat_id,
      rol_id: null,
    };
  
    dispatch({
      type: CREATE_EC2_STRAPI_WITHOUTDOMAIN_BEGIN,
      loading_message: 'Creating template...'
    });
    
    getAWSRoles(habitat_id)
    .then(response => {
      const awsRoles = response.data;
      const roleArn = awsRoles[awsRoles.length - 1].arn;
      rollBackInfo.rol_id = awsRoles[awsRoles.length - 1].id;
      createEC2(ec2Data)
      .then(response => {
        const instance = response.data.instance;
        const templateData = {
          name: name.replace(/[^A-Z0-9]/gi, '-'),
          domain_name: '',
          habitat_id,
          category: 'strapi'
        }
        
        createTemplate(templateData)
        .then(response => {
          const template = response.data.template;
          const elasticData = {
            habitat_id
          }
          
          addElasticIP(instance.id, elasticData)
          .then(response => {
            const publicDns = response.data.public_dns;
            const dbEndpoint = `http://${publicDns}:5050`;
            const devEndpoint = `http://${publicDns}:1337`;
            const distributionData = {
              site_id: template.id,
              distribution_name: name.replace(/[^A-Z0-9]/gi, '-'),
              end_point: publicDns,
              domain_name: '',
              habitat_id,
              root_object: 'index.html'
            }
            
            contentDistribution(distributionData)
            .then(response => {
              const prodEndpoint = `http://${publicDns}`;
              const bucketName = `strapi-build-template-${Date.now()}`;
               
              addBucket(bucketName, habitat_id, awsCred.region)
              .then(response => {
                const addBuildData = {
                  bucket: bucketName,
                  file: 'buildspec.yml',
                  content: buildspec,
                  habitat_id
                }
                
                addBuildspec(addBuildData)
                .then(response => {
                  const serverData = {
                    bucket: bucketName,
                    file: 'server.js',
                    content: serverStrapi,
                    habitat_id
                  }
                  addBuildspec(serverData)
                  .then(response => {
                    const buildData = {
                      habitat_id,
                      project_name: name.replace(/[^A-Z0-9]/gi, '-'),
                      artifac_name: bucketName,
                      buildspec,
                      source_location: bucketName,
                      artifac_location: bucketName,
                      service_role: roleArn,
                      zip: 'NONE',
                      site_id: template.id
                    }
                    
                    createProject(buildData)
                    .then(response => {
                      const build_id = response.data.params.id;
                      const sshData = {
                        instance_user: 'ubuntu',
                        instance_id: instance.id,
                        ec2_host: prodEndpoint,
                        jwt_secret: hash,
                        habitat_id,
                        build_id
                      }
  
                      DBPasswordGenerator(instance.id, elasticData)
                      .then(response => {
                        instanceBuild(sshData)
                        .then(response => {
                          const payload = {
                            dbEndpoint,
                            devEndpoint,
                            prodEndpoint
                          }
                          dispatch({
                            type: CREATE_EC2_STRAPI_WITHOUTDOMAIN_SUCCESS,
                            payload
                          });
                        })
                        .catch(error => {
                          dispatch({
                            type: CREATE_EC2_STRAPI_WITHOUTDOMAIN_ERROR,
                            error: error.response.data.error
                          });
                          rollBack(rollBackInfo);
                        });
                      })
                      .catch(error => {
                        dispatch({
                          type: CREATE_EC2_STRAPI_WITHOUTDOMAIN_ERROR,
                          error: error.response.data.error
                        });
                        rollBack(rollBackInfo);
                      });                  
                    })
                    .catch(error => {
                      dispatch({
                        type: CREATE_EC2_STRAPI_WITHOUTDOMAIN_ERROR,
                        error: error.response.data.error
                      });
                      rollBack(rollBackInfo);
                    });
                  })
                  .catch(error => {
                    dispatch({
                      type: CREATE_EC2_STRAPI_WITHOUTDOMAIN_ERROR,
                      error: error.response.data.error
                    });
                    rollBack(rollBackInfo);
                  });
                })
                .catch(error => {
                  dispatch({
                    type: CREATE_EC2_STRAPI_WITHOUTDOMAIN_ERROR,
                    error: error.response.data.error
                  });
                  rollBack(rollBackInfo);
                });
              })
              .catch(error => {
                dispatch({
                  type: CREATE_EC2_STRAPI_WITHOUTDOMAIN_ERROR,
                  error: error.response.data.error
                });
                rollBack(rollBackInfo);
              });
            })
            .catch(error => {
              dispatch({
                type: CREATE_EC2_STRAPI_WITHOUTDOMAIN_ERROR,
                error: error.response.data.error
              });
              rollBack(rollBackInfo);
            });
          })
          .catch(error => {
            dispatch({
              type: CREATE_EC2_STRAPI_WITHOUTDOMAIN_ERROR,
              error: error.response.data.error
            });
            rollBack(rollBackInfo);
          }); 
        })
        .catch(error => {
          dispatch({
            type: CREATE_EC2_STRAPI_WITHOUTDOMAIN_ERROR,
            error: error.response.data.error
          });
          rollBack(rollBackInfo);
        });
      })
      .catch(error => {
        dispatch({
          type: CREATE_EC2_STRAPI_WITHOUTDOMAIN_ERROR,
          error: error.response.data.error
        });
        rollBack(rollBackInfo);
      });
    })
    .catch(error => {
      dispatch({
        type: CREATE_EC2_STRAPI_WITHOUTDOMAIN_ERROR,
        error: error.response.data.error
      });
      rollBack(rollBackInfo);
    });
  }
};