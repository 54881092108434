import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  newFunction,
  editFunction,
  deleteFunction
} from '../../actions/funcs.actions';
import { getClones } from '../../actions/git-clone.actions';
import { Button, Alert, Col, Row, Form, Popover, OverlayTrigger } from 'react-bootstrap';
import { runtimeList } from '../../models/runtime';

const HabitatManageFunc = ({
  deleteFunction,
  editFunction,
  newFunction,
  getClones,
  gitClones,
  awsRoles,
  team,
  awsCreds,
  s3Buckets,
  habitat,
  handleShowModal,
  handleSelectFeatureModal
}) => {
  const [role, setRole] = useState('');
  const [handler, setHandler] = useState('');
  const [runtime, setRuntime] = useState('');
  const [gitClone, setGitClone] = useState('');

  const handleRemove = (func) => {
    deleteFunction(func.id, habitat.id);
  }

  useEffect(() => {
    getClones(team)
  }, [getClones]);

  const handleNewFunc = () => {
    const clone = gitClones.find(clone => clone.id === gitClone);
    newFunction(
      team,
      role,
      runtime,
      handler,
      clone.git_hash,
      awsCreds,
      s3Buckets,
      habitat.id,
      clone.project_name
    );
    // setShow(false);
    setHandler('');
    setRuntime('');
    handleShowModal()
  };


  return (
    <div className={'new-function-container'}>
      <div className="inputs-new-aws-credential-func">
        <p>Repository</p>
        <Form.Control
          as="select"
          defaultValue={'DEFAULT'}
          onChange={e => setGitClone(e.target.value)}
        >
          <option disabled value="DEFAULT">Select a Repository</option>
          {gitClones.map(clone => (
            team.name ? (
              clone.team_id && (
                <option key={clone.id} value={clone.id}>{clone.project_name}</option>
              )
            ) : (
              !clone.team_id && (
                <option key={clone.id} value={clone.id}>{clone.project_name}</option>
              )
            )
          ))}
        </Form.Control>
      </div>
      <div className="inputs-new-aws-credential-func">
        <p>Role</p>
        <Form.Control
          as="select"
          defaultValue={'DEFAULT'}
          onChange={e => setRole(e.target.value)}
        >
          <option disabled value="DEFAULT">Select a Role</option>
          {awsRoles.map(role => (
            <option key={role.id} value={role.arn}>{role.role_name}</option>
          ))}
        </Form.Control>
      </div>

      <div className="inputs-new-aws-credential-func">
        <p>Handler</p>
        <Form.Control
          type="text"
          aria-label="handler"
          aria-describedby="handler"
          value={handler}
          onChange={e => setHandler(e.target.value)}
          placeholder="Handler"
        />
      </div>

      <div className="inputs-new-aws-credential-func">
        <p>RunTime</p>
        <Form.Control as="select" defaultValue={'DEFAULT'} onChange={e => setRuntime(e.target.value)}>
          <option disabled value="DEFAULT">Select a Runtime</option>
          {runtimeList().map((run) => (
            <option key={run.Identifier} value={run.Identifier}>{run.Name}</option>
          ))}
        </Form.Control>
      </div>
      <p className="text-modal-1">*A S3 Bucket will be created</p>

      <div className="button-next-modal">
        <Button onClick={() => handleSelectFeatureModal('')}>
          Back to resources
        </Button>
        <Button disabled={!role || !gitClone || !runtime || !handler} onClick={() => handleNewFunc()}>
          Create
        </Button>
      </div>
    </div>
  )
}

const mapState = (state, props) => ({
  habitat: state.habitat.habitat,
  team: state.team.team,
  funcs: state.func.funcs,
  currentUser: state.user.user,
  loading: state.gitClone.loading,
  s3Buckets: state.awsS3.s3Buckets,
  awsRoles: state.awsRoles.awsRoles,
  gitClones: state.gitClone.gitClones,
  loadingFunction: state.func.loading,
  loadingMessage: state.func.loading_message,
  stepAppTour: state.appTour.stepAppTour,
  awsCreds: state.awsCreds.awsCredentials,
  statusAppTour: state.appTour.stateAppTour,
  onboardingClassFuncs: state.appTour.onboardingClassFuncs,
  error: state.func.error
})

const mapDispatch = {
  deleteFunction,
  editFunction,
  newFunction,
  getClones,
}

export default connect(mapState, mapDispatch)(HabitatManageFunc)