import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import HabitatTopMenu from '../components/Habitats/HabitatTopMenu';
import HabitatBuildLogs from '../components/Habitats/HabitatBuildLogs';
import HabitatBuildHistory from '../components/Habitats/HabitatBuildHistory';
import { listBuilds, listBuildLogs, listExecutions, listBuildStatus, deleteBuildCICD } from '../actions/aws-code-build.actions';
import { faGlobe, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { retrieveHabitat } from '../actions/habitat.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HabitatCIDC = ({
  params,
  builds,
  habitat,
  loading,
  buildLogs,
  listBuilds,
  buildsExecut,
  listBuildLogs,
  listExecutions,
  retrieveHabitat,
  listBuildStatus,
  deleteBuildCICD,
  errorBuildHistory,
  errorLogs,
  loadingMessage
}) => {

  useEffect(() => {
    if (habitat.id) {
      listBuilds(habitat.id);
    } else {
      retrieveHabitat(params.habitatId);
      listBuilds(params.habitatId);
    }
  }, []);

  return (
    <div>
      <div className="init-ctn title-section-ui title-section-ui-habitats">
        <div className="habitat-sub-menu">
          <div className="sub-menu-side-l">
            <h2>Habitats / <span>{habitat.name}</span></h2>
          </div>
          <HabitatTopMenu
            habitat={habitat}
          />
        </div>
      </div>

      <div className="habitat-settings-container-feature">
        <div className="habitat-settings-left-container builds-container-left">
          {habitat && (
            <HabitatBuildHistory
              builds={builds}
              habitat={habitat}
              loading={loading}
              buildsExecut={buildsExecut}
              listBuildLogs={listBuildLogs}
              listExecutions={listExecutions}
              listBuildStatus={listBuildStatus}
              deleteBuildCICD={deleteBuildCICD}
              buildLogs={buildLogs}
              errorBuildHistory={errorBuildHistory}
              loadingMessage={loadingMessage}
            />
          )}
        </div>

        <div className="habitat-settings-right-container">
          <div className="build-logs-container-cont">
            <p className="title-build">Logs</p>
            {errorLogs && (
              <div className="error-display text-danger">
                <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {errorLogs}</p>
              </div>
            )}

            {buildLogs ? (
              <HabitatBuildLogs
                loading={loading}
                buildLogs={buildLogs}
              />
            ) : (
              <div className="empty-div">No log selected.</div>
            )}
          </div>

        </div>
      </div>
    </div>
  );
};

const mapState = (state, props) => ({
  params: props.match.params,
  habitat: state.habitat.habitat,
  builds: state.awsCodeBuild.builds,
  loading: state.awsCodeBuild.loading,
  buildLogs: state.awsCodeBuild.buildLogs,
  buildsExecut: state.awsCodeBuild.buildsExecutions,
  errorBuildHistory: state.awsCodeBuild.error,
  errorLogs: state.awsCodeBuild.error_logs,
  loadingMessage: state.awsCodeBuild.loading_message
});

const mapDispatch = {
  listBuilds,
  listBuildLogs,
  listExecutions,
  listBuildStatus,
  deleteBuildCICD,
  retrieveHabitat,
};

export default connect(
  mapState,
  mapDispatch
)(HabitatCIDC);
