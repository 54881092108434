import React, { useState } from 'react';
import closeIcon from '../../../assets/images/icons/close-icon.svg';
import trash from '../../../assets/images/icons/trash.svg';
import authIcon from '../../../assets/images/icons/habitats/usersIcon.svg';
import enabledIcon from '../../../assets/images/icons/habitats/enabledUser.svg';
import disabledIcon from '../../../assets/images/icons/habitats/disabledUser.svg';
import actionsUser from '../../../assets/images/icons/habitats/actionsUser.svg';
import { Button, Form, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import HabitatAuthManagement from '../../../components/Habitats/HabitatAuthManagement';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const AuthModal = (props) => {
    const [showToolTip, setShowToolTip] = useState(false);
   

    const handleShowToolTip = (user) => {
        setShowToolTip(!showToolTip)
        props.handleSelectUser(user);
    }


    const handleShowToolTipT = () => {
        setShowToolTip(!showToolTip)
        props.setShowManagement(true);
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <p onClick={() => handleShowToolTipT()}>Reset Password</p>
            <p onClick={() => handleShowToolTipT()}>Enable or Disable User</p>
        </Tooltip>
    );

    return (
        <div className="modal-kor-small-right">
            <div className="box-modal-options-container-small-right">

                <div className="close-title-delete">
                    <div onClick={() => props.setShowDetailsModal(false)}>
                        <img src={closeIcon} />
                        <h3>{props.selectedCognitoName}</h3>
                    </div>

                    <div>
                        <Button onClick={() => props.handleDeleteCognito()}>
                            <img src={trash} /> Remove Pool
                        </Button>
                    </div>
                </div>

                <div className="auth-modal">
                    <div className="icon-storage-box">
                        <div className="img-box-storage">
                            <img src={authIcon} />
                        </div>
                        <div className="info-storage-bucket">
                            <p className="name-storage-box">{props.selectedCognitoName}</p>
                            <p className="last-updated-text"><span>Created On</span> </p>
                            <div className="managed-by-repo">
                                <p className="managed-by-text">Managed</p>
                                <div className="avatar-repo-author">
                                    <div className="avatar-1">
                                        <p>U</p>
                                    </div>
                                    <div className="name-author">
                                        <p>Unknown</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="table-users">
                        <Table>
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Status</th>
                                    <th>E-mail</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.userList.map(user => (
                                    <tr key={user.id}>
                                        <td>{user.username}</td>

                                        {user.enabled ? (
                                            <td className="status-user disabled-user">
                                                <div>
                                                    <img src={enabledIcon} />
                                                </div>
                                            </td>
                                        ) : (
                                            <td className="status-user disabled-user">
                                                <div>
                                                    <img src={disabledIcon} />
                                                </div>
                                            </td>
                                        )}
                                        <td>{user.attributes[2].value}</td>
                                        <td className="manage-user">
                                            <OverlayTrigger
                                                placement="bottom"
                                                show={showToolTip}
                                                overlay={renderTooltip}
                                                user={user}
                                                handleSelectUser={props.handleSelectUser}
                                            >
                                                <div onClick={() => handleShowToolTip(user)}>
                                                    <img src={actionsUser} />
                                                </div>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                    {props.showManagement && (
                        <HabitatAuthManagement
                            setShowDetailsModal={props.setShowDetailsModal}
                            selectedCognitoName={props.selectedCognitoName}
                            userList={props.userList}
                            handleSelectUser={props.handleSelectUser}
                            selectedUser={props.selectedUser}
                            selectedCognito={props.selectedCognito}
                            handleDisableUser={props.handleDisableUser}
                            habitat={props.habitat}
                            setResetPass={props.setResetPass}
                            handleShowReset={props.handleShowReset}
                            handleResetPassword={props.handleResetPassword}
                            handleShowDisableModal={props.handleShowDisableModal}
                            messageDisable={props.messageDisable}
                            resetMessage={props.resetMessage}
                            handleEnableUser={props.handleEnableUser}
                            showResetPass={props.showResetPass}
                            resetPass={props.resetPass}
                            setShowManagement={props.setShowManagement}
                        />
                    )}


                </div>

            </div>
        </div>
    )
}

export default AuthModal;