import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Form, Button } from 'react-bootstrap';
import authIcon from '../../assets/images/icons/habitats/usersIcon.svg';
import Moment from 'moment';
import eye from '../../assets/images/icons/eye-open.svg';

const HabitatAuthList = (props) => {

    return (
        <div className="list-habitat-auth">
            <div className="auth-box-container">
                {props.cognitoList.length === 0 ? (
                    <div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Users</th>
                                    <th scope="col">Created At</th>
                                </tr>
                            </thead>
                        </table>
                        <div className="empty-section-auth">
                            <p>No user pool yet.</p>
                        </div>
                    </div>
                ) : (
                    <div>
                        {props.cognitoList.map(cognito => (
                            <div className="auth-box" key={cognito.id}>
                                {/* <div className="check-radio">
                                        <div onChange={props.handleSelectedRadio}>
                                            <fieldset>
                                                <Form.Check
                                                    type="radio"
                                                    aria-label="radio 1"
                                                    name="s3Bucket"
                                                    value={cognito.id}
                                                    id={cognito.id}
                                                />
                                            </fieldset>
                                        </div>
                                    </td> */}
                                <div className="box-feature-name-auth">
                                    <div className="box-feature-name-icon">
                                        <img src={authIcon} />
                                    </div>
                                    <div className="auth-name-author">
                                        <p className="name-auth-box">{cognito.name}</p>
                                        <p className="last-update-auth"><span>Last Update</span> {Moment(cognito.created_at).format('lll')}</p>
                                        <div className="managed-by-repo">
                                            <p className="managed-by-text">Created by</p>
                                            <div className="avatar-repo-author">
                                                <div className="avatar-1">
                                                    <p>U</p>
                                                </div>
                                                <div className="name-author">
                                                    <p>Unknown</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="box-feature-item">
                                    <div>1</div>
                                    <p>Users</p>
                                </div>

                                <div className="button-delete-details">
                                    <Button onClick={() => props.handleSelectPool(cognito.name, cognito.user_pool_id, props.habitat.id)}>
                                        <img src={eye} /> View Details
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default HabitatAuthList;