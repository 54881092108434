import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import closeIcon from '../../../assets/images/icons/close-icon.svg';
import lambdasIcon from '../../../assets/images/icons/habitats/lambdasIcon.svg'
import storageIcon from '../../../assets/images/icons/habitats/storageIcon.svg'
import sitesIcon from '../../../assets/images/icons/habitats/sitesIcon.svg';
import databaseIcon from '../../../assets/images/icons/habitats/databaseIcon.svg';
import apiIcon from '../../../assets/images/icons/habitats/apiIcon.svg'
import usersIcon from '../../../assets/images/icons/habitats/usersIcon.svg';
import buildIcon from '../../../assets/images/icons/habitats/buildIcon.svg';
import EC2Icon from '../../../assets/images/icons/habitats/EC2Icon.svg';
import Button from 'react-bootstrap/Button';
import eye from '../../../assets/images/icons/eye-open.svg';
import HabitatManageFunc from '../HabitatManageFunc';
import HabitatNewAPIGateway from '../HabitatNewAPIGateway';
import HabitatNewS3Bucket from '../HabitatNewS3Bucket';
import HabitatNewUserPool from '../HabitatNewUserPool';
import HabitatNewDatabase from '../HabitatNewDatabase';
import { listAWSS3 } from '../../../actions/aws-s3.actions';
import { listFunctions } from '../../../actions/funcs.actions';
import { listAWSCreds } from '../../../actions/aws-creds.actions';
import { fetchAWSRole } from '../../../actions/aws-roles.actions';
import { listCognito } from '../../../actions/aws-cognito.actions';
import { getApiList } from '../../../actions/aws-apigateway.actions';
import { createAPI, createCrudApi, createCognitoAPI, createCognitoCrudApi } from '../../../actions/aws-apigateway.actions';

const AddResourcesModal = ({
    team,
    funcs,
    params,
    habitat,
    awsRoles,
    awsCreds,
    cognitos,
    s3Buckets,
    createAPI,
    listAWSS3,
    getApiList,
    listCognito,
    listAWSCreds, 
    fetchAWSRole, 
    createCrudApi, 
    listFunctions,
    handleShowModal,
    createCognitoAPI, 
    createCognitoCrudApi,
    selectedFeatureModal,
    handleSelectFeatureModal,
}) => {
    useEffect(() => {
        if (habitat.id) {
          listAWSS3(habitat.id);
          getApiList(habitat.id);
          listCognito(habitat.id);
          listAWSCreds(habitat.id);
          fetchAWSRole(habitat.id);
          listFunctions(habitat.id);
        } else {
          listAWSS3(params.habitatId);
          getApiList(params.habitatId);
          listCognito(params.habitatId);
          listAWSCreds(params.habitatId);
          fetchAWSRole(params.habitatId);
          listFunctions(params.habitatId);
          retrieveHabitat(params.habitatId);
        }
    }, []);
    return (
        <>
            <div className="modal-kor-small-right">
                <div className="box-modal-options-container-small-right">
                    <div className="close-title" onClick={() => handleShowModal()}>
                        <img src={closeIcon} />
                        {selectedFeatureModal === '' && (
                            <h3>Create a new resource</h3>
                        )}

                        {selectedFeatureModal === 'Lambda' && (
                            <h3>Create new function</h3>
                        )}

                        {selectedFeatureModal === 'APIs' && (
                            <h3>Create new API Gateway</h3>
                        )}

                        {selectedFeatureModal === 'Storage' && (
                            <h3>Create new Bucket</h3>
                        )}

                        {selectedFeatureModal === 'Auth' && (
                            <h3>Create new User Pool</h3>
                        )}

                        {selectedFeatureModal === 'Database' && (
                            <h3>Create new Database</h3>
                        )}

                    </div>

                    {selectedFeatureModal === '' && (
                        <p className="text-modal-muted">Create a new resource for your habitat, you’ll be able to configure it further in the next steps.</p>
                    )}

                    {selectedFeatureModal === 'Lambda' && (
                        <p className="text-modal-muted">Select your repository, role, handler and runtime for create a new function.</p>
                    )}

                    {selectedFeatureModal === 'APIs' && (
                        <p className="text-modal-muted">Create a new API or CRUD for your habitat.</p>
                    )}

                    {selectedFeatureModal === 'Storage' && (
                        <p className="text-modal-muted">Create a new bucket for your habitat.</p>
                    )}

                    {selectedFeatureModal === 'Auth' && (
                        <p className="text-modal-muted">Create a new User Pool for your habitat.</p>
                    )}

                    {selectedFeatureModal === 'Database' && (
                        <p className="text-modal-muted">Create a new Database for your habitat.</p>
                    )}



                    {selectedFeatureModal === '' && (
                        <div className="create-resources-list">
                            <div className="box-resource-modal" onClick={() => handleSelectFeatureModal('Lambda')}>
                                <div className="icon-box-resource-modal lambdas-icon">
                                    <img src={lambdasIcon} />
                                </div>
                                <p>
                                    <b>Lambda</b>
                                </p>
                            </div>

                            <div className="box-resource-modal" onClick={() => handleSelectFeatureModal('Auth')}>
                                <div className="icon-box-resource-modal auth-icon">
                                    <img src={usersIcon} />
                                </div>
                                <p>
                                    <b>Authentication</b>
                                </p>
                            </div>

                            <div className="box-resource-modal" onClick={() => handleSelectFeatureModal('Sites')}>
                                <div className="icon-box-resource-modal sites-icon">
                                    <img src={sitesIcon} />
                                </div>
                                <p>
                                    <b>Sites</b>
                                </p>
                            </div>

                            <div className="box-resource-modal" onClick={() => handleSelectFeatureModal('APIs')}>
                                <div className="icon-box-resource-modal apis-icon">
                                    <img src={apiIcon} />
                                </div>
                                <p>
                                    <b>APIs</b>
                                </p>
                            </div>

                            <div className="box-resource-modal" onClick={() => handleSelectFeatureModal('Storage')}>
                                <div className="icon-box-resource-modal storage-icon">
                                    <img src={storageIcon} />
                                </div>
                                <p>
                                    <b>Bucket</b>
                                </p>
                            </div>

                            <div className="box-resource-modal" onClick={() => handleSelectFeatureModal('Database')}>
                                <div className="icon-box-resource-modal database-icon">
                                    <img src={databaseIcon} />
                                </div>
                                <p>
                                    <b>Database</b>
                                </p>
                            </div>

                            <div className="box-resource-modal" onClick={() => handleSelectFeatureModal('Instance')}>
                                <div className="icon-box-resource-modal stepfunction-icon">
                                    <img src={EC2Icon} />
                                </div>
                                <p>
                                    <b>EC2 Instance</b>
                                </p>
                            </div>
                        </div>
                    )}

                    {selectedFeatureModal === 'Lambda' && (
                        <HabitatManageFunc
                            handleShowModal={handleShowModal}
                            handleSelectFeatureModal={handleSelectFeatureModal}
                        />
                    )}

                    {selectedFeatureModal === 'APIs' && (
                        <HabitatNewAPIGateway
                            team={team}
                            habitat={habitat}
                            cognitos={cognitos}
                            awsCreds={awsCreds}
                            awsRoles={awsRoles}
                            s3Buckets={s3Buckets}
                            createAPI={createAPI}
                            createCrudApi={createCrudApi}
                            handleShowModal={handleShowModal}
                            createCognitoAPI={createCognitoAPI}
                            createCognitoCrudApi={createCognitoCrudApi}
                            handleSelectFeatureModal={handleSelectFeatureModal}
                        />
                    )}

                    {selectedFeatureModal === 'Storage' && (
                        <HabitatNewS3Bucket
                            handleShowModal={handleShowModal}
                            handleSelectFeatureModal={handleSelectFeatureModal}
                        />
                    )}

                    {selectedFeatureModal === 'Auth' && (
                        <HabitatNewUserPool 
                            handleSelectFeatureModal={handleSelectFeatureModal}
                            handleShowModal={handleShowModal}
                        />
                    )}

                    {selectedFeatureModal === 'Database' && (
                        <HabitatNewDatabase 
                            handleSelectFeatureModal={handleSelectFeatureModal}
                            handleShowModal={handleShowModal}
                        />
                    )}


                </div>
            </div>
        </>
    )
}

const mapState = (state, props) => ({
    params: props.params,
    team: state.team.team,
    funcs: state.func.funcs,
    habitat: state.habitat.habitat,
    s3Buckets: state.awsS3.s3Buckets,
    awsRoles: state.awsRoles.awsRoles,
    cognitos: state.cognito.cognitoList,
    awsCreds: state.awsCreds.awsCredentials,
});

const mapDispatch = {
    listAWSS3,
    createAPI,
    getApiList, 
    listCognito,
    listAWSCreds,
    fetchAWSRole,
    listFunctions,
    createCrudApi, 
    createCognitoAPI, 
    createCognitoCrudApi
}

export default connect(
    mapState,
    mapDispatch
) (AddResourcesModal);