import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import HabitatTopMenu from '../components/Habitats/HabitatTopMenu';
import HabitatRightMenu from '../components/Habitats/HabitatRightMenu';
import { retrieveHabitat } from '../actions/habitat.actions';
import HabitatResourcesMenu from '../components/Habitats/HabitatResourcesMenu';
import HabitatSettings from '../components/Habitats/HabitatSettings';
import {
    selectSetUpFeature,
    requestDeleteHabitat
} from '../actions/habitat.actions';
import {
    createStatic,
    createStaticWithoutDomain
} from '../actions/templates.actions';
import {
    getStaticSites, removeTemplate
} from '../actions/templates.actions';
import { getAwsDomains } from '../actions/aws-route53.actions';
import { getClones } from '../actions/git-clone.actions';
import { listGitCreds } from '../actions/git-creds.actions';
import HabitatMonitorPage from './HabitatMonitorPage';
import HabitatCostsPage from './HabitatCostsPage';
import HabitatDeploymentPage from './HabitatDeploymentPage';
import HabitatLogsPage from './HabitatLogsPage';
import HabitatStepFuncPage from './HabitatStepFuncPage';
import HabitatCreds from '../components/Habitats/HabitatCredentials';
import HabitatRoles from '../components/Habitats/HabitatRoles';
import HabitatAPIGatewayPage from './HabitatAPIGatewayPage';
import HabitatFunc from '../components/Habitats/HabitatFunc';
import HabitatS3 from '../components/Habitats/HabitatS3';
import HabitatTemplates from '../components/Habitats/HabitatTemplates';
import HabitatDBPage from './HabitatDBPage';
import HabitatAuthPage from './HabitatAuthPage';
import HabitatResourceReport from '../components/Habitats/HabitatResourceReport';
import AddResourcesModal from '../components/Habitats/modals/AddResourcesModal';


const HabitatMainPage = ({
    habitat,
    params,
    retrieveHabitat,
    habitatSetUp,
    selectSetUpFeature,
    resources,
    team,
    domains,
    awsCreds,
    getClones,
    templates,
    gitClones,
    createStatic,
    createStaticWithoutDomain,
    listGitCreds,
    getAwsDomains,
    getStaticSites,
    removeTemplate,
    loadingTemplate,
    errorTemplates,
    requestDeleteHabitat
}) => {
    useEffect(() => {
        if (!habitat.id) {
            retrieveHabitat(params.habitatId);
            requestDeleteHabitat(params.habitatId);
        } else {
            requestDeleteHabitat(habitat.id);
        }
    }, [])

    const handleSelectFeature = (feature) => {
        selectSetUpFeature(feature);

        if (feature === habitatSetUp) {
            selectSetUpFeature('');
        }
    };

    const resourcesArray = [];
    const [showModal, setShowModal] = useState(false);
    const [selectedFeatureModal, setSelectedFeatureModal] = useState('');

    const handleShowModal = () => {
        setShowModal(!showModal);
        setSelectedFeatureModal('')
    }

    const handleSelectFeatureModal = (feature) => {
        setSelectedFeatureModal(feature)
    }

    return (
        <>
            {showModal && (
                <AddResourcesModal
                    setShowModal={setShowModal}
                    handleShowModal={ handleShowModal}
                    handleSelectFeatureModal={handleSelectFeatureModal}
                    selectedFeatureModal={selectedFeatureModal}
                />
            )}

            <div className="init-ctn title-section-ui title-section-ui-habitats">
                <div className="habitat-sub-menu">
                    <div className="sub-menu-side-l">
                        <h2>Habitats / <span>{habitat.name}</span></h2>
                    </div>
                    <HabitatTopMenu
                        habitat={habitat}
                        habitatSetUp={habitatSetUp}
                        handleShowModal={handleShowModal}
                    />
                </div>
            </div>

            <div className="habitat-container-c">
                <HabitatRightMenu />

                <div className="content-habitat-selected">
                    <HabitatResourcesMenu
                        habitatSetUp={habitatSetUp}
                        handleSelectFeature={handleSelectFeature}
                    />

                    <div className="content-habitat-item-selected">

                        {habitatSetUp === "All Resources" && (
                            <>
                                <HabitatResourceReport
                                    resources={resources}
                                    resourcesArray={resourcesArray}
                                    habitatSetUp={habitatSetUp}
                                    handleSelectFeature={handleSelectFeature}
                                />
                            </>
                        )}

                        {habitatSetUp === "Monitor" && (
                            <HabitatMonitorPage
                                params={params}
                            />
                        )}

                        {habitatSetUp === "Releases" && (
                            <HabitatDeploymentPage
                                params={params}
                            />
                        )}

                        {habitatSetUp === "Logs" && (
                            <HabitatLogsPage
                                params={params}
                            />
                        )}

                        {habitatSetUp === "Costs" && (
                            <HabitatCostsPage
                                params={params}
                            />
                        )}


                        {habitatSetUp === "Step Functions" && (
                            <HabitatStepFuncPage
                                params={params}
                            />
                        )}

                        {habitatSetUp === "Habitat Setup" && (
                            <>
                                <HabitatCreds
                                    habitat={habitat}
                                />

                                <HabitatRoles
                                    habitat={habitat}
                                />
                            </>
                        )}

                        {habitatSetUp === "APIs" && (
                            <>
                                <HabitatSettings
                                    habitat={habitat}
                                    habitatSetUp={habitatSetUp}
                                />
                                <HabitatAPIGatewayPage
                                    params={params}
                                />
                            </>
                        )}

                        {habitatSetUp === "Functions" && (
                            <>
                                <HabitatSettings
                                    habitat={habitat}
                                    habitatSetUp={habitatSetUp}
                                />
                                <HabitatFunc
                                    habitat={habitat}
                                />
                            </>
                        )}

                        {habitatSetUp === "File Storage" && (
                            <>
                                <HabitatSettings
                                    habitat={habitat}
                                    habitatSetUp={habitatSetUp}
                                />
                                <HabitatS3
                                    habitat={habitat}
                                />
                            </>
                        )}

                        {habitatSetUp === "Sites" && (
                            <>
                                <HabitatSettings
                                    habitat={habitat}
                                    habitatSetUp={habitatSetUp}
                                />
                                <HabitatTemplates
                                    team={team}
                                    habitat={habitat}
                                    domains={domains}
                                    awsCreds={awsCreds}
                                    getClones={getClones}
                                    templates={templates}
                                    gitClones={gitClones}
                                    createStatic={createStatic}
                                    createStaticWithoutDomain={createStaticWithoutDomain}
                                    listGitCreds={listGitCreds}
                                    getAwsDomains={getAwsDomains}
                                    getStaticSites={getStaticSites}
                                    removeTemplate={removeTemplate}
                                    loadingTemplate={loadingTemplate}
                                    errorTemplates={errorTemplates}
                                />
                            </>
                        )}

                        {habitatSetUp === "Databases" && (
                            <>
                                <HabitatSettings
                                    habitat={habitat}
                                    habitatSetUp={habitatSetUp}
                                />
                                <HabitatDBPage
                                    params={params}
                                />
                            </>
                        )}

                        {habitatSetUp === "Auth" && (
                            <>
                                <HabitatSettings
                                    habitat={habitat}
                                    habitatSetUp={habitatSetUp}
                                />
                                <HabitatAuthPage
                                    params={params}
                                />
                            </>
                        )}
                    </div>

                </div>
            </div>
        </>
    )
}

const mapState = (state, props) => ({
    habitat: state.habitat.habitat,
    costs: state.habitat.costs,
    error: state.habitat.error_costs,
    params: props.match.params,
    costsActivation: state.habitat.costsActivation,
    loading: state.habitat.loading,
    loadingMessage: state.habitat.loading_message,
    habitatSetUp: state.habitat.habitatSetup,
    resources: state.habitat.resources_habitat,
    team: state.team.team,
    domains: state.route53.domains,
    gitClones: state.gitClone.gitClones,
    templates: state.templates.templates,
    errorTemplates: state.templates.error,
    awsCreds: state.awsCreds.awsCredentials,
    gitCreds: state.gitCreds.gitCredentials.git_credentials,
})

const mapDispatch = {
    retrieveHabitat,
    selectSetUpFeature,
    getAwsDomains,
    getClones,
    listGitCreds,
    createStaticWithoutDomain,
    createStatic,
    removeTemplate,
    getStaticSites,
    requestDeleteHabitat
}

export default connect(mapState, mapDispatch)(HabitatMainPage);