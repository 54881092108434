import React, { useEffect } from 'react';
import TeamNav from '../components/header/TeamNavbar';
import { connect } from 'react-redux';
import { getServer } from '../actions/server-status.actions';
import InternalServerErrorPage from '../containers/InternalServerErrorPage';
import '../assets/styles/components/MobileTablet.scss';
import telescope from '../assets/images/telescope-image.png';
import { logout } from '../actions/user.actions';
import logo from '../assets/images/Kor-logo.svg';
import mobile from '../assets/images/mobile.svg';
import Button from 'react-bootstrap/Button'
import plus from '../assets/images/icons/overview/plus.svg';
import Avatar from '../components/avatar/Avatar';
import power from '../assets/images/powerbutton1.svg'


const AppTeamLayout = ({ children, error, logout, currentUser }) => {
  useEffect(() => {
    getServer();
  }, [getServer]);

  return (
    <>
    {error ? (
    <InternalServerErrorPage />
    ):(
      <div className="d-none d-lg-block">
        <div>
          <TeamNav teamName={children.props.match.params.name} />
          {children}
        </div>
      </div>
      )}
      <div id="mobile-tablet-main" className="d-sm-block d-md-block d-lg-none d-xl-none">
        <div className="mobile-tablet-container">
          <div className="header-kor-mobile">
            <img src={logo} />
            {currentUser && (
              <div className="avatar-logout">
                <div className="avatar-name">
                  <div className="photo-avatar">
                    <Avatar userName={currentUser.name} avatarClass="avatarPicTop" />
                  </div>
                  <p>{currentUser.name}</p>
                </div>
                <div className="logout-img">
                  <img src={power} alt="notifications" onClick={() => logout()} />
                </div>
              </div>
            )}
          </div>
          <div className="message-mobile-container">
            <img src={mobile} />
            <h1>We&apos;re not ready yet!</h1>
            <p>Kor is coming soon for mobile, Click below to suggest features you would like to have.</p>
            <a href="https://getkor.freshdesk.com/support/tickets/new">
              <Button>
                <img src={plus} /> ADD SUGGESTION
            </Button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  error: state.serverStatus.error,
  currentUser: state.user.user,
});

const mapDispatch = {
  getServer,
  logout
};

export default connect(mapState, mapDispatch)(AppTeamLayout);
