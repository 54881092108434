import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import HabitatDynamo from '../Habitats/HabitatDynamo';
import HabitatAuroraDB from '../Habitats/HabitatAuroraDB';

const HabitatNewDatabase = ({
    handleSelectFeatureModal,
    handleShowModal
}) => {
    const [selectedDB, setSelectedDB] = useState('Dynamo');

    const handleSelectedDB = (db) => {
        setSelectedDB(db);
    }

    return (
        <div className="db-create-modal">
            {selectedDB === 'Dynamo' ? (
                <>
                    <HabitatDynamo 
                        handleSelectFeatureModal={handleSelectFeatureModal}
                        handleShowModal={handleShowModal}
                    />
                </>
            ) : (
                <>
                    <HabitatAuroraDB 
                        handleSelectFeatureModal={handleSelectFeatureModal}
                        handleShowModal={handleShowModal}  
                    />
                </>
            )}

        </div>
    )
}

const mapState = (state, props) => ({

})

const mapDispatch = {

}

export default connect(mapState, mapDispatch)(HabitatNewDatabase);