import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

const HabitatNewCRUDForm = ({
  team,
  setShow,
  habitat,
  cognitos,
  awsCreds,
  awsRoles,
  s3Buckets,
  createCrudApi,
  createCognitoCrudApi,
  handleSelectFeatureModal
}) => {
  const [ name, setName ]             = useState('');
  const [ cognito, setCognito ]       = useState('');
  const [ useCognito, setUseCognito ] = useState(false);

  const handlerCreateCrud = () => {
    createCrudApi(team, awsRoles, name, awsCreds, s3Buckets, habitat.id);
    setShow(false);
  }

  const handlerCreateCognitoCrud = () => {
    createCognitoCrudApi(name, habitat, cognito);
    setShow(false);
  }

  return (
    <>
      <Row>
        <Col xs={2} className="p-0 mt-3 mr-2">
          <p className="input-label">
            CRUD name
          </p>
        </Col>
        <Col xs={9} className="p-0 mt-3">
          <Form.Control
            type="text"
            value={name}
            aria-label="Title"
            placeholder="CRUD name"
            aria-describedby="new-title"
            onChange={e => setName(e.target.value)}
          />
        </Col>
        {useCognito && (
          <>
            <Col xs={2} className="p-0 mr-2 mt-5">
              <p className="input-label">
                Add Cognito
              </p>
            </Col>
            <Col xs={9} className="p-0 mb-5 mt-5">
              <Form.Control
                as="select"
                defaultValue={'DEFAULT'}
                onChange={e => setCognito(e.target.value)}
              >
                <option disabled value="DEFAULT">
                  Select Cognito
                </option>
                {cognitos && cognitos.map(cognito => (
                  <option
                    key={cognito.id}
                    value={cognito.id}>
                    {cognito.name}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </>
        )}
        <Col xs={2} className="p-0 mt-4 mr-4">
          <p className="input-label">
            Use Cognito
          </p>
        </Col>
        <Col xs={9} className="p-0 mt-4 ml-2">
          <Form.Check
            type="checkbox"
            checked={useCognito}
            className="check-enable-build"
            onChange={() => setUseCognito(!useCognito)}
          />
        </Col>
      </Row>

      <div className="button-next-modal buttons-api-gateway">
          <Button onClick={() => handleSelectFeatureModal('')}>
            Back to resources
          </Button>
          {useCognito ? (
            <Button 
              onClick={() => handlerCreateCognitoCrud()}
              disabled={!name || !cognito}
            >
              CREATE
            </Button>
          ) : (
            <Button 
              onClick={() => handlerCreateCrud()}
              disabled={!name}
            >
              CREATE
            </Button>
          )}
        </div>
    </>
  );
}

export default HabitatNewCRUDForm;