import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import trash from '../../assets/images/icons/trash.svg';
import Moment from 'moment';
import databaseIcon from '../../assets/images/icons/habitats/databaseIcon.svg';

const HabitatAuroras = (props) => {
    return (
        <>

            {props.auroras.length === 0 ? (
                <div className="blank-section">No Aurora DB added yet.</div>
            ) : (
                    <div className="container-dynamos-box">
                        {props.auroras.map(db => (
                            <div className="funcs-setup-box" key={db.id}>
                                <div className="box-feature-name-funcs">
                                    <div className="box-feature-name-icon"> 
                                        <img src={databaseIcon} />
                                    </div>
                                    <p>{db.database_name}</p>
                                </div>

                                <div className="box-feature-item">
                                    <p><b>Cluster id:</b> {db.db_cluster_identifier}</p>
                                </div>

                                <div className="box-feature-item">
                                    <p><b>Engine: </b> {db.engine}</p>
                                </div>

                                <div className="box-feature-item">
                                    <Button variant="outline-danger" size="sm" onClick={() => props.handleRemoveAurora(db.id)}>Remove</Button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
        </>
    )
}

export default HabitatAuroras;