import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import {
    createUserPool,
} from '../../actions/aws-cognito.actions';

const HabitatNewUserPool = ({
    handleSelectFeatureModal,
    handleShowModal,
    habitat,
    createUserPool
}) => {
    const [newCognitoName, setNewCognitoName] = useState('')
    
    const handleNewPool = () => {
        createUserPool(newCognitoName, habitat.id)
        handleShowModal(false)
    }

    return (
        <>
            <div className="create-s3-input">
                <p>User Pool Name</p>
                <Form.Control
                    type="text"
                    aria-label="handler"
                    aria-describedby="handler"
                    value={newCognitoName}
                    onChange={e => setNewCognitoName(e.target.value)}
                    placeholder="User Pool Name"
                />
            </div>

            <div className="button-next-modal">
                    <Button onClick={() => handleSelectFeatureModal('')}>
                        Back to resources
                    </Button>
                    <Button onClick={() => handleNewPool()} disabled={!newCognitoName}>
                        Create
                    </Button>
            </div>
        </>
    )
}

const mapState = (state, props) => ({
    habitat: state.habitat.habitat,
})

const mapDispatch = {
    createUserPool
}

export default connect(mapState, mapDispatch)(HabitatNewUserPool)
