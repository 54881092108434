import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL + '/auth';
const URLUSER = process.env.REACT_APP_BACKEND_URL + '/users';
const URLRESET = process.env.REACT_APP_BACKEND_URL + '/auth/password';
const URLONBOARDING = process.env.REACT_APP_BACKEND_URL + '/onboarding';

export const loginUser = (username, password) => {    
  return axios.post(URL + '/sign_in', {
    email: username,
    password
  })      
};

export const validToken = () => {    
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/validate_token`, {headers: authToken});
};

export const statusOnboarding = (onboarding) => {
  const data = {onboarding}
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.put(URLONBOARDING, data, {headers: authToken});
}

export const createUser = (username, name, password, country, job_function) => {  
  return axios.post(URL, {
    email: username,
    name: name,
    password: password,
    country: country,
    job_function: job_function
  })   
};

export const updateUser = (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.put(URLUSER, data, {headers: authToken});
}

export const deleteUser = () => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.delete(URLUSER, {headers: authToken});
}

export const passwordNew = (params, password, confirmPassword) => {
  const accessToken = params.get('access-token');
  const email = params.get('uid');
  const client = params.get('client');
  const expiry = params.get('expiry');

  const uuid = email.replace(/%40/g, '@');

  const data = {
    password: password,
    password_confirmation: confirmPassword
  }

  const headers = {
    'content-type': 'application/json',
    'access-token': accessToken,
    'client': client,
    'expiry': expiry,
    'token-type': 'Bearer',
    'uid': uuid
  };

  return axios.put(URLRESET, data, {headers});
}

export const resetUserPassword = (email) => {
  const data = {
    email,
    'redirect_url': URL + '/ResetPasswordForm'
  };

  return axios.post(URL + '/password', data);
}
