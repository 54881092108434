// Teams
export const GET_TEAMS_BEGIN				        = 'GET_TEAMS_BEGIN';
export const GET_TEAMS_SUCCESS				        = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_ERROR				        = 'GET_TEAMS_ERROR';
export const GET_TEAM_BEGIN 				        = 'GET_TEAM_BEGIN';
export const GET_TEAM_SUCCESS 				        = 'GET_TEAM_SUCCESS';
export const GET_TEAM_ERROR 				        = 'GET_TEAM_ERROR';
export const CREATE_TEAM_BEGIN      		        = 'CREATE_TEAM_BEGIN';
export const CREATE_TEAM_SUCCESS    		        = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_ERROR      		        = 'CREATE_TEAM_ERROR';
export const RESTORE_TEAM_BEGIN 			        = 'RESTORE_TEAM_BEGIN';
export const RESTORE_TEAM_SUCCESS 			        = 'RESTORE_TEAM_SUCCESS';
export const RESTORE_TEAM_ERROR 			        = 'RESTORE_TEAM_ERROR';
export const DELETE_TEAM_BEGIN      		        = 'DELETE_TEAM_BEGIN';
export const DELETE_TEAM_SUCCESS    		        = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_ERROR      		        = 'DELETE_TEAM_ERROR';
export const UNSELECT_TEAM_BEGIN                    = 'UNSELECT_TEAM_BEGIN';
export const UNSELECT_TEAM_SUCCESS                  = 'UNSELECT_TEAM_SUCCESS';
export const UNSELECT_TEAM_ERROR                    = 'UNSELECT_TEAM_ERROR';
export const CHANGE_SELECTED_TEAM_BEGIN             = 'CHANGE_SELECTED_TEAM';
export const CHANGE_SELECTED_TEAM_SUCCESS           = 'CHANGE_SELECTED_TEAM_SUCCESS';
export const CHANGE_SELECTED_TEAM_ERROR             = 'CHANGE_SELECTED_TEAM_ERROR';
export const DELETE_TEAM_ERROR_BEGIN                = 'DELETE_TEAM_ERROR_BEGIN'
export const DELETE_TEAM_ERROR_SUCCESS              = 'DELETE_TEAM_ERROR_SUCCESS'
export const DELETE_TEAM_ERROR_ERROR                = 'DELETE_TEAM_ERROR_ERROR'
export const CHANGE_DUPLICATED_TEAM_BEGIN           = 'CHANGE_DUPLICATED_TEAM_BEGIN';
export const CHANGE_DUPLICATED_TEAM_SUCCESS         = 'CHANGE_DUPLICATED_TEAM_SUCCESS';
export const CHANGE_DUPLICATED_TEAM_ERROR           = 'CHANGE_DUPLICATED_TEAM_ERROR';
export const GET_VERIFICATION_DELETE_TEAM_BEGIN     = 'GET_VERIFICATION_DELETE_TEAM_BEGIN';
export const GET_VERIFICATION_DELETE_TEAM_SUCCESS   = 'GET_VERIFICATION_DELETE_TEAM_SUCCESS';
export const GET_VERIFICATION_DELETE_TEAM_ERROR     = 'GET_VERIFICATION_DELETE_TEAM_ERROR';
export const GET_DETAILS_TEAM_BEGIN                 = 'GET_DETAILS_TEAM_BEGIN';
export const GET_DETAILS_TEAM_SUCCESS               = 'GET_DETAILS_TEAM_SUCCESS';
export const GET_DETAILS_TEAM_ERROR                 = 'GET_DETAILS_TEAM_ERROR';
export const GET_TEAMS_WITHMEMBERS_BEGIN            = 'GET_TEAMS_WITHMEMBERS_BEGIN';
export const GET_TEAMS_WITHMEMBERS_SUCCESS          = 'GET_TEAMS_WITHMEMBERS_SUCCESS';
export const GET_TEAMS_WITHMEMBERS_ERROR            = 'GET_TEAMS_WITHMEMBERS_ERROR';

// Members
export const GET_MEMBERS_BEGIN				= 'GET_MEMBERS_BEGIN';
export const GET_MEMBERS_SUCCESS 			= 'GET_MEMBERS_SUCCESS';
export const GET_MEMBERS_ERROR  			= 'GET_MEMBERS_ERROR';
export const INVITE_MEMBER_BEGIN    		= 'INVITE_MEMBER_BEGIN';
export const INVITE_MEMBER_SUCCESS  		= 'INVITE_MEMBER_SUCCESS';
export const INVITE_MEMBER_ERROR    		= 'INVITE_MEMBER_ERROR';
export const REMOVE_MEMBER_BEGIN    		= 'REMOVE_MEMBER_BEGIN';
export const REMOVE_MEMBER_SUCCESS  		= 'REMOVE_MEMBER_SUCCESS';
export const REMOVE_MEMBER_ERROR    		= 'REMOVE_MEMBER_ERROR';

// User
export const SIGNUP_OAUTH_BEGIN        = 'SIGNUP_OAUTH_BEGIN';
export const SIGNUP_OAUTH_SUCCESS      = 'SIGNUP_OAUTH_SUCCESS';
export const LOGIN_BEGIN    				   = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS  				   = 'LOGIN_SUCCESS';
export const LOGIN_ERROR    				   = 'LOGIN_ERROR';
export const SIGNUP_BEGIN   				   = 'SIGNUP_BEGIN';
export const SIGNUP_SUCCESS 				   = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR   			 	   = 'SIGNUP_ERROR';
export const LOGOUT_SUCCESS 				   = 'LOGOUT_SUCCESS';
export const UPDATE_SUBSCRIPTION_PLAN          = 'UPDATE_SUBSCRIPTION_PLAN';
export const UPDATE_USER_BEGIN                 = 'UPDATE_USER_BEGIN';
export const UPDATE_USER_SUCCESS               = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR                 = 'UPDATE_USER_ERROR';
export const DELETE_USER_BEGIN                 = 'DELETE_USER_BEGIN';
export const DELETE_USER_SUCCESS               = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR                 = 'DELETE_USER_ERROR';
export const RESET_PASSWORD_BEGIN              = 'RESET_PASSWORD_BEGIN';
export const RESET_PASSWORD_SUCCESS            = 'RESET_PASSWORD_SUCCCESS';
export const RESET_PASSWORD_ERROR              = 'RESET_PASSWORD_ERROR';

// Payment
export const PAYMENT_BEGIN              		= 'PAYMENT_BEGIN';
export const PAYMENT_SUCCESS            		= 'PAYMENT_SUCCESS';
export const PAYMENT_ERROR              		= 'PAYMENT_ERROR';
export const GET_USER_PAYMENT_BEGIN     		= 'GET_USER_PAYMENT_BEGIN';
export const GET_USER_PAYMENT_SUCCESS   		= 'GET_USER_PAYMENT_SUCCESS';
export const GET_USER_PAYMENT_ERROR     		= 'GET_USER_PAYMENT_ERROR';

// Payment Startup
export const UPDATE_SUBSCRIPTION_PLAN_STARTUP 	= 'UPDATE_SUBSCRIPTION_PLAN_STARTUP';
export const GET_STARTUP_PAYMENT_BEGIN 			= 'GET_STARTUP_PAYMENT_BEGIN';
export const GET_STARTUP_PAYMENT_SUCCESS		= 'GET_STARTUP_PAYMENT_SUCCESS';
export const GET_STARTUP_PAYMENT_ERROR			= 'GET_STARTUP_PAYMENT_ERROR';

// Billing
export const GET_CARD_BEGIN                      = 'GET_CARD_BEGIN'; 
export const GET_CARD_SUCCESS                    = 'GET_CARD_SUCCESS';
export const GET_CARD_ERROR                      = 'GET_CARD_ERROR';
export const ADD_CHECKOUT_BEGIN                  = 'ADD_CHECKOUT_BEGING';
export const ADD_CHECKOUT_SUCCESS                = 'ADD_CHECKOUT_SUCCESS';
export const ADD_CHECKOUT_ERROR                  = 'ADD_CHECKOUT_ERROR'; 
export const ADD_CHECKOUT_CARD_BEGIN             = 'ADD_CHECKOUT_CARD_BEGIN';
export const ADD_CHECKOUT_CARD_SUCCESS           = 'ADD_CHECKOUT_CARD_SUCCESS';
export const ADD_CHECKOUT_CARD_ERROR             = 'ADD_CHECKOUT_CARD_ERROR';
export const GET_STRIPE_CUSTOMER_BEGIN           = 'GET_STRIPE_CUSTOMER_BEGIN';
export const GET_STRIPE_CUSTOMER_SUCCESS         = 'GET_STRIPE_CUSTOMER_SUCCESS';
export const GET_STRIPE_CUSTOMER_ERROR           = 'GET_STRIPE_CUSTOMER_ERROR';
export const ADD_STRIPE_CARD_BEGIN               = 'ADD_STRIPE_CARD_BEGIN';
export const ADD_STRIPE_CARD_SUCCESS             = 'ADD_STRIPE_CARD_SUCCESS';
export const ADD_STRIPE_CARD_ERROR               = 'ADD_STRIPE_CARD_ERROR';
export const GET_STRIPE_CARD_BEGIN               = 'GET_STRIPE_CARD_BEGIN';
export const GET_STRIPE_CARD_SUCCESS             = 'GET_STRIPE_CARD_SUCCESS';
export const GET_STRIPE_CARD_ERROR               = 'GET_STRIPE_CARD_ERROR';
export const REMOVE_STRIPE_CARD_BEGIN            = 'REMOVE_STRIPE_CARD_BEGIN';
export const REMOVE_STRIPE_CARD_SUCCESS          = 'REMOVE_STRIPE_CARD_SUCCESS';
export const REMOVE_STRIPE_CARD_ERROR            = 'REMOVE_STRIPE_CARD_ERROR';
export const GET_STRIPE_INVOICE_BEGIN            = 'GET_STRIPE_INVOICE_BEGIN';
export const GET_STRIPE_INVOICE_SUCCESS          = 'GET_STRIPE_INVOICE_SUCCESS';
export const GET_STRIPE_INVOICE_ERROR            = 'GET_STRIPE_INVOICE_ERROR';
export const ADD_STRIPE_SUBSCRIPTION_BEGIN       = 'ADD_STRIPE_SUBSCRIPTION_BEGIN';
export const ADD_STRIPE_SUBSCRIPTION_SUCCESS     = 'ADD_STRIPE_SUBSCRIPTION_SUCCESS';
export const ADD_STRIPE_SUBSCRIPTION_ERROR       = 'ADD_STRIPE_SUBSCRIPTION_ERROR';
export const UPDATE_STRIPE_SUBSCRIPTION_BEGIN    = 'UPDATE_STRIPE_SUBSCRIPTION_BEGIN';
export const UPDATE_STRIPE_SUBSCRIPTION_SUCCESS  = 'UPDATE_STRIPE_SUBSCRIPTION_SUCCESS';
export const UPDATE_STRIPE_SUBSCRIPTION_ERROR    = 'UPDATE_STRIPE_SUBSCRIPTION_ERROR';
export const CANCEL_STRIPE_SUBSCRIPTION_BEGIN    = 'CANCEL_STRIPE_SUBSCRIPTION_BEGIN';
export const CANCEL_STRIPE_SUBSCRIPTION_SUCCESS  = 'CANCEL_STRIPE_SUBSCRIPTION_SUCCESS';
export const CANCEL_STRIPE_SUBSCRIPTION_ERROR    = 'CANCEL_STRIPE_SUBSCRIPTION_ERROR'; 

//Downgrade
export const DOWNGRADE_BEGIN					= 'DOWNGRADE_BEGIN';
export const DOWNGRADE_SUCCESS					= 'DOWNGRADE_SUCCESS';
export const DOWNGRADE_ERROR					= 'DOWNGRADE_ERROR';

// Invitations
export const GET_INVITATIONS_BEGIN			    = 'GET_INVITATIONS_BEGIN';
export const GET_INVITATIONS_SUCCESS		    = 'GET_INVITATIONS_SUCCESS';
export const GET_INVITATIONS_ERROR			    = 'GET_INVITATIONS_ERROR';
export const ACCEPT_INVITATION_BEGIN		    = 'ACCEPT_INVITATION_BEGIN';
export const ACCEPT_INVITATION_SUCCESS		    = 'ACCEPT_INVITATION_SUCCESS';
export const ACCEPT_INVITATION_ERROR		    = 'ACCEPT_INVITATION_ERROR';
export const REJECT_INVITATION_BEGIN		    = 'REJECT_INVITATION_BEGIN';
export const REJECT_INVITATION_SUCCESS		    = 'REJECT_INVITATION_SUCCESS';
export const REJECT_INVITATION_ERROR		    = 'REJECT_INVITATION_ERROR';

//Team Subscription
export const GET_TEAM_SUBSCRIPTION_BEGIN		= 'GET_TEAM_SUBSCRIPTION_BEGIN';
export const GET_TEAM_SUBSCRIPTION_SUCCESS		= 'GET_TEAM_SUBSCRIPTION_SUCCESS';
export const GET_TEAM_SUBSCRIPTION_ERROR		= 'GET_TEAM_SUBSCRIPTION_ERROR';

// Collections
export const CREATE_COLLECTION_BEGIN      	= 'CREATE_COLLECTION_BEGIN';
export const CREATE_COLLECTION_SUCCESS    	= 'CREATE_COLLECTION_SUCCESS';
export const CREATE_COLLECTION_ERROR      	= 'CREATE_COLLECTION_ERROR';
export const GET_ALL_COLLECTIONS_BEGIN    	= 'GET_ALL_COLLECTIONS_BEGIN';
export const GET_ALL_COLLECTIONS_SUCCESS  	= 'GET_ALL_COLLECTIONS_SUCCESS';
export const GET_ALL_COLLECTIONS_ERROR    	= 'GET_ALL_COLLECTIONS_ERROR';
export const DELETE_COLLECTION_BEGIN      	= 'DELETE_COLLECTION_BEGIN';
export const DELETE_COLLECTION_SUCCESS    	= 'DELETE_COLLECTION_SUCCESS';
export const DELETE_COLLECTION_ERROR      	= 'DELETE_COLLECTION_ERROR';
export const UPDATE_COLLECTION_BEGIN      	= 'UPDATE_COLLECTION_BEGIN';
export const UPDATE_COLLECTION_SUCCESS    	= 'UPDATE_COLLECTION_SUCCESS';
export const UPDATE_COLLECTION_ERROR      	= 'UPDATE_COLLECTION_ERROR';

// Habitats
export const CREATE_HABITAT_BEGIN		                = 'CREATE_HABITAT_BEGIN';
export const CREATE_HABITAT_SUCCESS		  	          = 'CREATE_HABITAT_SUCCESS';
export const CREATE_HABITAT_ERROR		                = 'CREATE_HABITAT_ERROR';
export const GET_ALL_HABITATS_BEGIN		  	          = 'GET_ALL_HABITATS_BEGIN';
export const GET_ALL_HABITATS_SUCCESS	  	          = 'GET_ALL_HABITATS_SUCCESS';
export const GET_ALL_HABITATS_ERROR		  	          = 'GET_ALL_HABITATS_ERROR';
export const DELETE_HABITAT_BEGIN		                = 'DELETE_HABITAT_BEGIN';
export const DELETE_HABITAT_SUCCESS		  	          = 'DELETE_HABITAT_SUCCESS';
export const DELETE_HABITAT_ERROR		                = 'DELETE_HABITAT_ERROR';
export const UPDATE_HABITAT_BEGIN		                = 'UPDATE_HABITAT_BEGIN';
export const UPDATE_HABITAT_SUCCESS		  	          = 'UPDATE_HABITAT_SUCCESS';
export const UPDATE_HABITAT_ERROR		                = 'UPDATE_HABITAT_ERROR';
export const SELECT_HABITAT_BEGIN     		          = 'SELECT_HABITAT_BEGIN';
export const SELECT_HABITAT_SUCCESS   		          = 'SELECT_HABITAT_SUCCESS';
export const SELECT_HABITAT_ERROR     		          = 'SELECT_HABITAT_ERROR';
export const UPDATE_HABFUNC_BEGIN         	        = 'UPDATE_HABFUNC_BEGIN';
export const UPDATE_HABFUNC_SUCCESS       	        = 'UPDATE_HABFUNC_SUCCESS';
export const UPDATE_HABFUNC_ERROR         	        = 'UPDATE_HABFUNC_ERROR';
export const UPDATE_HAB_S3_BEGIN                    = 'UPDATE_HAB_S3_BEGIN';
export const UPDATE_HAB_S3_SUCCESS                  = 'UPDATE_HAB_S3_SUCCESS';
export const UPDATE_HAB_S3_ERROR                    = 'UPDATE_HAB_S3_ERROR';
export const ADD_FUNC_HAB_BEGIN         	          = 'ADD_FUNC_HAB_BEGIN';
export const ADD_FUNC_HAB_SUCCESS       	          = 'ADD_FUNC_HAB_SUCCESS';
export const ADD_FUNC_HAB_ERROR         	          = 'ADD_FUNC_HAB_ERROR';
export const REMOVE_FUNC_HAB_BEGIN      	          = 'REMOVE_FUNC_HAB_BEGIN';
export const REMOVE_FUNC_HAB_SUCCESS    	          = 'REMOVE_FUNC_HAB_SUCCESS';
export const REMOVE_FUNC_HAB_ERROR      	          = 'REMOVE_FUNC_HAB_ERROR';
export const GET_HABITAT_BEGIN          	          = 'GET_HABITAT_BEGIN';
export const GET_HABITAT_SUCCESS        	          = 'GET_HABITAT_SUCCESS';
export const GET_HABITAT_ERROR          	          = 'GET_HABITAT_ERROR';
export const GET_HABITATS_BEGIN                     = 'GET_HABITATS_BEGIN';
export const GET_HABITATS_SUCCESS                   = 'GET_HABITATS_SUCCESS';
export const GET_HABITATS_ERROR                     = 'GET_HABITATS_ERROR';
export const SELECT_CREDS_HAB_BEGIN                 = 'SELECT_CREDS_HAB_BEGIN';
export const SELECT_CREDS_HAB_SUCCESS               = 'SELECT_CREDS_HAB_SUCCESS';
export const SELECT_CREDS_HAB_ERROR                 = 'SELECT_CREDS_HAB_ERROR';
export const GET_ALL_CONNECTED_S3_BEGIN             = 'GET_ALL_CONNECTED_S3_BEGIN';
export const GET_ALL_CONNECTED_S3_SUCCESS           = 'GET_ALL_CONNECTED_S3_SUCCESS'; 
export const GET_ALL_CONNECTED_S3_ERROR             = 'GET_ALL_CONNECTED_S3_ERROR';
export const CONNECT_HAB_S3_BEGIN                   = 'CONNECT_HAB_S3_BEGIN'; 
export const CONNECT_HAB_S3_SUCCESS                 = 'CONNECT_HAB_S3_SUCCESS';
export const CONNECT_HAB_S3_ERROR                   = 'CONNECT_HAB_S3_ERROR';
export const DISCONNECT_HAB_S3_BEGIN                = 'DISCONNECT_HAB_S3_BEGIN'; 
export const DISCONNECT_HAB_S3_SUCCESS              = 'DISCONNECT_HAB_S3_SUCCESS';
export const DISCONNECT_HAB_S3_ERROR                = 'DISCONNECT_HAB_S3_ERROR';
export const REQUEST_DELETE_HABITAT_BEGIN           = 'REQUEST_DELETE_HABITAT_BEGIN';
export const REQUEST_DELETE_HABITAT_SUCCESS         = 'REQUEST_DELETE_HABITAT_SUCCESS';
export const REQUEST_DELETE_HABITAT_ERROR           = 'REQUEST_DELETE_HABITAT_ERROR';
export const GET_HABITAT_COSTS_BEGIN                = 'GET_HABITAT_COSTS_BEGIN';
export const GET_HABITAT_COSTS_SUCCESS              = 'GET_HABITAT_COSTS_SUCCESS';
export const GET_HABITAT_COSTS_ERROR                = 'GET_HABITAT_COSTS_ERROR';
export const GET_HABITAT_COSTS_ACTIVATION_BEGIN     = 'GET_HABITAT_COSTS_ACTIVATION_BEGIN'
export const GET_HABITAT_COSTS_ACTIVATION_SUCCESS   = 'GET_HABITAT_COSTS_ACTIVATION_SUCCESS'
export const GET_HABITAT_COSTS_ACTIVATION_ERROR     = 'GET_HABITAT_COSTS_ACTIVATION_ERROR'


// GIT
export const CLONE_PULL_BEGIN                    = 'CLONE_PULL_BEGIN';
export const CLONE_PULL_SUCCESS                  = 'CLONE_PULL_SUCCESS';
export const CLONE_PULL_ERROR                    = 'CLONE_PULL_ERROR'; 
export const CLONE_CHECKOUT_BEGIN                = 'CLONE_CHECKOUT_BEGIN';
export const CLONE_CHECKOUT_SUCCESS              = 'CLONE_CHECKOUT_SUCCESS';
export const CLONE_CHECKOUT_ERROR                = 'CLONE_CHECKOUT_ERROR';
export const GET_BRANCHES_BEGIN                  = 'GET_BRANCHES_BEGIN';
export const GET_BRANCHES_SUCCESS                = 'GET_BRANCHES_SUCCESS';
export const GET_BRANCHES_ERROR                  = 'GET_BRANCHES_ERROR';
export const INFO_GIT_BEGIN                	     = 'INFO_GIT_BEGIN';
export const INFO_GIT_SUCCESS              		   = 'INFO_GIT_SUCCESS';
export const INFO_GIT_ERROR                		   = 'INFO_GIT_ERROR';
export const VERSION_GIT_BEGIN             		   = 'VERSION_GIT_BEGIN';
export const VERSION_GIT_SUCCESS           		   = 'VERSION_GIT_SUCCESS';
export const VERSION_GIT_ERROR             		   = 'VERSION_GIT_ERROR';
export const VERSION_GIT_COMPOSE_BEGIN     		   = 'VERSION_GIT_COMPOSE_BEGIN';
export const VERSION_GIT_COMPOSE_SUCCESS   		   = 'VERSION_GIT_COMPOSE_SUCCESS';
export const VERSION_GIT_COMPOSE_ERROR     		   = 'VERSION_GIT_COMPOSE_ERROR';
export const BRANCH_GIT_BEGIN					           = 'BRANCH_GIT_BEGIN';
export const BRANCH_GIT_SUCCESS   				       = 'BRANCH_GIT_SUCCESS';
export const BRANCH_GIT_ERROR     				       = 'BRANCH_GIT_ERROR';
export const BRANCH_CHECKOUT_GIT_BEGIN			     = 'BRANCH_CHECKOUT_GIT_BEGIN';
export const BRANCH_CHECKOUT_GIT_SUCCESS   		   = 'BRANCH_CHECKOUT_GIT_SUCCESS';
export const BRANCH_CHECKOUT_GIT_ERROR     		   = 'BRANCH_CHECKOUT_GIT_ERROR';
export const LOG_GIT_BEGIN						           = 'LOG_GIT_BEGIN';
export const LOG_GIT_SUCCESS   					         = 'LOG_GIT_SUCCESS';
export const LOG_GIT_ERROR     					         = 'LOG_GIT_ERROR';
export const LOG_HEAD_BEGIN						           = 'LOG_HEAD_BEGIN';
export const LOG_HEAD_SUCCESS   				         = 'LOG_HEAD_SUCCESS';
export const LOG_HEAD_ERROR     				         = 'LOG_HEAD_ERROR';
export const CREATE_CREDENTIALS_GIT_BEGIN		     = 'CREATE_CREDENTIALS_GIT_BEGIN';
export const CREATE_CREDENTIALS_GIT_SUCCESS	     = 'CREATE_CREDENTIALS_GIT_SUCCESS';
export const CREATE_CREDENTIALS_GIT_ERROR		     = 'CREATE_CREDENTIALS_GIT_ERROR';
export const UPDATE_CREDENTIAL_GIT_BEGIN         = 'UPDATE_CREDENTIAL_GIT_BEGIN';
export const UPDATE_CREDENTIAL_GIT_SUCCESS       = 'UPDATE_CREDENTIAL_GIT_SUCCESS';
export const UPDATE_CREDENTIAL_GIT_ERROR         = 'UPDATE_CREDENTIAL_GIT_ERROR';
export const GET_CREDENTIAL_GIT_BEGIN            = 'GET_CREDENTIAL_GIT_BEGIN';
export const GET_CREDENTIAL_GIT_SUCCESS          = 'GET_CREDENTIAL_GIT_SUCCESS'; 
export const GET_CREDENTIAL_GIT_ERROR            = 'GET_CREDENTIAL_GIT_ERROR';
export const GET_CREDENTIALS_GIT_BEGIN			     = 'GET_CREDENTIALS_GIT_BEGIN';
export const GET_CREDENTIALS_GIT_SUCCESS		     = 'GET_CREDENTIALS_GIT_SUCCESS';
export const GET_CREDENTIALS_GIT_ERROR			     = 'GET_CREDENTIALS_GIT_ERROR';
export const DELETE_CREDENTIAL_GIT_BEGIN         = 'DELETE_CREDENTIAL_GIT_BEGIN';
export const DELETE_CREDENTIAL_GIT_SUCCESS       = 'DELETE_CREDENTIAL_GIT_SUCCESS';
export const DELETE_CREDENTIAL_GIT_ERROR         = 'DELETE_CREDENTIAL_GIT_ERROR';
export const CLONE_GIT_BEGIN					           = 'CLONE_GIT_BEGIN';
export const CLONE_GIT_SUCCESS					         = 'CLONE_GIT_SUCCESS';
export const CLONE_GIT_ERROR					           = 'CLONE_GIT_ERROR';
export const GET_CLONE_GIT_BEGIN                 = 'GET_CLONE_GIT_BEGIN';
export const GET_CLONE_GIT_SUCCESS               = 'GET_CLONE_GIT_SUCCESS';
export const GET_CLONE_GIT_ERROR                 = 'GET_CLONE_GIT_ERROR';
export const DELETE_CLONE_GIT_BEGIN              = 'DELETE_CLONE_GIT_BEGIN';
export const DELETE_CLONE_GIT_SUCCESS            = 'DELETE_CLONE_GIT_SUCCESS';
export const DELETE_CLONE_GIT_ERROR              = 'DELETE_CLONE_GIT_ERROR';
export const SSH_KEYS_BEGIN                      = 'SSH_KEYS_BEGIN';
export const SSH_KEYS_SUCCESS                    = 'SSH_KEYS_SUCCESS';
export const SSH_KEYS_ERROR                      = 'SSH_KEYS_ERROR';
export const GIT_REMOTE_BEGIN                    = 'GIT_REMOTE_BEGIN';
export const GIT_REMOTE_SUCCESS                  = 'GIT_REMOTE_SUCCESS';
export const GIT_REMOTE_ERROR                    = 'GIT_REMOTE_ERROR';
export const ADD_GITHUB_OAUTH_BEGIN              = 'ADD_GITHUB_OAUTH_BEGIN';
export const ADD_GITHUB_OAUTH_SUCCESS            = 'ADD_GITHUB_OAUTH_SUCCESS';
export const ADD_GITHUB_OAUTH_ERROR              = 'ADD_GITHUB_OAUTH_ERROR';
export const ADD_GITLAB_OAUTH_BEGIN              = 'ADD_GITLAB_OAUTH_BEGIN';
export const ADD_GITLAB_OAUTH_SUCCESS            = 'ADD_GITLAB_OAUTH_SUCCESS';
export const ADD_GITLAB_OAUTH_ERROR              = 'ADD_GITLAB_OAUTH_ERROR';


// File System
export const NEW_TEMPLATE_BEGIN     			= 'NEW_TEMPLATE_BEGIN';
export const NEW_TEMPLATE_SUCCESS   			= 'NEW_TEMPLATE_SUCCESS';
export const NEW_TEMPLATE_ERROR     			= 'NEW_TEMPLATE_ERROR';
export const GET_FILE_TREE_BEGIN          = 'GET_FILE_TREE_BEGIN';
export const GET_FILE_TREE_SUCCESS        = 'GET_FILE_TREE_SUCCESS';
export const GET_FILE_TREE_ERROR          = 'GET_FILE_TREE_ERROR';
export const FILE_OPEN_BEGIN          		    = 'FILE_OPEN_BEGIN';
export const FILE_OPEN_SUCCESS        		    = 'FILE_OPEN_SUCCESS';
export const FILE_OPEN_ERROR          		    = 'FILE_OPEN_ERROR';
export const ZIP_FILE_BEGIN                     = 'ZIP_FILE_BEGIN';
export const ZIP_FILE_SUCCESS                   = 'ZIP_FILE_SUCCESS';
export const ZIP_FILE_ERROR                     = 'ZIP_FILE_ERROR';
export const ADD_FILETREE_BEGIN                 = 'ADD_FILETREE_SUCCESS';
export const ADD_FILETREE_SUCCESS               = 'ADD_FILETREE_SUCCESS';
export const ADD_FILETREE_ERROR                 = 'ADD_FILETREE_ERROR';
export const DELETE_FILETREE_BEGIN              = 'DELETE_FILETREE_BEGIN';
export const DELETE_FILETREE_SUCCESS            = 'DELETE_FILETREE_SUCCESS';
export const DELETE_FILETREE_ERROR              = 'DELETE_FILETREE_ERROR';

// AWS Creds
export const CREATE_AWSCRED_BEGIN       		    = 'CREATE_AWSCRED_BEGIN';
export const CREATE_AWSCRED_SUCCESS     		    = 'CREATE_AWSCRED_SUCCESS';
export const CREATE_AWSCRED_ERROR       		    = 'CREATE_AWSCRED_ERROR';
export const GET_ALL_AWSCREDS_BEGIN     		    = 'GET_ALL_AWSCREDS_BEGIN';
export const GET_ALL_AWSCREDS_SUCCESS   		    = 'GET_ALL_AWSCREDS_SUCCESS';
export const GET_ALL_AWSCREDS_ERROR     		    = 'GET_ALL_AWSCREDS_ERROR';
export const DELETE_AWSCRED_BEGIN       		    = 'DELETE_AWSCRED_BEGIN';
export const DELETE_AWSCRED_SUCCESS     		    = 'DELETE_AWSCRED_SUCCESS';
export const DELETE_AWSCRED_ERROR       		    = 'DELETE_AWSCRED_ERROR';
export const UPDATE_AWSCRED_BEGIN       		    = 'UPDATE_AWSCRED_BEGIN';
export const UPDATE_AWSCRED_SUCCESS     		    = 'UPDATE_AWSCRED_SUCCESS';
export const UPDATE_AWSCRED_ERROR       		    = 'UPDATE_AWSCRED_ERROR';
export const GET_AWSCRED_BEGIN                  = 'GET_AWSCRED_BEGIN';
export const GET_AWSCRED_SUCCESS                = 'GET_AWSCRED_SUCCESS'; 
export const GET_AWSCRED_ERROR                  = 'GET_AWSCRED_ERROR';
export const ENCRYPT_REQUEST_AWSCRED_BEGIN      = 'ENCRYPT_REQUEST_AWSCRED_BEGIN';
export const ENCRYPT_REQUEST_AWSCRED_SUCCESS    = 'ENCRYPT_REQUEST_AWSCRED_SUCCESS';
export const ENCRYPT_REQUEST_AWSCRED_ERROR      = 'ENCRYPT_REQUEST_AWSCRED_ERROR';
export const ENCRYPT_KEY_BEGIN                  = 'ENCRYPT_KEY_BEGIN';
export const ENCRYPT_KEY_SUCCESS                = 'ENCRYPT_KEY_SUCCESS';
export const ENCRYPT_KEY_ERROR                  = 'ENCRYPT_KEY_ERROR';
export const GET_ALL_USER_AWSCREDS_BEGIN        = 'GET_ALL_USER_AWSCREDS_BEGIN'; 
export const GET_ALL_USER_AWSCREDS_SUCCESS      = 'GET_ALL_USER_AWSCREDS_SUCCESS'; 
export const GET_ALL_USER_AWSCREDS_ERROR        = 'GET_ALL_USER_AWSCREDS_ERROR';
export const CREATE_AWSCREDS_WITH_URL_BEGIN     = 'CREATE_AWSCREDS_WITH_URL_BEGIN';
export const CREATE_AWSCREDS_WITH_URL_SUCCESS   = 'CREATE_AWSCREDS_WITH_URL_SUCCESS';
export const CREATE_AWSCREDS_WITH_URL_ERROR     = 'CREATE_AWSCREDS_WITH_URL_ERROR';

// Deployments
export const NEW_DEPLOYMENT_BEGIN               = 'NEW_DEPLOYMENT_BEGIN';
export const NEW_DEPLOYMENT_SUCCESS             = 'NEW_DEPLOYMENT_SUCCESS';
export const NEW_DEPLOYMENT_ERROR               = 'NEW_DEPLOYMENT_ERROR';
export const ALL_DEPLOYMENTS_BEGIN              = 'ALL_DEPLOYMENTS_BEGIN';
export const ALL_DEPLOYMENTS_SUCCESS            = 'ALL_DEPLOYMENTS_SUCCESS';
export const ALL_DEPLOYMENTS_ERROR              = 'ALL_DEPLOYMENTS_ERROR';
export const REMOVE_DEPLOYMENT_BEGIN            = 'REMOVE_DEPLOYMENT_BEGIN';
export const REMOVE_DEPLOYMENT_SUCCESS          = 'REMOVE_DEPLOYMENT_SUCCESS';
export const REMOVE_DEPLOYMENT_ERROR            = 'REMOVE_DEPLOYMENT_ERROR';

// AWS SDK
export const GET_S3_FILES_BEGIN            = 'GET_S3_FILES_BEGIN';
export const GET_S3_FILES_SUCCESS          = 'GET_S3_FILES_SUCCESS';
export const GET_S3_FILES_ERROR            = 'GET_S3_FILES_ERROR';
export const CLEAR_S3_BEGIN                = 'CLEAR_S3_BEGIN';
export const CLEAR_S3_SUCCESS              = 'CLEAR_S3_SUCCESS';
export const CLEAR_S3_ERROR                = 'CLEAR_S3_ERROR';
export const CREATE_S3_BEGIN               = 'CREATE_S3_BEGIN';
export const CREATE_S3_SUCCESS             = 'CREATE_S3_SUCCESS';
export const CREATE_S3_ERROR               = 'CREATE_S3_ERROR';
export const GET_S3_BEGIN                  = 'GET_S3_BEGIN';
export const GET_S3_SUCCESS                = 'GET_S3_SUCCESS';
export const GET_S3_ERROR                  = 'GET_S3_ERROR';
export const GET_ALL_S3_BEGIN              = 'GET_ALL_S3_BEGIN';
export const GET_ALL_S3_SUCCESS            = 'GET_ALL_S3_SUCCESS';
export const GET_ALL_S3_ERROR              = 'GET_ALL_S3_ERROR';
export const DELETE_S3_BEGIN               = 'DELETE_S3_BEGIN';
export const DELETE_S3_SUCCESS             = 'DELETE_S3_SUCCESS';
export const DELETE_S3_ERROR               = 'DELETE_S3_ERROR';
export const SET_AWSCRED_BEGIN             = 'SET_AWSCRED_BEGIN';
export const SET_AWSCRED_SUCCESS           = 'SET_AWSCRED_SUCCESS';
export const SET_AWSCRED_ERROR             = 'SET_AWSCRED_ERROR';
export const GET_AWS_METRICS_BEGIN         = 'GET_AWS_METRICS_BEGIN'; 
export const GET_AWS_METRICS_SUCCESS       = 'GET_AWS_METRICS_SUCCESS';
export const GET_AWS_METRICS_ERROR         = 'GET_AWS_METRICS_ERROR';
export const GET_AWS_LOGS_BEGIN            = 'GET_AWS_LOGS_BEGIN';
export const GET_AWS_LOGS_SUCCESS          = 'GET_AWS_LOGS_SUCCESS'; 
export const GET_AWS_LOGS_ERROR            = 'GET_AWS_LOGS_ERROR';
export const GET_AWS_LOG_BEGIN             = 'GET_AWS_LOG_BEGIN';
export const GET_AWS_LOG_SUCCESS           = 'GET_AWS_LOG_SUCCESS'; 
export const GET_AWS_LOG_ERROR             = 'GET_AWS_LOG_ERROR';
export const GET_AWS_OVERVIEW_BEGIN        = 'GET_AWS_OVERVIEW_BEGIN';
export const GET_AWS_OVERVIEW_SUCCESS      = 'GET_AWS_OVERVIEW_SUCCESS'; 
export const GET_AWS_OVERVIEW_ERROR        = 'GET_AWS_OVERVIEW_ERROR';
export const GET_AWS_ALL_LOGS_BEGIN        = 'GET_AWS_ALL_LOGS_BEGIN'; 
export const GET_AWS_ALL_LOGS_SUCCESS      = 'GET_AWS_ALL_LOGS_SUCCESS';
export const GET_ALL_AWS_LOGS_ERROR        = 'GET_ALL_AWS_LOGS_ERROR';
export const GET_IAM_USER_BEGIN            = 'GET_IAM_USER_BEGIN'; 
export const GET_IAM_USER_SUCCESS          = 'GET_IAM_USER_SUCCESS'; 
export const GET_IAM_USER_ERROR            = 'GET_IAM_USER_ERROR';
export const VERIFY_CREDS_BEGIN            = 'VERIFY_CREDS_BEGIN'; 
export const VERIFY_CREDS_SUCCESS          = 'VERIFY_CREDS_SUCCESS';
export const VERIFY_CREDS_ERROR            = 'VERIFY_CREDS_ERROR';
export const CLEAR_VERIFIED_ERROR_STATE    = 'CLEAR_VERIFIED_ERROR_STATE';
export const GET_AWS_DURATION_BEGIN        = 'GET_AWS_DURATION_BEGIN';
export const GET_AWS_DURATION_SUCCESS      = 'GET_AWS_DURATION_SUCCESS'; 
export const GET_AWS_DURATION_ERROR        = 'GET_AWS_DURATION_ERROR';
export const GET_AWS_MEMORY_BEGIN          = 'GET_AWS_MEMORY_BEGIN';
export const GET_AWS_MEMORY_SUCCESS        = 'GET_AWS_MEMORY_SUCCESS'; 
export const GET_AWS_MEMORY_ERROR          = 'GET_AWS_MEMORY_ERROR';
export const GET_ALL_FUNCTIONS_BEGIN       = 'GET_ALL_FUNCTIONS_BEGIN';
export const GET_ALL_FUNCTIONS_SUCCESS     = 'GET_ALL_FUNCTIONS_SUCCESS';
export const GET_ALL_FUNCTIONS_ERROR       = 'GET_ALL_FUNCTIONS_ERROR';
export const GET_AWS_ALL_FUNC_LOGS_BEGIN   = 'GET_AWS_ALL_FUNC_LOGS_BEGIN';
export const GET_AWS_ALL_FUNC_LOGS_SUCCESS = 'GET_AWS_ALL_LOGS_FUNC_SUCCESS';
export const GET_AWS_ALL_FUNC_LOGS_ERROR   = 'GET_AWS_ALL_FUNC_LOGS_ERROR';
export const GET_FUNC_FILTER_BEGIN         = 'GET_FUNC_FILTER_BEGIN';
export const GET_FUNC_FILTER_SUCCESS       = 'GET_FUNC_FILTER_SUCCESS';
export const GET_FUNC_FILTER_ERROR         = 'GET_FUNC_FILTER_ERROR';
export const CHANGE_STATE_FUNC_BEGIN       = 'CHANGE_STATE_FUNC_BEGIN';
export const CHANGE_STATE_FUNC_SUCCESS     = 'CHANGE_STATE_FUNC_SUCCESS';
export const CHANGE_STATE_FUNC_ERROR       = 'CHANGE_STATE_FUNC_ERROR';
export const DELETE_BUILD_BEGIN            = 'DELETE_BUILD_BEGIN';
export const DELETE_BUILD_SUCCESS          = 'DELETE_BUILD_SUCCESS';
export const DELETE_BUILD_ERROR            = 'DELETE_BUILD_ERROR';

//Navbar
export const CHANGE_NAVBAR_CLASS_BEGIN     = 'CHANGE_NAVBAR_CLASS_BEGIN';
export const CHANGE_NAVBAR_CLASS_SUCCESS   = 'CHANGE_NAVBAR_CLASS_SUCCESS';
export const CHANGE_NAVBAR_CLASS_ERROR     = 'CHANGE_NAVBAR_CLASS_ERROR';
export const CHANGE_INITIAL_NAVBAR_BEGIN   = 'CHANGE_INITIAL_NAVBAR_BEGIN';
export const CHANGE_INITIAL_NAVBAR_SUCCESS = 'CHANGE_INITIAL_NAVBAR_SUCCESS';

//Settings
export const SETTINGS_AWSCREDS_TOGGLE      = 'SETTINGS_AWSCREDS_TOGGLE';
export const SETTINGS_GITCREDS_TOGGLE      = 'SETTINGS_GITCREDS_TOGGLE'

//Checkout
export const CREATE_CHECKOUT_BEGIN         = 'CREATE_CHECKOUT_BEGIN';
export const CREATE_CHECKOUT_SUCCESS       = 'CREATE_CHECKOUT_SUCCESS';
export const CREATE_CHECKOUT_ERROR         = 'CREATE_CHECKOUT_ERROR';

// AWS Step Functions
export const GET_STEP_FUNCTION_BEGIN       = 'GET_STEP_FUNCTION_BEGIN'; 
export const GET_STEP_FUNCTION_SUCCESS     = 'GET_STEP_FUNCTION_SUCCESS';
export const GET_STEP_FUNCTION_ERROR       = 'GET_STEP_FUNCTION_ERROR';
export const CREATE_STEP_FUNCTION_BEGIN    = 'CREATE_STEP_FUNCTION_BEGIN';
export const CREATE_STEP_FUNCTION_SUCCESS  = 'CREATE_STEP_FUNCTION_SUCCESS';
export const CREATE_STEP_FUNCTION_ERROR    = 'CREATE_STEP_FUNCTION_ERROR';
export const UPDATE_STEP_FUNCTION_BEGIN    = 'UPDATE_STEP_FUNCTION_BEGIN';
export const UPDATE_STEP_FUNCTION_SUCCESS  = 'UPDATE_STEP_FUNCTION_SUCCESS';
export const UPDATE_STEP_FUNCTION_ERROR    = 'UPDATE_STEP_FUNCTION_ERROR';
export const DELETE_STEP_FUNCTION_BEGIN    = 'DELETE_STEP_FUNCTION_BEGIN';
export const DELETE_STEP_FUNCTION_SUCCESS  = 'DELETE_STEP_FUNCTION_SUCCESS';
export const DELETE_STEP_FUNCTION_ERROR    = 'DELETE_STEP_FUNCTION_ERROR'; 
export const BUILD_FUNCTION_BEGIN          = 'BUILD_FUNCTION_BEGIN';
export const BUILD_FUNCTION_SUCCESS        = 'BUILD_FUNCTION_SUCCESS';
export const BUILD_FUNCTION_ERROR          = 'BUILD_FUNCTION_ERROR';
// AWS Dynamo DB
export const SET_AWS_DYNAMO_BEGIN          = 'SET_AWS_DYNAMO_BEGIN';
export const SET_AWS_DYNAMO_SUCCESS        = 'SET_AWS_DYNAMO_SUCCESS';
export const SET_AWS_DYNAMO_ERROR          = 'SET_AWS_DYNAMO_ERROR';
export const GET_AWS_DYNAMO_BEGIN          = 'GET_AWS_DYNAMO_BEGIN';
export const GET_AWS_DYNAMO_SUCCESS        = 'GET_AWS_DYNAMO_SUCCESS';
export const GET_AWS_DYNAMO_ERROR          = 'GET_AWS_DYNAMO_ERROR';
export const REMOVE_AWS_DYNAMO_BEGIN       = 'REMOVE_AWS_DYNAMO_BEGIN';
export const REMOVE_AWS_DYNAMO_SUCCESS     = 'REMOVE_AWS_DYNAMO_SUCCESS';
export const REMOVE_AWS_DYNAMO_ERROR       = 'REMOVE_AWS_DYNAMO_ERROR';

// Functions
export const GET_TEST_BEGIN               = 'GET_TEST_BEGIN';
export const GET_TEST_SUCCESS             = 'GET_TEST_SUCCESS';
export const GET_TEST_ERROR               = 'GET_TEST_ERROR';
export const STORE_TEST_BEGIN             = 'STORE_TEST_BEGIN';
export const STORE_TEST_SUCCESS           = 'STORE_TEST_SUCCESS';
export const STORE_TEST_ERROR             = 'STORE_TEST_ERROR';
export const UPDATA_CODE_BEGIN            = 'UPDATA_CODE_BEGIN';
export const UPDATA_CODE_SUCCESS          = 'UPDATA_CODE_SUCCESS';
export const UPDATA_CODE_ERROR            = 'UPDATA_CODE_ERROR';
export const CREATE_FUNCTION_BEGIN        = 'CREATE_FUNCTION_BEGIN';
export const CREATE_FUNCTION_SUCCESS      = 'CREATE_FUNCTION_SUCCESS';
export const CREATE_FUNCTION_ERROR        = 'CREATE_FUNCTION_ERROR';
export const GET_ALL_FUNCTION_BEGIN       = 'GET_ALL_FUNCTION_BEGIN';
export const GET_ALL_FUNCTION_SUCCESS     = 'GET_ALL_FUNCTION_SUCCESS';
export const GET_ALL_FUNCTION_ERROR       = 'GET_ALL_FUNCTION_ERROR';
export const DELETE_FUNCTION_BEGIN        = 'DELETE_FUNCTION_BEGIN';
export const DELETE_FUNCTION_SUCCESS      = 'DELETE_FUNCTION_SUCCESS';
export const DELETE_FUNCTION_ERROR        = 'DELETE_FUNCTION_ERROR';
export const UPDATE_FUNCTION_BEGIN        = 'UPDATE_FUNCTION_BEGIN';
export const UPDATE_FUNCTION_SUCCESS      = 'UPDATE_FUNCTION_SUCCESS';
export const UPDATE_FUNCTION_ERROR        = 'UPDATE_FUNCTION_ERROR';
export const INVOKE_FUNCTION_BEGIN        = 'INVOKE_FUNCTION_BEGIN';
export const INVOKE_FUNCTION_SUCCESS      = 'INVOKE_FUNCTION_SUCCESS'; 
export const INVOKE_FUNCTION_ERROR        = 'INVOKE_FUNCTION_ERROR';
export const DEPLOY_FUNCTION_BEGIN        = 'DEPLOY_FUNCTION_BEGIN';
export const DEPLOY_FUNCTION_SUCCESS      = 'DEPLOY_FUNCTION_SUCCESS'; 
export const DEPLOY_FUNCTION_ERROR        = 'DEPLOY_FUNCTION_ERROR';
export const UPDATE_ALL_FUCTIONS_BEGIN    = 'UPDATE_ALL_FUCTIONS_BEGIN';
export const UPDATE_ALL_FUCTIONS_SUCCESS  = 'UPDATE_ALL_FUCTIONS_SUCCESS';
export const UPDATE_ALL_FUCTIONS_ERROR    = 'UPDATE_ALL_FUCTIONS_ERROR';
export const DEPLOY_ALL_FUCTIONS_BEGIN    = 'DEPLOY_ALL_FUCTIONS_BEGIN';
export const DEPLOY_ALL_FUCTIONS_SUCCESS  = 'DEPLOY_ALL_FUCTIONS_SUCCESS';
export const DEPLOY_ALL_FUCTIONS_ERROR    = 'DEPLOY_ALL_FUCTIONS_ERROR';
export const SET_BUILD_STATUS_BEGIN       = 'SET_BUILD_STATUS_BEGIN';
export const SET_BUILD_STATUS_SUCCESS     = 'SET_BUILD_STATUS_SUCCESS';
export const SET_BUILD_STATUS_ERROR       = 'SET_BUILD_STATUS_ERROR';

// Get Server Status
export const GET_STATUS_BEGIN             = 'GET_STATUS_BEGIN';
export const GET_STATUS_SUCCESS           = 'GET_STATUS_SUCCESS';   
export const GET_STATUS_ERROR             = 'GET_STATUS_ERROR';
//AWS Roles
export const CREATE_AWSROLE_BEGIN           = 'CREATE_AWSROLE_BEGIN';
export const CREATE_AWSROLE_SUCCESS         = 'CREATE_AWSROLE_SUCCESS';
export const CREATE_AWSROLE_ERROR           = 'CREATE_AWSROLE_ERROR';
export const DELETE_AWSROLE_BEGIN           = 'DELETE_AWSROLE_BEGIN';
export const DELETE_AWSROLE_SUCCESS         = 'DELETE_AWSROLE_SUCCESS';
export const DELETE_AWSROLE_ERROR           = 'DELETE_AWSROLE_ERROR';
export const GET_AWSROLE_BEGIN              = 'GET_AWSROLE_BEGIN';
export const GET_AWSROLE_SUCCESS            = 'GET_AWSROLE_SUCCESS';
export const GET_AWSROLE_ERROR              = 'GET_AWSROLE_ERROR';

//Onboarding 
export const GET_ONBOARDINGSTEP_BEGIN       = 'GET_ONBOARDINGSTEP_BEGIN';
export const GET_ONBOARDINGSTEP_SUCCESS     = 'GET_ONBOARDINGSTEP_SUCCESS';
export const GET_ONBOARDINGSTEP_ERROR       = 'GET_ONBOARDINGSTEP_ERROR';

//App Tour
export const GET_APPTOURSTATE_BEGIN         = 'GET_APPTOURSTATE_BEGIN';
export const GET_APPTOURSTATE_SUCCESS       = 'GET_APPTOURSTATE_SUCCESS';
export const GET_APPTOURSTATE_ERROR         = 'GET_APPTOURSTATE_ERROR ';
export const NEXTSTEP_BEGIN                 = 'NEXTSTEP_BEGIN';
export const NEXTSTEP_SUCCESS               = 'NEXTSTEP_SUCCESS';
export const NEXTSTEP_ERROR                 = 'NEXTSTEP_ERROR';
export const STOP_TOUR_BEGIN                = 'STOP_TOUR_BEGIN';
export const STOP_TOUR_SUCCESS              = 'STOP_TOUR_SUCCESS';
export const STOP_TOUR_ERROR                = 'STOP_TOUR_ERROR';
export const CHANGE_CLASS_HABITAT_BEGIN     = 'CHANGE_CLASS_HABITAT_BEGIN';
export const CHANGE_CLASS_HABITAT_SUCCESS   = 'CHANGE_CLASS_HABITAT_SUCCESS';
export const CHANGE_CLASS_HABITAT_ERROR     = 'CHANGE_CLASS_HABITAT_ERROR';
export const CHANGE_CLASS_ROLES_BEGIN       = 'CHANGE_CLASS_ROLES_BEGIN';
export const CHANGE_CLASS_ROLES_SUCCESS     = 'CHANGE_CLASS_ROLES_SUCCESS';
export const CHANGE_CLASS_ROLES_ERROR       = 'CHANGE_CLASS_ROLES_ERROR';
export const CHANGE_CLASS_AWS_BEGIN         = 'CHANGE_CLASS_AWS_BEGIN ';
export const CHANGE_CLASS_AWS_SUCCESS       = 'CHANGE_CLASS_AWS_SUCCESS';
export const CHANGE_CLASS_AWS_ERROR         = 'CHANGE_CLASS_AWS_ERROR';
export const CHANGE_CLASS_FUNCTIONS_BEGIN   = 'CHANGE_CLASS_FUNCTIONS_BEGIN';
export const CHANGE_CLASS_FUNCTIONS_SUCCESS = 'CHANGE_CLASS_FUNCTIONS_SUCCESS'; 
export const CHANGE_CLASS_FUNCTIONS_ERROR   = 'CHANGE_CLASS_FUNCTIONS_ERROR ';
export const CHANGE_ONBOARDING_BEGIN        = 'CHANGE_ONBOARDING_BEGIN';
export const CHANGE_ONBOARDING_SUCCESS      = 'CHANGE_ONBOARDING_SUCCESS';
export const CHANGE_ONBOARDING_ERROR        = 'CHANGE_ONBOARDING_ERROR';
export const SELECT_TYPE_APP_TOUR_START     = 'SELECT_TYPE_APP_TOUR_START';
export const SELECT_TYPE_APP_TOUR_SUCCESS   = 'SELECT_TYPE_APP_TOUR_SUCCESS';
export const SELECT_TYPE_APP_TOUR_ERROR     = 'SELECT_TYPE_APP_TOUR_ERROR';

export const HABITAT_SETUP_BEGIN            = 'HABITAT_SETUP_BEGIN';
export const HABITAT_SETUP_ERROR            = 'HABITAT_SETUP_ERROR';
export const HABITAT_SETUP_SUCCESS          = 'HABITAT_SETUP_SUCCESS';

//Templates
export const ADD_GIT_INFO_BEGIN                      = 'ADD_GIT_INFO_BEGIN';
export const CREATE_STATIC_BEGIN                     = 'CREATE_STATIC_BEGIN';
export const DEPLOY_STATIC_BEGIN                     = 'DEPLOY_STATIC_BEGIN';
export const DEPLOY_STATIC_ERROR                     = 'DEPLOY_STATIC_ERROR';
export const CREATE_STATIC_ERROR                     = 'CREATE_STATIC_ERROR';
export const ADD_GIT_INFO_SUCCESS                    = 'ADD_GIT_INFO_SUCCESS';
export const DEPLOY_STATIC_SUCCESS                   = 'DEPLOY_STATIC_SUCCESS';
export const CREATE_STATIC_SUCCESS                   = 'CREATE_STATIC_SUCCESS';
export const ADD_STATIC_INFO_BEGIN                   = 'ADD_STATIC_INFO_BEGIN';
export const ADD_DOMIAN_INFO_BEGIN                   = 'ADD_DOMIAN_INFO_BEGIN';
export const ADD_DOMIAN_INFO_ERROR                   = 'ADD_DOMIAN_INFO_ERROR';
export const ADD_DOMIAN_INFO_SUCCESS                 = 'ADD_DOMIAN_INFO_SUCCESS';
export const ADD_STATIC_INFO_SUCCESS                 = 'ADD_STATIC_INFO_SUCCESS';
export const GET_TEMPLATE_INFO_BEGIN                 = 'GET_TEMPLATE_INFO_BEGIN';
export const GET_STATIC_TEMPLATE_BEGIN               = 'GET_STATIC_TEMPLATE_BEGIN';
export const GET_STATIC_TEMPLATE_ERROR               = 'GET_STATIC_TEMPLATE_ERROR';
export const GET_TEMPLATE_INFO_SUCCESS               = 'GET_TEMPLATE_INFO_SUCCESS';
export const BUILD_AND_UPDATE_BEGIN                  = 'BUILD_AND_UPDATE_BEGIN';
export const BUILD_AND_UPDATE_SUCCESS                = 'BUILD_AND_UPDATE_SUCCESS';
export const BUILD_AND_UPDATE_ERROR                  = 'BUILD_AND_UPDATE_ERROR';
export const GET_STATIC_TEMPLATE_SUCCESS             = 'GET_STATIC_TEMPLATE_SUCCESS';
export const CREATE_STRAPI_TEMPLATE_BEGIN            = 'CREATE_STRAPI_TEMPLATE_BEGIN';
export const CREATE_STRAPI_TEMPLATE_SUCCESS          = 'CREATE_STRAPI_TEMPLATE_SUCCESS';
export const CREATE_STRAPI_TEMPLATE_ERROR            = 'CREATE_STRAPI_TEMPLATE_ERROR';
export const CREATE_STRAPI_WITHOUT_DOMAIN_BEGIN      = 'CREATE_STRAPI_WITHOUT_DOMAIN_BEGIN';
export const CREATE_STRAPI_WITHOUT_DOMAIN_SUCCESS    = 'CREATE_STRAPI_WITHOUT_DOMAIN_SUCCESS';
export const CREATE_STRAPI_WITHOUT_DOMAIN_ERROR      = 'CREATE_STRAPI_WITHOUT_DOMAIN_ERROR';
export const CREATE_STATIC_TEMPLATE_ERROR            = 'CREATE_STATIC_TEMPLATE_ERROR';
export const CREATE_STATIC_TEMPLATE_BEGIN            = 'CREATE_STATIC_TEMPLATE_BEGIN';
export const CREATE_STATIC_TEMPLATE_SUCCESS          = 'CREATE_STATIC_TEMPLATE_SUCCESS';
export const REMOVE_STATIC_TEMPLATE_BEGIN            = 'REMOVE_STATIC_TEMPLATE_BEGIN';
export const REMOVE_STATIC_TEMPLATE_SUCCESS          = 'REMOVE_STATIC_TEMPLATE_SUCCESS';
export const REMOVE_STATIC_TEMPLATE_ERROR            = 'REMOVE_STATIC_TEMPLATE_ERROR';
export const CREATE_WEB_APP_TEMPLATE_BEGIN           = 'CREATE_WEB_APP_TEMPLATE_BEGIN';
export const CREATE_WEB_APP_TEMPLATE_SUCCESS         = 'CREATE_WEB_APP_TEMPLATE_SUCCESS';
export const CREATE_WEB_APP_TEMPLATE_ERROR           = 'CREATE_WEB_APP_TEMPLATE_ERROR';
export const ADD_HABITAT_AND_CREDS_TEMPLATE_BEGIN    = 'ADD_HABITAT_AND_CREDS_TEMPLATE_BEGIN';
export const ADD_HABITAT_AND_CREDS_TEMPLATE_SUCCCESS = 'ADD_HABITAT_AND_CREDS_TEMPLATE_SUCCCESS';
export const ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR    = 'ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR';
export const CHANGE_SITE_STATUS_BEGIN                = 'CHANGE_SITE_STATUS_BEGIN';
export const CHANGE_SITE_STATUS_SUCCESS              = 'CHANGE_SITE_STATUS_SUCCESS';
export const CHANGE_SITE_STATUS_ERROR                = 'CHANGE_SITE_STATUS_ERROR';
export const MANAGE_CLOUDFRONT_BEGIN                 = 'MANAGE_CLOUDFRONT_BEGIN';
export const MANAGE_CLOUDFRONT_SUCCESS               = 'MANAGE_CLOUDFRONT_SUCCESS';
export const MANAGE_CLOUDFRONT_ERROR                 = 'MANAGE_CLOUDFRONT_ERROR';
export const GET_INDEX_INVALIDATION_BEGIN            = 'GET_INDEX_INVALIDATION_BEGIN';
export const GET_INDEX_INVALIDATION_SUCCESS          = 'GET_INDEX_INVALIDATION_SUCCESS';
export const GET_INDEX_INVALIDATION_ERROR            = 'GET_INDEX_INVALIDATION_ERROR';
export const GET_INVALIDATIONS_BEGIN                 = 'GET_INVALIDATIONS_BEGIN';
export const GET_INVALIDATIONS_SUCCESS               = 'GET_INVALIDATIONS_SUCCESS';
export const GET_INVALIDATIONS_ERROR                 = 'GET_INVALIDATIONS_ERROR';
export const CREATE_INVALIDATIONS_BEGIN              = 'CREATE_INVALIDATIONS_BEGIN';
export const CREATE_INVALIDATIONS_SUCCESS            = 'CREATE_INVALIDATIONS_SUCCESS';
export const CREATE_INVALIDATIONS_ERROR              = 'CREATE_INVALIDATIONS_ERROR';
export const CREATE_STATIC_WITHOUTDOMAIN_BEGIN       = 'CREATE_STATIC_WITHOUTDOMAIN_BEGIN';
export const CREATE_STATIC_WITHOUTDOMAIN_SUCCESS     = 'CREATE_STATIC_WITHOUTDOMAIN_SUCCESS';
export const CREATE_STATIC_WITHOUTDOMAIN_ERROR       = 'CREATE_STATIC_WITHOUTDOMAIN_ERROR';
export const CREATE_WEB_APP_WITHOUTDOMAIN_BEGIN      = 'CREATE_WEB_APP_WITHOUTDOMAIN_BEGIN';
export const CREATE_WEB_APP_WITHOUTDOMAIN_SUCCESS    = 'CREATE_WEB_APP_WITHOUTDOMAIN_SUCCESS';
export const CREATE_WEB_APP_WITHOUTDOMAIN_ERROR      = 'CREATE_WEB_APP_WITHOUTDOMAIN_ERROR';
export const CREATE_EC2_STRAPI_WITHDOMAIN_BEGIN      = 'CREATE_EC2_STRAPI_WITHDOMAIN_BEGIN';
export const CREATE_EC2_STRAPI_WITHDOMAIN_SUCCESS    = 'CREATE_EC2_STRAPI_WITHDOMAIN_SUCCESS';
export const CREATE_EC2_STRAPI_WITHDOMAIN_ERROR      = 'CREATE_EC2_STRAPI_WITHDOMAIN_ERROR';
export const CREATE_EC2_STRAPI_WITHOUTDOMAIN_BEGIN   = 'CREATE_EC2_STRAPI_WITHOUTDOMAIN_BEGIN';
export const CREATE_EC2_STRAPI_WITHOUTDOMAIN_SUCCESS = 'CREATE_EC2_STRAPI_WITHOUTDOMAIN_SUCCESS';
export const CREATE_EC2_STRAPI_WITHOUTDOMAIN_ERROR   = 'CREATE_EC2_STRAPI_WITHOUTDOMAIN_ERROR';

//Deployment History
export const GET_DEPLOYMENTHISTORY_BEGIN    = 'GET_DEPLOYMENTHISTORY_BEGIN';
export const GET_DEPLOYMENTHISTORY_ERROR    = 'GET_DEPLOYMENTHISTORY_ERROR';
export const GET_DEPLOYMENTHISTORY_SUCCESS  = 'GET_DEPLOYMENTHISTORY_SUCCESS';
//Route53
export const GET_DOMAINS_BEGIN              = 'GET_DOMAINS_BEGIN';
export const GET_DOMAINS_ERROR              = 'GET_DOMAINS_ERROR';
export const GET_DOMAINS_SUCCESS            = 'GET_DOMAINS_SUCCESS';
// CodeBuild
export const GET_AWS_BUILDS_BEGIN             = 'GET_AWS_BUILDS_BEGIN';
export const GET_AWS_BUILDS_SUCCESS           = 'GET_AWS_BUILDS_SUCCESS';
export const GET_AWS_BUILDS_ERROR             = 'GET_AWS_BUILDS_ERROR';
export const GET_AWS_BUILDS_LOGS_BEGIN        = 'GET_AWS_BUILDS_LOGS_BEGIN';
export const GET_AWS_BUILDS_LOGS_SUCCESS      = 'GET_AWS_BUILDS_LOGS_SUCCESS'; 
export const GET_AWS_BUILDS_LOGS_ERROR        = 'GET_AWS_BUILDS_LOGS_ERROR';
export const GET_AWS_BUILDS_STATUS_BEGIN      = 'GET_AWS_BUILDS_STATUS_BEGIN';
export const GET_AWS_BUILDS_STATUS_SUCCESS    = 'GET_AWS_BUILDS_STATUS_SUCCESS';
export const GET_AWS_BUILDS_STATUS_ERROR      = 'GET_AWS_BUILDS_STATUS_ERROR';
export const GET_AWS_BUILDS_EXECUTION_BEGIN   = 'GET_AWS_BUILDS_EXECUTION_BEGIN';
export const GET_AWS_BUILDS_EXECUTION_SUCCESS = 'GET_AWS_BUILDS_EXECUTION_SUCCESS';
export const GET_AWS_BUILDS_EXECUTION_ERROR   = 'GET_AWS_BUILDS_EXECUTION_ERROR';

//Cognito
export const CREATE_AWS_COGNITO_BEGIN       = 'CREATE_AWS_COGNITO_START';
export const CREATE_AWS_COGNITO_SUCCESS     = 'CREATE_AWS_COGNITO_BEGIN';
export const CREATE_AWS_COGNITO_ERROR       = 'CREATE_AWS_COGNITO_ERROR';
export const GET_LIST_AWS_COGNITO_BEGIN     = 'GET_LIST_AWS_COGNITO_BEGIN';
export const GET_LIST_AWS_COGNITO_SUCCESS   = 'GET_LIST_AWS_COGNITO_SUCCESS';
export const GET_LIST_AWS_COGNITO_ERROR     = 'GET_LIST_AWS_COGNITO_ERROR';
export const DELETE_AWS_COGNITO_BEGIN       = 'DELETE_AWS_COGNITO_BEGIN';
export const DELETE_AWS_COGNITO_SUCCESS      = 'DELETE_AWS_COGNITO_SUCCESS';
export const DELETE_AWS_COGNITO_ERROR       = 'DELETE_AWS_COGNITO_ERROR';
export const LIST_USERS_COGNITO_BEGIN       = 'LIST_USERS_COGNITO_BEGIN';
export const LIST_USERS_COGNITO_SUCCESS     = 'LIST_USERS_COGNITO_SUCCESS';
export const LIST_USERS_COGNITO_ERROR       = 'LIST_USERS_COGNITO_ERROR';
export const DISABLE_USER_COGNITO_BEGIN     = 'DISABLE_USER_COGNITO_BEGIN';
export const DISABLE_USER_COGNITO_SUCCESS   = 'DISABLE_USER_COGNITO_SUCCESS';
export const DISABLE_USER_COGNITO_ERROR     = 'DISABLE_USER_COGNITO_ERROR';
export const DELETE_USER_COGNITO_BEGIN      = 'DELETE_USER_COGNITO_BEGIN';
export const DELETE_USER_COGNITO_SUCCESS    = 'DELETE_USER_COGNITO_SUCCESS';
export const DELETE_USER_COGNITO_ERROR      = 'DELETE_USER_COGNITO_ERROR';
export const RESET_USER_COGNITO_BEGIN       = 'RESET_USER_COGNITO_BEGIN';
export const RESET_USER_COGNITO_SUCCESS     = 'RESET_USER_COGNITO_SUCCESS';
export const RESET_USER_COGNITO_ERROR       = 'RESET_USER_COGNITO_ERROR';
export const ENABLE_USER_COGNITO_BEGIN      = 'ENABLE_USER_COGNITO_BEGIN';
export const ENABLE_USER_COGNITO_SUCCESS     = 'ENABLE_USER_COGITO_SUCCESS';
export const ENABLE_USER_COGNITO_ERROR      = 'ENABLE_USER_COGNITO_ERROR';

//Api Gateway
export const TEST_APIGATEWAY_BEGIN                  = 'TEST_APIGATEWAY_BEGIN';
export const TEST_APIGATEWAY_SUCCESS                = 'TEST_APIGATEWAY_SUCCESS';
export const TEST_APIGATEWAY_ERROR                  = 'TEST_APIGATEWAY_ERROR';
export const CREATE_APIGATEWAY_BEGIN                = 'CREATE_APIGATEWAY_BEGIN';
export const CREATE_APIGATEWAY_SUCCESS              = 'CREATE_APIGATEWAY_SUCCESS';
export const CREATE_APIGATEWAY_ERROR                = 'CREATE_APIGATEWAY_ERROR';
export const GET_METHOD_BEGIN                       = 'GET_METHOD_BEGIN';
export const GET_METHOD_SUCCESS                     = 'GET_METHOD_SUCCESS';
export const GET_METHOD_ERROR                       = 'GET_METHOD_ERROR';
export const DELETE_METHOD_BEGIN                    = 'DELETE_METHOD_BEGIN';
export const DELETE_METHOD_SUCCESS                  = 'DELETE_METHOD_SUCCESS';
export const DELETE_METHOD_ERROR                    = 'DELETE_METHOD_ERROR';
export const GET_RESOURCE_BEGIN                     = 'GET_RESOURCE_BEGIN';
export const GET_RESOURCE_SUCCESS                   = 'GET_RESOURCE_SUCCESS';
export const GET_RESOURCE_ERROR                     = 'GET_RESOURCE_ERROR';
export const GET_APIGATEWAY_BEGIN                   = 'GET_APIGATEWAY_BEGIN';
export const CREATE_METHOD_BEGIN                    = 'CREATE_METHOD_BEGIN';
export const CREATE_METHOD_SUCCESS                  = 'CREATE_METHOD_SUCCESS';
export const CREATE_METHOD_ERROR                    = 'CREATE_METHOD_ERROR';
export const GET_APIGATEWAY_SUCCESS                 = 'GET_APIGATEWAY_SUCCESS';
export const GET_APIGATEWAY_ERROR                   = 'GET_APIGATEWAY_ERROR';
export const DEPLOY_APIGATEWAY_BEGIN                = 'DEPLOY_APIGATEWAY_BEGIN';
export const DEPLOY_APIGATEWAY_SUCCESS              = 'DEPLOY_APIGATEWAY_SUCCESS';
export const DEPLOY_APIGATEWAY_ERROR                = 'DEPLOY_APIGATEWAY_ERROR';
export const CREATE_RESOURCE_APIGATEWAY_BEGIN       = 'CREATE_RESOURCE_APIGATEWAY_BEGIN';
export const CREATE_RESOURCE_APIGATEWAY_SUCCESS     = 'CREATE_RESOURCE_APIGATEWAY_SUCCESS';
export const CREATE_RESOURCE_APIGATEWAY_ERROR       = 'CREATE_RESOURCE_APIGATEWAY_ERROR';
export const DELETE_APIGATEWAY_BEGIN                = 'DELETE_APIGATEWAY_BEGIN';
export const DELETE_APIGATEWAY_SUCCESS              = 'DELETE_APIGATEWAY_SUCCESS';
export const DELETE_APIGATEWAY_ERROR                = 'DELETE_APIGATEWAY_ERROR';
export const CREATE_CRUD_API_BEGIN                  = 'CREATE_CRUD_API_BEGIN';
export const CREATE_CRUD_API_SUCCESS                = 'CREATE_CRUD_API_SUCCESS';
export const CREATE_CRUD_API_ERROR                  = 'CREATE_CRUD_API_ERROR';
export const GET_APIGATEWAY_METRICS_BEGIN           = 'GET_APIGATEWAY_METRICS_BEGIN';
export const GET_APIGATEWAY_METRICS_SUCCESS         = 'GET_APIGATEWAY_METRICS_SUCCESS';
export const GET_APIGATEWAY_METRICS_ERROR           = 'GET_APIGATEWAY_METRICS_ERROR';
export const GET_CLOUDFRONT_METRICS_BEGIN           = 'GET_CLOUDFRONT_METRICS_BEGIN';
export const GET_CLOUDFRONT_METRICS_SUCCESS         = 'GET_CLOUDFRONT_METRICS_SUCCESS';
export const GET_CLOUDFRONT_METRICS_ERROR           = 'GET_CLOUDFRONT_METRICS_ERROR';
// RDS
export const GET_RDS_DB_BEGIN                       = 'GET_RDS_DB_BEGIN';
export const GET_RDS_DB_SUCCESS                     = 'GET_RDS_DB_SUCCESS';
export const GET_RDS_DB_ERROR                       = 'GET_RDS_DB_ERROR';
export const GET_AURORA_DB_BEGIN                    = 'GET_AURORA_DB_BEGIN';
export const GET_AURORA_DB_SUCCESS                  = 'GET_AURORA_DB_SUCCESS';
export const GET_AURORA_DB_ERROR                    = 'GET_AURORA_DB_ERROR';
export const CREATE_RDS_DB_BEGIN                    = 'CREATE_RDS_DB_BEGIN';
export const CREATE_RDS_DB_SUCCESS                  = 'CREATE_RDS_DB_SUCCESS';
export const CREATE_RDS_DB_ERROR                    = 'CREATE_RDS_DB_ERROR';
export const CREATE_AURORA_DB_BEGIN                 = 'CREATE_AURORA_DB_BEGIN';
export const CREATE_AURORA_DB_SUCCESS               = 'CREATE_AURORA_DB_SUCCESS';
export const CREATE_AURORA_DB_ERROR                 = 'CREATE_AURORA_DB_ERROR';
export const DELETE_RDS_DB_BEGIN                    = 'DELETE_RDS_DB_BEGIN';
export const DELETE_RDS_DB_SUCCESS                  = 'DELETE_RDS_DB_SUCCESS';
export const DELETE_RDS_DB_ERROR                    = 'DELETE_RDS_DB_ERROR';
export const DELETE_AURORA_DB_BEGIN                 = 'DELETE_AURORA_DB_BEGIN';
export const DELETE_AURORA_DB_SUCCESS               = 'DELETE_AURORA_DB_SUCCESS';
export const DELETE_AURORA_DB_ERROR                 = 'DELETE_AURORA_DB_ERROR';
//EC2
export const GET_INSTANCES_BEGIN                   = 'GET_INSTANCES_BEGIN';
export const GET_INSTANCES_SUCCESS                 = 'GET_INSTANCES_SUCCESS';
export const GET_INSTANCES_ERROR                   = 'GET_INSTANCES_ERROR';
export const CHANGE_INSTANCE_STATUS_BEGIN          = 'CHANGE_INSTANCE_STATUS_BEGIN';
export const CHANGE_INSTANCE_STATUS_SUCCESS        = 'CHANGE_INSTANCE_STATUS_SUCCESS';
export const CHANGE_INSTANCE_STATUS_ERROR          = 'CHANGE_INSTANCE_STATUS_ERROR';
export const DELETE_INSTANCE_BEGIN                 = 'DELETE_INSTANCE_BEGIN';
export const DELETE_INSTANCE_SUCCESS               = 'DELETE_INSTANCE_SUCCESS';
export const DELETE_INSTANCE_ERROR                 = 'DELETE_INSTANCE_ERROR';
export const GET_INSTANCE_STATUS_BEGIN             = 'GET_INSTANCE_STATUS_BEGIN';
export const GET_INSTANCE_STATUS_SUCCESS           = 'GET_INSTANCE_STATUS_SUCCESS';
export const GET_INSTANCE_STATUS_ERROR             = 'GET_INSTANCE_STATUS_ERROR';