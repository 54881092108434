import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import '../assets/styles/pages/Habitats.scss';
import HabitatTopMenu from '../components/Habitats/HabitatTopMenu';
import { Row, Col, ListGroup, Form, Accordion, Card, Button } from 'react-bootstrap';
import { getAwsLogs, getAwsLog } from '../actions/aws-sdk.actions';
import Spinner from 'react-bootstrap/Spinner';
import Moment from 'moment';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { retrieveHabitat } from '../actions/habitat.actions';

const HabitatLogs = ({
  funcs,
  awsLog,
  params,
  habitat,
  awsLogs,
  loading,
  loading2,
  awsCreds,
  getAwsLog,
  getAwsLogs,
  retrieveHabitat,
}) => {

  const [hours] = useState(3);
  const [funcName, setFuncName] = useState();
  const [endDate, setEndDate] = useState(Moment().subtract(hours, 'hours').format());
  const [startDate, setStartDate] = useState(Moment().format());

  const selectEnvironment = () => {
    if (!funcName) {
      getAwsLogs(habitat, awsLogs.function, startDate, endDate);
    } else {
      getAwsLogs(habitat, funcName, startDate, endDate);
    }
  }

  useEffect(() => {
    if (!habitat.id) {
      retrieveHabitat(params.habitatId);
    }
  }, []);

  const handleLog = async (logName) => {
    getAwsLog(habitat, awsLogs.function, logName);
  }

  return (
    <div>
      {!loading && (
        <div className="habitat-container">
          <div className="logs-white-box">
            <div className="logs-menu">
              <Form.Control
                className="logs-selectbox"
                as="select"
                onChange={e => setFuncName(e.target.value)}
              >
                <option value="all-funcs">Functions</option>
                {funcs.map((func) => (
                  <option key={func.id} value={func.function_name}>{func.function_name}</option>
                ))}
              </Form.Control>
              <Form.Control
                type='date'
                className="logs-selectbox"
                onChange={s => setStartDate(Moment(s.target.value).format('L'))}
              />
              <Form.Control
                type='date'
                className="logs-selectbox"
                onChange={e => setEndDate(Moment(e.target.value).format('L'))}
              />
              <Button className="logs-selectbox" onClick={() => selectEnvironment()} disabled={!startDate || !endDate}>Search</Button>
            </div>
            <div>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <ListGroup>
                  <Accordion>
                    {awsLogs.logStreams.map((log, index) => (
                      <div key={log.log_stream_name}>
                        <Accordion.Toggle className="logs-item" as={Card.Header} eventKey={index} onClick={() => handleLog(log.log_stream_name)}>
                          <Row>
                            <Col xs="3">
                              <div >{Moment(log.creationTime).format('lll')}</div>
                            </Col>
                            <Col>
                              <div >{log.log_stream_name}</div>
                            </Col>
                          </Row>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={index}>
                          <Card.Body>
                            {loading2 && (
                              <div className="d-flex justify-content-center">
                                <Spinner animation="border" variant="primary" />
                              </div>
                            )}
                            {!loading2 && (
                              <div>
                                {awsLog.map((l, ind) => (
                                  <Col key={l.message + ind} className="mt-2">
                                    <code>{l.message}</code>
                                  </Col>
                                ))}
                              </div>
                            )}
                          </Card.Body>
                        </Accordion.Collapse>
                      </div>
                    ))}
                  </Accordion>
                </ListGroup>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapState = (state, props) => ({
  funcs: state.func.funcs,
  awsCreds: state.awsCreds,
  awsLog: state.awsSdk.log,
  params: props.params,
  awsLogs: state.awsSdk.logs,
  loading: state.awsSdk.loading,
  habitat: state.habitat.habitat,
  loading2: state.awsSdk.logLoading,
})

const mapDispatch = {
  getAwsLog,
  getAwsLogs,
  retrieveHabitat
}

export default connect(
  mapState,
  mapDispatch
)(HabitatLogs);
