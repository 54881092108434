import React, { useState, useEffect } from 'react';
import HabitatTopMenu from '../components/Habitats/HabitatTopMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { retrieveHabitat } from '../actions/habitat.actions';
import HabitatDB from '../components/Habitats/HabitatBD'
import '../assets/styles/components/habitats/HabitatDB.scss'
import { createDynamo, removeDynamo } from '../actions/aws-dynamo.actions';
import { getDynamos } from '../actions/aws-dynamo.actions';
import { fetchAurora, removeAurora } from '../actions/aws-rds-actions.js'

const HabitatDBPage = ({
    habitat,
    retrieveHabitat,
    dynamos,
    params,
    getDynamos,
    fetchAurora,
    removeDynamo,
    auroras,
    removeAurora,
    loading,
    loadingMessage,
    error
}) => {
    const [selectedDB, setSelectedDB] = useState('Dynamo');
    const [showDynamoManagement, setShowDynamoManagement] = useState('display-none')
    const [showAuroraManagement, setShowAuroraManagement] = useState('display-none')
    const [showForm, setShowForm] = useState(true);
    const [tableName, setTableName] = useState('');
    const [hash, setHash] = useState(false);
    const [schema, setSchema] = useState(false);
    const [schemaCount, setSchemaCount] = useState(0);
    const [fields, setFields] = useState([
        {
            attribute_name: '',
            attribute_type: '',
            key_type: ''
        }
    ]);

    useEffect(() => {
        if (habitat.id) {
            getDynamos(params.habitatId);
            fetchAurora(params.habitatId)
        } else {
            retrieveHabitat(params.habitatId);
            getDynamos(params.habitatId);
            fetchAurora(params.habitatId)
        }
    }, [])

    const handleShowDynamoManagement = () => {
        setShowDynamoManagement('')
    }

    const handleShowAuroraManagement = () => {
        setShowAuroraManagement('')
    }

    const handleSelectDB = (DB) => {
        setSelectedDB(DB)
    }

    const handleRemoveDynamo = (db) => {
        removeDynamo(db.id);
    }

    const handleCreateDB = () => {
        createDynamo(habitat.id, tableName, fields);
        setTableName('');
        setShowForm(false);
        setFields([
            {
                attribute_name: '',
                attribute_type: '',
                key_type: ''
            }
        ]);
        setHash(false);
        setSchema(false);
        setSchemaCount(0);
    }

    const handleRemoveAurora = (id_db) => {
        removeAurora(habitat.id, id_db)
    }


    return (
        <>
            <HabitatDB
                dynamos={dynamos}
                handleRemoveDynamo={handleRemoveDynamo}
                habitat={habitat}
                handleCreateDB={handleCreateDB}
                handleSelectDB={handleSelectDB}
                selectedDB={selectedDB}
                showDynamoManagement={showDynamoManagement}
                handleShowDynamoManagement={handleShowDynamoManagement}
                auroras={auroras}
                showAuroraManagement={showAuroraManagement}
                handleShowAuroraManagement={handleShowAuroraManagement}
                handleRemoveAurora={handleRemoveAurora}
                loading={loading}
                loadingMessage={loadingMessage}
                error={error}
            />
        </>
    )
}

const mapState = (state, props) => ({
    params: props.params,
    habitat: state.habitat.habitat,
    dynamos: state.awsDynamo.dynamos,
    auroras: state.awsRds.auroraDB,
    loading: state.awsRds.loading,
    loadingMessage: state.awsRds.loading_message,
    error: state.awsRds.error
})

const mapDispatch = {
    retrieveHabitat,
    getDynamos,
    fetchAurora,
    removeAurora,
    removeDynamo
}

export default connect(mapState, mapDispatch)(HabitatDBPage);