import React from 'react';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import usersIcon from '../../assets/images/icons/habitats/usersIcon.svg';

const HabitatAuthBox = ({
  cognitos,
  habitatSetUp,
  handleSelectFeature
}) => {
  return (
    <>
      {habitatSetUp === 'Auth' ? (
        <div className={'auth-selected box-feature'}>
          <div className="box-feature-name">
            <div className="box-feature-name-icon-auth">
                <img src={usersIcon} />
            </div>
            <p>Auth</p>
          </div>

          <div className="box-feature-plus-1">
            <p>{cognitos.length}</p>
          </div>
        </div>
      ) : (
        <div className="box-feature" onClick={() => handleSelectFeature('Auth')}>
          <div className="box-feature-name">
          <div className="box-feature-name-icon-auth">
                <img src={usersIcon} />
            </div>
            <p>Auth</p>
          </div>

          <div className="box-feature-plus-1">
            <p>{cognitos.length}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default HabitatAuthBox;