import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../../constants/routes';
import {
    selectSetUpFeature,
} from '../../actions/habitat.actions';

const HabitatRightMenu = ({
    selectSetUpFeature,
    habitatSetUp
}) => {

    const handleSelectFeature = (feature) => {
        selectSetUpFeature(feature);
    
        if (feature === habitatSetUp) {
          selectSetUpFeature('');
        }
      };

    return(
        <div className="habitat-menu-right-container">
            <p>Menu</p>
            {habitatSetUp === "Monitor" ? (
                <p className="active-menu-habitat" onClick={() => handleSelectFeature('Monitor')}>Status</p>
            ):(
                <p onClick={() => handleSelectFeature('Monitor')}>Status</p>
            )}

            {habitatSetUp === "Releases" ? (
                <p className="active-menu-habitat" onClick={() => handleSelectFeature('Releases')}>Releases</p>
            ):(
                <p onClick={() => handleSelectFeature('Releases')}>Releases</p>
            )}
            
            {habitatSetUp === "Logs" ? (
                <p className="active-menu-habitat" onClick={() => handleSelectFeature('Logs')}>Logs</p>
            ):(
                <p onClick={() => handleSelectFeature('Logs')}>Logs</p>
            )}

            {habitatSetUp === "Costs" ? (
                <p className="active-menu-habitat" onClick={() => handleSelectFeature('Costs')}>Costs</p>
            ):(
                <p onClick={() => handleSelectFeature('Costs')}>Costs</p>
            )}

            {habitatSetUp === "Step Functions" ? (
                <p className="active-menu-habitat" onClick={() => handleSelectFeature('Step Functions')}>Step Functions</p>
            ):(
                <p onClick={() => handleSelectFeature('Step Functions')}>Step Functions</p>
            )}

            {habitatSetUp === "Habitat Setup" ? (
                <p className="active-menu-habitat" onClick={() => handleSelectFeature('Habitat Setup')}>Habitat Setup</p>
            ):(
                <p onClick={() => handleSelectFeature('Habitat Setup')}>Habitat Setup</p>
            )}
        </div>
    )
}

const mapState = (state, props) => ({
    habitatSetUp: state.habitat.habitatSetup,
})

const mapDispatch = {
    selectSetUpFeature
}

export default connect(mapState, mapDispatch)(HabitatRightMenu);