import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../assets/styles/pages/Habitats.scss';
import HabitatTopMenu from '../components/Habitats/HabitatTopMenu';
import { Row, Col, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCode, faGlobe } from '@fortawesome/free-solid-svg-icons';
import gitIcon from '../assets/images/icons/git.svg';
import pythonIcon from '../assets/images/icons/python.svg';
import deployedIcon from '../assets/images/icons/deploy.svg';
import checkIcon from '../assets/images/icons/check.svg';
import exclamationIcon from '../assets/images/icons/exclamation.svg';
import AwsChart from '../components/AWS/AwsChart';
import { getAwsLogs, getAwsData } from '../actions/aws-sdk.actions';
import { listFunctions } from '../actions/funcs.actions';
import Moment from 'moment';
import { nextStepTour, stopTour, changeOnboardingClassHabitat, changeOnboardingClassAWS } from '../actions/app-tour.actions';
import AppTour from '../components/AppTour';
import { changeStatusOnboarding } from '../actions/user.actions';
import { listAWSS3 } from '../actions/aws-s3.actions';
import { listAWSCreds } from '../actions/aws-creds.actions';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { 
  faQuestionCircle, 
  faArrowAltCircleUp, 
  faCheckCircle, 
  faExclamationCircle, 
  faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import { retrieveHabitat } from '../actions/habitat.actions';
import Spinner from 'react-bootstrap/Spinner';
import { faNodeJs } from '@fortawesome/free-brands-svg-icons'


const HabitatDashboard = ({
  funcs,
  params,
  habitat,
  stopTour,
  listAWSS3,
  getAwsData,
  getAwsLogs,
  stepAppTour,
  listAWSCreds,
  nextStepTour,
  listFunctions,
  statusAppTour,
  retrieveHabitat,
  changeStatusOnboarding,
  changeOnboardingClassAWS,
  changeOnboardingClassHabitat,
  loading,
  loadingMessage,
  loadingFuncs,
  loadingMessageFunc
}) => {

  useEffect(() => {
    if (habitat.id) {
      listFunctions(habitat.id);
      listAWSS3(habitat.id);
      listAWSCreds(habitat.id);
    } else {
      retrieveHabitat(params.habitatId);
      listFunctions(params.habitatId);
      listAWSS3(params.habitatId);
      listAWSCreds(params.habitatId);
    }
  }, []);

  const [endTime] = useState(Moment().format());
  const [hours, setHours] = useState(3);
  const [nameFunc, setNameFunc] = useState('Untitled');
  const [startTime, setStartTime] = useState(Moment(endTime).subtract(hours, 'hours').format());
  const [labelChart, setLabelChart] = useState('Invocations');

  const handleSelectFunc = (name) => {
    setNameFunc(name);
    getAwsData(habitat.id, startTime, endTime, labelChart, name);
  }

  const handleChart = () => {
    setNameFunc(funcs[0].function_name);
    if (habitat.id) {
      getAwsData(habitat.id, startTime, endTime, labelChart, funcs[0].function_name);
    } else {
      getAwsData(params.habitatId, startTime, endTime, labelChart, funcs[0].function_name);
    }
  };

  const selectType = varType => {
    setLabelChart(varType);
    getAwsData(habitat.id, startTime, endTime, varType, nameFunc);
  };

  const selectLastHour = varLastHour => {
    setHours(varLastHour);
    setStartTime(Moment()
      .subtract(varLastHour, 'hours')
      .format()
    );
    getAwsData(
      habitat,
      Moment()
        .subtract(varLastHour, 'hours')
        .format(),
      endTime,
      labelChart,
      nameFunc
    );
  };

  return (
      <div className="resources-container">
        <div className="resources-container-dashboard">

          {loading && (
            <div className="spinner-container habitat-loader">
              <Spinner animation="border" variant="primary" />
              <p>{loadingMessage}</p>
            </div>
          )}
          <br />
          {funcs && funcs.length > 0 ? (
            <AwsChart
              selectLastHour={selectLastHour}
              setLabelChart={setLabelChart}
              setStartTime={setStartTime}
              handleChart={handleChart}
              getAwsData={getAwsData}
              selectType={selectType}
              labelChart={labelChart}
              startTime={startTime}
              setHours={setHours}
              nameFunc={nameFunc}
              endTime={endTime}
              habitat={habitat}
              hours={hours}
              funcs={funcs}
            ></AwsChart>
          ) : (
              <div className="blank-section">No resources attached, visit settings to get started.</div>
            )}
          <br /><br />
          <Row className="funcs-dashboard-container">
          <div className="funcs-dashboard">
            <div className="title-deployments title-funcs-dashboard">
              <p>Functions</p>
              <div className="question-mark-documentation-habitat">
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip className="red-tooltip">
                      <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                    </Tooltip>
                  }

                >
                  <a
                    href="https://getkor.freshdesk.com/support/solutions/articles/47001164408-dashboard"
                    rel="noopener noreferrer"
                    className="help"
                    target="_blank"
                  >

                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </a>
                </OverlayTrigger>
              </div>
            </div>

            {loadingFuncs && (
            <div className="spinner-container habitat-loader">
              <Spinner animation="border" variant="primary" />
              <p>{loadingMessageFunc}</p>
            </div>
          )}

            {funcs && funcs.length > 0 ? (
              <ListGroup>
                { funcs.map((func) => (
                  <div key={func.id} className="habitat-func-list-item pos-rel" onClick={() => handleSelectFunc(func.function_name)}>
                    <Row>
                      <Col xs="1">
                        <div className="habitat-function-icon">
                          <FontAwesomeIcon className="func-hab-icon" icon={faFileCode} />
                        </div>
                      </Col>
                      <Col className="habitats-dashboard-functions-title" xs="2">
                        <div className="name">
                          <div className="h-f-name">
                            <h3 className="func-hab-title">{func.function_name}</h3>
                          </div>
                          <div className="git-info-wrapper">
                            <FontAwesomeIcon icon={faCodeBranch} />
                            <span className="git-info">Iteration 3.2 - f2a1166</span>
                          </div>
                        </div>
                      </Col>
                      <Col className="habitats-dashboard-functions" xs="2">
                        <div className="h-f-languague hf-info  func-runtime">
                          <FontAwesomeIcon icon={faNodeJs} />
                          <span className="habitat-function-text">{func.runtime}</span>
                        </div>
                      </Col>
                      <Col className="habitats-dashboard-functions" xs="2">
                        <div className="h-f-deployed hf-info deployed-icon">
                          <FontAwesomeIcon icon={faArrowAltCircleUp} />
                          <span className="habitat-function-text">Deployed</span>
                        </div>
                      </Col>
                      <Col className="habitats-dashboard-functions" xs="2">
                        <div className="h-f-active hf-info active-icon">
                        <FontAwesomeIcon icon={faCheckCircle} />
                          <span className="habitat-function-text">Active</span>
                        </div>
                      </Col>
                      <Col className="habitats-dashboard-functions" xs="2">
                        <div className="h-f-error hf-info error-icon">
                        <FontAwesomeIcon icon={faExclamationCircle} />
                          <span className="habitat-function-text">0 Error</span>
                        </div>
                      </Col>
                      <Col className="habitats-dashboard-functions" xs="1">
                        <div className="h-f-dots hf-info">
                          <div className="habitat-dots">
                            <b>...</b>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))}
              </ListGroup>
            ) : (
                <div className="blank-section">No function attached, visit settings to get started.</div>
              )}
          </div>
        </Row>
        </div>
        <br />
        <br />
        <Row >
          <Col>

          </Col>
        </Row>
      </div>
  )

}

const mapState = (state, props) => ({
  funcs: state.func.funcs,
  habitat: state.habitat.habitat,
  stepAppTour: state.appTour.stepAppTour,
  statusAppTour: state.user.user.onboarding,
  deployments: state.deployment.deployments,
  loading: state.awsSdk.loading,
  loadingMessage: state.awsSdk.loading_message,
  loadingFuncs: state.func.loading,
  loadingMessageFunc: state.func.loading_message
})

const mapDispatch = {
  stopTour,
  listAWSS3,
  getAwsData,
  getAwsLogs,
  listAWSCreds,
  nextStepTour,
  listFunctions,
  retrieveHabitat,
  changeStatusOnboarding,
  changeOnboardingClassAWS,
  changeOnboardingClassHabitat,
}

export default connect(
  mapState,
  mapDispatch
)(HabitatDashboard);
