import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';


const HabitatNewAPIForm = ({
  setShow,
  habitat,
  cognitos,
  createAPI,
  createCognitoApi,
  handleSelectFeatureModal
}) => {

  const [name, setName] = useState('');
  const [cognito, setCognito] = useState('');
  const [useCognito, setUseCognito] = useState(false);

  const handlerCreateApi = () => {
    createAPI(name, habitat.id);
    setShow(false);
  }

  const handlerCreateCognitoApi = () => {
    createCognitoAPI(name, habitat.id, cognito);
    setShow(false);
  }

  return (
    <>
      <Row>
        <Col xs={2} className="p-0 mt-3 mr-2">
          <p className="input-label">
            API name
          </p>
        </Col>
        <Col xs={9} className="p-0 mt-3">
          <Form.Control
            type="text"
            value={name}
            aria-label="Title"
            placeholder="API name"
            aria-describedby="new-title"
            onChange={e => setName(e.target.value)}
          />
        </Col>
        {useCognito && (
          <>
            <Col xs={2} className="p-0 mr-2 mt-5">
              <p className="input-label">
                Add Cognito
              </p>
            </Col>
            <Col xs={9} className="p-0 mb-5 mt-5">
              <Form.Control
                as="select"
                defaultValue={'DEFAULT'}
                onChange={e => setCognito(e.target.value)}
              >
                <option disabled value="DEFAULT">
                  Select Cognito
                </option>
                {cognitos && cognitos.map(cognito => (
                  <option
                    key={cognito.id}
                    value={cognito.id}>
                    {cognito.name}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </>
        )}
        <Col xs={2} className="p-0 mt-4 mr-4">
          <p className="input-label">
            Use Cognito
          </p>
        </Col>
        <Col xs={9} className="p-0 mt-4 ml-2">
          <Form.Check
            type="checkbox"
            checked={useCognito}
            className="check-enable-build"
            onChange={() => setUseCognito(!useCognito)}
          />
        </Col>


      </Row>

      <div className="button-next-modal buttons-api-gateway">
          <Button onClick={() => handleSelectFeatureModal('')}>
            Back to resources
          </Button>
          {useCognito ? (
            <Button 
              onClick={() => handlerCreateCognitoApi()}
              disabled={!name || !cognito}
            >
              CREATE
            </Button>
          ) : (
            <Button 
            onClick={() => handlerCreateApi()}
            disabled={!name}
            >
              CREATE
            </Button>
          )}
        </div>
    </>
  );
}

export default HabitatNewAPIForm;