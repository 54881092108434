import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import routes from '../../constants/routes';
import { Link } from 'react-router-dom';

const LaunchTemplateWebApp = ({
  team,
  name,
  deploy,
  prefix,
  loading,
  repoURI,
  gitCred,
  pathApi,
  habitat,
  nameCred,
  domainName,
  setRepoURI,
  regionCred,
  repoLambda,
  cognitoName,
  selectedCred,
  handleDeploy,
  selectHabitat,
  runtimeLambda,
  handlerLambda,
  apiGatewayName,
  errorTemplates,
  setCognitoName,
  setApiGatewayName,
  siteWithoutDomain,
  endpointWebApp
}) => {
  useEffect(() => {
    if (!repoURI) {
      setRepoURI('https://gitlab.com/kor-comunity/kor-web-app-template.git');
    }
    setCognitoName(`KOR-COGNITO-${name}-${Date.now()}`);
    setApiGatewayName(`KOR-API-GATEWAY-${name}-${Date.now()}`);

  }, []);

  return (
    <>
      {loading ? (
        <Row className="p-5">
          <Col xs={12} className="col-template-text">
            <span className="text-muted mb-5">
              We are connecting all of the components needed to create your web application.
            </span>
          </Col>
          <Col xs={12}>
            <div className="d-flex justify-content-center mt-5 mb-5">
              <div className="loader"></div>
            </div>
          </Col>
          <Col xs={12} className="mt-5 col-template-text">
            <span className="text-muted mt-5" >
              We are completing the infrastructure creation, this could take up to 10 minutes.
            </span>
          </Col>
        </Row>
      ) : (
        deploy ? (
          (errorTemplates ? (
            <Row className="p-5">
              <Col xs={12} className="text-center mt-5 mb-5">
                <h4 className="text-danger"><b>Something went wrong!</b></h4>
                <span className="text-muted">Details: {errorTemplates}</span> <br />
                <Link to={routes.TEMPLATES} className="link-to-templates">
                  <p className="mt-5">Click here to try again.</p>
                </Link>
              </Col>
            </Row>
          ) : (
            <Row className="p-5">
              <Col xs={12} className="text-center mt-5 mb-5">
                <span className="text-muted">
                  Please wait a few moments while your site is being generated, DNS is propagated and your content is distributed globally.
                    </span>
              </Col>

              {siteWithoutDomain === false ? (
                <Col xs={12} className="text-center mb-3">
                  <a className="deploy-link" target="_blank" rel="noopener noreferrer" href={prefix ? `https://${prefix}.${domainName}` : `https://${domainName}`}>
                    {prefix ? (
                      `https://${prefix}.${domainName}`
                    ) : (
                      `https://${domainName}`
                    )}
                  </a>
                </Col>
              ):(
                <Col xs={12} className="text-center mb-3">
                  <a className="deploy-link" target="_blank" rel="noopener noreferrer" href={`http://${endpointWebApp}`}>
                      http://{endpointWebApp}
                  </a>
                </Col>
              )}

              <Col xs={12} className="text-center mb-5">
                <Link to={`${routes.HABITATMAINPAGE}/${habitat.id}`} className="link-to-templates" onClick={selectHabitat(habitat)}>
                  <p>Click here to to go to the habitat.</p>
                </Link>
              </Col> 
            </Row>
          ))

        ) : (
          <Row className="p-5">
            <Col xs={12}>
              <span className="text-muted">
                Verify your information before launching the web application.
                  </span>
            </Col>
            <Col xs={12}>
              <h5 className="font-weight-bold mt-5 mb-3">Application name & Domain</h5>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={12} md={3}><b>Name:</b></Col>
                <Col xs={12} md={9}>{name}</Col>
                <Col xs={12} md={3}><b>Domain:</b></Col>
                <Col xs={12} md={9}>
                  {prefix ? `${prefix}.${domainName}` : domainName}
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <h5 className="font-weight-bold mt-5 mb-3">Repository</h5>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={12} md={3}><b>Link:</b></Col>
                <Col xs={12} md={9}>{repoURI}</Col>
              </Row>
            </Col>
            <Col xs={12}>
              <h5 className="font-weight-bold mt-5 mb-3">Lambda Function</h5>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={12} md={3}><b>Repository:</b></Col>
                <Col xs={12} md={9}>{repoLambda}</Col>
              </Row>
              <Row>
                <Col xs={12} md={3}><b>Runtime:</b></Col>
                <Col xs={12} md={9}>{runtimeLambda}</Col>
              </Row>
              <Row>
                <Col xs={12} md={3}><b>Handler:</b></Col>
                <Col xs={12} md={9}>{handlerLambda}</Col>
              </Row>
            </Col>
            <Col xs={12}>
              <h5 className="font-weight-bold mt-5 mb-3">Cognito Pool User</h5>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={12} md={3}><b>Name:</b></Col>
                <Col xs={12} md={9}>{cognitoName}</Col>
              </Row>
            </Col>
            <Col xs={12}>
              <h5 className="font-weight-bold mt-5 mb-3">API Gateway</h5>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={12} md={3}><b>Name:</b></Col>
                <Col xs={12} md={9}>{apiGatewayName}</Col>
              </Row>
              <Row>
                <Col xs={12} md={3}><b>Path:</b></Col>
                <Col xs={12} md={9}>{pathApi}</Col>
              </Row>
            </Col>
            <Col xs={12} className="mt-3">
              <span className="text-muted">
                Before we create the web application please verify that all of the information is correct. If you have to make changes click back to the previous steps.
                  </span>
            </Col>
          </Row>
        )
      )}
    </>
  );
}

export default LaunchTemplateWebApp;