import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  fetchHabitat,
  selectSetUpFeature,
} from '../../actions/habitat.actions';
import {
  listCognito
} from '../../actions/aws-cognito.actions';
import {
  getApiList
} from '../../actions/aws-apigateway.actions';
import '../../assets/styles/pages/Habitats.scss';
import { getDynamos } from '../../actions/aws-dynamo.actions';
import '../../assets/styles/components/habitats/HabitatSetUp.scss';
import { fetchAWSRole } from '../../actions/aws-roles.actions';
import HabitatAWSCreds from './HabitatAWSCredsBox';
import HabitatRoles from './HabitatRolesBox';
import HabitatAPIGateway from './HabitatAPIGatewayBox';
import HabitatFuncs from './HabitatFuncsBox';
import HabitatS3 from './HabitatS3BucketBox';
import HabitatDynamoBox from './HabitatDynamoBox';
import HabitatTemplates from './HabitatTemplatesBox';
import HabitatInstanceBox from './HabitatInstanceBox';
import HabitatApiBox from './HabitatApiBox';
import HabitatDBBox from './HabitatDBBox';
import HabitatAuthBox from './HabitatAuthBox';
import HabitatCICDBox from './HabitatCICDBox';
import { nextStepTour } from '../../actions/app-tour.actions';
import { getStaticSites } from '../../actions/templates.actions';

const HabitatSettings = ({
  funcs,
  awsS3,
  habitat,
  dynamos,
  awsRoles,
  cognitos,
  instances,
  templates,
  getApiList,
  getDynamos,
  stepAppTour,
  newFunction,
  listCognito,
  apiGateways,
  fetchAWSRole,
  nextStepTour,
  fetchHabitat,
  habitatSetUp,
  getStaticSites,
  loadingTemplate,
  selectSetUpFeature,
  onboardingClassAWS,
  onboardingClassRoles,
  onboardingClassFuncs,
  loadingTemplateMessage
}) => {
  useEffect(() => {
    getDynamos(habitat.id);
    fetchAWSRole(habitat.id);
    if(habitat.cred_id){
      listCognito(habitat.id);
      getApiList(habitat.id)
    }
  }, [fetchAWSRole]);

  const handleSelectFeature = (feature) => {
    selectSetUpFeature(feature);

    if (feature === habitatSetUp) {
      selectSetUpFeature('');
    }
  };

  const handleNextStep = () => {
    if (stepAppTour === 6) {
      nextStepTour();
    }
  }


  return (
    <div className="global-settings-habitat">

      {funcs && (
        <HabitatFuncs
          handleSelectFeature={handleSelectFeature}
          habitatSetUp={habitatSetUp}
          funcs={funcs}
          onboardingClassFuncs={onboardingClassFuncs}
          nextStepTour={nextStepTour}
          stepAppTour={stepAppTour}
        />
      )}

      {awsS3 && (

        <HabitatS3
          handleSelectFeature={handleSelectFeature}
          habitatSetUp={habitatSetUp}
          awsS3={awsS3}
          habitat={habitat}
        />
      )}


      {templates && (
        <HabitatTemplates
          handleSelectFeature={handleSelectFeature}
          loadingTemplate={loadingTemplate}
          habitatSetUp={habitatSetUp}
          templates={templates}
          loadingTemplateMessage={loadingTemplateMessage}
        />
      )}

      {instances && (
        <HabitatInstanceBox
          instances={instances} 
          habitatSetUp={habitatSetUp}
          handleSelectFeature={handleSelectFeature}
        />
      )}
      
      {apiGateways && (
      <HabitatApiBox 
          apiGateways={apiGateways} 
          habitatSetUp={habitatSetUp}
          handleSelectFeature={handleSelectFeature}
      />
      )}

      {dynamos && (
      <HabitatDBBox 
          dynamos={dynamos} 
          habitatSetUp={habitatSetUp}
          handleSelectFeature={handleSelectFeature}
      />
      )}

      {cognitos && (
      <HabitatAuthBox 
          cognitos={cognitos} 
          habitatSetUp={habitatSetUp}
          handleSelectFeature={handleSelectFeature}
      />
      )}

      
    </div>
  );
};

const mapState = (state, props) => ({
  funcs: state.func.funcs,
  awsS3: state.awsS3.s3Buckets,
  habitat: state.habitat.habitat,
  dynamos: state.awsDynamo.dynamos,
  awsRoles: state.awsRoles.awsRoles,
  cognitos: state.cognito.cognitoList,
  templates: state.templates.templates,
  instances: state.instances.instances,
  stepAppTour: state.appTour.stepAppTour,
  loadingTemplate: state.templates.loading,
  loadingTemplateMessage: state.templates.loading_message,
  habitatSetUp: state.habitat.habitatSetup,
  apiGateways: state.apiGateway.apiGateways,
  onboardingClassAWS: state.appTour.onboardingClassAWS,
  onboardingClassRoles: state.appTour.onboardingClassRoles,
  onboardingClassFuncs: state.appTour.onboardingClassFuncs,
});

const mapDispatch = {
  getApiList,
  getDynamos,
  listCognito,
  fetchHabitat,
  fetchAWSRole,
  nextStepTour,
  getStaticSites,
  selectSetUpFeature,
};

export default connect(mapState, mapDispatch)(HabitatSettings);
