import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { listAWSCreds } from '../../actions/aws-creds.actions';
import { getAWSListLog } from '../../actions/aws-sdk.actions';
import {
  newDeployment,
  allDeployments,
} from '../../actions/deployment.actions';
import HabitatDeployItem from './HabitatDeployItemHistory';
import { ListGroup, Button, Form, Pagination } from 'react-bootstrap';
import { nextStepTour, stopTour } from '../../actions/app-tour.actions';
import { changeStatusOnboarding } from '../../actions/user.actions';
import AppTour from '../../components/AppTour';
import Spinner from 'react-bootstrap/Spinner';
import { siteStatus } from '../../actions/templates.actions';
import Moment from 'moment';
import {
  fetchDeploymentHistory,
  fetchFilterFunc,
  changeStateDeploymentHistory,
} from '../../actions/funcs.actions';
import PaginationItem from './PaginationItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

const HabitatDeployList = ({
  step,
  funcs,
  active,
  habitat,
  awsCreds,
  stopTour,
  getTests,
  resetStep,
  siteStatus,
  funcFilter,
  stepAppTour,
  listAWSCreds,
  nextStepTour,
  getAWSListLog,
  statusAppTour,
  loadingFunction,
  fetchFilterFunc,
  deploymentHistory,
  pagesDeploymentHistory,
  changeStatusOnboarding,
  fetchDeploymentHistory,
  deploymentHistoryStatus,
  changeStateDeploymentHistory,
  error,
  loadingDeploy,
  loadingMessageDeploy
}) => {
  const [hours] = useState(3);
  const [depClass, setDepclass] = useState('unDeploy-ctn');
  const [noCredsAlertShow, setNoCredsAlertShow] = useState(true);
  const [endDate, setEndDate] = useState(
    Moment().subtract(hours, 'hours').format()
  );
  const [startDate, setStartDate] = useState(Moment().format());
  const [page, setPage] = useState(0);
  const [filterNameFunc, setFilterNameFunc] = useState('');

  useEffect(() => {
    handleActive();
    fetchDeploymentHistory(habitat.id, '01/11/2020', endDate, page);
  }, []);

  const handleActive = () => {
    if (active && step === 10) {
      setDepclass('deploy-ctn');
    }
  };

  const handleFilterFuncs = () => {
    fetchDeploymentHistory(habitat.id, startDate, endDate, page);
  };

  const handleLogsFunc = (func_name_log) => {
    getAWSListLog(habitat.id, func_name_log);
  };

  const handlePaginationNumber = (page_num) => {
    setPage(page_num);
    fetchDeploymentHistory(habitat.id, startDate, endDate, page_num);
  };

  const handleFilterNameFunc = (function_id) => {
    setFilterNameFunc(function_id);
    fetchFilterFunc(habitat.id, function_id);
  };

  const handleShowAllFuncs = () => {
    changeStateDeploymentHistory();
    setFilterNameFunc('');
  };

  return (
    <div className="deployments-container-history">
      <AppTour
        stepAppTour={stepAppTour}
        statusAppTour={statusAppTour}
        nextStepTour={nextStepTour}
        stopTour={stopTour}
        changeStatusOnboarding={changeStatusOnboarding}
        loadingFunction={loadingFunction}
      />

      {error && (
        <div className="error-display text-danger">
          <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {error}</p>
        </div>
      )}


      <div className="search-funcs-deployed">
        <div className="dates-from-to">
          <p>From</p>
          <Form.Control
            type="date"
            className="logs-selectbox"
            onChange={(s) =>
              setStartDate(Moment(s.target.value).format('YYYY-MM-DD'))
            }
          />
          <p>To</p>
          <Form.Control
            type="date"
            className="logs-selectbox"
            onChange={(s) =>
              setEndDate(Moment(s.target.value).format('YYYY-MM-DD'))
            }
          />
        </div>

        <Button
          variant="primary"
          size="sm"
          className="btn-search-deployed"
          onClick={() => handleFilterFuncs()}
        >
          Search
        </Button>
      </div>

      <div className="funcs-deployed-container">
        <div>

        {deploymentHistoryStatus && (
          <div className="filter-deployed">
            <Form.Control
              as="select"
              defaultValue={'DEFAULT'}
              aria-label="Region"
              aria-describedby="region"
              className="aws-creds-form"
              onChange={(e) => handleFilterNameFunc(e.target.value)}
              type="text"
              name="region"
              placeholder="AWS Region"
            >
              <option disabled value="DEFAULT">
                Functions
            </option>
              {deploymentHistory.deploys.map((func, index) => (
                <option key={func.id} value={func.id}>
                  {func.function_name}
                </option>
              ))}
            </Form.Control>

            <Button
              variant="primary"
              size="sm"
              className="btn-search-deployed"
              onClick={() => handleShowAllFuncs()}
            >
              Show All
          </Button>
          </div>
        )}

          {loadingDeploy && (
            <div className="spinner-container">
              <Spinner animation="border" variant="primary" />
              <p>{loadingMessageDeploy}</p>
            </div>
          )}

          {deploymentHistoryStatus && (
            <div className="results-deployment">
              <p>
                Results: <b> {deploymentHistory.deploys.length} </b>
              </p>
            </div>
          )}

          {!deploymentHistoryStatus && (
            <>
              {filterNameFunc && (
                <>
                  {funcFilter.map((func, index) => (
                    <HabitatDeployItem
                      key={func.id}
                      func={func}
                      index={index}
                      getTests={getTests}
                      handleFilterFuncs={handleFilterFuncs}
                      handleLogsFunc={handleLogsFunc}
                    />
                  ))}
                </>
              )}

              {!filterNameFunc && (
                <>
                  {funcs.map((func, index) => (
                    <HabitatDeployItem
                      key={func.id}
                      func={func}
                      index={index}
                      getTests={getTests}
                      handleFilterFuncs={handleFilterFuncs}
                      handleLogsFunc={handleLogsFunc}
                    />
                  ))}
                </>
              )}
            </>
          )}

          {deploymentHistoryStatus && (
            <div className="deploy-items-container">

              {deploymentHistory.deploys.map((func, index) => (
                <HabitatDeployItem
                  func={func}
                  key={func.id}
                  index={index}
                  habitat={habitat}
                  getTests={getTests}
                  siteStatus={siteStatus}
                  handleLogsFunc={handleLogsFunc}
                  handleFilterFuncs={handleFilterFuncs}
                />
              ))}

            </div>
          )}

        </div>
        {deploymentHistoryStatus && (
          <div className="pagination-deployment-history">
            <Pagination>
              <PaginationItem
                pagesDeploymentHistory={pagesDeploymentHistory}
                handlePaginationNumber={handlePaginationNumber}
                page={page}
              />
            </Pagination>
          </div>
        )}

      </div>
    </div>
  );
};

const mapState = (state) => ({
  habitat: state.habitat.habitat,
  active: state.user.user.active,
  funcs: state.func.funcs,
  stepAppTour: state.appTour.stepAppTour,
  statusAppTour: state.user.user.onboarding,
  loadingFunction: state.func.loading,
  deploymentHistoryStatus: state.func.deploymentHistoryStatus,
  deploymentHistory: state.func.deploymentHistory,
  pagesDeploymentHistory: state.func.deploymentHistory.quantity,
  funcFilter: state.func.funcFilterSelected,
  error: state.deployment.error,
  loadingDeploy: state.func.loading_deploy,
  loadingMessageDeploy: state.func.loading_message_deploy
});

const mapDispatch = {
  stopTour,
  siteStatus,
  nextStepTour,
  listAWSCreds,
  getAWSListLog,
  newDeployment,
  allDeployments,
  fetchFilterFunc,
  changeStatusOnboarding,
  fetchDeploymentHistory,
  changeStateDeploymentHistory,
};

export default connect(mapState, mapDispatch)(HabitatDeployList);
