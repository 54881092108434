import {
  TEST_APIGATEWAY_BEGIN,
  TEST_APIGATEWAY_SUCCESS,
  TEST_APIGATEWAY_ERROR,
  CREATE_APIGATEWAY_BEGIN,
  CREATE_APIGATEWAY_SUCCESS,
  CREATE_APIGATEWAY_ERROR,
  CREATE_METHOD_BEGIN,
  CREATE_METHOD_SUCCESS,
  CREATE_METHOD_ERROR,
  GET_METHOD_BEGIN,
  GET_METHOD_SUCCESS,
  GET_METHOD_ERROR,
  DELETE_METHOD_BEGIN,
  DELETE_METHOD_SUCCESS,
  DELETE_METHOD_ERROR,
  GET_RESOURCE_BEGIN,
  GET_RESOURCE_SUCCESS,
  GET_RESOURCE_ERROR,
  GET_APIGATEWAY_BEGIN,
  GET_APIGATEWAY_SUCCESS,
  GET_APIGATEWAY_ERROR,
  DELETE_APIGATEWAY_BEGIN,
  DELETE_APIGATEWAY_SUCCESS,
  DELETE_APIGATEWAY_ERROR,
  CREATE_CRUD_API_BEGIN,
  CREATE_CRUD_API_SUCCESS,
  CREATE_CRUD_API_ERROR,
  GET_APIGATEWAY_METRICS_BEGIN,
  GET_APIGATEWAY_METRICS_SUCCESS,
  GET_APIGATEWAY_METRICS_ERROR,
  GET_CLOUDFRONT_METRICS_BEGIN,
  GET_CLOUDFRONT_METRICS_SUCCESS,
  GET_CLOUDFRONT_METRICS_ERROR,
} from './types';
import {
  getAPIGatewayMetrics,
  getcloudFrontMetrics,
  createApiGateway,
  authorizeCognito,
  createResource,
  testApiGateway,
  createRespose,
  listResources,
  createMethod,
  removeMethod,
  listMethods,
  deployApi,
  removeApi,
  listApi,
} from '../api/aws/apigateway';

import { setFunc, deployFunc } from '../api/funcs';

import { gitClone } from '../api/git/gitClone';

import {
  addBucket,
  createBuild,
  uploadClone,
  createProject,
  createDB,
} from '../api/aws/sdk';

export const getFrontMetrics = (
  habitat_id,
  start_time,
  end_time,
  func_name
) => {
  const data = {
    namespace: 'AWS/CloudFront',
    habitat_id,
    start_time,
    end_time,
    period: '6000',
    stat: 'Average',
    unit: 'None',
    func_name,
  };
  return (dispatch) => {
    dispatch({
      type: GET_CLOUDFRONT_METRICS_BEGIN,
      loading_message: 'Get CloudFront Metrics...',
    });

    getcloudFrontMetrics(data)
      .then((response) => {
        const pieData = [];
        pieData.push(response.data.Error4XX.values.reduce((a, b) => a + b, 0));
        pieData.push(response.data.Error5XX.values.reduce((a, b) => a + b, 0));
        pieData.push(
          response.data.ErrorTotal.values.reduce((a, b) => a + b, 0)
        );

        const visits = response.data.Requests.values.length;

        const bytesDownloaded = {
          series: [
            {
              name: 'Bytes Downloaded',
              data: response.data.BytesDownloaded.values,
            },
          ],
          options: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false,
              },
            },
            colors: ['#77B6EA', '#545454'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'straight',
            },
            title: {
              text: 'Bytes Downloaded',
              align: 'left',
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            xaxis: {
              categories: response.data.BytesDownloaded.timestamps,
            },
          },
        };

        const requests = {
          series: [
            {
              name: 'Requests',
              data: response.data.Requests.values,
            },
          ],
          options: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false,
              },
            },
            colors: ['#0f2589', '#1e7aff'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'straight',
            },
            title: {
              text: 'Requests',
              align: 'left',
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            xaxis: {
              categories: response.data.Requests.timestamps,
            },
          },
        };

        const bytesUploaded = {
          series: [
            {
              name: 'Bytes Uploaded',
              data: response.data.BytesUploaded.values,
            },
          ],
          options: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false,
              },
            },
            colors: ['#909aff', '#bfc6ff'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'straight',
            },
            title: {
              text: 'Bytes Uploaded',
              align: 'left',
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            xaxis: {
              categories: response.data.BytesUploaded.timestamps,
            },
          },
        };

        const errors = {
          series: pieData,
          options: {
            chart: {
              width: 380,
              type: 'pie',
            },
            labels: ['Error 4XX', 'Error 5XX', 'Error Total'],
            colors: ['#909aff', '#0f2589', '#1e7aff', '#a5adfa', '#bfc6ff'],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
              },
            ],
          },
        };

        const payload = {
          bytesDownloaded,
          bytesUploaded,
          requests,
          errors,
          visits,
        };
        dispatch({
          type: GET_CLOUDFRONT_METRICS_SUCCESS,
          payload,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_CLOUDFRONT_METRICS_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const getApiMetrics = (habitat_id, start_time, end_time, func_name) => {
  const data = {
    namespace: 'AWS/ApiGateway',
    habitat_id,
    start_time,
    end_time,
    period: '6000',
    stat: 'Sum',
    unit: 'Milliseconds',
    func_name,
  };
  return (dispatch) => {
    dispatch({
      type: GET_APIGATEWAY_METRICS_BEGIN,
      loading_message: 'Get API Gateway Metrics...',
    });
    getAPIGatewayMetrics(data)
      .then((response) => {
        const pieData = [];
        pieData.push(response.data.Error4XX.values.reduce((a, b) => a + b, 0));
        pieData.push(response.data.Error5XX.values.reduce((a, b) => a + b, 0));

        const Count = {
          series: [
            {
              name: 'Count',
              data: response.data.Count.values,
            },
          ],
          options: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false,
              },
            },
            colors: ['#77B6EA', '#545454'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'straight',
            },
            title: {
              text: 'Count',
              align: 'left',
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            xaxis: {
              categories: response.data.Count.timestamps,
            },
          },
        };

        const Latency = {
          series: [
            {
              name: 'Latency',
              data: response.data.IntegrationLatency.values,
            },
          ],
          options: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false,
              },
            },
            colors: ['#909aff', '#a5adfa'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'straight',
            },
            title: {
              text: 'Latency',
              align: 'left',
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            xaxis: {
              categories: response.data.Latency.timestamps,
            },
          },
        };

        const IntegrationLatency = {
          series: [
            {
              name: 'Integration Latency',
              data: response.data.IntegrationLatency.values,
            },
          ],
          options: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false,
              },
            },
            colors: ['#0f2589', '#bfc6ff'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'straight',
            },
            title: {
              text: 'Integration Latency',
              align: 'left',
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            xaxis: {
              categories: response.data.IntegrationLatency.timestamps,
            },
          },
        };

        const Errors = {
          series: pieData,
          options: {
            chart: {
              width: 380,
              type: 'pie',
            },
            labels: ['Error 4XX', 'Error 5XX'],
            colors: ['#909aff', '#0f2589', '#1e7aff', '#a5adfa', '#bfc6ff'],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
              },
            ],
          },
        };

        const payload = {
          Count,
          Errors,
          Latency,
          IntegrationLatency,
        };

        dispatch({
          type: GET_APIGATEWAY_METRICS_SUCCESS,
          payload,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_APIGATEWAY_METRICS_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const apiTest = (habitat_id, headers, apiPayload, api_method, apiId) => {
  const data = {
    habitat_id,
    headers,
    payload: apiPayload,
    api_method,
  };
  return (dispatch) => {
    dispatch({
      type: TEST_APIGATEWAY_BEGIN,
      loading_message: 'Testing API Gateway...',
    });
    testApiGateway(apiId, data)
      .then((response) => {
        dispatch({
          type: TEST_APIGATEWAY_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TEST_APIGATEWAY_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const createAPI = (apiName, habitatId) => {
  return (dispatch) => {
    dispatch({
      type: CREATE_APIGATEWAY_BEGIN,
      loading_message: 'Creating API Gateway...',
    });
    createApiGateway(apiName, habitatId)
      .then((response) => {
        const apigateway = response.data.api_gateway;
        createResource(apigateway.id, habitatId, null)
          .then((response) => {
            deployApi(apigateway.id, habitatId, null)
              .then((response) => {
                const payload = response.data;
                dispatch({
                  type: CREATE_APIGATEWAY_SUCCESS,
                  payload,
                });
              })
              .catch((error) =>
                dispatch({
                  type: CREATE_APIGATEWAY_ERROR,
                  errorNewApi: 'Create API Gateway fail',
                  error,
                })
              );
          })
          .catch((error) =>
            dispatch({
              type: CREATE_APIGATEWAY_ERROR,
              errorNewApi: 'Create API Gateway fail',
              error,
            })
          );
      })
      .catch((error) =>
        dispatch({
          type: CREATE_APIGATEWAY_ERROR,
          errorNewApi: 'Create API Gateway fail',
          error,
        })
      );
  };
};

export const createCognitoAPI = (apiName, habitatId, cognitoArn) => {
  return (dispatch) => {
    dispatch({
      type: CREATE_APIGATEWAY_BEGIN,
      loading_message: 'Creating API Gateway...',
    });
    createApiGateway(apiName, habitatId)
      .then((response) => {
        const apigateway = response.data.api_gateway;
        authorizeCognito(habitatId, cognitoArn, apigateway.id)
          .then((response) => {
            createResource(apigateway.id, habitatId, null)
              .then((response) => {
                deployApi(apigateway.id, habitatId, null)
                  .then((response) => {
                    const payload = response.data;
                    dispatch({
                      type: CREATE_APIGATEWAY_SUCCESS,
                      payload,
                    });
                  })
                  .catch((error) =>
                    dispatch({
                      type: CREATE_APIGATEWAY_ERROR,
                      errorNewApi: error.response.data.error,
                    })
                  );
              })
              .catch((error) =>
                dispatch({
                  type: CREATE_APIGATEWAY_ERROR,
                  errorNewApi: error.response.data.error,
                })
              );
          })
          .catch((error) =>
            dispatch({
              type: CREATE_APIGATEWAY_ERROR,
              errorNewApi: error.response.data.error,
            })
          );
      })
      .catch((error) =>
        dispatch({
          type: CREATE_APIGATEWAY_ERROR,
          errorNewApi: error.response.data.error,
        })
      );
  };
};

export const createRoute = (
  path_part,
  apiId,
  method,
  awsRoles,
  lambda_arn,
  habitat_id,
  type
) => {
  return (dispatch) => {
    dispatch({
      type: CREATE_METHOD_BEGIN,
      loading_message: 'Creating method...',
    });
    listResources(apiId, habitat_id)
      .then((response) => {
        const resources = response.data.resources;
        let existing_path_id;
        let methodData;
        resources.forEach((resource) => {
          if (resource.path_part === path_part) {
            existing_path_id = resource.id;
          }
        });

        if (path_part.length < 1) {
          path_part = null;
        }

        if (existing_path_id) {
          methodData = {
            habitat_id,
            method,
            type,
            lambda_arn,
            rol: awsRoles[0].arn,
            existing_path_id,
            path_part: `/${path_part}`,
          };
        } else {
          methodData = {
            habitat_id,
            method,
            type,
            lambda_arn,
            rol: awsRoles[0].arn,
            path_part,
          };
        }
        createMethod(apiId, methodData)
          .then((response) => {
            const payload = response.data.historical;
            const method = response.data.method;
            deployApi(apiId, habitat_id, path_part)
              .then((response) => {
                const responseData = {
                  habitat_id,
                };
                createRespose(method.id, responseData)
                  .then((response) => {
                    dispatch({
                      type: CREATE_METHOD_SUCCESS,
                      payload,
                    });
                  })
                  .catch((err) => {
                    const error = err.response.data.error;
                    dispatch({ type: CREATE_METHOD_ERROR, error });
                  });
              })
              .catch((err) => {
                const error = err.response.data.error;
                dispatch({ type: CREATE_METHOD_ERROR, error });
              });
          })
          .catch((err) => {
            const error = err.response.data.error;
            dispatch({ type: CREATE_METHOD_ERROR, error });
          });
      })
      .catch((err) => {
        const error = err.response.data.error;
        dispatch({ type: CREATE_METHOD_ERROR, error });
      });
  };
};

export const createCrudApi = (
  team,
  awsRoles,
  crudName,
  awsCreds,
  s3Buckets,
  habitat_id
) => {
  const function_name = `${crudName}-${Date.now()}`;
  const s3Data = {
    file_name: function_name,
    team_id: team.id,
    habitat_id,
  };

  return (dispatch) => {
    dispatch({
      type: CREATE_CRUD_API_BEGIN,
      loading_message: 'Loading CRUD API Gateway...',
    });

    const bucketName = `kor-lambda-${habitat_id}`;
    let bucket;
    if (s3Buckets) {
      bucket = s3Buckets.find((bucket) => bucket.bucket === bucketName);
    }

    const cloneData = {
      uri: 'https://gitlab.com/kor-comunity/kor-crud-lambda.git',
      project_name: function_name,
      team_id: team.id,
      git_credential_id: '',
    };
    gitClone(cloneData)
      .then((response) => {
        if (!bucket) {
          addBucket(bucketName, habitat_id, awsCreds.region)
            .then((response) => {
              uploadClone(s3Data)
                .then((response) => {
                  let data = {
                    function_name,
                    runtime: 'nodejs12.x',
                    handler: 'index.handler',
                    role: awsRoles[0].arn,
                    habitat_id,
                    memory_size: null,
                    timeout: null,
                    code_s3_bucket: null,
                    code_s3_key: null,
                  };
                  data.code_s3_bucket = bucketName;
                  data.code_s3_key = `${function_name}.zip`;
                  setFunc(data)
                    .then((response) => {
                      const func = response.data.aws_lambda;
                      const buildData = {
                        habitat_id: func.habitat_id,
                        project_name: func.function_name,
                        artifac_name: `${func.function_name}.zip`,
                        buildspec: 'buildspec.yml',
                        source_location: func.function_name,
                        artifac_location: func.code_s3_bucket,
                        service_role: awsRoles[awsRoles.length - 1].arn,
                        zip: 'ZIP',
                        lambda_id: func.id,
                      };

                      createProject(buildData)
                        .then((response) => {
                          const buildInfo = {
                            habitat_id: func.habitat_id,
                            build_id: response.data.params.id,
                          };
                          createBuild(buildInfo)
                            .then((response) => {
                              const table_name = crudName;
                              const schema = {
                                attribute_definitions: [
                                  {
                                    attribute_name: 'id',
                                    attribute_type: 'S',
                                  },
                                ],
                                key_schema: [
                                  {
                                    attribute_name: 'id',
                                    key_type: 'HASH',
                                  },
                                ],
                                provisioned_throughput: {
                                  read_capacity_units: 5,
                                  write_capacity_units: 5,
                                },
                                table_name: table_name,
                              };

                              const definition = JSON.stringify(schema)
                                .replace(/\\n/g, '\\n')
                                .replace(/\\'/g, "\\'")
                                .replace(/\\"/g, '\\"')
                                .replace(/\\&/g, '\\&')
                                .replace(/\\r/g, '\\r')
                                .replace(/\\t/g, '\\t')
                                .replace(/\\b/g, '\\b')
                                .replace(/\\f/g, '\\f');

                              const dynamoData = {
                                habitat_id,
                                table_name,
                                definition,
                              };

                              createDB(dynamoData)
                                .then((response) => {
                                  createApiGateway(function_name, habitat_id)
                                    .then((response) => {
                                      const apigateway =
                                        response.data.api_gateway;
                                      createResource(apigateway.id, habitat_id)
                                        .then((response) => {
                                          deployApi(apigateway.id, habitat_id)
                                            .then((response) => {
                                              const preEnv = {
                                                TABLE: table_name,
                                              };
                                              const env = JSON.stringify(preEnv)
                                                .replace(/\\n/g, '\\n')
                                                .replace(/\\'/g, "\\'")
                                                .replace(/\\"/g, '\\"')
                                                .replace(/\\&/g, '\\&')
                                                .replace(/\\r/g, '\\r')
                                                .replace(/\\t/g, '\\t')
                                                .replace(/\\b/g, '\\b')
                                                .replace(/\\f/g, '\\f');
                                              deployFunc(func.id, env)
                                                .then((response) => {
                                                  let existing_path_id = null;
                                                  const lambdas = response.data;
                                                  const lambda_arn =
                                                    lambdas[lambdas.length - 1]
                                                      .function_arn;
                                                  const postData = {
                                                    habitat_id,
                                                    method: 'POST',
                                                    type: 'AWS_PROXY',
                                                    lambda_arn,
                                                    rol: awsRoles[0].arn,
                                                    path_part: 'v1',
                                                  };
                                                  createMethod(
                                                    apigateway.id,
                                                    postData
                                                  )
                                                    .then((response) => {
                                                      const methodInfo =
                                                        response.data.method;
                                                      listResources(
                                                        apigateway.id,
                                                        habitat_id
                                                      )
                                                        .then((response) => {
                                                          const resources =
                                                            response.data
                                                              .resources;
                                                          resources.forEach(
                                                            (resource) => {
                                                              if (
                                                                resource.path_part ===
                                                                'v1'
                                                              ) {
                                                                existing_path_id =
                                                                  resource.id;
                                                              }
                                                            }
                                                          );
                                                          const getData = {
                                                            habitat_id,
                                                            method: 'GET',
                                                            type: 'AWS_PROXY',
                                                            lambda_arn,
                                                            rol:
                                                              awsRoles[0].arn,
                                                            existing_path_id,
                                                            path_part: '/v1',
                                                          };
                                                          createMethod(
                                                            apigateway.id,
                                                            getData
                                                          )
                                                            .then(
                                                              (response) => {
                                                                const putData = {
                                                                  habitat_id,
                                                                  method: 'PUT',
                                                                  type:
                                                                    'AWS_PROXY',
                                                                  lambda_arn,
                                                                  rol:
                                                                    awsRoles[0]
                                                                      .arn,
                                                                  existing_path_id,
                                                                  path_part:
                                                                    '/v1',
                                                                };
                                                                createMethod(
                                                                  apigateway.id,
                                                                  putData
                                                                )
                                                                  .then(
                                                                    (
                                                                      response
                                                                    ) => {
                                                                      const deleteData = {
                                                                        habitat_id,
                                                                        method:
                                                                          'DELETE',
                                                                        type:
                                                                          'AWS_PROXY',
                                                                        lambda_arn,
                                                                        rol:
                                                                          awsRoles[0]
                                                                            .arn,
                                                                        existing_path_id,
                                                                        path_part:
                                                                          '/v1',
                                                                      };
                                                                      createMethod(
                                                                        apigateway.id,
                                                                        deleteData
                                                                      )
                                                                        .then(
                                                                          (
                                                                            response
                                                                          ) => {
                                                                            deployApi(
                                                                              apigateway.id,
                                                                              habitat_id,
                                                                              'v1'
                                                                            )
                                                                              .then(
                                                                                (
                                                                                  response
                                                                                ) => {
                                                                                  const responseData = {
                                                                                    habitat_id,
                                                                                  };
                                                                                  createRespose(
                                                                                    methodInfo.id,
                                                                                    responseData
                                                                                  )
                                                                                    .then(
                                                                                      (
                                                                                        response
                                                                                      ) => {
                                                                                        const payload =
                                                                                          response.data.aws_api_gateway;
                                                                                        dispatch(
                                                                                          {
                                                                                            type: CREATE_CRUD_API_SUCCESS,
                                                                                            payload,
                                                                                          }
                                                                                        );
                                                                                      }
                                                                                    )
                                                                                    .catch(
                                                                                      (
                                                                                        error
                                                                                      ) =>
                                                                                        dispatch(
                                                                                          {
                                                                                            type: CREATE_CRUD_API_ERROR,
                                                                                            error:
                                                                                              error
                                                                                                .response
                                                                                                .data
                                                                                                .error,
                                                                                            errorNewFunction:
                                                                                              'Create function fail',
                                                                                          }
                                                                                        )
                                                                                    );
                                                                                }
                                                                              )
                                                                              .catch(
                                                                                (
                                                                                  error
                                                                                ) =>
                                                                                  dispatch(
                                                                                    {
                                                                                      type: CREATE_CRUD_API_ERROR,
                                                                                      error:
                                                                                        error
                                                                                          .response
                                                                                          .data
                                                                                          .error,
                                                                                      errorNewFunction:
                                                                                        'Create function fail',
                                                                                    }
                                                                                  )
                                                                              );
                                                                          }
                                                                        )
                                                                        .catch(
                                                                          (
                                                                            error
                                                                          ) =>
                                                                            dispatch(
                                                                              {
                                                                                type: CREATE_CRUD_API_ERROR,
                                                                                error:
                                                                                  error
                                                                                    .response
                                                                                    .data
                                                                                    .error,
                                                                                errorNewFunction:
                                                                                  'Create function fail',
                                                                              }
                                                                            )
                                                                        );
                                                                    }
                                                                  )
                                                                  .catch(
                                                                    (error) =>
                                                                      dispatch({
                                                                        type: CREATE_CRUD_API_ERROR,
                                                                        error:
                                                                          error
                                                                            .response
                                                                            .data
                                                                            .error,
                                                                        errorNewFunction:
                                                                          'Create function fail',
                                                                      })
                                                                  );
                                                              }
                                                            )
                                                            .catch((error) =>
                                                              dispatch({
                                                                type: CREATE_CRUD_API_ERROR,
                                                                error:
                                                                  error.response
                                                                    .data.error,
                                                                errorNewFunction:
                                                                  'Create function fail',
                                                              })
                                                            );
                                                        })
                                                        .catch((error) =>
                                                          dispatch({
                                                            type: CREATE_CRUD_API_ERROR,
                                                            error:
                                                              error.response
                                                                .data.error,
                                                            errorNewFunction:
                                                              'Create function fail',
                                                          })
                                                        );
                                                    })
                                                    .catch((error) =>
                                                      dispatch({
                                                        type: CREATE_CRUD_API_ERROR,
                                                        error:
                                                          error.response.data
                                                            .error,
                                                        errorNewFunction:
                                                          'Create function fail',
                                                      })
                                                    );
                                                })
                                                .catch((error) =>
                                                  dispatch({
                                                    type: CREATE_CRUD_API_ERROR,
                                                    error:
                                                      error.response.data.error,
                                                    errorNewFunction:
                                                      'Create function fail',
                                                  })
                                                );
                                            })
                                            .catch((error) =>
                                              dispatch({
                                                type: CREATE_CRUD_API_ERROR,
                                                error:
                                                  error.response.data.error,
                                                errorNewFunction:
                                                  'Create function fail',
                                              })
                                            );
                                        })
                                        .catch((error) =>
                                          dispatch({
                                            type: CREATE_CRUD_API_ERROR,
                                            error: error.response.data.error,
                                            errorNewFunction:
                                              'Create function fail',
                                          })
                                        );
                                    })
                                    .catch((error) =>
                                      dispatch({
                                        type: CREATE_CRUD_API_ERROR,
                                        error: error.response.data.error,
                                        errorNewFunction:
                                          'Create function fail',
                                      })
                                    );
                                })
                                .catch((error) =>
                                  dispatch({
                                    type: CREATE_CRUD_API_ERROR,
                                    error: error.response.data.error,
                                    errorNewFunction: 'Create function fail',
                                  })
                                );
                            })
                            .catch((error) =>
                              dispatch({
                                type: CREATE_CRUD_API_ERROR,
                                error: error.response.data.error,
                                errorNewFunction: 'Create function fail',
                              })
                            );
                        })
                        .catch((error) =>
                          dispatch({
                            type: CREATE_CRUD_API_ERROR,
                            error: error.response.data.error,
                            errorNewFunction: 'Create function fail',
                          })
                        );
                    })
                    .catch((error) =>
                      dispatch({
                        type: CREATE_CRUD_API_ERROR,
                        error: error.response.data.error,
                        errorNewFunction: 'Create function fail',
                      })
                    );
                })
                .catch((error) =>
                  dispatch({
                    type: CREATE_CRUD_API_ERROR,
                    error: error.response.data.error,
                    errorNewFunction: 'Create function fail',
                  })
                );
            })
            .catch((error) =>
              dispatch({
                type: CREATE_CRUD_API_ERROR,
                error: error.response.data.error,
                errorNewFunction: 'Create function fail',
              })
            );
        } else {
          uploadClone(s3Data)
            .then((response) => {
              let data = {
                function_name,
                runtime: 'nodejs12.x',
                handler: 'index.handler',
                role: awsRoles[0].arn,
                habitat_id,
                memory_size: null,
                timeout: null,
                code_s3_bucket: null,
                code_s3_key: null,
              };
              data.code_s3_bucket = bucketName;
              data.code_s3_key = `${function_name}.zip`;
              setFunc(data)
                .then((response) => {
                  const func = response.data.aws_lambda;
                  const buildData = {
                    habitat_id: func.habitat_id,
                    project_name: func.function_name,
                    artifac_name: `${func.function_name}.zip`,
                    buildspec: 'buildspec.yml',
                    source_location: func.function_name,
                    artifac_location: func.code_s3_bucket,
                    service_role: awsRoles[awsRoles.length - 1].arn,
                    zip: 'ZIP',
                    lambda_id: func.id,
                  };

                  createProject(buildData)
                    .then((response) => {
                      const buildInfo = {
                        habitat_id: func.habitat_id,
                        build_id: response.data.params.id,
                      };
                      createBuild(buildInfo)
                        .then((response) => {
                          const table_name = crudName;
                          const schema = {
                            attribute_definitions: [
                              {
                                attribute_name: 'id',
                                attribute_type: 'S',
                              },
                            ],
                            key_schema: [
                              {
                                attribute_name: 'id',
                                key_type: 'HASH',
                              },
                            ],
                            provisioned_throughput: {
                              read_capacity_units: 5,
                              write_capacity_units: 5,
                            },
                            table_name: table_name,
                          };

                          const definition = JSON.stringify(schema)
                            .replace(/\\n/g, '\\n')
                            .replace(/\\'/g, "\\'")
                            .replace(/\\"/g, '\\"')
                            .replace(/\\&/g, '\\&')
                            .replace(/\\r/g, '\\r')
                            .replace(/\\t/g, '\\t')
                            .replace(/\\b/g, '\\b')
                            .replace(/\\f/g, '\\f');

                          const dynamoData = {
                            habitat_id,
                            table_name,
                            definition,
                          };

                          createDB(dynamoData)
                            .then((response) => {
                              createApiGateway(function_name, habitat_id)
                                .then((response) => {
                                  const apigateway = response.data.api_gateway;
                                  createResource(apigateway.id, habitat_id)
                                    .then((response) => {
                                      deployApi(apigateway.id, habitat_id)
                                        .then((response) => {
                                          const preEnv = {
                                            TABLE: crudName,
                                          };
                                          const env = JSON.stringify(preEnv)
                                            .replace(/\\n/g, '\\n')
                                            .replace(/\\'/g, "\\'")
                                            .replace(/\\"/g, '\\"')
                                            .replace(/\\&/g, '\\&')
                                            .replace(/\\r/g, '\\r')
                                            .replace(/\\t/g, '\\t')
                                            .replace(/\\b/g, '\\b')
                                            .replace(/\\f/g, '\\f');
                                          deployFunc(func.id, env)
                                            .then((response) => {
                                              let existing_path_id = null;
                                              const lambdas = response.data;
                                              const lambda_arn =
                                                lambdas[lambdas.length - 1]
                                                  .function_arn;
                                              const postData = {
                                                habitat_id,
                                                method: 'POST',
                                                type: 'AWS_PROXY',
                                                lambda_arn,
                                                rol: awsRoles[0].arn,
                                                path_part: 'v1',
                                              };
                                              createMethod(
                                                apigateway.id,
                                                postData
                                              )
                                                .then((response) => {
                                                  const methodInfo =
                                                    response.data.method;
                                                  listResources(
                                                    apigateway.id,
                                                    habitat_id
                                                  )
                                                    .then((response) => {
                                                      const resources =
                                                        response.data.resources;
                                                      resources.forEach(
                                                        (resource) => {
                                                          if (
                                                            resource.path_part ===
                                                            'v1'
                                                          ) {
                                                            existing_path_id =
                                                              resource.id;
                                                          }
                                                        }
                                                      );
                                                      const getData = {
                                                        habitat_id,
                                                        method: 'GET',
                                                        type: 'AWS_PROXY',
                                                        lambda_arn,
                                                        rol: awsRoles[0].arn,
                                                        existing_path_id,
                                                        path_part: '/v1',
                                                      };
                                                      createMethod(
                                                        apigateway.id,
                                                        getData
                                                      )
                                                        .then((response) => {
                                                          const putData = {
                                                            habitat_id,
                                                            method: 'PUT',
                                                            type: 'AWS_PROXY',
                                                            lambda_arn,
                                                            rol:
                                                              awsRoles[0].arn,
                                                            existing_path_id,
                                                            path_part: '/v1',
                                                          };
                                                          createMethod(
                                                            apigateway.id,
                                                            putData
                                                          )
                                                            .then(
                                                              (response) => {
                                                                const deleteData = {
                                                                  habitat_id,
                                                                  method:
                                                                    'DELETE',
                                                                  type:
                                                                    'AWS_PROXY',
                                                                  lambda_arn,
                                                                  rol:
                                                                    awsRoles[0]
                                                                      .arn,
                                                                  existing_path_id,
                                                                  path_part:
                                                                    '/v1',
                                                                };
                                                                createMethod(
                                                                  apigateway.id,
                                                                  deleteData
                                                                )
                                                                  .then(
                                                                    (
                                                                      response
                                                                    ) => {
                                                                      deployApi(
                                                                        apigateway.id,
                                                                        habitat_id,
                                                                        'v1'
                                                                      )
                                                                        .then(
                                                                          (
                                                                            response
                                                                          ) => {
                                                                            const responseData = {
                                                                              habitat_id,
                                                                            };
                                                                            createRespose(
                                                                              methodInfo.id,
                                                                              responseData
                                                                            )
                                                                              .then(
                                                                                (
                                                                                  response
                                                                                ) => {
                                                                                  const payload =
                                                                                    response.data.aws_api_gateway;
                                                                                  dispatch(
                                                                                    {
                                                                                      type: CREATE_CRUD_API_SUCCESS,
                                                                                      payload,
                                                                                    }
                                                                                  );
                                                                                }
                                                                              )
                                                                              .catch(
                                                                                (
                                                                                  error
                                                                                ) =>
                                                                                  dispatch(
                                                                                    {
                                                                                      type: CREATE_CRUD_API_ERROR,
                                                                                      error:
                                                                                        error
                                                                                          .response
                                                                                          .data
                                                                                          .error,
                                                                                      errorNewFunction:
                                                                                        'Create function fail',
                                                                                    }
                                                                                  )
                                                                              );
                                                                          }
                                                                        )
                                                                        .catch(
                                                                          (
                                                                            error
                                                                          ) =>
                                                                            dispatch(
                                                                              {
                                                                                type: CREATE_CRUD_API_ERROR,
                                                                                error:
                                                                                  error
                                                                                    .response
                                                                                    .data
                                                                                    .error,
                                                                                errorNewFunction:
                                                                                  'Create function fail',
                                                                              }
                                                                            )
                                                                        );
                                                                    }
                                                                  )
                                                                  .catch(
                                                                    (error) =>
                                                                      dispatch({
                                                                        type: CREATE_CRUD_API_ERROR,
                                                                        error:
                                                                          error
                                                                            .response
                                                                            .data
                                                                            .error,
                                                                        errorNewFunction:
                                                                          'Create function fail',
                                                                      })
                                                                  );
                                                              }
                                                            )
                                                            .catch((error) =>
                                                              dispatch({
                                                                type: CREATE_CRUD_API_ERROR,
                                                                error:
                                                                  error.response
                                                                    .data.error,
                                                                errorNewFunction:
                                                                  'Create function fail',
                                                              })
                                                            );
                                                        })
                                                        .catch((error) =>
                                                          dispatch({
                                                            type: CREATE_CRUD_API_ERROR,
                                                            error:
                                                              error.response
                                                                .data.error,
                                                            errorNewFunction:
                                                              'Create function fail',
                                                          })
                                                        );
                                                    })
                                                    .catch((error) =>
                                                      dispatch({
                                                        type: CREATE_CRUD_API_ERROR,
                                                        error:
                                                          error.response.data
                                                            .error,
                                                        errorNewFunction:
                                                          'Create function fail',
                                                      })
                                                    );
                                                })
                                                .catch((error) =>
                                                  dispatch({
                                                    type: CREATE_CRUD_API_ERROR,
                                                    error:
                                                      error.response.data.error,
                                                    errorNewFunction:
                                                      'Create function fail',
                                                  })
                                                );
                                            })
                                            .catch((error) =>
                                              dispatch({
                                                type: CREATE_CRUD_API_ERROR,
                                                error:
                                                  error.response.data.error,
                                                errorNewFunction:
                                                  'Create function fail',
                                              })
                                            );
                                        })
                                        .catch((error) =>
                                          dispatch({
                                            type: CREATE_CRUD_API_ERROR,
                                            error: error.response.data.error,
                                            errorNewFunction:
                                              'Create function fail',
                                          })
                                        );
                                    })
                                    .catch((error) =>
                                      dispatch({
                                        type: CREATE_CRUD_API_ERROR,
                                        error: error.response.data.error,
                                        errorNewFunction:
                                          'Create function fail',
                                      })
                                    );
                                })
                                .catch((error) =>
                                  dispatch({
                                    type: CREATE_CRUD_API_ERROR,
                                    error: error.response.data.error,
                                    errorNewFunction: 'Create function fail',
                                  })
                                );
                            })
                            .catch((error) =>
                              dispatch({
                                type: CREATE_CRUD_API_ERROR,
                                error: error.response.data.error,
                                errorNewFunction: 'Create function fail',
                              })
                            );
                        })
                        .catch((error) =>
                          dispatch({
                            type: CREATE_CRUD_API_ERROR,
                            error: error.response.data.error,
                            errorNewFunction: 'Create function fail',
                          })
                        );
                    })
                    .catch((error) =>
                      dispatch({
                        type: CREATE_CRUD_API_ERROR,
                        error: error.response.data.error,
                        errorNewFunction: 'Create function fail',
                      })
                    );
                })
                .catch((error) =>
                  dispatch({
                    type: CREATE_CRUD_API_ERROR,
                    error: error.response.data.error,
                    errorNewFunction: 'Create function fail',
                  })
                );
            })
            .catch((error) =>
              dispatch({
                type: CREATE_CRUD_API_ERROR,
                error: error.response.data.error,
                errorNewFunction: 'Create function fail',
              })
            );
        }
      })
      .catch((error) =>
        dispatch({
          type: CREATE_CRUD_API_ERROR,
          error: error.response.data.error,
          errorNewFunction: 'Create function fail',
        })
      );
  };
};

export const createCognitoCrudApi = (
  team,
  awsRoles,
  crudName,
  awsCreds,
  s3Buckets,
  cognitoArn,
  habitat_id
) => {
  const function_name = `${crudName}-${Date.now()}`;

  const s3Data = {
    file_name: function_name,
    team_id: team.id,
    habitat_id,
  };

  return (dispatch) => {
    dispatch({
      type: CREATE_CRUD_API_BEGIN,
      loading_message: 'Loading CRUD API Gateway...',
    });

    const bucketName = `kor-lambda-${habitat_id}`;
    let bucket;
    if (s3Buckets) {
      bucket = s3Buckets.find((bucket) => bucket.bucket === bucketName);
    }

    const cloneData = {
      uri: 'https://gitlab.com/kor-comunity/kor-crud-lambda.git',
      project_name: function_name,
      team_id: team.id,
      git_credential_id: '',
    };
    gitClone(cloneData)
      .then((response) => {
        if (!bucket) {
          addBucket(bucketName, habitat_id, awsCreds.region)
            .then((response) => {
              uploadClone(s3Data)
                .then((response) => {
                  let data = {
                    function_name,
                    runtime: 'nodejs12.x',
                    handler: 'index.handler',
                    role: awsRoles[0].arn,
                    habitat_id,
                    memory_size: null,
                    timeout: null,
                    code_s3_bucket: null,
                    code_s3_key: null,
                  };
                  data.code_s3_bucket = bucketName;
                  data.code_s3_key = `${function_name}.zip`;
                  setFunc(data)
                    .then((response) => {
                      const func = response.data.aws_lambda;
                      const buildData = {
                        habitat_id: func.habitat_id,
                        project_name: func.function_name,
                        artifac_name: `${func.function_name}.zip`,
                        buildspec: 'buildspec.yml',
                        source_location: func.function_name,
                        artifac_location: func.code_s3_bucket,
                        service_role: awsRoles[awsRoles.length - 1].arn,
                        zip: 'ZIP',
                        lambda_id: func.id,
                      };

                      createProject(buildData)
                        .then((response) => {
                          const buildInfo = {
                            habitat_id: func.habitat_id,
                            build_id: response.data.params.id,
                          };
                          createBuild(buildInfo)
                            .then((response) => {
                              const table_name = crudName;
                              const schema = {
                                attribute_definitions: [
                                  {
                                    attribute_name: 'id',
                                    attribute_type: 'S',
                                  },
                                ],
                                key_schema: [
                                  {
                                    attribute_name: 'id',
                                    key_type: 'HASH',
                                  },
                                ],
                                provisioned_throughput: {
                                  read_capacity_units: 5,
                                  write_capacity_units: 5,
                                },
                                table_name: table_name,
                              };

                              const definition = JSON.stringify(schema)
                                .replace(/\\n/g, '\\n')
                                .replace(/\\'/g, "\\'")
                                .replace(/\\"/g, '\\"')
                                .replace(/\\&/g, '\\&')
                                .replace(/\\r/g, '\\r')
                                .replace(/\\t/g, '\\t')
                                .replace(/\\b/g, '\\b')
                                .replace(/\\f/g, '\\f');

                              const dynamoData = {
                                habitat_id,
                                table_name,
                                definition,
                              };

                              createDB(dynamoData)
                                .then((response) => {
                                  createApiGateway(function_name, habitat_id)
                                    .then((response) => {
                                      const apigateway =
                                        response.data.api_gateway;
                                      authorizeCognito(
                                        habitat_id,
                                        cognitoArn,
                                        apigateway.id
                                      )
                                        .then((response) => {
                                          createResource(
                                            apigateway.id,
                                            habitat_id
                                          )
                                            .then((response) => {
                                              deployApi(
                                                apigateway.id,
                                                habitat_id
                                              )
                                                .then((response) => {
                                                  const preEnv = {
                                                    TABLE: table_name,
                                                  };
                                                  const env = JSON.stringify(
                                                    preEnv
                                                  )
                                                    .replace(/\\n/g, '\\n')
                                                    .replace(/\\'/g, "\\'")
                                                    .replace(/\\"/g, '\\"')
                                                    .replace(/\\&/g, '\\&')
                                                    .replace(/\\r/g, '\\r')
                                                    .replace(/\\t/g, '\\t')
                                                    .replace(/\\b/g, '\\b')
                                                    .replace(/\\f/g, '\\f');
                                                  deployFunc(func.id, env)
                                                    .then((response) => {
                                                      let existing_path_id = null;
                                                      const lambdas =
                                                        response.data;
                                                      const lambda_arn =
                                                        lambdas[
                                                          lambdas.length - 1
                                                        ].function_arn;
                                                      const postData = {
                                                        habitat_id,
                                                        method: 'POST',
                                                        type: 'AWS_PROXY',
                                                        lambda_arn,
                                                        rol: awsRoles[0].arn,
                                                        path_part: 'v1',
                                                      };
                                                      createMethod(
                                                        apigateway.id,
                                                        postData
                                                      )
                                                        .then((response) => {
                                                          const methodInfo =
                                                            response.data
                                                              .method;
                                                          listResources(
                                                            apigateway.id,
                                                            habitat_id
                                                          )
                                                            .then(
                                                              (response) => {
                                                                const resources =
                                                                  response.data
                                                                    .resources;
                                                                resources.forEach(
                                                                  (
                                                                    resource
                                                                  ) => {
                                                                    if (
                                                                      resource.path_part ===
                                                                      'v1'
                                                                    ) {
                                                                      existing_path_id =
                                                                        resource.id;
                                                                    }
                                                                  }
                                                                );
                                                                const getData = {
                                                                  habitat_id,
                                                                  method: 'GET',
                                                                  type:
                                                                    'AWS_PROXY',
                                                                  lambda_arn,
                                                                  rol:
                                                                    awsRoles[0]
                                                                      .arn,
                                                                  existing_path_id,
                                                                  path_part:
                                                                    '/v1',
                                                                };
                                                                createMethod(
                                                                  apigateway.id,
                                                                  getData
                                                                )
                                                                  .then(
                                                                    (
                                                                      response
                                                                    ) => {
                                                                      const putData = {
                                                                        habitat_id,
                                                                        method:
                                                                          'PUT',
                                                                        type:
                                                                          'AWS_PROXY',
                                                                        lambda_arn,
                                                                        rol:
                                                                          awsRoles[0]
                                                                            .arn,
                                                                        existing_path_id,
                                                                        path_part:
                                                                          '/v1',
                                                                      };
                                                                      createMethod(
                                                                        apigateway.id,
                                                                        putData
                                                                      )
                                                                        .then(
                                                                          (
                                                                            response
                                                                          ) => {
                                                                            const deleteData = {
                                                                              habitat_id,
                                                                              method:
                                                                                'DELETE',
                                                                              type:
                                                                                'AWS_PROXY',
                                                                              lambda_arn,
                                                                              rol:
                                                                                awsRoles[0]
                                                                                  .arn,
                                                                              existing_path_id,
                                                                              path_part:
                                                                                '/v1',
                                                                            };
                                                                            createMethod(
                                                                              apigateway.id,
                                                                              deleteData
                                                                            )
                                                                              .then(
                                                                                (
                                                                                  response
                                                                                ) => {
                                                                                  deployApi(
                                                                                    apigateway.id,
                                                                                    habitat_id,
                                                                                    'v1'
                                                                                  )
                                                                                    .then(
                                                                                      (
                                                                                        response
                                                                                      ) => {
                                                                                        const responseData = {
                                                                                          habitat_id,
                                                                                        };
                                                                                        createRespose(
                                                                                          methodInfo.id,
                                                                                          responseData
                                                                                        )
                                                                                          .then(
                                                                                            (
                                                                                              response
                                                                                            ) => {
                                                                                              const payload =
                                                                                                response.data.aws_api_gateway;
                                                                                              dispatch(
                                                                                                {
                                                                                                  type: CREATE_CRUD_API_SUCCESS,
                                                                                                  payload,
                                                                                                }
                                                                                              );
                                                                                            }
                                                                                          )
                                                                                          .catch(
                                                                                            (
                                                                                              error
                                                                                            ) =>
                                                                                              dispatch(
                                                                                                {
                                                                                                  type: CREATE_CRUD_API_ERROR,
                                                                                                  error:
                                                                                                    error
                                                                                                      .response
                                                                                                      .data
                                                                                                      .error,
                                                                                                  errorNewFunction:
                                                                                                    'Create function fail',
                                                                                                }
                                                                                              )
                                                                                          );
                                                                                      }
                                                                                    )
                                                                                    .catch(
                                                                                      (
                                                                                        error
                                                                                      ) =>
                                                                                        dispatch(
                                                                                          {
                                                                                            type: CREATE_CRUD_API_ERROR,
                                                                                            error:
                                                                                              error
                                                                                                .response
                                                                                                .data
                                                                                                .error,
                                                                                            errorNewFunction:
                                                                                              'Create function fail',
                                                                                          }
                                                                                        )
                                                                                    );
                                                                                }
                                                                              )
                                                                              .catch(
                                                                                (
                                                                                  error
                                                                                ) =>
                                                                                  dispatch(
                                                                                    {
                                                                                      type: CREATE_CRUD_API_ERROR,
                                                                                      error:
                                                                                        error
                                                                                          .response
                                                                                          .data
                                                                                          .error,
                                                                                      errorNewFunction:
                                                                                        'Create function fail',
                                                                                    }
                                                                                  )
                                                                              );
                                                                          }
                                                                        )
                                                                        .catch(
                                                                          (
                                                                            error
                                                                          ) =>
                                                                            dispatch(
                                                                              {
                                                                                type: CREATE_CRUD_API_ERROR,
                                                                                error:
                                                                                  error
                                                                                    .response
                                                                                    .data
                                                                                    .error,
                                                                                errorNewFunction:
                                                                                  'Create function fail',
                                                                              }
                                                                            )
                                                                        );
                                                                    }
                                                                  )
                                                                  .catch(
                                                                    (error) =>
                                                                      dispatch({
                                                                        type: CREATE_CRUD_API_ERROR,
                                                                        error:
                                                                          error
                                                                            .response
                                                                            .data
                                                                            .error,
                                                                        errorNewFunction:
                                                                          'Create function fail',
                                                                      })
                                                                  );
                                                              }
                                                            )
                                                            .catch((error) =>
                                                              dispatch({
                                                                type: CREATE_CRUD_API_ERROR,
                                                                error:
                                                                  error.response
                                                                    .data.error,
                                                                errorNewFunction:
                                                                  'Create function fail',
                                                              })
                                                            );
                                                        })
                                                        .catch((error) =>
                                                          dispatch({
                                                            type: CREATE_CRUD_API_ERROR,
                                                            error:
                                                              error.response
                                                                .data.error,
                                                            errorNewFunction:
                                                              'Create function fail',
                                                          })
                                                        );
                                                    })
                                                    .catch((error) =>
                                                      dispatch({
                                                        type: CREATE_CRUD_API_ERROR,
                                                        error:
                                                          error.response.data
                                                            .error,
                                                        errorNewFunction:
                                                          'Create function fail',
                                                      })
                                                    );
                                                })
                                                .catch((error) =>
                                                  dispatch({
                                                    type: CREATE_CRUD_API_ERROR,
                                                    error:
                                                      error.response.data.error,
                                                    errorNewFunction:
                                                      'Create function fail',
                                                  })
                                                );
                                            })
                                            .catch((error) =>
                                              dispatch({
                                                type: CREATE_CRUD_API_ERROR,
                                                error:
                                                  error.response.data.error,
                                                errorNewFunction:
                                                  'Create function fail',
                                              })
                                            );
                                        })
                                        .catch((error) =>
                                          dispatch({
                                            type: CREATE_CRUD_API_ERROR,
                                            error: error.response.data.error,
                                            errorNewFunction:
                                              'Create function fail',
                                          })
                                        );
                                    })
                                    .catch((error) =>
                                      dispatch({
                                        type: CREATE_CRUD_API_ERROR,
                                        error: error.response.data.error,
                                        errorNewFunction:
                                          'Create function fail',
                                      })
                                    );
                                })
                                .catch((error) =>
                                  dispatch({
                                    type: CREATE_CRUD_API_ERROR,
                                    error: error.response.data.error,
                                    errorNewFunction: 'Create function fail',
                                  })
                                );
                            })
                            .catch((error) =>
                              dispatch({
                                type: CREATE_CRUD_API_ERROR,
                                error: error.response.data.error,
                                errorNewFunction: 'Create function fail',
                              })
                            );
                        })
                        .catch((error) =>
                          dispatch({
                            type: CREATE_CRUD_API_ERROR,
                            error: error.response.data.error,
                            errorNewFunction: 'Create function fail',
                          })
                        );
                    })
                    .catch((error) =>
                      dispatch({
                        type: CREATE_CRUD_API_ERROR,
                        error: error.response.data.error,
                        errorNewFunction: 'Create function fail',
                      })
                    );
                })
                .catch((error) =>
                  dispatch({
                    type: CREATE_CRUD_API_ERROR,
                    error: error.response.data.error,
                    errorNewFunction: 'Create function fail',
                  })
                );
            })
            .catch((error) =>
              dispatch({
                type: CREATE_CRUD_API_ERROR,
                error: error.response.data.error,
                errorNewFunction: 'Create function fail',
              })
            );
        } else {
          uploadClone(s3Data)
            .then((response) => {
              let data = {
                function_name,
                runtime: 'nodejs12.x',
                handler: 'index.handler',
                role: awsRoles[0].arn,
                habitat_id,
                memory_size: null,
                timeout: null,
                code_s3_bucket: null,
                code_s3_key: null,
              };
              data.code_s3_bucket = bucketName;
              data.code_s3_key = `${function_name}.zip`;
              setFunc(data)
                .then((response) => {
                  const func = response.data.aws_lambda;
                  const buildData = {
                    habitat_id: func.habitat_id,
                    project_name: func.function_name,
                    artifac_name: `${func.function_name}.zip`,
                    buildspec: 'buildspec.yml',
                    source_location: func.function_name,
                    artifac_location: func.code_s3_bucket,
                    service_role: awsRoles[awsRoles.length - 1].arn,
                    zip: 'ZIP',
                    lambda_id: func.id,
                  };

                  createProject(buildData)
                    .then((response) => {
                      const buildInfo = {
                        habitat_id: func.habitat_id,
                        build_id: response.data.params.id,
                      };
                      createBuild(buildInfo)
                        .then((response) => {
                          const table_name = crudName;
                          const schema = {
                            attribute_definitions: [
                              {
                                attribute_name: 'id',
                                attribute_type: 'S',
                              },
                            ],
                            key_schema: [
                              {
                                attribute_name: 'id',
                                key_type: 'HASH',
                              },
                            ],
                            provisioned_throughput: {
                              read_capacity_units: 5,
                              write_capacity_units: 5,
                            },
                            table_name: table_name,
                          };

                          const definition = JSON.stringify(schema)
                            .replace(/\\n/g, '\\n')
                            .replace(/\\'/g, "\\'")
                            .replace(/\\"/g, '\\"')
                            .replace(/\\&/g, '\\&')
                            .replace(/\\r/g, '\\r')
                            .replace(/\\t/g, '\\t')
                            .replace(/\\b/g, '\\b')
                            .replace(/\\f/g, '\\f');

                          const dynamoData = {
                            habitat_id,
                            table_name,
                            definition,
                          };

                          createDB(dynamoData)
                            .then((response) => {
                              createApiGateway(function_name, habitat_id)
                                .then((response) => {
                                  const apigateway = response.data.api_gateway;
                                  authorizeCognito(
                                    habitat_id,
                                    cognitoArn,
                                    apigateway.id
                                  )
                                    .then((response) => {
                                      createResource(apigateway.id, habitat_id)
                                        .then((response) => {
                                          deployApi(apigateway.id, habitat_id)
                                            .then((response) => {
                                              const preEnv = {
                                                TABLE: crudName,
                                              };
                                              const env = JSON.stringify(preEnv)
                                                .replace(/\\n/g, '\\n')
                                                .replace(/\\'/g, "\\'")
                                                .replace(/\\"/g, '\\"')
                                                .replace(/\\&/g, '\\&')
                                                .replace(/\\r/g, '\\r')
                                                .replace(/\\t/g, '\\t')
                                                .replace(/\\b/g, '\\b')
                                                .replace(/\\f/g, '\\f');
                                              deployFunc(func.id, env)
                                                .then((response) => {
                                                  let existing_path_id = null;
                                                  const lambdas = response.data;
                                                  const lambda_arn =
                                                    lambdas[lambdas.length - 1]
                                                      .function_arn;
                                                  const postData = {
                                                    habitat_id,
                                                    method: 'POST',
                                                    type: 'AWS_PROXY',
                                                    lambda_arn,
                                                    rol: awsRoles[0].arn,
                                                    path_part: 'v1',
                                                  };
                                                  createMethod(
                                                    apigateway.id,
                                                    postData
                                                  )
                                                    .then((response) => {
                                                      const methodInfo =
                                                        response.data.method;
                                                      listResources(
                                                        apigateway.id,
                                                        habitat_id
                                                      )
                                                        .then((response) => {
                                                          const resources =
                                                            response.data
                                                              .resources;
                                                          resources.forEach(
                                                            (resource) => {
                                                              if (
                                                                resource.path_part ===
                                                                'v1'
                                                              ) {
                                                                existing_path_id =
                                                                  resource.id;
                                                              }
                                                            }
                                                          );
                                                          const getData = {
                                                            habitat_id,
                                                            method: 'GET',
                                                            type: 'AWS_PROXY',
                                                            lambda_arn,
                                                            rol:
                                                              awsRoles[0].arn,
                                                            existing_path_id,
                                                            path_part: '/v1',
                                                          };
                                                          createMethod(
                                                            apigateway.id,
                                                            getData
                                                          )
                                                            .then(
                                                              (response) => {
                                                                const putData = {
                                                                  habitat_id,
                                                                  method: 'PUT',
                                                                  type:
                                                                    'AWS_PROXY',
                                                                  lambda_arn,
                                                                  rol:
                                                                    awsRoles[0]
                                                                      .arn,
                                                                  existing_path_id,
                                                                  path_part:
                                                                    '/v1',
                                                                };
                                                                createMethod(
                                                                  apigateway.id,
                                                                  putData
                                                                )
                                                                  .then(
                                                                    (
                                                                      response
                                                                    ) => {
                                                                      const deleteData = {
                                                                        habitat_id,
                                                                        method:
                                                                          'DELETE',
                                                                        type:
                                                                          'AWS_PROXY',
                                                                        lambda_arn,
                                                                        rol:
                                                                          awsRoles[0]
                                                                            .arn,
                                                                        existing_path_id,
                                                                        path_part:
                                                                          '/v1',
                                                                      };
                                                                      createMethod(
                                                                        apigateway.id,
                                                                        deleteData
                                                                      )
                                                                        .then(
                                                                          (
                                                                            response
                                                                          ) => {
                                                                            deployApi(
                                                                              apigateway.id,
                                                                              habitat_id,
                                                                              'v1'
                                                                            )
                                                                              .then(
                                                                                (
                                                                                  response
                                                                                ) => {
                                                                                  const responseData = {
                                                                                    habitat_id,
                                                                                  };
                                                                                  createRespose(
                                                                                    methodInfo.id,
                                                                                    responseData
                                                                                  )
                                                                                    .then(
                                                                                      (
                                                                                        response
                                                                                      ) => {
                                                                                        const payload =
                                                                                          response.data.aws_api_gateway;
                                                                                        dispatch(
                                                                                          {
                                                                                            type: CREATE_CRUD_API_SUCCESS,
                                                                                            payload,
                                                                                          }
                                                                                        );
                                                                                      }
                                                                                    )
                                                                                    .catch(
                                                                                      (
                                                                                        error
                                                                                      ) =>
                                                                                        dispatch(
                                                                                          {
                                                                                            type: CREATE_CRUD_API_ERROR,
                                                                                            error:
                                                                                              error
                                                                                                .response
                                                                                                .data
                                                                                                .error,
                                                                                            errorNewFunction:
                                                                                              'Create function fail',
                                                                                          }
                                                                                        )
                                                                                    );
                                                                                }
                                                                              )
                                                                              .catch(
                                                                                (
                                                                                  error
                                                                                ) =>
                                                                                  dispatch(
                                                                                    {
                                                                                      type: CREATE_CRUD_API_ERROR,
                                                                                      error:
                                                                                        error
                                                                                          .response
                                                                                          .data
                                                                                          .error,
                                                                                      errorNewFunction:
                                                                                        'Create function fail',
                                                                                    }
                                                                                  )
                                                                              );
                                                                          }
                                                                        )
                                                                        .catch(
                                                                          (
                                                                            error
                                                                          ) =>
                                                                            dispatch(
                                                                              {
                                                                                type: CREATE_CRUD_API_ERROR,
                                                                                error:
                                                                                  error
                                                                                    .response
                                                                                    .data
                                                                                    .error,
                                                                                errorNewFunction:
                                                                                  'Create function fail',
                                                                              }
                                                                            )
                                                                        );
                                                                    }
                                                                  )
                                                                  .catch(
                                                                    (error) =>
                                                                      dispatch({
                                                                        type: CREATE_CRUD_API_ERROR,
                                                                        error:
                                                                          error
                                                                            .response
                                                                            .data
                                                                            .error,
                                                                        errorNewFunction:
                                                                          'Create function fail',
                                                                      })
                                                                  );
                                                              }
                                                            )
                                                            .catch((error) =>
                                                              dispatch({
                                                                type: CREATE_CRUD_API_ERROR,
                                                                error:
                                                                  error.response
                                                                    .data.error,
                                                                errorNewFunction:
                                                                  'Create function fail',
                                                              })
                                                            );
                                                        })
                                                        .catch((error) =>
                                                          dispatch({
                                                            type: CREATE_CRUD_API_ERROR,
                                                            error:
                                                              error.response
                                                                .data.error,
                                                            errorNewFunction:
                                                              'Create function fail',
                                                          })
                                                        );
                                                    })
                                                    .catch((error) =>
                                                      dispatch({
                                                        type: CREATE_CRUD_API_ERROR,
                                                        error:
                                                          error.response.data
                                                            .error,
                                                        errorNewFunction:
                                                          'Create function fail',
                                                      })
                                                    );
                                                })
                                                .catch((error) =>
                                                  dispatch({
                                                    type: CREATE_CRUD_API_ERROR,
                                                    error:
                                                      error.response.data.error,
                                                    errorNewFunction:
                                                      'Create function fail',
                                                  })
                                                );
                                            })
                                            .catch((error) =>
                                              dispatch({
                                                type: CREATE_CRUD_API_ERROR,
                                                error:
                                                  error.response.data.error,
                                                errorNewFunction:
                                                  'Create function fail',
                                              })
                                            );
                                        })
                                        .catch((error) =>
                                          dispatch({
                                            type: CREATE_CRUD_API_ERROR,
                                            error: error.response.data.error,
                                            errorNewFunction:
                                              'Create function fail',
                                          })
                                        );
                                    })
                                    .catch((error) =>
                                      dispatch({
                                        type: CREATE_CRUD_API_ERROR,
                                        error: error.response.data.error,
                                        errorNewFunction:
                                          'Create function fail',
                                      })
                                    );
                                })
                                .catch((error) =>
                                  dispatch({
                                    type: CREATE_CRUD_API_ERROR,
                                    error: error.response.data.error,
                                    errorNewFunction: 'Create function fail',
                                  })
                                );
                            })
                            .catch((error) =>
                              dispatch({
                                type: CREATE_CRUD_API_ERROR,
                                error: error.response.data.error,
                                errorNewFunction: 'Create function fail',
                              })
                            );
                        })
                        .catch((error) =>
                          dispatch({
                            type: CREATE_CRUD_API_ERROR,
                            error: error.response.data.error,
                            errorNewFunction: 'Create function fail',
                          })
                        );
                    })
                    .catch((error) =>
                      dispatch({
                        type: CREATE_CRUD_API_ERROR,
                        error: error.response.data.error,
                        errorNewFunction: 'Create function fail',
                      })
                    );
                })
                .catch((error) =>
                  dispatch({
                    type: CREATE_CRUD_API_ERROR,
                    error: error.response.data.error,
                    errorNewFunction: 'Create function fail',
                  })
                );
            })
            .catch((error) =>
              dispatch({
                type: CREATE_CRUD_API_ERROR,
                error: error.response.data.error,
                errorNewFunction: 'Create function fail',
              })
            );
        }
      })
      .catch((error) =>
        dispatch({
          type: CREATE_CRUD_API_ERROR,
          error: error.response.data.error,
          errorNewFunction: 'Create function fail',
        })
      );
  };
};

export const getApiList = (habitatId) => {
  return (dispatch) => {
    dispatch({
      type: GET_APIGATEWAY_BEGIN,
      loading_message: 'Loading API Gateways...',
    });
    listApi(habitatId)
      .then((response) => {
        dispatch({
          type: GET_APIGATEWAY_SUCCESS,
          payload: response.data.aws_api_gateway,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_APIGATEWAY_ERROR,
          errorGetApi: 'Get API Gateway fail',
          error,
        });
      });
  };
};

export const getApiMethods = (id, habitatId) => {
  return (dispatch) => {
    dispatch({
      type: GET_METHOD_BEGIN,
      loading_message: 'Get API Methods...',
    });
    listMethods(id, habitatId)
      .then((response) => {
        dispatch({
          type: GET_METHOD_SUCCESS,
          payload: response.data.methods,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_METHOD_ERROR,
          error: 'GET API Methods fail',
        });
      });
  };
};

export const getApiResources = (id, habitatId) => {
  return (dispatch) => {
    dispatch({
      type: GET_RESOURCE_BEGIN,
      loading_message: 'Creating API Resources...',
    });
    listResources(id, habitatId)
      .then((response) => {
        dispatch({
          type: GET_RESOURCE_SUCCESS,
          payload: response.data.resources,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_RESOURCE_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const deleteApi = (habitatId, apiId) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_APIGATEWAY_BEGIN,
      loading_message: 'Deleting API...',
    });
    removeApi(habitatId, apiId)
      .then((response) => {
        dispatch({
          type: DELETE_APIGATEWAY_SUCCESS,
          payload: response.data.aws_api_gateway,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_APIGATEWAY_ERROR,
          errorDeleteApi: 'Delete API fail',
          error,
        });
      });
  };
};

export const deleteMethod = (methodId, habitat_id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_METHOD_BEGIN,
      loading_message: 'Deleting Method...',
    });
    removeMethod(methodId, habitat_id)
      .then((response) => {
        dispatch({
          type: DELETE_METHOD_SUCCESS,
          payload: response.data.methods,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_METHOD_ERROR,
          error: error.response.data.error,
        });
      });
  };
};
