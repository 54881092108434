import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getStepFunc, stepFuncAdd } from '../actions/aws-step-func.actions';
import '../assets/styles/pages/stepFunctionPage.scss';
import HabitatTopMenu from '../components/Habitats/HabitatTopMenu';
import StepFuncList from '../components/Habitats/StepFuncList';
import CreateStateMachine from '../components/Habitats/CreateStateMachine';
import StateMachineViewer from '../components/Habitats/StateMachineViewer';
import AddStepFunc from '../components/Habitats/AddStepfunc';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchAWSRole } from '../actions/aws-roles.actions';
import { Button } from 'react-bootstrap';
import { listFunctions } from '../actions/funcs.actions';
import { retrieveHabitat } from '../actions/habitat.actions';
import { faGlobe, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';

const HabitatStepFuncPage = ({
  funcs,
  params,
  habitat,
  loading,
  loadingMessage,
  awsRoles,
  stepFuncAdd,
  getStepFunc,
  fetchAWSRole,
  listFunctions,
  stateMachines,
  retrieveHabitat,
  error
}) => {

  useEffect(() => {
    if (habitat.id) {
      getStepFunc(habitat.id);
      fetchAWSRole(habitat.id);
    } else {
      retrieveHabitat(params.habitatId);
      listFunctions(params.habitatId);
      getStepFunc(params.habitatId);
      fetchAWSRole(params.habitatId);
    }
  }, []);

  const [show, setShow] = useState(false);
  const [role, setRole] = useState('');
  const [name, setName] = useState('');
  const [active, setActive] = useState(false);
  const [states, setStates] = useState([
    {
      id: Date.now(),
      type: '',
      resource: '',
      name: '',
      process: ''
    }
  ]);
  const [objInfo, setObjInfo] = useState([]);
  const [comment, setComment] = useState('');
  const [machine, setMachine] = useState({
    'Comment': '',
    'StartAt': states.name,
    'States': {}
  });
  const [definition, setDefinition] = useState(null);
  const [oldStateData, setOldStateData] = useState(null);

  const handleToggle = () => {
    setShow(!show);
    setActive(false);
    setOldStateData(null);
    setObjInfo([]);
    setRole('');
    setName('');
    setStates([
      {
        id: Date.now(),
        type: '',
        resource: '',
        name: '',
        process: ''
      }
    ]);
    setComment('');
    setMachine({
      'Comment': '',
      'StartAt': states.name,
      'States': {}
    });
  }

  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };

  const handleComment = (event) => {
    setComment(event.target.value);
    setMachine({
      'Comment': event.target.value,
      'StartAt': '',
      'States': {}
    });
  }

  const handleSelectFuntion = (e, index) => {
    const info = funcs.find(n => n.function_arn === e.target.value);
    const state = [...states];
    state[index].resource = info.function_arn;
    state[index].name = info.function_name;
    setStates(state);
    if (states.length === 1) {
      setMachine({
        'Comment': comment,
        'StartAt': info.function_name,
        'States': {
          [info.function_name]: {
            'Type': '',
            'Resource': info.function_arn
          }
        }
      });
    } else if (states.length === 2) {
      setObjInfo(objInfo => [...objInfo,
      {
        id: states[index - 1].name,
        Type: states[index - 1].type,
        Resource: states[index - 1].resource,
        Next: states[index].name
      }
      ]);
      setMachine({
        'Comment': comment,
        'StartAt': states[0].name,
        'States': {
          [states[0].name]: {
            'Type': states[0].type,
            'Resource': states[0].resource,
            'Next': info.function_name
          },
          [info.function_name]: {
            'Type': '',
            'Resource': info.function_arn
          }
        }
      });
    } else if (states.length > 2) {
      setObjInfo(objInfo => [...objInfo,
      {
        id: states[index - 1].name,
        Type: states[index - 1].type,
        Resource: states[index - 1].resource,
        Next: states[index].name
      }
      ]);
      setMachine({
        'Comment': comment,
        'StartAt': states[0].name,
        'States': oldStateData,
        [states[index - 1].name]: {
          'Type': states[index - 1].type,
          'Resource': states[index - 1].resource,
          'Next': state[index].name
        },
        [info.function_name]: {
          'Type': '',
          'Resource': info.function_arn
        }
      });
    }
  }

  const handleSelectTask = (e, index) => {
    const type = e.target.value;
    const state = [...states];
    state[index].type = type;
    setStates(state);
    if (states.length === 1) {
      setMachine({
        'Comment': comment,
        'StartAt': states[index].name,
        'States': {
          [states[index].name]: {
            'Type': type,
            'Resource': states[index].resource
          }
        }
      });
    } else if (states.length === 2) {
      const steps = convertArrayToObject(objInfo, 'id');
      setOldStateData(steps);
      Object.defineProperty(steps[states[index - 1].name], 'id', {
        enumerable: false,
        writable: true
      });
      setMachine({
        'Comment': comment,
        'StartAt': states[0].name,
        'States': {
          [states[0].name]: {
            'Type': states[0].type,
            'Resource': states[0].resource,
            'Next': states[1].name
          },
          [states[index].name]: {
            'Type': type,
            'Resource': states[index].resource
          }
        }
      });
    } else if (states.length > 2) {
      const steps = convertArrayToObject(objInfo, 'id');
      setOldStateData(steps);
      Object.defineProperty(steps[states[index - 1].name], 'id', {
        enumerable: false,
        writable: true
      });
      setMachine({
        'Comment': comment,
        'StartAt': states[0].name,
        'States': oldStateData,
        [states[index - 1].name]: {
          'Type': states[index - 1].type,
          'Resource': states[index - 1].resource,
          'Next': states[index].name
        },
        [states[index].name]: {
          'Type': type,
          'Resource': states[index].resource
        }
      });
    }
  }

  const handleSelectProcess = (e, index) => {
    const process = e.target.value
    const state = [...states];
    state[index].process = process;
    setStates(state);
    if (process === 'End') {
      if (states.length === 1) {
        setMachine({
          'Comment': comment,
          'StartAt': states[index].name,
          'States': {
            [states[index].name]: {
              'Type': states[index].type,
              'Resource': states[index].resource,
              [process]: true
            }
          }
        });
      } else if (states.length === 2) {
        setMachine({
          'Comment': comment,
          'StartAt': states[0].name,
          'States': {
            [states[0].name]: {
              'Type': states[0].type,
              'Resource': states[0].resource,
              'Next': states[index].name
            },
            [states[index].name]: {
              'Type': states[index].type,
              'Resource': states[index].resource,
              [process]: true
            }
          }
        });
      } else if (states.length > 2) {
        setActive(true);
        setObjInfo(objInfo => [...objInfo,
        {
          id: states[index].name,
          Type: states[index].type,
          Resource: states[index].resource,
          End: true
        }
        ]);
        const steps = convertArrayToObject(objInfo, 'id');
        setOldStateData(steps);
        Object.defineProperty(steps[states[index - 1].name], 'id', {
          enumerable: false,
          writable: true
        });
        setMachine({
          'Comment': comment,
          'StartAt': states[0].name,
          'States': oldStateData,
          [states[index].name]: {
            'Type': states[index].type,
            'Resource': states[index].resource,
            [process]: true
          }
        });
      }
    } else {
      setStates(currentState => [...currentState, {
        id: Date.now(),
        type: '',
        resource: '',
        name: '',
        process: ''
      }]);
    }
  }

  const handleAddStepFunc = async () => {
    if (active) {
      const steps = await convertArrayToObject(objInfo, 'id');
      await setOldStateData(steps);
      await setMachine({
        'Comment': comment,
        'StartAt': states[0].name,
        'States': steps
      });
      const info = {
        'Comment': comment,
        'StartAt': states[0].name,
        'States': steps
      }
      Object.defineProperty(steps[states[states.length - 1].name], 'id', {
        enumerable: false,
        writable: true
      });
      stepFuncAdd(name, info, role, habitat.id);
    } else {
      stepFuncAdd(name, machine, role, habitat.id);
    }
    handleToggle();
  }

  const handleShowDefinition = (definition) => {
    setDefinition(definition);
  }

  return (
    <>
      <div className="container-step-functions">
        <div className="step-functions-list">
          <div className="list-step-funcs">
            <div className="d-flex justify-content-between">
              <p className="title-step-funcs">Step Functions</p>
              <div>
                {!show ? (
                  <Button variant="primary" size="sm" onClick={handleToggle}>
                    <b>+ Add Step Function</b>
                  </Button>
                ) : (
                  <Button variant="primary" size="sm" onClick={handleToggle}>
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                    <span> Back</span>
                  </Button>
                )}
              </div>
            </div>
            {error && (
              <div className="error-display text-danger">
                <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {error}</p>
              </div>
            )}


            {loading ? (
              <div className="spinner-container step-function-spinner">
                <Spinner animation="border" variant="primary" />
                <p>{loadingMessage}</p>
              </div>
            ) : (
              <div>
                { !show ? (
                  <div className="m-4">
                    <StepFuncList
                      stateMachines={stateMachines}
                      handleShowDefinition={handleShowDefinition}
                    />
                  </div>
                ) : (
                  <div>
                    <CreateStateMachine
                      role={role}
                      name={name}
                      setRole={setRole}
                      setName={setName}
                      comment={comment}
                      awsRoles={awsRoles}
                      handleComment={handleComment}
                    />
                    {name && role && (
                      <AddStepFunc
                        funcs={funcs}
                        states={states}
                        handleSelectFuntion={handleSelectFuntion}
                        handleSelectTask={handleSelectTask}
                        handleSelectProcess={handleSelectProcess}
                        handleAddStepFunc={handleAddStepFunc}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="state-machine-container">
            <StateMachineViewer
              definition={definition}
              machine={machine}
              states={states}
              show={show}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const mapState = (state, props) => ({
  funcs: state.func.funcs,
  params: props.params,
  habitat: state.habitat.habitat,
  loading: state.stepFunc.loading,
  loadingMessage: state.stepFunc.loading_message,
  awsRoles: state.awsRoles.awsRoles,
  stateMachines: state.stepFunc.stateMachines,
  error: state.stepFunc.error
})

const mapDispatch = {
  getStepFunc,
  stepFuncAdd,
  fetchAWSRole,
  listFunctions,
  retrieveHabitat,
}

export default connect(
  mapState,
  mapDispatch
)(HabitatStepFuncPage);
