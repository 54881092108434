import React, { useState } from 'react';
import { connect } from 'react-redux';
import routes from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { faTrashAlt, faLayerGroup, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  deleteHabitat,
  editHabitat,
  selectHabitat,
  requestDeleteHabitat
} from '../../actions/habitat.actions';
import habitatIcon from '../../assets/images/icons/HabitatLight.svg';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { nextStepTour, stopTour } from '../../actions/app-tour.actions';
import HabitatDeleteModal from '../Modals/HabitatDeleteModal';
import RemoveHabitatModal from '../Modals/RemoveHabitatModal';
import trash from '../../assets/images/icons/trash.svg';
import eye from '../../assets/images/icons/eye-open.svg';
import habitatIcon2 from '../../assets/images/icons/habitats/habitatIcon.svg';
import Moment from 'moment';
import check from '../../assets/images/icons/habitats/iconCheck.svg';

const HabitatItem = withRouter((
  {
    step,
    active,
    habitat,
    deleteHabitat,
    editHabitat,
    selectHabitat,
    requestDeleteHabitat,
    history,
    nextStep,
    stepAppTour,
    nextStepTour,
    stopTour,
    errorDeleteHabitatCode,
    numResources
  }) => {

  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [showRemoveHabitat, setShowRemoveHabitat] = useState('display-none-kor');

  const handleShow = () => {
    setShow(true);
    document.body.click();
  };

  const handleUpdateHabitat = (e) => {
    e.preventDefault();
    let newHab = { name: name };
    editHabitat(habitat, newHab);
    setShow(false);
  }

  const handleRemoveHabitat = (e) => {
    deleteHabitat(habitat.id);
    document.body.click()
    setShowDelete(false)
  }

  const handleSelect = () => {
    if (stepAppTour === 4) {
      selectHabitat(habitat);
      nextStepTour();
      history.push(`${routes.HABITATMAINPAGE}/${habitat.id}`);
    } else {
      selectHabitat(habitat);
      history.push(`${routes.HABITATMAINPAGE}/${habitat.id}`);
    }
  }

  const handleShowDelete = (habitat_id) => {
    requestDeleteHabitat(habitat_id)
    setShowRemoveHabitat('')
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>
        <div className="new-collection" onClick={handleShow}>
          <FontAwesomeIcon className="rename-icon-collection" icon={faPencilAlt} />
          <span>Rename</span>
        </div>
        <div className="new-function" onClick={() => handleShowDelete(habitat.id)}>
          <FontAwesomeIcon className="delete-icon-function" icon={faTrashAlt} />
          <span>Delete</span>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="habitat-list-item">
      <RemoveHabitatModal
        showRemoveHabitat={showRemoveHabitat}
        handleShowDelete={handleShowDelete}
        setShowRemoveHabitat={setShowRemoveHabitat}
        handleRemoveHabitat={handleRemoveHabitat}
        habitat={habitat}
        errorDeleteHabitatCode={errorDeleteHabitatCode}
        numResources={numResources}
      />

      <div className="habitat-model-wrapper">
        <HabitatDeleteModal
          setShowDelete={setShowDelete}
          showDelete={showDelete}
          handleRemoveHabitat={handleRemoveHabitat}
        />
        <Modal
          size="sm"
          show={show}
          onHide={() => setShow(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Rename Habitat</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Control
                  aria-label="Name"
                  aria-describedby="new-name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  type="text"
                  maxlength="30"
                  placeholder="Habitat New Name"
                />
              </Form.Group>
            </Form>
            <div className="button-update-name-function">
              <Button variant="primary" onClick={(e) => handleUpdateHabitat(e)}>
                Save
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <div className="habitat-item-container">
        <div className="habitat-list-name-functions" onClick={handleSelect}>
          <div className="habitat-list-title">
            <div className="habitat-icon-box-container">
              <div className="habitat-icon-box">
                <img src={habitatIcon2} alt="collection-icon" />
              </div>
            </div>

            <div onClick={handleSelect}>
              <div className="habitat-title-date">
                <p className="habitat-name">{habitat.name}</p>
                <p className="last-update-habitat"><b>Last Update</b> <span>{Moment(habitat.updated_at).format('lll')}</span></p>
                <div className="habitat-created-by">
                  <p className="habitat-created-by-p"><b>Created by</b></p>
                  {habitat.user_name === null ? (
                    <div className="name-avatar-habitat">
                      <p>U</p>
                      <p>Unknown</p>
                    </div>
                  ) : (
                    <div className="name-avatar-habitat">
                      <p>A</p>
                      <p>{habitat.user_name}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="resources-functions-container">

            <div className="habitats-list" xs="1" onClick={handleSelect}>
              <div className="habitat-sect-item">
                <p className="habitat-quantity">0</p>
                <p className="habitat-list-titles">Resources</p>
              </div>
            </div>

            <div className="habitats-list" xs="1" onClick={handleSelect}>
              <div className="habitat-sect-item">
                <p className="habitat-quantity">0</p>
                <p className="habitat-list-titles">Functions</p>
              </div>
            </div>

            <div className="habitats-list" xs="1" onClick={handleSelect}>
              <div className="habitat-sect-item">
                <p className="habitat-quantity">0</p>
                <p className="habitat-list-titles">Databases</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="habitats-list" xs="1" onClick={handleSelect}>
            <div className="habitat-sect-item">
              <div className="habitat-quantity check-health">
                <img src={check} /> <p>OK</p>
              </div>
              <p className="habitat-list-titles">Creds Verified</p>
            </div>
          </div>
        </div>

        <div className="habitats-list-buttons">
          <Button onClick={() => handleShowDelete(habitat.id)}>
            <img src={trash} /> Delete Habitat
              </Button>
          <Button onClick={handleSelect}>
            <img src={eye} /> View Details
            </Button>
        </div>
      </div>
    </div>
  );
})

const mapState = (state, props) => ({
  habitat: props.value,
  stepAppTour: state.appTour.stepAppTour,
  errorDeleteHabitatCode: state.habitat.remove_habitat_request.verification,
  numResources: state.habitat.remove_habitat_request.num_resources
})

const mapDispatch = {
  deleteHabitat,
  editHabitat,
  selectHabitat,
  requestDeleteHabitat,
  withRouter,
  nextStepTour,
  stopTour,
}

export default connect(
  mapState,
  mapDispatch
)(HabitatItem);
