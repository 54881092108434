import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faLayerGroup
} from '@fortawesome/free-solid-svg-icons';
import sitesIcon from '../../assets/images/icons/habitats/sitesIcon.svg';

const HabitatTemplates = (props) => {

    return (
        <div>
            {props.habitatSetUp === 'Sites' ? (
                <div className={'templates-selected box-feature'}>
                    <div className="box-feature-name">
                        <div className="box-feature-name-icon-templates">
                            <img src={sitesIcon} />
                        </div>
                        <p>Sites</p>
                    </div>

                    <div className="box-feature-plus-1">
                        <p>{props.templates.length}</p>
                    </div>

                </div>
            ) : (
                <div className="box-feature" onClick={() => props.handleSelectFeature('Sites')}>
                    <div className="box-feature-name">
                        <div className="box-feature-name-icon-templates">
                            <img src={sitesIcon} />
                        </div>
                        <p>Sites</p>
                    </div>

                    <div className="box-feature-plus-1">
                        <p>{props.templates.length}</p>
                    </div>
                </div>
            )}

        </div>
    )
}

export default HabitatTemplates;