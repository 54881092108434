import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFileCode
} from '@fortawesome/free-solid-svg-icons';
import lambdasIcon from '../../assets/images/icons/habitats/lambdasIcon.svg'

const HabitatFuncs = (props) => {

    const handleNextStep = () => {
        if(props.stepAppTour === 10){
            props.nextStepTour();
        }
    }
    return (
        <div className={props.onboardingClassFuncs} onClick={handleNextStep}>
            {props.habitatSetUp === 'Functions' ? (
                <div className={'funcs-selected box-feature'}>
                    <div className="box-feature-name">
                        <div className="box-feature-name-icon-funcs">
                            <img src={lambdasIcon} />
                        </div>
                        <p>Functions</p>
                    </div>

                    <div className="box-feature-plus-1">
                        <p>{props.funcs.length}</p>
                    </div>
                </div>
            ) : (
                    <div className="box-feature" onClick={() => props.handleSelectFeature('Functions')}>
                        <div className="box-feature-name">
                            <div className="box-feature-name-icon-funcs">
                                <img src={lambdasIcon} />
                            </div>
                            <p>Functions</p>
                        </div>

                        <div className="box-feature-plus-1">
                            <p>{props.funcs.length}</p>
                        </div>
                    </div>
                )}

        </div>
    )
}

export default HabitatFuncs;