import React, { useState } from 'react';
import { connect } from 'react-redux';
import Loading from '../Loading';
import { clearS3, getS3Info, deleteAWSS3, createS3Bucket, disconnectS3Bucket } from '../../actions/aws-s3.actions';
import { chooseS3Bucket } from '../../actions/habitat.actions';
import { Button, Table, Col, Alert, Popover, Overlay, OverlayTrigger, Form } from 'react-bootstrap';
import { faInfoCircle, faFill, faExclamationCircle, faTimes, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../assets/styles/components/AwsS3.scss';
import '../../assets/styles/components/habitats/HabitatS3.scss'
import Spinner from 'react-bootstrap/Spinner';
import storageIcon from '../../assets/images/icons/habitats/storageIcon.svg';
import eye from '../../assets/images/icons/eye-open.svg';
import Moment from 'moment';
import trash from '../../assets/images/icons/trash.svg';
import ManageS3Bucket from '../Habitats/modals/ManageS3Bucket';

const HabitatS3 = ({
  allS3,
  files,
  s3Load,
  clearS3,
  habitat,
  buckets,
  s3Error,
  quantity,
  awsCreds,
  getS3Info,
  s3AwsLoad,
  s3AwsError,
  clearBucket,
  deleteAWSS3,
  errorClearS3,
  errorDeleteS3,
  allConnectedS3,
  chooseS3Bucket,
  createS3Bucket,
  disconnectS3Bucket,
  loadingMessage,
  error,
  loadingConnect,
  loadingConnectMessage,
  errorMessage
}) => {
  const [show, setShow] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [nameBucket, setNameBucket] = useState('');
  const [showS3Error, setShowS3Error] = useState(true);
  const [showS3AwsError, setShowS3AwsError] = useState(true);
  const [showConfirmDel, setShowConfirmDel] = useState(false);
  const [selectedS3Radio, setSelectedS3Radio] = useState('');
  const [s3BucketName, setS3BucketName] = useState('');
  const [activeSelected, setActiveSelected] = useState('connected');
  const [showFilesModal, setShowFilesModal] = useState(false);
  const [s3Id, setS3id] = useState('');

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">AWS Restrictions</Popover.Title>
      <Popover.Content>
        <li>Bucket names must be between 3 and 63 characters long.</li>
        <li>Bucket names can consist only of lowercase letters, numbers, dots (.), and hyphens (-).</li>
        <li>Bucket names must begin and end with a letter or number.</li>
        <li>Bucket names must not be formatted as an IP address.</li>
        <li>Bucket names can&apos;t begin with xn-- (for buckets created after February 2020).</li>
        <li>Bucket names must be unique within a partition. A partition is a grouping of Regions.</li>
        <li>Buckets used with Amazon S3 Transfer Acceleration can&apos;t have dots (.) in their names.</li>
      </Popover.Content>
    </Popover>
  );

  const RestrictionsTooltip = () => (
    <OverlayTrigger trigger={['hover', 'focus']} placement="left" overlay={popover}>
      <p className="restrictionsText">
        Naming restrictions &nbsp; <FontAwesomeIcon className="restrictInfo" icon={faInfoCircle} />
      </p>
    </OverlayTrigger>
  );

  const handleShow = () => {
    setShowNew(true);
    document.body.click();
  };

  const handleConnect = () => {
    chooseS3Bucket(habitat.id, selectedS3Radio);
  }

  const handleDisconnect = (s3Name) => {
    disconnectS3Bucket(habitat.id, s3Name);
  }

  const handleShowDel = (s3Name) => {
    setNameBucket(s3Name);
    setShowConfirmDel(true);
  }

  const handleShowInfo = (bucket) => {
    setS3BucketName(bucket)
    setS3id(bucket)
    getS3Info(bucket, habitat.id);
    setShowFilesModal(true)
  }

  const handleHideInfo = () => {
    setShow(false);
  }

  const handleCloseS3Error = () => {
    setShowS3Error(false);
  }
  const handleCloseS3AwsError = () => {
    setShowS3AwsError(false);
  }

  const handleNewBucket = () => {
    const region = awsCreds.region
    setShowNew(false);
    createS3Bucket(nameBucket, habitat.id, region);
  };

  const handleDelete = (s3Bucket) => {
    setShowConfirmDel(false);
    deleteAWSS3(habitat.id, s3Bucket);
    setSelectedS3Radio('');
    setS3BucketName('');
  }

  const handleSelectedRadio = (e) => {
    setSelectedS3Radio(e.target.value);
  }

  const handleSelectActive = (selected) => {
    setActiveSelected(selected);
  }
  
  const handleClearBucket = (s3Bucket) => {
    clearS3(s3Bucket, habitat.id)
    setShowFilesModal(false)
  }


  return (
    <div>

      {showFilesModal && (
        <ManageS3Bucket
          showFilesModal={showFilesModal}
          setShowFilesModal={setShowFilesModal}
          s3BucketName={s3BucketName}
          quantity={quantity}
          files={files}
          clearS3={clearS3}
          habitat={habitat}
          s3Id={s3Id}
          handleClearBucket={handleClearBucket}
        />
      )}


      <div className="habitat-s3-management-container">

        {errorMessage && (
          <div className="error-display text-danger">
            <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {errorMessage}</p>
          </div>
        )}

        {s3AwsLoad && (
          <div className="spinner-container s3bucket-spinner">
            <Spinner animation="border" variant="primary" />
            <p>{loadingMessage}</p>
          </div>
        )}

        {loadingConnect && (
          <div className="spinner-container s3bucket-spinner">
            <Spinner animation="border" variant="primary" />
            <p>{loadingConnectMessage}</p>
          </div>
        )}

        {activeSelected === 'connected' && (
          <div className='select-connected-avaiable'>
            <p className="active-tab">Connected</p>
            <p onClick={() => handleSelectActive('available')}>Available</p>
          </div>
        )}

        {activeSelected === 'available' && (
          <div className='select-connected-avaiable'>
            <p onClick={() => handleSelectActive('connected')}>Connected</p>
            <p className="active-tab">Available</p>
          </div>
        )}


        {activeSelected === 'connected' && (
          <div className="list-s3-creds">
            <div className='container-connected-buckets'>
              {buckets && (
                buckets.map((cs3) => (
                  <div className='container-box-s3' key={`${cs3.bucket}`}>
                    <div className="icon-storage-box">
                      <div className="img-box-storage">
                        <img src={storageIcon} />
                      </div>
                      <div className="info-storage-bucket">
                        <p className="name-storage-box">{cs3.bucket}</p>
                        <p className="last-updated-text"><span>Created On</span> {Moment(cs3.created_at).format('lll')}</p>
                        <div className="managed-by-repo">
                          <p className="managed-by-text">Managed</p>
                          <div className="avatar-repo-author">
                            <div className="avatar-1">
                              <p>U</p>
                            </div>
                            <div className="name-author">
                              <p>Unknown</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="button-details">
                      <Button onClick={() => handleShowInfo(cs3.bucket)}>
                        <img src={eye} /> View Details
                      </Button>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

        )}

        {activeSelected === 'available' && (
          <div className="list-s3-creds">
            {/* <div className="management-s3-container">
            <p>Available S3 Buckets</p>
            <div className="options-aws">
              <Button variant="outline-primary" size="sm" disabled={!selectedS3Radio} onClick={() => handleConnect()}>Connect</Button>
              <Button variant="outline-warning" size="sm" disabled={!selectedS3Radio} onClick={() => clearS3(selectedS3Radio, habitat.id)}>Clear</Button>
              <Button variant="outline-danger" size="sm" disabled={!selectedS3Radio} onClick={() => handleDelete()}>Remove</Button>
            </div>
          </div> */}
            <div className='container-connected-buckets'>
              {buckets && buckets.length > 0 && (
                buckets.map((s3) => (
                  <div className='container-box-s3' key={`${s3.bucket}`}>
                    <div className="icon-storage-box">
                      <div className="img-box-storage">
                        <img src={storageIcon} />
                      </div>
                      <div className="info-storage-bucket">
                        <p className="name-storage-box">{s3.bucket}</p>
                        <p className="last-updated-text"><span>Created On</span> {Moment(s3.created_at).format('lll')}</p>
                        <div className="managed-by-repo">
                          <p className="managed-by-text">Managed</p>
                          <div className="avatar-repo-author">
                            <div className="avatar-1">
                              <p>U</p>
                            </div>
                            <div className="name-author">
                              <p>Unknown</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="button-delete-details">
                      <Button onClick={() => handleDelete(s3.bucket)}>
                        <img src={trash} /> Remove
                      </Button>
                      <Button onClick={() => handleShowInfo(s3.bucket)}>
                        <img src={eye} /> View Details
                      </Button>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

        )}
      </div>


    </div>
  );
}
const mapState = (state, props) => ({
  habitat: props.habitat,
  files: state.awsS3.files,
  quantity: state.awsS3.quantity,
  buckets: state.awsS3.s3Buckets,
  s3AwsLoad: state.awsS3.loading,
  loadingMessage: state.awsS3.loading_message,
  allS3: state.habitat.habitat_s3s,
  s3Error: state.habitat.s3s_error,
  error: state.awsS3,
  errorMessage: state.awsS3.error_message,
  s3Load: state.habitat.s3s_loading,
  clearBucket: state.awsS3.clearBucket,
  errorClearS3: state.awsS3.errorClearS3,
  awsCreds: state.awsCreds.awsCredentials,
  errorDeleteS3: state.awsS3.errorDeleteS3,
  allConnectedS3: state.habitat.habitat_s3s,
  loadingConnect: state.habitat.loading,
  loadingConnectMessage: state.habitat.loading_message
})

const mapDispatch = {
  clearS3,
  getS3Info,
  deleteAWSS3,
  chooseS3Bucket,
  createS3Bucket,
  disconnectS3Bucket,
}

export default connect(mapState, mapDispatch)(HabitatS3);