import React, { useState } from 'react';
import Moment from 'moment';
import HabitatNewTest from './HabitatNewTest';
import ApiIcon from '../../assets/images/api.svg';
import HabitatCreateMethod from './HabitatCreateMethod';
import HabitatApiEndpoints from './HabitatApiEndpoints';
import Trash from '../../assets/images/icons/trash.svg';
import closeIcon from '../../assets/images/icons/close-icon.svg';

const HabitatInfoApiGateway = ({
  show,
  funcs,
  apiTest,
  testApi,
  setShow,
  methods,
  habitat,
  awsRoles,
  awsCreds,
  deleteApi,
  createRoute,
  selectedAPI,
  deleteMethod
}) => {

  const [ section, setSection ] = useState('api');

  const handleDeleteAPI = () => {
    deleteApi(habitat.id, selectedAPI.id);
    setShow(false);
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="close-title">
          <img src={closeIcon} onClick={() => setShow(!show)} />
          <h3>{selectedAPI.name.substring(0,18)}</h3>
        </div>
        <button className="btn btn-grey" onClick={() => handleDeleteAPI()}>
          <img src={Trash} />
            Delete {selectedAPI.name.substring(0,12)}
        </button>
      </div>

      <div className="d-flex mt-5">
        <div className="folder-icon-repo mr-4">
          <img src={ApiIcon} />
        </div>
        <div>
          <p className="title-item font-weight-bold">{selectedAPI.name}</p>
          <p className="text-item font-weight-bold"><b>LAST UPDATED</b> <span className="text-muted ml-2">{Moment(selectedAPI.updated_at).format('lll')}</span></p>
          <div className="managed-by-repo">
            <p className="managed-by-text font-weight-bold mr-2">Managed</p>
            <div className="avatar-repo-author">
              <div className="avatar-1">
                <p className="font-weight-bold">{!selectedAPI.user_name ? 'U' : selectedAPI.user_name.charAt(0).toUpperCase()}</p>
              </div>
              <div className="name-author">
                {!selectedAPI.user_name ? (
                  <p className="font-weight-bold" >Unknown</p>
                ) : (
                  <p className="font-weight-bold" >{selectedAPI.user_name}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {section === 'api' && (
        <HabitatApiEndpoints
          Trash={Trash} 
          methods={methods}
          habitat={habitat}
          awsCreds={awsCreds}
          setSection={setSection}
          selectedAPI={selectedAPI}
          deleteMethod={deleteMethod}
        />
      )}

      {section === 'test' && (
        <HabitatNewTest
          apiTest={apiTest}
          testApi={testApi}
          methods={methods}
          habitat={habitat}
          setSection={setSection}
          selectedAPI={selectedAPI}
        />
      )}

      {section === 'methods' && (
        <HabitatCreateMethod
          funcs={funcs}
          habitat={habitat}
          awsRoles={awsRoles} 
          setSection={setSection}
          selectedAPI={selectedAPI}
          createRoute={createRoute}
        />
      )}
    </>
  );
} 

export default HabitatInfoApiGateway;