import React from 'react';
import Moment from 'moment';
import ApiIcon from '../../assets/images/api.svg';
import Eye from '../../assets/images/icons/eye-open.svg';

const HabitatAPIList = ({ api, handlerInfo }) => {
  return (
    <div className="rounded-kor-repo-1">
      <div className="info-repo-kor">
        <div className="folder-icon-repo">
          <img src={ApiIcon} />
        </div>
        <div>
          <p className="title-item">{api.name}</p>
          <p className="text-item">
            <b>LAST UPDATED</b> 
            <span className="text-muted ml-2">
              {Moment(api.updated_at).format('lll')}
            </span>
          </p>
          <div className="managed-by-repo">
            <p className="managed-by-text">Managed</p>
            <div className="avatar-repo-author">
              <div className="avatar-1">
                <p>
                  {!api.user_name 
                  ? 'U' 
                  : api.user_name.charAt(0).toUpperCase()}
                </p>
              </div>
              <div className="name-author">
                {!api.user_name ? (
                  <p>Unknown</p>
                ) : (
                  <p>{api.user_name}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="avatar-repo-author d-felx justify-content-center">
          <p className="text-center pt-2 pb-2 pl-4 pr-4 mr-3 ml-3">
            {api.methods}
          </p>
        </div>
        <p className="text-small text-bold text-center mt-2">ENDPOINTS</p>  
      </div>
      <div className="buttons-delete-view">
        <button className="btn kor-btn" onClick={() => handlerInfo(api)}>
          <img src={Eye} /> View Details
        </button>
      </div>
    </div>
  );
}

export default HabitatAPIList;