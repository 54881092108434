import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HabitatTopMenu from '../components/Habitats/HabitatTopMenu';
import '../assets/styles/containers/HabitatCostsPage.scss';
import HabitatCosts from '../components/Habitats/HabitatCosts';
import HabitatCostsActivation from '../components/Habitats/HabitatCostsActivation';
import { retrieveHabitat } from '../actions/habitat.actions';
import { getHabitatCosts, updateActivationCost } from '../actions/habitat.actions';

const HabitatCostsPage = ({
    costs,
    params,
    habitat,
    getHabitatCosts,
    retrieveHabitat,
    error,
    updateActivationCost,
    costsActivation,
    loading,
    loadingMessage
}) => {
    const [startDate, setStartDate] = useState(Moment().format());
    const [endDate, setEndDate] = useState(Moment().format());
    const [granularity, setGranularity] = useState('MONTHLY')
    const nowStartDate = Moment().format('YYYY-MM-DD');;
    const nowEndDate = Moment().subtract(3, 'days').format('YYYY-MM-DD');
    const nowGranularity = 'DAILY';

    const handleCostActivation = () => {
        updateActivationCost(habitat.id, true);
    }

    const handleGetCost = () => {
        getHabitatCosts(habitat.id, startDate, endDate, granularity)
    }

    const handleCurrentDate = () => {
        getHabitatCosts(habitat.id, nowEndDate, nowStartDate, nowGranularity)
    }

    useEffect(() => {
        if (!habitat.id) {
            retrieveHabitat(params.habitatId);
            handleCurrentDate()
        }
        handleCurrentDate()
    }, [])

    return (
        <div>


            <div className="habitat-costs-container">
                {!habitat.costs_activation && !costsActivation ? (
                    <HabitatCostsActivation
                        handleCostActivation={handleCostActivation}
                    />
                ) : (
                    <HabitatCosts
                        costs={costs}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setGranularity={setGranularity}
                        granularity={granularity}
                        handleGetCost={handleGetCost}
                        error={error}
                        loading={loading}
                        loadingMessage={loadingMessage}
                    />
                )}


            </div>
        </div>
    )
}

const mapState = (state, props) => ({
    habitat: state.habitat.habitat,
    costs: state.habitat.costs,
    params: props.params,
    error: state.habitat.error_costs,
    costsActivation: state.habitat.costsActivation,
    loading: state.habitat.loading,
    loadingMessage: state.habitat.loading_message
})

const mapDispatch = {
    getHabitatCosts,
    retrieveHabitat,
    updateActivationCost
}

export default connect(
    mapState,
    mapDispatch
)(HabitatCostsPage)