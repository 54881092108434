import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { Controlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/keymap/sublime';
import 'codemirror/theme/base16-light.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import {
  deleteDeployment,
  allDeployments,
} from '../../actions/deployment.actions';
import { cloudFrontStatus } from '../../actions/templates.actions';
import { getAwsLogs, getAwsLog } from '../../actions/aws-sdk.actions';
import {
  updateFunction,
  funcInvoke,
} from '../../actions/funcs.actions';
import { withRouter } from 'react-router-dom';
import { nextStepTour, stopTour } from '../../actions/app-tour.actions';
import routes from '../../constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCode, faEye, faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  siteStatus,
  updateStatic,
  deployStatic,
  buildAndUpdate,
  buildAndDeployStatic,
  fetchInvalidations,
  newInvalidation,
  getValidation
} from '../../actions/templates.actions';
import { fetchAWSRole } from '../../actions/aws-roles.actions';
import deployIcon from '../../assets/images/icons/habitats/deploymentIcon.svg';
import sitesIcon from '../../assets/images/icons/habitats/sitesIcon.svg';
import Moment from 'moment';
import eyeIcon from '../../assets/images/icons/eye-open.svg';
import iconCheck from '../../assets/images/icons/habitats/iconCheck.svg';
import HabitatSitesDeploymentInfo from '../Habitats/HabitatSitesDeploymentInfo';
require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');

const HabitatTemplateItem = withRouter(
  ({
    log,
    func,
    team,
    index,
    habitat,
    history,
    awsRoles,
    template,
    gitClones,
    siteStatus,
    funcInvoke,
    stepAppTour,
    deployStatic,
    updateStatic,
    fetchAWSRole,
    nextStepTour,
    buildAndUpdate,
    updateFunction,
    buildAndDeployStatic,
    templates,
    errorSites,
    cloudFrontStatus,
    fetchInvalidations,
    invalidationsArray,
    newInvalidation,
    getValidation,
    invalidationItem,
    setShowModal,
    setShowModalTemplate,
    handleSelectSite
  }) => {
    const [code, setCode] = useState(
      'version: 0.2\r\n\r\n#env:\r\n  #variables:\r\n     # key: \"value\"\r\n     # key: \"value\"\r\n  #parameter-store:\r\n     # key: \"value\"\r\n     # key: \"value\"\r\n  #secrets-manager:\r\n     # key: secret-id:json-key:version-stage:version-id\r\n     # key: secret-id:json-key:version-stage:version-id\r\n  #exported-variables:\r\n     # - variable\r\n     # - variable\r\n  #git-credential-helper: yes\r\n#batch:\r\n  #fast-fail: true\r\n  #build-list:\r\n  #build-matrix:\r\n  #build-graph:\r\nphases:\r\n  #install:\r\n    #If you use the Ubuntu standard image 2.0 or later, you must specify runtime-versions.\r\n    #If you specify runtime-versions and use an image other than Ubuntu standard image 2.0, the build fails.\r\n    #runtime-versions:\r\n      # name: version\r\n      # name: version\r\n    #commands:\r\n      # - command\r\n      # - command\r\n  #pre_build:\r\n    #commands:\r\n      # - command\r\n      # - command\r\n  build:\r\n    commands:\r\n      # - command\r\n      # - command\r\n  #post_build:\r\n    #commands:\r\n      # - command\r\n      # - command\r\n#reports:\r\n  #report-name-or-arn:\r\n    #files:\r\n      # - location\r\n      # - location\r\n    #base-directory: location\r\n    #discard-paths: yes\r\n    #file-format: JunitXml | CucumberJson\r\n#artifacts:\r\n  #files:\r\n    # - location\r\n    # - location\r\n  #name: $(date +%Y-%m-%d)\r\n  #discard-paths: yes\r\n  #base-directory: location\r\n#cache:\r\n  #paths:\r\n    # - paths'
    );

    const initialCode = 'version: 0.2\r\n\r\n#env:\r\n  #variables:\r\n     # key: \"value\"\r\n     # key: \"value\"\r\n  #parameter-store:\r\n     # key: \"value\"\r\n     # key: \"value\"\r\n  #secrets-manager:\r\n     # key: secret-id:json-key:version-stage:version-id\r\n     # key: secret-id:json-key:version-stage:version-id\r\n  #exported-variables:\r\n     # - variable\r\n     # - variable\r\n  #git-credential-helper: yes\r\n#batch:\r\n  #fast-fail: true\r\n  #build-list:\r\n  #build-matrix:\r\n  #build-graph:\r\nphases:\r\n  #install:\r\n    #If you use the Ubuntu standard image 2.0 or later, you must specify runtime-versions.\r\n    #If you specify runtime-versions and use an image other than Ubuntu standard image 2.0, the build fails.\r\n    #runtime-versions:\r\n      # name: version\r\n      # name: version\r\n    #commands:\r\n      # - command\r\n      # - command\r\n  #pre_build:\r\n    #commands:\r\n      # - command\r\n      # - command\r\n  build:\r\n    commands:\r\n      # - command\r\n      # - command\r\n  #post_build:\r\n    #commands:\r\n      # - command\r\n      # - command\r\n#reports:\r\n  #report-name-or-arn:\r\n    #files:\r\n      # - location\r\n      # - location\r\n    #base-directory: location\r\n    #discard-paths: yes\r\n    #file-format: JunitXml | CucumberJson\r\n#artifacts:\r\n  #files:\r\n    # - location\r\n    # - location\r\n  #name: $(date +%Y-%m-%d)\r\n  #discard-paths: yes\r\n  #base-directory: location\r\n#cache:\r\n  #paths:\r\n    # - paths'

    const html = '{code}'
    const [show, setShow] = useState(false);
    const [type, setType] = useState('');
    const [build, setBuild] = useState(false);
    const [payload, setPayload] = useState('');
    const [showInfo, setShowInfo] = useState('info-func-deploy');
    const [showCode, setShowCode] = useState('opacity-display');
    const [ownBuild, setOwnBuild] = useState(false);
    const [checked, setChecked] = useState(false);
    const [invalidations, setInvalidations] = useState('');
    const [showInvalidations, setShowInvalidations] = useState('display-none');
    const [siteId, setSiteId] = useState(null);

    const handleDeploy = (template) => {
      deployStatic(template, team.id);
      if (stepAppTour === 14) {
        nextStepTour();
        history.push(routes.OVERVIEW);
      }
    };

    const handleFetchInvalidations = (template_id) => {
      fetchInvalidations(habitat.id, template.id)
      if (showInvalidations === 'display-none') {
        setShowInvalidations('')
        setSiteId(template_id)
      } else {
        setShowInvalidations('display-none')
      }
    }

    const handleNewInvalidation = () => {
      newInvalidation(habitat.id, template.id, invalidations)
      setInvalidations('')
    }

    const handleShowInvalidation = (invalidation_id) => {
      getValidation(habitat.id, invalidation_id, template.id)
    }

    const handleCloudFrontStatus = (distribution_status) => {
      cloudFrontStatus(habitat.id, template.id, distribution_status);
    }

    const handleDeploySite = () => {
      ownBuild ?
        buildAndDeployStatic(awsRoles, template, null, habitat.id, team.id)
        :
        buildAndDeployStatic(awsRoles, template, code, habitat.id, team.id)
    };

    const handleShowInfoFunc = (template) => {
      if (showInfo === 'info-func-deploy') {
        setShowInfo('info-func-deploy-show');
        setShowCode('opacity-display-show');
      } else {
        setShowInfo('info-func-deploy');
        setShowCode('opacity-display');
      }

      if (template.buildspec_source) {
        setCode(template.buildspec_source);
      }

    };

    useEffect(() => {
      fetchAWSRole(habitat.id);
    }, []);

    return (
      <div>
        <div className="box-func-deploy" key={template.id}>
          <div className="box-feature-name" onClick={() => handleShowInfoFunc(template)}>
            <div className="box-feature-name-container">
              <div className="box-feature-name-icon-templates-deploy">
                <img src={sitesIcon} />
              </div>
              <div className="func-name-date-deploy">
                <p className="name-func-box">
                  {template.name}
                </p>
                <p className="last-update-func">
                  <span>Last Update</span> {Moment(template.updated_at).format('lll')}
                </p>
                <p className="last-update-func"><span>Domain</span> {template.domain_name}</p>
                <div className="managed-by-repo">
                  <p className="managed-by-text">Managed</p>
                  <div className="avatar-repo-author">
                    <div className="avatar-1">
                      <p>U</p>
                    </div>
                    <div className="name-author">
                      <p>Unknown</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {template.cicd_enable ? (
              <div className="enabled-cicd">
                <div>
                  <img src={iconCheck} />
                  Enabled
                </div>
                <p>CI Status</p>
              </div>
            ):(
              <div className="enabled-cicd-disabled">
              <div>
                Disabled
              </div>
              <p>CI Status</p>
            </div>
            )}



            <div className="runtime-deploy">

              {gitClones.map(clone => (
                clone.project_name === template.name &&
                clone.git_hash === template.git_hash &&
                template.cicd_enable && (
                  <Button
                    key={clone.id}
                    className="button-deploy-releases"
                    onClick={handleDeploySite}
                  >
                    <img src={deployIcon} />
                    Deploy
                  </Button>
                )
              ))}


              {gitClones.map(clone => (
                clone.project_name === template.name &&
                clone.git_hash === template.git_hash &&
                !template.cicd_enable && (
                  <Button
                    key={clone.id}
                    className="button-deploy-releases"
                    onClick={() => handleDeploy(template)}
                  >
                    <img src={deployIcon} />
                    Deploy
                  </Button>
                )
              ))}

              {gitClones.map(clone => (
                clone.project_name === template.name &&
                clone.git_hash !== template.git_hash &&
                template.cicd_enable && template.build_id && (
                  <Button
                    key={clone.id}
                    className="button-deploy-releases"
                    onClick={() => buildAndUpdate(template, habitat.id, clone.git_hash, team.id)}
                  >
                    <img src={deployIcon} />
                    Update
                  </Button>
                )
              ))}


              {gitClones.map(clone => (
                clone.project_name === template.name &&
                clone.git_hash !== template.git_hash &&
                template.cicd_enable && !template.build_id && (
                  <Button
                    key={clone.id}
                    className="button-deploy-releases"
                    onClick={handleDeploySite}
                  >
                    <img src={deployIcon} />
                    Update
                  </Button>
                )
              ))}

              {gitClones.map(clone => (
                clone.id === template.repository_id &&
                clone.git_hash !== template.git_hash &&
                !template.cicd_enable && (
                  <Button
                    key={clone.id}
                    className="button-deploy-releases"
                    onClick={() => updateStatic(template, team, clone.git_hash)}
                  >
                    <img src={deployIcon} />
                    Update
                  </Button>
                )
              ))}

              <Button
                className="button-deploy-releases-details"
               onClick={() =>  handleSelectSite(template)}
              >
                <img src={eyeIcon} />
                View Details
              </Button>
            </div>
          </div>

          <div>



          </div>
        </div>
      </div>
    );
  }
);

const mapState = (state, props) => ({
  index: props.index,
  log: state.awsSdk.log,
  team: state.team.team,
  history: props.history,
  logs: state.awsSdk.logs,
  template: props.template,
  templates: state.templates.templates,
  invoke: state.func.invoke,
  loading: state.func.loading,
  habitat: state.habitat.habitat,
  awsRoles: state.awsRoles.awsRoles,
  gitClones: state.gitClone.gitClones,
  stepAppTour: state.appTour.stepAppTour,
  statusAppTour: state.appTour.stateAppTour,
  errorSites: state.templates.error,
  invalidationsArray: state.templates.invalidations,
  invalidationItem: state.templates.invalidation.invalidations
});

const mapDispatch = {
  stopTour,
  getAwsLog,
  funcInvoke,
  getAwsLogs,
  siteStatus,
  deployStatic,
  fetchAWSRole,
  nextStepTour,
  updateStatic,
  updateFunction,
  allDeployments,
  buildAndUpdate,
  deleteDeployment,
  buildAndDeployStatic,
  cloudFrontStatus,
  fetchInvalidations,
  newInvalidation,
  getValidation
};

export default connect(mapState, mapDispatch)(HabitatTemplateItem);
