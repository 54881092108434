import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import closeIcon from '../../../assets/images/icons/close-icon.svg';
import lambdaIcon from '../../../assets/images/icons/habitats/lambdasIcon.svg';
import HabitatDeploymentInfo from '../HabitatDeploymentInfo';

const HabitatReleasesModal = ({
    setShowModal,
    selectedFunc,
    func,
    varShow,
    lambdaBuildStatus,
    habitat,
    ownBuild,
    showCode,
    code,
    setVarShow,
    fields,
    setFields,
    setOwnBuild,
    handleBuild,
    setCode,
    handleLambdaBuildStatus
}) => {
    return (
        <>
            <div className="modal-kor-small-right">
                <div className="box-modal-options-container-small-right">
                    <div className="close-title" onClick={() => setShowModal(false)}>
                        <img src={closeIcon} />
                        <h3>{selectedFunc.function_name}</h3>
                    </div>
                    <div className="box-feature-name-container funcs-modal">
                        <div className="box-feature-name-icon">
                            <img src={lambdaIcon} />
                        </div>
                        <div className="func-name-date-deploy">
                            <p className="name-func-box">
                                {selectedFunc.function_name}
                            </p>
                            <p className="last-update-func">
                                <span>Last Update</span>{Moment(selectedFunc.created_at).format('lll')}
                            </p>
                            <div className="managed-by-repo">
                                <p className="managed-by-text">Managed</p>
                                <div className="avatar-repo-author">
                                    <div className="avatar-1">
                                        <p>U</p>
                                    </div>
                                    <div className="name-author">
                                        <p>Unknown</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <HabitatDeploymentInfo
                        lambdaBuildStatus={lambdaBuildStatus}
                        handleBuild={handleBuild}
                        setVarShow={setVarShow}
                        setOwnBuild={setOwnBuild}
                        func={selectedFunc}
                        varShow={varShow}
                        habitat={habitat}
                        ownBuild={ownBuild}
                        showCode={showCode}
                        code={code}
                        fields={fields}
                        setFields={setFields}
                        setCode={setCode}
                        handleLambdaBuildStatus={handleLambdaBuildStatus}
                    />
                </div>
            </div>
        </>
    )
}

const mapState = (state, props) => ({

});

const mapDispatch = {

}

export default connect(
    mapState,
    mapDispatch
)(HabitatReleasesModal);