import React from 'react';
import routes from './constants/routes';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

// Pages
import SigninPage from './containers/SigninPage';
import OverviewPage from './containers/OverviewPage';
import NotFoundPage from './containers/NotFoundPage';
import OauthDonePage from './containers/OauthDonePage';
import InternalServerErrorPage from './containers/InternalServerErrorPage';
import ConfirmationPendingPage from './containers/ConfirmationPendingPage';
import SignupPage from './containers/SignupPage';
import SettingsPage from './containers/SettingsPage';
import ResetPasswordPage from './containers/ResetPasswordPage';
import HabitatsPage from './containers/HabitatsPage';
import HabitatDeploymentPage from './containers/HabitatDeploymentPage';
import HabitatStepFuncPage from './containers/HabitatStepFuncPage';
import HabitatLogsPage from './containers/HabitatLogsPage';
import HabitatMonitorPage from './containers/HabitatMonitorPage';
import HabitatSettingsPage from './containers/HabitatSettingsPage';
import HabitatAPIGatewayPage from './containers/HabitatAPIGatewayPage';
import HabitatCostsPage from './containers/HabitatCostsPage';
import FuncsPage from './containers/FuncsPage';
import AccountPage from './containers/AccountPage';
import HelpPage from './containers/HelpPage';
import CheckOutPage from './components/billing/CheckOutPage';
import ResetPasswordForm from './containers/ResetPasswordForm';
import CreaditCardPage from './containers/CreditCardPage';
import TemplatePage from './containers/TemplatePage';
import NewTemplateFormPage from './containers/NewTemplateFormPage';
import AccountConfirmationError from './containers/AccountConfirmationError';
import HabitatCICD from './containers/HabitatCICDPage';
import HabitatAuthPage from './containers/HabitatAuthPage';
import HabitatDBPage from './containers/HabitatDBPage';
import OnboardingPage from './containers/OnboardingPage';
import RepositoriesPage from './containers/RepositoriesPage';
import HabitatMainPage from  './containers/HabitatMainPage';

// Components
import TeamProfile from './components/TeamProfile';
import TeamBilling from './components/TeamBilling';
import TeamMembers from './components/TeamMembers';
import Billing from './components/Billing';
import Notifications from './components/Notifications';
import Teams from './components/Teams';

// Layout Router
import AppLayoutRoute from './layouts/AppLayoutRouter';
import AppTeamLayoutRoute from './layouts/AppTeamLayoutRouter';
import SettingsLayoutRoute from './layouts/SettingsLayoutRouter';
import PublicLayoutRoute from './layouts/PublicLayoutRouter';


require('dotenv').config()
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_API);
    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
}

const App = () => {
  return(
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to={routes.SIGNIN} />
        </Route>

        <PublicLayoutRoute path={routes.SIGNIN} component={SigninPage} />    
        <PublicLayoutRoute path={routes.SIGNUP} component={SignupPage} />        
        <PublicLayoutRoute path={routes.OAUTHPAGEDONE} component={OauthDonePage} />
        <PublicLayoutRoute path={routes['RESET PASSWORD']} component={ResetPasswordPage} />
        <PublicLayoutRoute path={routes['PASSWORD FORM']} component={ResetPasswordForm} />
        <PublicLayoutRoute path={routes.CONFIRMPEND} component={ConfirmationPendingPage} />        

        <AppLayoutRoute path={routes.OVERVIEW} component={OverviewPage} />
        <AppLayoutRoute path={routes.TEAMS} component={Teams} />
        <AppLayoutRoute path={routes.HABITATS} component={HabitatsPage} />
        <AppLayoutRoute path={`${routes.RELEASES}/:habitatId`} component={HabitatDeploymentPage} />
        <AppLayoutRoute path={`${routes.HABITATMAINPAGE}/:habitatId`} component={HabitatMainPage} />
        <AppLayoutRoute path={`${routes['HABITAT LOGS']}/:habitatId`} component={HabitatLogsPage} />
        <AppLayoutRoute path={`${routes['HABITAT SETTINGS']}/:habitatId`} component={HabitatSettingsPage} />
        <AppLayoutRoute path={`${routes['HABITAT STEP FUNCTIONS']}/:habitatId`} component={HabitatStepFuncPage} />
        <AppLayoutRoute path={`${routes['HABITATCICD']}/:habitatId`} component={HabitatCICD} />
        <AppLayoutRoute path={`${routes.HABITAT}/:habitatId`} component={HabitatMonitorPage} />
        <AppLayoutRoute path={`${routes.HABITATCOSTS}/:habitatId`} component={HabitatCostsPage} />
        <AppLayoutRoute path={`${routes.HABITATAUTH}/:habitatId`} component={HabitatAuthPage} />
        <AppLayoutRoute path={`${routes.HABITATDB}/:habitatId`} component={HabitatDBPage} />
        <AppLayoutRoute path={`${routes['HABITAT API GATEWAY']}/:habitatId`} component={HabitatAPIGatewayPage} />
        <AppLayoutRoute path={routes.REPOSITORIES} component={RepositoriesPage} />
        <AppLayoutRoute path={routes.NOTIFICATIONS} component={Notifications} />
        <AppLayoutRoute path={routes.TEMPLATES} component={TemplatePage} />
        <AppLayoutRoute path={routes['TEMPLATE FORM']} component={NewTemplateFormPage} />
        <AppLayoutRoute path={routes['ONBOARDING']} component={OnboardingPage} />
        

        <SettingsLayoutRoute path={routes['CREDIT CARD']} component={CreaditCardPage} />
        <SettingsLayoutRoute path={routes.ACCOUNT} component={AccountPage} />
        <SettingsLayoutRoute path={routes.BILLING} component={Billing} />
        <SettingsLayoutRoute path={routes.HELP} component={HelpPage} />        
        <SettingsLayoutRoute path={routes.CREDENTIALS} component={SettingsPage} />
        <SettingsLayoutRoute path={routes.CHECKOUT} component={CheckOutPage} />


        <AppTeamLayoutRoute path="/TeamProfile/:teamId/:name" component={TeamProfile} />
        <AppTeamLayoutRoute path="/TeamBilling/:teamId/:name" component={TeamBilling} />
        <AppTeamLayoutRoute path="/TeamMembers/:teamId/:name" component={TeamMembers} />
        
        <PublicLayoutRoute path={routes.INTERNALSERVERERROR} component={InternalServerErrorPage} />∫
        <PublicLayoutRoute path={routes.ACCOUNTCONFERROR} component={AccountConfirmationError} />
        <PublicLayoutRoute path="/*" component={NotFoundPage} />


      </Switch>
    </Router>
  )
}

export default App;