import React from 'react';
import TeamCard from './TeamCard';
import { faTimes, faQuestionCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, OverlayTrigger, } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const TeamList = (props) => {
	return (
		<div className="team-page-box">
			<div className="teams-box-title-mark">
				<p><b>Teams</b></p>
			</div>

			{props.teams === 0 ?

				<div className="empty-div">
					No team created yet
			</div>

				:

				<TeamCard
					teamsWithMembers={props.teamsWithMembers}
					teams={props.teams}
					fetchTeam={props.fetchTeam}
					team={props.team}
					unselectTeam={props.unselectTeam}
					fetchMembers={props.fetchMembers}
					handleButtons={props.handleButtons}
					buttonDisabled={props.buttonDisabled}
					setButtonDisabled={props.setButtonDisabled}
					changeSelectedTeam={props.changeSelectedTeam}
					setShow={props.setShow}
					handleGetDetailsTeam={props.handleGetDetailsTeam}
					handleModalDeleteTeam={props.handleModalDeleteTeam}
				/>
			}

			{props.errorTeam && (
				<div className={props.showError + ' alert alert-danger animate__animated animate__fadeIn'} role="alert">
					<p>Team name {props.errorTeam}!</p>
					<div className="icon-close" onClick={props.handleCloseError}>
						<FontAwesomeIcon icon={faTimes} />
					</div>
				</div>
			)}


			{props.teams.length > 0 && props.loading && (
				<div className="spinner-container habitat-loader">
					<Spinner animation="border" variant="primary" />
					<p>{props.loadingMessage}</p>
				</div>
			)}

		</div>
	);
}

export default TeamList;