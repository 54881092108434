import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../assets/styles/components/habitats/HabitatReleases.scss';
import HabitatTopMenu from '../components/Habitats/HabitatTopMenu';
import { listFunctions, deployAll, getTests } from '../actions/funcs.actions';
import HabitatDeployList from '../components/Habitats/HabitatDeployList';
import HabitatTemplatesList from '../components/Habitats/HabitatTemplatesList';
import HabitatDeployListHistory from '../components/Habitats/HabitatDeploymentHistory';
import HabitatRelasesTitle from '../components/Habitats/HabitatReleasesTitle';
import HabitatReleasesModal from '../components/Habitats/modals/HabitatReleasesModal';
import HabitatReleasesTemplatesModal from '../components/Habitats/modals/HabitatReleasesTemplatesModal';
import { getStaticSites } from '../actions/templates.actions';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { retrieveHabitat } from '../actions/habitat.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  lambdaBuildStatus,
  deployAfterBuild,
  updateAndBuild,
  updateFunction,
  funcInvoke,
  buildDeploy,
  deploy,
} from '../actions/funcs.actions';
import {
  siteStatus,
  updateStatic,
  deployStatic,
  buildAndUpdate,
  buildAndDeployStatic,
  fetchInvalidations,
  newInvalidation,
  getValidation,
  cloudFrontStatus
} from '../actions/templates.actions';

const HabitatDeploymentPage = ({
  params,
  habitat,
  getTests,
  templates,
  listFunctions,
  getStaticSites,
  retrieveHabitat,
  loadingTemplate,
  awsRoles,
  lambdaBuildStatus,
  buildDeploy,
  siteStatus,
  updateStatic,
  deployStatic,
  buildAndUpdate,
  buildAndDeployStatic,
  fetchInvalidations,
  newInvalidation,
  getValidation,
  invalidationsArray,
  invalidationItem,
  cloudFrontStatus
}) => {
  useEffect(() => {
    if (habitat.id) {
      listFunctions(habitat.id);
      getStaticSites(habitat.id);
    } else {
      retrieveHabitat(params.habitatId);
      listFunctions(params.habitatId);
      getStaticSites(params.habitatId);
    }
  }, []);
  

  const [featureSelected, setFeatureSelected] = useState('funcs');
  const [showModal, setShowModal] = useState(false);
  const [selectedFunc, setSelectedFunc] = useState('');
  const [code, setCode] = useState('version: 0.2\r\n\r\n#env:\r\n  #variables:\r\n     # key: \"value\"\r\n     # key: \"value\"\r\n  #parameter-store:\r\n     # key: \"value\"\r\n     # key: \"value\"\r\n  #secrets-manager:\r\n     # key: secret-id:json-key:version-stage:version-id\r\n     # key: secret-id:json-key:version-stage:version-id\r\n  #exported-variables:\r\n     # - variable\r\n     # - variable\r\n  #git-credential-helper: yes\r\n#batch:\r\n  #fast-fail: true\r\n  #build-list:\r\n  #build-matrix:\r\n  #build-graph:\r\nphases:\r\n  #install:\r\n    #If you use the Ubuntu standard image 2.0 or later, you must specify runtime-versions.\r\n    #If you specify runtime-versions and use an image other than Ubuntu standard image 2.0, the build fails.\r\n    #runtime-versions:\r\n      # name: version\r\n      # name: version\r\n    #commands:\r\n      # - command\r\n      # - command\r\n  #pre_build:\r\n    #commands:\r\n      # - command\r\n      # - command\r\n  build:\r\n    commands:\r\n      # - command\r\n      # - command\r\n  #post_build:\r\n    #commands:\r\n      # - command\r\n      # - command\r\n#reports:\r\n  #report-name-or-arn:\r\n    #files:\r\n      # - location\r\n      # - location\r\n    #base-directory: location\r\n    #discard-paths: yes\r\n    #file-format: JunitXml | CucumberJson\r\n#artifacts:\r\n  #files:\r\n    # - location\r\n    # - location\r\n  #name: $(date +%Y-%m-%d)\r\n  #discard-paths: yes\r\n  #base-directory: location\r\n#cache:\r\n  #paths:\r\n    # - paths');
  const [fields, setFields] = useState([{ key: '', value: '' }]);
  const [varShow, setVarShow] = useState(false);
  const [showInfo, setShowInfo] = useState('info-func-deploy');
  const [showCode, setShowCode] = useState('opacity-display');
  const [ownBuild, setOwnBuild] = useState(false);

  const [showModalTemplate, setShowModalTemplate] = useState(false);
  const [selectedSite, setSelectedSite] = useState('');
  const [invalidations, setInvalidations] = useState('');
  const [showInvalidations, setShowInvalidations] = useState('display-none');
  const [showInfoT, setShowInfoT] = useState('info-func-deploy');
  const [showCodeT, setShowCodeT] = useState('opacity-display');
  const [ownBuildT, setOwnBuildT] = useState(false);
  const [codeT, setCodeT] = useState(
    'version: 0.2\r\n\r\n#env:\r\n  #variables:\r\n     # key: \"value\"\r\n     # key: \"value\"\r\n  #parameter-store:\r\n     # key: \"value\"\r\n     # key: \"value\"\r\n  #secrets-manager:\r\n     # key: secret-id:json-key:version-stage:version-id\r\n     # key: secret-id:json-key:version-stage:version-id\r\n  #exported-variables:\r\n     # - variable\r\n     # - variable\r\n  #git-credential-helper: yes\r\n#batch:\r\n  #fast-fail: true\r\n  #build-list:\r\n  #build-matrix:\r\n  #build-graph:\r\nphases:\r\n  #install:\r\n    #If you use the Ubuntu standard image 2.0 or later, you must specify runtime-versions.\r\n    #If you specify runtime-versions and use an image other than Ubuntu standard image 2.0, the build fails.\r\n    #runtime-versions:\r\n      # name: version\r\n      # name: version\r\n    #commands:\r\n      # - command\r\n      # - command\r\n  #pre_build:\r\n    #commands:\r\n      # - command\r\n      # - command\r\n  build:\r\n    commands:\r\n      # - command\r\n      # - command\r\n  #post_build:\r\n    #commands:\r\n      # - command\r\n      # - command\r\n#reports:\r\n  #report-name-or-arn:\r\n    #files:\r\n      # - location\r\n      # - location\r\n    #base-directory: location\r\n    #discard-paths: yes\r\n    #file-format: JunitXml | CucumberJson\r\n#artifacts:\r\n  #files:\r\n    # - location\r\n    # - location\r\n  #name: $(date +%Y-%m-%d)\r\n  #discard-paths: yes\r\n  #base-directory: location\r\n#cache:\r\n  #paths:\r\n    # - paths'
  );
  const [siteId, setSiteId] = useState(null);

  const handleChangeActive = (activeFeature) => {
    setFeatureSelected(activeFeature)
  }

  const handleSelectFunc = (func) => {
    setSelectedFunc(func);
    setShowModal(!showModal);
    if (func.buildspec_source) {
      setCode(func.buildspec_source);
    }
  }

  const handleDeploy = (func) => {
    func.cicd_enable ?
      deployAfterBuild(func.id, fields) :
      deploy(func, fields)
    if (stepAppTour === 12) {
      nextStepTour();
      history.push(`${routes.OVERVIEW}/:habitatId`)
    }
  };

  const handleBuild = (func) => {
    if(ownBuild){
      buildDeploy(awsRoles, func, null)
      setShowModal(!showModal);
    }else{
      buildDeploy(awsRoles, func, code)
      setShowModal(!showModal);
    }      
  };

  const handleShowInfoFunc = (func) => {
    if (showInfo === 'info-func-deploy') {
      setShowInfo('info-func-deploy-show');
      setShowCode('opacity-display-show');
    } else {
      setShowInfo('info-func-deploy');
      setShowCode('opacity-display');
    }

    if (func.buildspec_source) {
      setCode(func.buildspec_source);
    }
  };

  const handleLambdaBuildStatus = (funcId, funcCICD, habitatId, func) => {
    lambdaBuildStatus(funcId, funcCICD, habitatId);
    setShowModal(!showModal);
  }

  const handleSelectSite = (site) => {
    setShowModalTemplate(!showModalTemplate);
    if (site.buildspec_source) {
      setCode(site.buildspec_source);
    }
    setSelectedSite(site)
  }

  const handleDeployT = (template) => {
    deployStatic(template, team.id);
    if (stepAppTour === 14) {
      nextStepTour();
      history.push(routes.OVERVIEW);
    }
  };

  const handleFetchInvalidations = (template_id) => {
    fetchInvalidations(habitat.id, selectedSite.id)
    if (showInvalidations === 'display-none') {
      setShowInvalidations('')
      setSiteId(template_id)
    } else {
      setShowInvalidations('display-none')
    }
  }

  const handleNewInvalidation = () => {
    newInvalidation(habitat.id, selectedSite.id, invalidations)
    setInvalidations('')
  }

  const handleShowInvalidation = (invalidation_id) => {
    getValidation(habitat.id, invalidation_id, selectedSite.id)
  }

  const handleCloudFrontStatus = (distribution_status) => {
    cloudFrontStatus(habitat.id, selectedSite.id, distribution_status);
    setShowModalTemplate(!showModalTemplate)
  }

  const handleDeploySite = () => {
    ownBuild ?
      buildAndDeployStatic(awsRoles, template, null, habitat.id, team.id)
      :
      buildAndDeployStatic(awsRoles, template, code, habitat.id, team.id)
  };

  const handleSiteStatus = () => {
    siteStatus(selectedSite.id, habitat.id, !selectedSite.cicd_enable)
    setShowModalTemplate(!showModalTemplate)
  }

  return (
    <div>

      {showModalTemplate && (
        <HabitatReleasesTemplatesModal
          setShowModalTemplate={setShowModalTemplate}
          template={selectedSite}
          habitat={habitat}
          siteStatus={siteStatus}
          ownBuild={ownBuildT}
          templates={templates}
          showCode={showCodeT}
          code={codeT}
          setOwnBuild={setOwnBuildT}
          handleCloudFrontStatus={handleCloudFrontStatus}
          showInvalidations={showInvalidations}
          invalidationsArray={invalidationsArray}
          handleFetchInvalidations={handleFetchInvalidations}
          invalidationItem={invalidationItem}
          invalidations={invalidations}
          setInvalidations={setInvalidations}
          handleNewInvalidation={handleNewInvalidation}
          handleShowInvalidation={handleShowInvalidation}
          handleSiteStatus={handleSiteStatus}
        />
      )}

      {showModal && selectedFunc && (
        <HabitatReleasesModal 
          setShowModal={setShowModal}
          selectedFunc={selectedFunc}
          lambdaBuildStatus={lambdaBuildStatus}
          handleBuild={handleBuild}
          setVarShow={setVarShow}
          setOwnBuild={setOwnBuild}
          varShow={varShow}
          habitat={habitat}
          ownBuild={ownBuild}
          showCode={showCode}
          code={code}
          fields={fields}
          setFields={setFields}
          setCode={setCode}
          handleLambdaBuildStatus={handleLambdaBuildStatus}
        />
      )}
      
      <div className="habitat-container-deployment">
        <div className="habitat-all-funcs-deployment">
          <HabitatRelasesTitle
            handleChangeActive={handleChangeActive}
            featureSelected={featureSelected}
          />

          {featureSelected === 'funcs' && (
            <HabitatDeployList 
              handleSelectFunc={handleSelectFunc}
            />
          )}

          {featureSelected === 'sites' && (
            <HabitatTemplatesList
              setShowModal={setShowModal}
              showModalTemplate={showModalTemplate}
              handleSelectSite={handleSelectSite}
            />
          )}

          {featureSelected === 'history' && (
            <HabitatDeployListHistory
              getTests={getTests}
              templates={templates}
              loadingTemplate={loadingTemplate}

            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapState = (state, props) => ({
  funcs: state.func.funcs,
  params: props.params,
  habitat: state.habitat.habitat,
  gitClones: state.gitClone.gitClones,
  templates: state.templates.templates,
  loadingTemplate: state.templates.loading,
  awsRoles: state.awsRoles.awsRoles,
  invalidationsArray: state.templates.invalidations,
  invalidationItem: state.templates.invalidation.invalidations,
  templates: state.templates.templates,
});

const mapDispatch = {
  getTests,
  deployAll,
  listFunctions,
  getStaticSites,
  retrieveHabitat,
  lambdaBuildStatus,
  buildDeploy,
  siteStatus,
  updateStatic,
  deployStatic,
  buildAndUpdate,
  buildAndDeployStatic,
  fetchInvalidations,
  newInvalidation,
  getValidation,
  cloudFrontStatus
}

export default connect(
  mapState,
  mapDispatch
)(HabitatDeploymentPage);
