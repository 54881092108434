import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

const AddDynamoFields = ({
  hash,
  schema,
  fields,
  tableName,
  setTableName,
  handleAddKey,
  handleAddType,
  handleNewField,
  handleCreateDB,
  handleAddAttribute,
  handleSelectFeatureModal
}) => {
  return (
    <>

      <div className="form-container-dynamo">
      <p className="dynamo-management-title">Table Name</p>
        <div className="new-dynamo-input">
          <p>Table Name</p>
          <Form.Control
            type="text"
            placeholder="Table Name"
            onChange={(e) => setTableName(e.target.value)}
          />
        </div>
       
        {tableName && (
          fields.map((field, index) => (
            <>
              <p className="dynamo-management-title">Attributes</p>
              <div className="attr-container" key={index}>
                <div className="new-dynamo-input">
                  <p>Attribute Name</p>
                  <Form.Control
                    type="text"
                    custom
                    placeholder="Attrribute Name"
                    onChange={(e) => handleAddAttribute(e, index)}
                  />
                </div>
                <div className="new-dynamo-input">
                  <p>Type</p>
                  <Form.Control
                    as="select"
                    custom
                    onChange={(e) => handleAddType(e, index)}
                  >
                    <option>Select type</option>
                    <option value="S">String</option>
                    <option value="N">Number</option>
                    <option value="B">Binary</option>
                  </Form.Control>
                </div>
                <div className="new-dynamo-input">
                    <p>Key</p>
                    <Form.Control
                    as="select"
                    custom
                    onChange={(e) => handleAddKey(e, index)}
                    disabled={schema}
                  >
                    <option>Select Key</option>
                    <option disabled={hash}>HASH</option>
                    <option>RANGE</option>
                  </Form.Control>
                </div>
              </div>
            </>
          ))
        )}
        <div className="button-add-fields">
          {tableName && fields.length < 2 && (
            <Button disabled={fields.length === 2} variant="primary" onClick={handleNewField}> + Add Field</Button>
          )}
        </div>

        
        <div className="button-next-modal">
                <Button onClick={() => handleSelectFeatureModal('')}>
                    Back to resources
                </Button>
                <Button onClick={handleCreateDB}>
                    Create
                </Button>
            </div>
      </div>
    </>
  );
}

export default AddDynamoFields;