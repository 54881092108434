import React  from 'react';
import '../../assets/styles/modals/ModalKorSmallRight.scss'
import HabitatNewAPIGateway from '../Habitats/HabitatNewAPIGateway';
import HabitatInfoApiGateway from '../Habitats/HabitatInfoApiGateway';

const APIGatewayModal = ({
  team,
  show,
  funcs,
  apiTest,
  testApi, 
  setShow,
  habitat,
  methods,
  awsCreds,
  cognitos,
  awsRoles,
  modalType,
  createAPI,
  deleteApi,
  s3Buckets,
  createRoute,
  selectedAPI,
  deleteMethod,
  createCrudApi,
  createCognitoAPI,
  createCognitoCrudApi
}) => {

  return (
    <>
      {show && (
        <div className="modal-kor-small-right">
          <div className="box-modal-options-container-small-right">
            {modalType === 'new' && (
              <HabitatNewAPIGateway
                team={team} 
                show={show}
                setShow={setShow}
                habitat={habitat}
                cognitos={cognitos}
                awsCreds={awsCreds}
                awsRoles={awsRoles}
                s3Buckets={s3Buckets}
                createAPI={createAPI}
                createCrudApi={createCrudApi}
                createCognitoAPI={createCognitoAPI}
                createCognitoCrudApi={createCognitoCrudApi}
              />
            )}
            {modalType === 'info' && (
              <HabitatInfoApiGateway
                show={show}
                funcs={funcs}
                apiTest={apiTest}
                testApi={testApi}
                setShow={setShow}
                methods={methods}
                habitat={habitat}
                awsRoles={awsRoles}
                awsCreds={awsCreds}
                deleteApi={deleteApi}
                createRoute={createRoute} 
                selectedAPI={selectedAPI}
                deleteMethod={deleteMethod}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default APIGatewayModal;