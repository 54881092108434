import React, { useState } from 'react';
import closeIcon from '../../../assets/images/icons/close-icon.svg';
import storageIcon from '../../../assets/images/icons/habitats/storageIcon.svg';
import Button from 'react-bootstrap/Button';
import trash from '../../../assets/images/icons/trash.svg';
import { Table } from 'react-bootstrap';

const ManageS3Bucket = (props) => {
    return (
        <>
            <div className="modal-kor-small-right">
                <div className="box-modal-options-container-small-right">
                    <div className="close-title" onClick={() => props.setShowFilesModal(false)}>
                        <img src={closeIcon} />
                        <h3>{props.s3BucketName}</h3>
                    </div>

                    <div className="modal-manage-s3-buckets">
                        <div className="icon-storage-box">
                            <div className="img-box-storage">
                                <img src={storageIcon} />
                            </div>
                            <div className="info-storage-bucket">
                                <p className="name-storage-box">{props.s3BucketName}</p>
                                <p className="last-updated-text"><span>Created On</span> </p>
                                <div className="managed-by-repo">
                                    <p className="managed-by-text">Managed</p>
                                    <div className="avatar-repo-author">
                                        <div className="avatar-1">
                                            <p>U</p>
                                        </div>
                                        <div className="name-author">
                                            <p>Unknown</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="files-container-storage">
                            <div className="title-button-clear">
                                <p>Files: {props.quantity}</p>
                                <Button onClick={() => props.handleClearBucket(props.s3BucketName)}>
                                    <img src={trash}  />
                                    Clear Bucket
                                </Button>
                            </div>
                        </div>

                        {props.files.length === 0 && props.quantity === 0 ? (
                            <>
                            <Table>
                                <thead>
                                    <tr>
                                        <th scope="col">FILE NAME</th>
                                    </tr>
                                </thead>
                            </Table>

                            <div className="empty-bucket">
                                <p>Bucket is empty</p>
                            </div>
                            </>
                        ) : (
                            <>
                            <Table>
                                <thead>
                                    <tr>
                                        <th scope="col">FILE NAME</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.files.map((file, index) => (
                                        <tr key={index}>
                                            <td>{file}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <p><b>*This files works as reference.</b></p>
                            </>
                        )}


                    </div>


                </div>
            </div>
        </>
    )
}

export default ManageS3Bucket;