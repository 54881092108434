import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import resources from '../../assets/images/icons/habitats/resources.svg'
import status from '../../assets/images/icons/habitats/status.svg'

const HabitatResourcesMenu = (props) => {
    return (
        <div className="habitat-resource-menu-container">
            <div className="title-sections-menu">
                <p>{props.habitatSetUp}</p>

                {props.habitatSetUp === "Monitor" && (
                    <Button onClick={() => props.handleSelectFeature('All Resources')}>
                        <img src={resources} /> All Resources
                    </Button>
                )}

                {props.habitatSetUp === "Releases" && (
                    <Button onClick={() => props.handleSelectFeature('All Resources')}>
                        <img src={resources} /> All Resources
                    </Button>
                )}

                {props.habitatSetUp === "Logs" && (
                    <Button onClick={() => props.handleSelectFeature('All Resources')}>
                        <img src={resources} /> All Resources
                    </Button>
                )}

                {props.habitatSetUp === "Costs" && (
                    <Button onClick={() => props.handleSelectFeature('All Resources')}>
                        <img src={resources} /> All Resources
                    </Button>
                )}

                {props.habitatSetUp === "Step Functions" && (
                    <Button onClick={() => props.handleSelectFeature('All Resources')}>
                        <img src={resources} /> All Resources
                    </Button>
                )}

                {props.habitatSetUp === "Habitat Setup" && (
                    <Button onClick={() => props.handleSelectFeature('All Resources')}>
                        <img src={resources} /> All Resources
                    </Button>
                )}

                {props.habitatSetUp === "Lambdas" && (
                    <Button onClick={() => props.handleSelectFeature('All Resources')}>
                        <img src={resources} /> All Resources
                    </Button>
                )}

                {props.habitatSetUp === "Storage" && (
                    <Button onClick={() => props.handleSelectFeature('All Resources')}>
                        <img src={resources} /> All Resources
                    </Button>
                )}

                {props.habitatSetUp === "Sites" && (
                    <Button onClick={() => props.handleSelectFeature('All Resources')}>
                        <img src={resources} /> All Resources
                    </Button>
                )}

                {props.habitatSetUp === "APIs" && (
                    <Button onClick={() => props.handleSelectFeature('All Resources')}>
                        <img src={resources} /> All Resources
                    </Button>
                )}

                {props.habitatSetUp === "Databases" && (
                    <Button onClick={() => props.handleSelectFeature('All Resources')}>
                        <img src={resources} /> All Resources
                    </Button>
                )}

                {props.habitatSetUp === "Auth" && (
                    <Button onClick={() => props.handleSelectFeature('All Resources')}>
                        <img src={resources} /> All Resources
                    </Button>
                )}

                {props.habitatSetUp === "Instances" && (
                    <Button onClick={() => props.handleSelectFeature('All Resources')}>
                        <img src={resources} /> All Resources
                    </Button>
                )}

                {props.habitatSetUp === "All Resources" && (
                    <Button onClick={() => props.handleSelectFeature('Monitor')}>
                        <img src={status} /> Status Dashboard
                    </Button>
                )}



            </div>
        </div>
    )
}

export default HabitatResourcesMenu;