import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNetworkWired, faFill, faDatabase, faInfinity, faFileCode, faUserFriends, faLayerGroup, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import lambdasIcon from '../../assets/images/icons/habitats/lambdasIcon.svg'
import storageIcon from '../../assets/images/icons/habitats/storageIcon.svg'
import sitesIcon from '../../assets/images/icons/habitats/sitesIcon.svg';
import databaseIcon from '../../assets/images/icons/habitats/databaseIcon.svg';
import apiIcon from '../../assets/images/icons/habitats/apiIcon.svg'
import usersIcon from '../../assets/images/icons/habitats/usersIcon.svg';
import Button from 'react-bootstrap/Button';
import eye from '../../assets/images/icons/eye-open.svg';

const HabitatResourceReport = (props) => {
    return (
        <div className="resources-report-container">
            <div className="report-container">
                {props.resources.length > 0 ? (
                    <div className="resources-list-container">
                        {props.resources.map(resource => (
                            <div className="resource-box" key={resource.id}>
                                <div className="icon-resource">
                                    {resource.name === 'Functions' && (
                                        <div className="lambdas-box">
                                            <img src={lambdasIcon} />
                                        </div>
                                    )}

                                    {resource.name === 'File Storage' && (
                                        <div className="buckets-box">
                                            <img src={storageIcon} />
                                        </div>
                                    )}


                                    {resource.name === 'Databases' && (
                                        <div className="dynamos-box">
                                            <img src={databaseIcon} />
                                        </div>
                                    )}


                                    {resource.name === 'Code Builds' && (
                                        <div className="builds-box">
                                            <FontAwesomeIcon icon={faInfinity} />
                                        </div>
                                    )}

                                    {resource.name === 'APIs' && (
                                        <div className="api-box">
                                            <img src={apiIcon} />
                                        </div>
                                    )}


                                    {resource.name === 'Auth' && (
                                        <div className="cognito-box">
                                            <img src={usersIcon} />
                                        </div>
                                    )}


                                    {resource.name === 'Sites' && (
                                        <div className="sites-box">
                                            <img src={sitesIcon} />
                                        </div>
                                    )}
                                    <div className="name-updated-at">
                                        <p>{resource.name}</p>
                                        <p><span>Last Updated</span> Oct 23, 2020 4:09 PM</p>
                                    </div>
                                </div>
                                <div className="number-resource">
                                    <p>{resource.total}</p>
                                    <p>Total</p>
                                </div>
                                <div className="button-view-details">
                                    <Button onClick={() => props.handleSelectFeature(resource.name)}>
                                        <img src={eye} /> View Details
                                    </Button>

                                </div>
                            </div>
                        ))}

                    </div>
                ) : (
                    <div className="resources-list-container">
                        <div className="resource-box">
                            <div className="icon-resource">
                                <div className="lambdas-box">
                                    <img src={lambdasIcon} />
                                </div>
                                <div className="name-updated-at">
                                    <p>Lambdas</p>
                                    <p><span></span> No Resources created yet</p>
                                </div>
                            </div>
                            <div className="number-resource">
                                <p>0</p>
                                <p>Total</p>
                            </div>
                            <div className="button-view-details">
                                <Button onClick={() => props.handleSelectFeature('Lambdas')}>
                                    <img src={eye} /> View Details
                                </Button>
                            </div>
                        </div>

                        <div className="resource-box">
                            <div className="icon-resource">
                                <div className="buckets-box">
                                    <img src={storageIcon} />
                                </div>
                                <div className="name-updated-at">
                                    <p>Storage</p>
                                    <p><span></span> No Resources created yet</p>
                                </div>
                            </div>
                            <div className="number-resource">
                                <p>0</p>
                                <p>Total</p>
                            </div>
                            <div className="button-view-details">
                                <Button onClick={() => props.handleSelectFeature('Storage')}>
                                    <img src={eye} /> View Details
                                </Button>
                            </div>
                        </div>

                        <div className="resource-box">
                            <div className="icon-resource">
                                <div className="dynamos-box">
                                    <img src={databaseIcon} />
                                </div>
                                <div className="name-updated-at">
                                    <p>Databases</p>
                                    <p><span></span> No Resources created yet</p>
                                </div>
                            </div>
                            <div className="number-resource">
                                <p>0</p>
                                <p>Total</p>
                            </div>
                            <div className="button-view-details">
                                <Button onClick={() => props.handleSelectFeature('Databases')}>
                                    <img src={eye} /> View Details
                                </Button>
                            </div>
                        </div>

                        <div className="resource-box">
                            <div className="icon-resource">
                                <div className="builds-box">
                                    <FontAwesomeIcon icon={faInfinity} />
                                </div>
                                <div className="name-updated-at">
                                    <p>Code Builds</p>
                                    <p><span></span> No Resources created yet</p>
                                </div>
                            </div>
                            <div className="number-resource">
                                <p>0</p>
                                <p>Total</p>
                            </div>
                            <div className="button-view-details">
                                <Button onClick={() => props.handleSelectFeature('Storage')}>
                                    <img src={eye} /> View Details
                                </Button>
                            </div>
                        </div>

                        <div className="resource-box">
                            <div className="icon-resource">
                                <div className="api-box">
                                    <img src={apiIcon} />
                                </div>
                                <div className="name-updated-at">
                                    <p>APIs</p>
                                    <p><span></span> No Resources created yet</p>
                                </div>
                            </div>
                            <div className="number-resource">
                                <p>0</p>
                                <p>Total</p>
                            </div>
                            <div className="button-view-details">
                                <Button onClick={() => props.handleSelectFeature('APIs')}>
                                    <img src={eye} /> View Details
                                </Button>
                            </div>
                        </div>

                        <div className="resource-box">
                            <div className="icon-resource">
                                <div className="cognito-box">
                                    <img src={usersIcon} />
                                </div>
                                <div className="name-updated-at">
                                    <p>Auth</p>
                                    <p><span></span> No Resources created yet</p>
                                </div>
                            </div>
                            <div className="number-resource">
                                <p>0</p>
                                <p>Total</p>
                            </div>
                            <div className="button-view-details">
                                <Button onClick={() => props.handleSelectFeature('Auth')}>
                                    <img src={eye} /> View Details
                                </Button>
                            </div>
                        </div>

                        <div className="resource-box">
                            <div className="icon-resource">
                                <div className="sites-box">
                                    <img src={sitesIcon} />
                                </div>
                                <div className="name-updated-at">
                                    <p>Sites</p>
                                    <p><span></span> No Resources created yet</p>
                                </div>
                            </div>
                            <div className="number-resource">
                                <p>0</p>
                                <p>Total</p>
                            </div>
                            <div className="button-view-details">
                                <Button onClick={() => props.handleSelectFeature('Sites')}>
                                    <img src={eye} /> View Details
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

            </div>

        </div>
    )
}

export default HabitatResourceReport;