import React from 'react';
import { Row, Col } from 'react-bootstrap';

const HabitatApiEndpoints = ({
  Trash,
  methods,
  habitat,
  awsCreds,
  setSection,
  selectedAPI,
  deleteMethod
}) => {
  return (
    <>
      <Row>
        <Col xs="12" className="mt-5">
          <h5 className="font-weight-bold">Endpoints</h5>
        </Col>
      </Row>
      <Row className="mt-3 border-bottom text-center">
        <Col xs={1}>METHOD</Col>
        <Col xs={7}>ENDPOINTS</Col>
        <Col xs={3}></Col>
      </Row>
      {methods && methods.map(method => (
        <Row key={method.id} className="border-bottom text-center">
          <Col xs={1} className="mt-2 mb-2">{method.method}</Col>
          <Col xs={7} className="mt-2 mb-2">
            {`https://${selectedAPI.api_id}.execute-api.${awsCreds.region}.amazonaws.com/production${method.resource}`}
          </Col>
          <Col xs={3} className="mt-3 mb-2">
            <button className="btn btn-secondary btn-sm" onClick={() => deleteMethod(method.id, habitat.id)}>
              <img src={Trash} />
            </button>
          </Col>
        </Row>
      ))}
      <div className="d-flex justify-content-around mt-5 pt-5">
        <button 
          type="button" 
          onClick={() => setSection('test')}
          className="btn btn-outline-primary" 
        >TEST</button>
        <button 
          type="button"
          className="btn btn-primary"
          onClick={() => setSection('methods')} 
        >CREATE ENDPOINT</button>
      </div>
    </>
  );
}

export default HabitatApiEndpoints;